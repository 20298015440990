export interface Candidates {
  candidates: [];
}

export interface CandidateTags {
  candidateTags: [];
}

export const SELECT_CANDIDATES = 'SELECT_CANDIDATES';
export const UN_SELECT_CANDIDATES = 'UN_SELECT_CANDIDATES';
export const CLEAR_SELECT_CANDIDATES = 'CLEAR_SELECT_CANDIDATES';
export const CLEAR_SELECT_TAGS = 'CLEAR_SELECT_TAGS';
export const GET_CANDIDATE_TAGS = 'GET_CANDIDATE_TAGS';

interface selectCandidates {
  type: typeof SELECT_CANDIDATES;
  candidates: Array<Object>;
}

interface unSelectCandidates {
  type: typeof UN_SELECT_CANDIDATES;
  candidates: Array<Object>;
}

interface clearSelectCandidates {
  type: typeof CLEAR_SELECT_CANDIDATES;
  candidates: Array<Object>;
}

interface clearSelectTags {
  type: typeof CLEAR_SELECT_TAGS;
  candidateTags: Array<Object>;
}

interface getCandidateTags {
  type: typeof GET_CANDIDATE_TAGS;
  candidateTags: Array<Object>;
}

export type candidatesActionTypes =
  | selectCandidates
  | unSelectCandidates
  | clearSelectCandidates
  | getCandidateTags
  | clearSelectTags;
