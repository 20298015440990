/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import Highlighter from 'react-highlight-words';
import PageTitle from '../../../../components/UI/PageTitle/PageTitle';
import { Link } from 'react-router-dom';

import {
  Row,
  Col,
  Tabs,
  Card,
  Table,
  Input,
  Button,
  Space,
  Tooltip,
  Switch,
  Popconfirm,
  Tag,
  Skeleton,
} from 'antd';
import {
  FolderOpenOutlined,
  DeleteOutlined,
  SearchOutlined,
  UserOutlined,
  EditOutlined,
  LoginOutlined,
} from '@ant-design/icons';
import { useRouteMatch } from 'react-router-dom';

import { Bounce, Fade } from 'react-awesome-reveal';
import ErrorCard from '../../../../components/UI/ErrorCard/ErrorCard';
import { generateUsers } from '../../../../faker/users';
import CompanyDrawer from '../../../../components/BackOffice/CompanyDrawer';
// import JobDrawer from '../partials/JobDrawer';
import { ColumnsType } from 'antd/es/table';
import { useGetAllRecruitersInBoQuery } from '../../../../graphql/generated/graphql';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { StoreRecruiters } from 'store/recruiters/actions';
import { RecruitersState } from 'store/recruiters/types';

interface Recruiters {
  _id: string;
  fullName: string;
  profilePic: string;
  user: {
    roles: string;
    email: string;
    title: string;
    verified: boolean;
    subscriptionPlanId: string;
    token: string;
  };
  title: string;
  verified: boolean;
  companiesCount: number;
  jobsCount: number;
  createdAt: string;
}

const { TabPane } = Tabs;

const RecruitersList: React.FC = () => {
  const dispatch = useDispatch();
  const { url } = useRouteMatch();

  // const [loading, setLoading] = useState(true);
  const [fakeData, setFakeData] = useState<any>({
    recruiterRecord: [],
  });
  const [searchText, setSearchText] = useState('');
  const [displayCompanyDrawer, setDisplayCompanyDrawer] = useState(false);
  const [displayJobDrawer, setDisplayJobDrawer] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState('');
  const [selectedRecruiter, setSelectedRecruiter] = useState(null);

  const { data, loading, error }: any = useGetAllRecruitersInBoQuery({
    fetchPolicy: 'network-only',
  });
  const [activeRecruiters, setActiveRecruiters]: any = useState([]);
  const [archivedRecruiters, setArchivedRecruiters]: any = useState([]);

  const recruiters = useSelector(
    (state: RecruitersState) => state.recruiters?.recruiters
  );

  let searchInput: any;

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: any, record: any) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
    render: (text: any) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
    setSearchText('');
  };

  // const setDisplayCompanyDrawerHandler = (recruiter: any) => {
  //   setSelectedRecruiter(recruiter);
  // };

  const companiesHandler = (companiesCount: any, row: any) => {
    return (
      <Tooltip title={companiesCount}>
        <Button
          className="ant-btn-light"
          shape="circle"
          // onClick={() => setDisplayCompanyDrawerHandler(row)}
          icon={<UserOutlined />}
        />
      </Tooltip>
    );
  };

  const careerPageHandler = (id: any) => {
    return (
      <Tooltip title="Activate career page">
        <Switch />
      </Tooltip>
    );
  };

  const actionsHandler = (record: any) => {
    return (
      <Space>
        <Tooltip title="edit">
          <Button
            className="border-primary"
            shape="circle"
            icon={<EditOutlined className="text-primary" />}
          />
        </Tooltip>
        <Tooltip title="archive">
          <Popconfirm
            title="Are you sure archive this recruiter?"
            okText="Yes"
            cancelText="No"
          >
            <Button className="border-amber" shape="circle">
              <i className="dripicons-archive text-amber" />
            </Button>
          </Popconfirm>
        </Tooltip>
        <Popconfirm
          title="Are you sure delete this recruiter?"
          okText="Yes"
          cancelText="No"
        >
          <Button
            className="border-burgundy"
            shape="circle"
            icon={<DeleteOutlined className="text-burgundy" />}
          />
        </Popconfirm>
        <Tooltip title="Reset password">
          <Link to={{ pathname: `recruiters/${record.id}/resetPassword` }}>
            <Button
              className="border-burgundy"
              shape="circle"
              icon={<LoginOutlined className="text-burgundy" />}
            />
          </Link>
        </Tooltip>
      </Space>
    );
  };

  const jobsHandler = (jobsCount: any) => {
    return (
      <Tooltip title={jobsCount}>
        <Button
          className="ant-btn-teal"
          shape="circle"
          onClick={() => setDisplayJobDrawer(true)}
        >
          {jobsCount}
        </Button>
      </Tooltip>
    );
  };

  const renderRoles = (roles: any) => {
    return (
      <>
        {roles.map((role: any, index: number) => (
          <Tag key={index} color="blue">
            {role}
          </Tag>
        ))}
      </>
    );
  };

  const generateActiveRecruitersTableData = () => {
    if (activeRecruiters) {
      const recruitersData = activeRecruiters?.map((recruiter: any) => {
        return {
          key: recruiter._id,
          id: recruiter._id,
          fullName: recruiter.fullName,
          link: (
            <Link
              to={`${url}/recruiters/${recruiter._id}`}
            >{`${recruiter.fullName}`}</Link>
          ),
          title: recruiter.title,
          jobsCount: recruiter.jobsCount,
          companiesCount: recruiter.companiesCount,
          email: recruiter.user.email,
          roles: recruiter.user.roles,
          createdAt: moment(parseInt(recruiter.createdAt)).format(
            'MMMM DD,  YYYY'
          ),
        };
      });
      console.log('***** ', recruitersData);
      return recruitersData;
    }
  };

  const generateArchiveRecruitersTableData = () => {
    if (archivedRecruiters) {
      const recruitersData = archivedRecruiters?.map((recruiter: any) => {
        return {
          key: recruiter._id,
          id: recruiter._id,
          fullName: recruiter.fullName,
          link: (
            <Link
              to={`${url}/candidates/${recruiter._id}`}
            >{`${recruiter.fullName}`}</Link>
          ),
          title: recruiter.title,
          jobsCount: recruiter.jobsCount,
          companiesCount: recruiter.companiesCount,
          email: recruiter.user.email,
          roles: recruiter.user.roles,
          createdAt: moment(parseInt(recruiter.createdAt)).format(
            'MMMM DD,  YYYY'
          ),
        };
      });
      console.log('***** ', recruitersData);
      return recruitersData;
    }
  };

  const columns: ColumnsType<Recruiters> = [
    {
      title: 'Name',
      dataIndex: 'link',
      key: 'link',
      fixed: 'left',
      width: 200,
      showSorterTooltip: false,
      sorter: (a: any, b: any) => {
        console.log(a);
        return a.fullName.localeCompare(b.fullName);
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'ascend',
      ...getColumnSearchProps('link'),
    },
    {
      title: 'Email address',
      dataIndex: 'email',
      key: 'email',
      width: 250,
      ...getColumnSearchProps('email'),
    },
    // {
    //   title: 'Companies',
    //   dataIndex: 'companiesCount',
    //   width: 100,
    //   render: (companiesCount, row) => companiesHandler(companiesCount, row),

    //   // render: recruiters,
    // },
    // {
    //   title: 'Jobs',
    //   dataIndex: 'jobsCount',
    //   width: 80,
    //   render: (jobsCount, row) => jobsHandler(jobsCount),
    // },

    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      ...getColumnSearchProps('title'),
    },

    {
      title: 'Role',
      dataIndex: 'roles',
      key: 'roles',
      render: renderRoles,
    },
    {
      title: 'Created on',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Action',
      key: 'id',
      fixed: 'right',
      width: 160,
      render: actionsHandler,
    },
  ];

  let displayContent: any;

  displayContent = (
    <Col span={24}>
      <Bounce>
        <Card>
          <Skeleton active paragraph={{ rows: 7 }} />
        </Card>
      </Bounce>
    </Col>
  );

  if (!loading && error) {
    console.log(error);
    displayContent = (
      <Col span={12}>
        <ErrorCard />
      </Col>
    );
  }
  if (!loading && data) {
    dispatch(StoreRecruiters(data.getAllRecruitersInBO));

    const activeRecruitersData: any = generateActiveRecruitersTableData() || [];

    displayContent = (
      <Table
        className="table-striped"
        columns={columns}
        dataSource={activeRecruitersData}
        loading={loading}
        size="small"
        pagination={{ pageSize: 20 }}
        scroll={{ x: 1300 }}
      />
    );
  }

  useEffect(() => {
    if (recruiters) {
      console.log('recruiters ==> ', recruiters);
      const activerecruiters = recruiters.filter(
        (job: any) => job.status == 'active'
      );
      const archivedrecruiters = recruiters.filter(
        (job: any) => job.status == 'archive'
      );
      setActiveRecruiters(activerecruiters);
      setArchivedRecruiters(archivedrecruiters);

      console.log('Active : ', activerecruiters);
      console.log('Archive: ', archivedrecruiters);
    }
  }, [recruiters]);

  return (
    <>
      <PageTitle
        back
        title="List of recruiters"
        btnText="Add recruiter"
        to={`${url}/add`}
        btnActive={true}
      />
      <Row justify="center" className="pb-4" gutter={16}>
        <Col span={24}>
          <Fade>
            <Card>
              <Tabs defaultActiveKey="1">
                <TabPane
                  tab={
                    <span>
                      <FolderOpenOutlined /> Active recruiters
                      <span className="tab-pane-count">
                        {activeRecruiters.length}
                      </span>
                    </span>
                  }
                  key="1"
                >
                  {displayContent}
                </TabPane>
                <TabPane
                  tab={
                    <span>
                      <DeleteOutlined /> Archived recruiters
                      <span className="tab-pane-count">
                        {archivedRecruiters.length}
                      </span>
                    </span>
                  }
                  key="2"
                >
                  <Table<Recruiters>
                    columns={columns}
                    bordered={true}
                    dataSource={generateArchiveRecruitersTableData()}
                    size="small"
                    pagination={{ pageSize: 10 }}
                    scroll={{ x: 1300 }}
                    className="table-striped"
                  />
                </TabPane>
              </Tabs>
            </Card>
          </Fade>
        </Col>
      </Row>
      {/* {
      selectedCompany && 
        <RecruiterDrawer
          company={selectedCompany}
          recruiters={selectedCompany?.users}
          setRecruitersListHandler={setRecruitersList}
          show={displayRecruiterDrawer}
          hide={() => setDisplayRecruiterDrawer(false)}
        />
      } */}
      {/* <JobDrawer
        show={displayJobDrawer}
        hide={() => setDisplayJobDrawer(false)}
      /> */}
    </>
  );
};

export default RecruitersList;
