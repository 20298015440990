import { useState, useEffect } from 'react';
import { useGetRecruitersQuery } from '../graphql/generated/graphql';

const useRecruiters = () => {
  const [recruiters, setRecruiters]: any = useState([]);
  const { data, loading, error } = useGetRecruitersQuery();

  const loadrecruiters = () => {
    if (!loading && data) {
      setRecruiters([...data.allRecruiters]);
    }
  };

  useEffect(() => {
    loadrecruiters();
  }, [data]);

  return recruiters;
};

export default useRecruiters;
