import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import thunkMiddleware from 'redux-thunk';

import authReducer from './auth/reducers';
import companiesReducer from './companies/reducers';
import jobsReducer from './jobs/reducers';
import matchsReducer from './matchs/reducers';
import httpReducer from './http/reducers';
import generalReducers from './settings/reducers';
import recruitersReducer from './recruiters/reducers';
import skillsReducer from './skills/reducers';
import { candidatesReducer, candidateTagsReducer } from './candidates/reducers';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  auth: authReducer,
  companies: companiesReducer,
  recruiters: recruitersReducer,
  jobs: jobsReducer,
  matchs: matchsReducer,
  skills: skillsReducer,
  http: httpReducer,
  settings: generalReducers,
  candidates: candidatesReducer,
  candidateTags: candidateTagsReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'companies',
    'jobs',
    'matchs',
    'recruiters',
    'skills',
    'candidates',
    'candidateTags',
  ], // which reducer want to store
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export type AppState = ReturnType<typeof rootReducer>;

export default function configureStore() {
  const middlewares = [thunkMiddleware];
  const middleWareEnhancer = applyMiddleware(...middlewares);

  const store = createStore(
    persistedReducer,
    composeEnhancers(middleWareEnhancer)
  );
  const persistor = persistStore(store);

  return { store, persistor };
}
