import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import RecoverPasswordForm from './partials/RecoverPasswordForm';
import RecoveryCodeForm from './partials/RecoveryCodeForm';
import ResetPasswordForm from './partials/ResetPasswordForm';
import ResetPasswordSuccess from './partials/ResetPasswordSuccess';

import Logo from '../../../components/UI/Logo/Logo';
import Footer from '../../../components/Common/Footer/Footer';
import {
  Card,
  Row,
  Col,
  Form,
  Input,
  Button,
  Checkbox,
  notification,
} from 'antd';
import { Affix } from 'antd';
import { AuthState } from '../../../store/auth/types';
import { HttpState } from '../../../store/http/types';
import './recoverPassword.scss';
import { useForgetPasswordMutation } from 'graphql/generated/graphql';
import { useVerifyResetCodeMutation } from 'graphql/generated/graphql';
import { useGenericResetPasswordMutation } from 'graphql/generated/graphql';

const RecoverPassword: React.FC = () => {
  const isAuthenticated = useSelector(
    (state: AuthState) => state.auth.accessToken !== null
  );
  const [hasError, setHasError] = useState(false);
  const [forgetPasswordMutation] = useForgetPasswordMutation();
  const [verifyResetCodeMutation] = useVerifyResetCodeMutation();
  const [genericResetPasswordMutation] = useGenericResetPasswordMutation();
  const [resetSuccess, setResetSuccess] = useState(false);
  const [manageScreen, setManageScreen] = useState(
    localStorage.getItem('fpsDisplay')
  );
  const setResentCode = () => {
    if (
      localStorage.getItem('email') != '' ||
      localStorage.getItem('email') != undefined
    ) {
      forgetPasswordMutation({
        variables: {
          email: localStorage.getItem('email')!,
        },
      })
        .then(({ data }) => {
          if (data?.forgetPassword?.status == 200) {
            localStorage.setItem('fpsDisplay', 'recoveryCode');
            setManageScreen(localStorage.getItem('fpsDisplay'));
          }
        })
        .catch((err) => {
          const errors = err.graphQLErrors.map((el: any) => el.message);
          notification['error']({
            message: 'Not a regitered Email!',
            description: errors.map((er: any) => <div key={er}>{er}</div>),
            duration: 3,
            onClose: () => setHasError(false),
          });
        });
    }
    // localStorage.setItem('fpsDisplay', 'recoveryCode');
    setManageScreen(localStorage.getItem('fpsDisplay'));
  };
  const { data, error, loading }: any = useSelector(
    (state: HttpState) => state.http
  );
  const authRedirectPath = useSelector(
    (state: AuthState) => state.auth.authRedirectPath
  );

  const dispatch = useDispatch();

  const onRecoverPasswordHandler = (values: any) => {
    if (values?.emailAddress) {
      forgetPasswordMutation({
        variables: {
          email: values?.emailAddress,
        },
      })
        .then(({ data }) => {
          if (data?.forgetPassword?.status == 200) {
            localStorage.setItem('email', values.emailAddress);
            localStorage.setItem('fpsDisplay', 'recoveryCode');
            setManageScreen(localStorage.getItem('fpsDisplay'));
          }
        })
        .catch((err) => {
          const errors = err.graphQLErrors.map((el: any) => el.message);
          notification['error']({
            message: 'Not a regitered Email!',
            description: errors.map((er: any) => <div key={er}>{er}</div>),
            duration: 3,
            onClose: () => setHasError(false),
          });
        });
    }
    // localStorage.setItem('fpsDisplay', 'recoveryCode');
    setManageScreen(localStorage.getItem('fpsDisplay'));
  };

  const onValidateCodeHandler = (values: any) => {
    if (values.recoveryCode) {
      const userEmail = localStorage.getItem('email')!;
      verifyResetCodeMutation({
        variables: {
          email: userEmail,
          resetCode: values.recoveryCode,
        },
      })
        .then(({ data }: any) => {
          if (data?.verifyResetCode?.status == 200) {
            // localStorage.setItem("email",data?.verifyResetCode?.email)
            localStorage.setItem('code', data?.verifyResetCode?.code);
            localStorage.setItem('fpsDisplay', 'resetPassword');
            setManageScreen(localStorage.getItem('fpsDisplay'));
          }
        })
        .catch((err: any) => {
          const errors = err.graphQLErrors.map((el: any) => el.message);
          console.log(errors, err);

          notification['error']({
            message: 'Reset failed!',
            description: errors.map((er: any) => <div key={er}>{er}</div>),
            duration: 3,
            onClose: () => setHasError(false),
          });
        });
    }
  };

  const onResetPasswordHandler = (values: any) => {
    if (values.password && values.confirm) {
      genericResetPasswordMutation({
        variables: {
          email: localStorage.getItem('email')!,
          resetCode: parseInt(localStorage.getItem('code')!),
          password: values.password,
          bypassResetCode: false,
        },
      })
        .then(({ data }) => {
          if (data?.resetPassword?.status == 200) {
            localStorage.removeItem('email');
            localStorage.removeItem('code');
            localStorage.removeItem('fpsDisplay');
            setManageScreen(localStorage.getItem('fpsDisplay'));
            setResetSuccess(true);
            localStorage.setItem('fpsDisplay', 'resetPassword');
            setManageScreen(localStorage.getItem('fpsDisplay'));
            setResetSuccess(true);
            localStorage.removeItem('fpsDisplay');
          }
        })
        .catch((err: any) => {
          const errors = err.graphQLErrors.map((el: any) => el.message);
          console.log(errors, err);

          notification['error']({
            message: 'Reset failed!',
            description: errors.map((er: any) => <div key={er}>{er}</div>),
            duration: 3,
            onClose: () => setHasError(false),
          });
        });
    }
  };

  let dispayData = <RecoverPasswordForm onFinish={onRecoverPasswordHandler} />;

  if (manageScreen === 'recoveryCode') {
    dispayData = (
      <RecoveryCodeForm
        onFinish={onValidateCodeHandler}
        onResendCode={setResentCode}
      />
    );
  }

  if (manageScreen === 'resetPassword') {
    dispayData = resetSuccess ? (
      <ResetPasswordSuccess />
    ) : (
      <ResetPasswordForm onFinish={onResetPasswordHandler} />
    );
  }

  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div style={{ width: '1500px' }}>
        {isAuthenticated && <Redirect to={authRedirectPath} />}
        <div className="pb-3">
          <h3 className="text-center ">
            <Logo height="40" />
          </h3>
        </div>
        <Row justify="center">
          <Col span={8} className="test">
            <Card>
              <div>
                <div className="p-4">
                  {dispayData}
                  {/* {fetchData && fetchData.success ? (
                      <RegistrationSuccess
                        message={fetchData.message}
                        username={fetchData.userData.fullName}
                      />
                    ) : (
                      <RecoverPasswordForm onFinish={onFinishHandler} />
                    )} */}
                </div>
              </div>
              <div className="pb-2 ">
                <Footer footer={false} />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default RecoverPassword;
