import React from 'react';
import { Button } from 'antd';
import './styles.scss';

interface PropsTypes {
  text: any;
  handler: any;
}

const AddButton: React.FC<PropsTypes> = ({ text, handler }) => {
  return (
    <>
      <Button
        onClick={handler}
        className="add-button"
        size="small"
        type="primary"
      >
        + {text}
      </Button>
    </>
  );
};

export default AddButton;
