import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { EditContext } from 'containers/BackOffice/Candidates/Profile/Context/EditContext';

const Logo: React.FC<{ height: string }> = ({ height }) => {
  const editContext = useContext<any>(EditContext);

  //THIS FUNCTION ENABLES EDIT BUTTON
  //AND  DISABLES "CANCLE AND SAVE BUTTONS" IF CHANGES ARE MADE
  const handleuUnSavedChangesModal = () => {
    editContext &&
      editContext.isDataChanged &&
      editContext.confirmPopUpOpeneHandler();
  };
  //THIS FUNCTION DISABLES "CANCLE AND SAVE BUTTONS"
  //AND ENABLES EDIT BUTTON IF NO CHANGES ARE MADE
  const handleUnSavedChangesModalForNavigation = () => {
    editContext &&
      !editContext.isDataChanged &&
      editContext.cancelEditProfile();
  };

  return (
    <>
      {editContext?.isDataChanged ? (
        <p onClick={handleuUnSavedChangesModal}>
          <img
            src="https://res.cloudinary.com/blonk-group/image/upload/v1556608934/blonk/logo/blonk-blue.png"
            height={height}
          />
        </p>
      ) : (
        <a
          href="/backoffice/jobs"
          onClick={handleUnSavedChangesModalForNavigation}
        >
          <img
            src="https://res.cloudinary.com/blonk-group/image/upload/v1556608934/blonk/logo/blonk-blue.png"
            height={height}
          />
        </a>
      )}
    </>
  );
};

export default Logo;
