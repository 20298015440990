import React from 'react';
import {
  Row,
  Col,
  Tabs,
  Card,
  Form,
  Input,
  Select,
  Button,
  DatePicker,
  Popover,
  notification,
  Tooltip,
  Tag,
} from 'antd';
import Icon, {
  DeleteOutlined,
  CheckCircleOutlined,
  TagFilled,
} from '@ant-design/icons';
import {
  useGetTagsQuery,
  useCreateTagMutation,
  useTagCandidatesMutation,
} from '../../../../../graphql/generated/graphql';
import { useSelector, useDispatch } from 'react-redux';
import { Candidates } from 'store/candidates/types';
import {
  ClearSelectTags,
  ClearSelectCandidates,
} from 'store/candidates/actions';
import styled from 'styled-components';

const { Option } = Select;

interface Props {
  refreshCandidates: any;
  data: any;
  isEnable: boolean;
  refetchTags?: any;
}

interface CustomTagProps {
  label: any;
  value: any;
  closable: any;
  onClose: any;
}

const TagsWidget: React.FC<Props> = ({
  refreshCandidates,
  data,
  isEnable,
  refetchTags,
}) => {
  const [tags, setTags] = React.useState<any[]>([]);
  const [newTags, setNewTags] = React.useState<any[]>([]);
  const [existingTags, setExistingTags] = React.useState<any[]>([]);
  const [allTags, setAllTags] = React.useState<any[]>([]);
  const [candidates, setCandidates] = React.useState<any>();

  const dispatch = useDispatch();
  const selectedCandidates = useSelector((state: any) => state?.candidates);
  const selectedCandidateTags = useSelector(
    (state: any) => state?.candidateTags
  );

  const handleChange = (value: string) => {
    let newT = Object.values(value).filter(
      (tag: string) => !allTags.includes(tag)
    );
    setNewTags(newT);
    setExistingTags(
      Object.values(value).filter((tag: string) => allTags.includes(tag))
    );
  };

  const [createTagMutation] = useCreateTagMutation();
  const [tagCandidatesMutation] = useTagCandidatesMutation();

  const showNotification = (message: String) => {
    const args = {
      message: message,
      duration: 5,
      icon: <CheckCircleOutlined style={{ color: 'green' }} />,
    };
    notification.open(args);
  };

  const clearTagsAndCandidates = () => {
    dispatch(ClearSelectTags([]));
    dispatch(ClearSelectCandidates([]));
  };

  const handleSaveTags = async () => {
    let tags: any = [];
    if (newTags.length > 0) {
      await createTagMutation({
        variables: {
          name: newTags,
        },
      }).then(({ data }) => {
        let newOne: any = data?.createTag?.map((tag: any) => {
          return tag._id;
        });
        tags = [...existingTags, ...newOne];
        refetchTags();
      });
    } else {
      tags = [...existingTags];
    }

    tagCandidatesMutation({
      variables: {
        candidateIds: selectedCandidates.candidates,
        tags: tags,
      },
    }).then(({ data }) => {
      showNotification('Tags saved successfully!');
      clearTagsAndCandidates();
      refreshCandidates(data, selectedCandidates.candidates);
    });
  };

  React.useEffect(() => {
    if (data) {
      setTags(data);
      setAllTags(data.map((tag: any) => tag._id));
    }
  }, [data]);

  React.useEffect(() => {
    console.log(selectedCandidateTags);
  }, [selectedCandidateTags]);

  const tagContent = () => {
    const options: any = tags.map((tag) => {
      return { label: tag.name, value: tag._id };
    });

    const tagRender = (props: CustomTagProps) => {
      const { label, value, closable, onClose } = props;
      const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        event.stopPropagation();
      };
      const valueText = value.split('|')[0];
      const valueColor = value.split('|')[1];
      return (
        <Tag
          color={valueColor}
          onMouseDown={onPreventMouseDown}
          closable={closable}
          onClose={onClose}
          style={{ marginRight: 3 }}
        >
          {label}
        </Tag>
      );
    };

    return (
      <>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Select
            mode="tags"
            size={'middle'}
            placeholder="Please select"
            onChange={handleChange}
            style={{ width: 400, borderRadius: '5px' }}
            options={options}
            filterOption={true}
            className="tags-border"
            optionFilterProp="label"
          ></Select>
          <Button
            type="primary"
            onClick={handleSaveTags}
            style={{
              backgroundColor: '#53946F',
              marginLeft: 10,
              borderRadius: 5,
              borderColor: '#53946F',
              fontSize: '16px',
              height: '50px',
            }}
          >
            Save
          </Button>
        </div>
      </>
    );
  };

  return (
    <>
      {!isEnable ? (
        <Tooltip placement="topRight" title={'Select  a candidate to tag'}>
          <Button
            disabled={true}
            style={{
              borderRadius: 5,
              backgroundColor: '#C4C4C4',
              border: 'none',
            }}
            type="primary"
          >
            <TagFilled
              style={{
                color: '#5B626B',
                fontSize: 18,
                transform: 'rotate(-90deg)',
              }}
            />
          </Button>
        </Tooltip>
      ) : (
        <Popover
          placement="leftBottom"
          content={tagContent}
          title={<span style={{ fontSize: '12px' }}>Tag a user</span>}
          trigger="click"
        >
          <Button style={{ borderRadius: 5 }} type="primary">
            <TagFilled
              style={{
                color: '#fffff',
                fontSize: 18,
                transform: 'rotate(-90deg)',
              }}
            />
          </Button>
        </Popover>
      )}
    </>
  );
};

export default TagsWidget;
