import React, { useContext } from 'react';
import { Typography } from 'antd';
import { EditIcon } from 'assets/icons/DetailedProfile';
import EditButton from '../EditIcon';
import AddButton from '../AddIcon';
import UVDButton from '../UVDIcon';
import { EditContext } from '../Context/EditContext';

interface PropsType {
  title?: any;
  fontSize?: any;
  icon?: any;
  actionType?: any;
  actionHandler?: any;
  actionText?: any;
  fileLink?: any;
  deleteAction?: any;
  updateAction?: any;
  fontWeight?: any;
  lastEndIcon?: any;
  candidateId?: string;
}

const Styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  iconStyle: {},
  titleStyle: {
    fontWeight: '500',
    marginLeft: 5,
    color: '#012653',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  actionWrapper: {},
};

const SectionTitle: React.FC<PropsType> = ({
  title,
  fontSize,
  icon,
  actionType,
  actionHandler,
  actionText,
  deleteAction,
  updateAction,
  fileLink,
  fontWeight,
  lastEndIcon,
  candidateId,
}) => {
  const editContext = useContext(EditContext);

  return (
    <div style={Styles.root}>
      <div style={Styles.flex}>
        <img src={icon} style={Styles.iconStyle} />
        <Typography
          style={
            fontWeight
              ? {
                  ...Styles.titleStyle,
                  fontSize: fontSize,
                  fontWeight: fontWeight,
                  marginLeft: title == 'summary' ? '0px' : '5px',
                }
              : { ...Styles.titleStyle, fontSize: fontSize }
          }
        >
          {title}
        </Typography>
        <img src={lastEndIcon} style={{ ...Styles.iconStyle, marginLeft: 5 }} />
      </div>
      <div style={Styles.actionWrapper}>
        {actionType == 'edit' && editContext?.edit && (
          <EditButton handler={actionHandler} />
        )}
        {actionType == 'add' && editContext?.edit && (
          <AddButton handler={actionHandler} text={actionText} />
        )}
        {actionType == 'uvd' && (
          <UVDButton
            updateAction={updateAction}
            fileLink={fileLink}
            title={title}
            candidateId={candidateId}
          />
        )}
      </div>
    </div>
  );
};

export default SectionTitle;
