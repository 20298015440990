import { NextButton, PreviousButton } from 'assets/icons/DetailedProfile';
import { useGetSingleJobQuery } from 'graphql/generated/graphql';
import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { EditContext } from '../Context/EditContext';

const Styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    color: '#070B26',
    fontSize: 20,
    fontWeight: 500,
  },
  navBtn: {
    width: 40,
    cursor: 'pointer',
  },
  disabledNavBtn: {
    width: 40,
    cursor: 'not-allowed',
    opacity: 0.5,
  },
};

const TopNavigation: React.FC = () => {
  const location: any = useLocation();
  const history = useHistory();
  const [candidates, setCandidates] = useState<any>(
    location.state?.candidateIDs
      ? location.state?.candidateIDs
      : location.state?.candidateArray
  );
  const [candidateIndex, setCandidateIndex]: any = useState(
    candidates?.indexOf(location.state?.candidateId)
  );
  const editContext: any = useContext(EditContext);

  const btnHandleNavigate = (e: 'next' | 'prev') => {
    let nextCandidate = null;

    if (e === 'next') {
      nextCandidate = candidates[candidateIndex + 1];
    } else {
      nextCandidate = candidates[candidateIndex - 1];
    }

    if (!editContext?.edit) {
      history.push({
        pathname: `/backoffice/candidates/${nextCandidate}`,
        state: {
          candidateIDs: candidates,
          candidateId: nextCandidate,
          fromURL: `${location.state?.fromURL}`,
        },
      });
    }
  };
  return (
    <div style={Styles.root}>
      {candidates?.length > 1 && (
        <span
          onClick={() => {
            candidateIndex > 0 && btnHandleNavigate('prev');
          }}
        >
          <img
            style={
              candidateIndex === 0 || candidateIndex === -1 || editContext?.edit
                ? Styles.disabledNavBtn
                : Styles.navBtn
            }
            src={PreviousButton}
            alt="Previous"
          />
        </span>
      )}

      <span style={Styles.title}>
        {location.state?.pageTitle || 'Candidate Information'}
      </span>

      {candidates?.length > 1 && (
        <span
          onClick={() => {
            candidateIndex < candidates?.length - 1 &&
              btnHandleNavigate('next');
          }}
        >
          <img
            style={
              candidateIndex === candidates?.length - 1 || editContext?.edit
                ? Styles.disabledNavBtn
                : Styles.navBtn
            }
            src={NextButton}
            alt="Next"
          />
        </span>
      )}
    </div>
  );
};

export default TopNavigation;
