import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useContext } from 'react';
import { EditContext } from 'containers/BackOffice/Candidates/Profile/Context/EditContext';
import './styles.css';

const buttonStyle = {
  // Other styles for the default state
  display: 'flex',
  padding: '8px 32px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  borderRadius: '20px',
  backgroundColor: '#1A838C',
  color: '#FFFFFF',

  // Hover styles
  ':hover': {
    cursor: 'pointer', // Add cursor pointer on hover
    backgroundColor: '#135c64', // Change background color on hover
    // Add other hover styles as needed
  },
};

interface PropsType {
  save: () => void;
  handleCancel?: () => void;
  discard?: () => void;
  status?: any;
  refetch?: any;
  to?: any;
  isOpen?: any;
  reopener?: () => void;
}

const CancelModal: React.FC<PropsType> = ({
  isOpen,
  handleCancel,
  save,
  discard,
  reopener,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  const editContext = useContext(EditContext);

  const showModal = () => {
    if (reopener) reopener();

    setIsModalOpen(false);
  };

  const handleDiscard = () => {
    setIsModalOpen(false);
    if (discard) {
      discard();
    }
  };

  const handleCancelModal = () => {
    setIsModalOpen(false);
    if (handleCancel) {
      handleCancel();
    }
  };

  return (
    <>
      <Modal
        width={500}
        footer={null}
        open={isModalOpen}
        onOk={handleCancelModal}
        onCancel={showModal}
      >
        <div style={{ padding: '16px', fontFamily: 'Poppins' }}>
          <div className="span-container-style">
            <span className="warning-style">
              Your changes will be discarded.
            </span>
          </div>
          <div className="button-container-style">
            <button
              onClick={save}
              className="button-style"
              style={{ backgroundColor: '#1A838C' }}
            >
              Save the changes
            </button>
            <button
              onClick={handleDiscard}
              className="button-style"
              style={{ backgroundColor: '#990000' }}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CancelModal;
