import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { HttpState } from '../../../../store/http/types';

import {
  Row,
  Col,
  Card,
  notification,
  Form,
  Input,
  Select,
  Button,
  Upload,
  message,
  Space,
} from 'antd';
import PageTitle from '../../../../components/UI/PageTitle/PageTitle';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import useCompanies from 'hooks/useCompanies';
import {
  useGetAllRecruitersInBoQuery,
  useCompanyJobsLazyQuery,
  useAttachRecruiterToAJobMutation,
} from '../../../../graphql/generated/graphql';

const { Option } = Select;
const { TextArea } = Input;
interface Props {}

const AddRecruiterToJobs: React.FC<Props> = () => {
  const [form] = Form.useForm();
  const [hasError, setHasError] = useState(false);
  const [recruiters, setRecruiters]: any = useState([]);
  const [jobs, setJobs]: any = useState([]);
  const [companyId, setCompanyId] = useState(null);
  const [jobId, setJobId] = useState(null);
  const { redirectPath } = useSelector((state: HttpState) => state.http);
  const companies = useCompanies();

  const { data, loading, error }: any = useGetAllRecruitersInBoQuery({
    fetchPolicy: 'network-only',
  });

  const [getCompanyJobs, { loading: loading1, data: data1 }]: any =
    useCompanyJobsLazyQuery({ fetchPolicy: 'network-only' });
  const [attachRecruiterToAJobMutation, attachRecruiterToAJobMutationMutation] =
    useAttachRecruiterToAJobMutation();

  const onCompanyDropDownChange = (companyId: any) => {
    console.log('Selected Value for company: ', companyId);
    setCompanyId(companyId);
    setJobId(null);
    setJobs([]);
    form.setFieldsValue({
      jobId: null,
      recruiters: [],
    });
  };

  const onJobDropDownChange = (jobId: any) => {
    console.log('Selected Value: ', jobId);
    setJobId(jobId);
    // setJobRecruiters()
  };

  const onFinish = (values: any) => {
    console.log('Submitting: ', values);
    debugger;
    const updatedrecruiters = values.recruiters
      .map((c: any) => {
        return recruiters.find((recruiter: any) => recruiter.user.email == c);
      })
      .map((d: any) => d.user._id);
    console.log('Updated recruiters : ', updatedrecruiters);

    attachRecruiterToAJobMutation({
      variables: {
        companyId: values.companyId,
        jobId: values.jobId,
        userIds: updatedrecruiters,
      },
    })
      .then(({ data }: any) => {
        console.log('Response: ', data);
        if (data.AttachRecruiterToAJob.status == 200)
          notification['success']({
            message: data.AttachRecruiterToAJob.message,
            // onClose: () =>
            //   history.push(`/recruiters/companies/jobs/add`),
            duration: 3,
            description: `You has successfully attached the recruiter(s).`,
          });
        form.resetFields();
      })
      .catch((err) => {
        const errors = err.graphQLErrors.map((el: any) => el.message);
        notification['error']({
          message: 'Unable to attach the recruiter account',
          description: errors.map((er: any) => <div key={er}>{er}</div>),
          duration: 3,
          onClose: () => setHasError(false),
        });
      });
  };

  useEffect(() => {
    if (companies) {
      const companyObj = companies.find(
        (company: any) => company._id == companyId
      );
      console.log('===> ', companyObj);
      getCompanyJobs({
        variables: {
          companyId: companyObj._id,
        },
      });
      // setRecruiters(companyObj.users);
    }
  }, [companyId]);

  useEffect(() => {
    if (data && data.getAllRecruitersInBO) {
      console.log('Recruiters ==> ', [...data.getAllRecruitersInBO]);
      setRecruiters([...data.getAllRecruitersInBO]);
    }
  }, [data]);

  useEffect(() => {
    if (data1 && data1.getCompanyJobs) {
      console.log('JOBS ==> ', [...data1.getCompanyJobs]);
      setJobs([...data1.getCompanyJobs]);
    }
  }, [data1]);

  useEffect(() => {
    if (jobId) {
      console.log('UseEffect JOB ID ==> ', jobId);
      const jobObj = jobs.find((job: any) => job._id == jobId);
      if (jobObj) {
        console.log('Job found ', jobObj);
        form.setFieldsValue({
          recruiters: jobObj.recruiters.map((rec: any) => rec.email),
        });
      }
    }
  }, [jobId]);

  return (
    <>
      <PageTitle title="Add recruiter to a job" back />
      <Row justify="center" gutter={16}>
        <Col span={16}>
          <Card className="mb-4">
            <Form
              name="registration-form"
              layout="vertical"
              form={form}
              //   onFieldsChange={() => dispatch(httpClear())}
              onFinish={onFinish}
              scrollToFirstError
            >
              <Form.Item name="companyId" label="Company name">
                <Select
                  showSearch
                  placeholder="Select Company"
                  // optionFilterProp="children"
                  onChange={onCompanyDropDownChange}
                  // onFocus={onFocus}
                  // onBlur={onBlur}
                  // onSearch={onSearch}
                  filterOption={(input, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {companies &&
                    companies.map((company: any) => {
                      return (
                        <Option key={company._id} value={company._id}>
                          {company.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item name="jobId" label="Select job">
                <Select
                  showSearch
                  placeholder="Select Job"
                  onChange={onJobDropDownChange}
                  filterOption={(input, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {jobs &&
                    jobs.map((job: any) => {
                      return (
                        <Option key={job._id} value={job._id}>
                          {job.title}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item
                name="recruiters"
                label="Required recruiters"
                rules={[
                  {
                    required: true,
                    message: 'Please select recruiter email!',
                  },
                ]}
                // hasFeedback
              >
                <Select
                  showSearch
                  // disabled={!companyId && !job}
                  mode="multiple"
                  placeholder="Select required recruiters"
                  filterOption={(input, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  // onChange={onRecruiterSelected}
                >
                  {console.log('`recruitrsss ', recruiters)}
                  {recruiters &&
                    recruiters.map((rec: any) => {
                      return (
                        <Option key={rec._id} value={rec.user.email}>
                          {rec.user.email}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>

              <Form.Item>
                <div className="text-right">
                  <Space>
                    <Button
                      type="default"
                      shape="round"
                      onClick={() => form.resetFields()}
                    >
                      Reset
                    </Button>
                    <Button
                      className="ant-btn-amber"
                      type="default"
                      shape="round"
                      htmlType="submit"
                    >
                      Attach
                    </Button>
                  </Space>
                </div>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AddRecruiterToJobs;
