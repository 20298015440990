import React, { useState, useEffect } from 'react';
import Highlighter from 'react-highlight-words';
import PageTitle from '../../../../components/UI/PageTitle/PageTitle';

import {
  Row,
  Col,
  Tabs,
  Card,
  Table,
  Input,
  Button,
  Space,
  Tooltip,
  Switch,
  Popconfirm,
  Tag,
  notification,
} from 'antd';
import {
  DeleteOutlined,
  SearchOutlined,
  UserOutlined,
  EditOutlined,
  StarTwoTone,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
// import '../../../../components/UI/Buttons/Buttons.scss'
import Buttons from 'components/UI/Buttons';
import {
  useAllJobsQuery,
  useAllActiveJobsQuery,
  useAllActiveJobsLazyQuery,
  useUpdateJobStatusMutation,
  useDeleteJobMutation,
  useUpdateJobIsFavouriteMutation,
  useGetCurrentUserQuery,
  useGetJobsCountQuery,
  useGetJobsCountLazyQuery,
} from '../../../../graphql/generated/graphql';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';

import { Fade } from 'react-awesome-reveal';
import ErrorCard from '../../../../components/UI/ErrorCard/ErrorCard';
import { generateJobs } from '../../../../faker/jobs';
import CompanyDrawer from '../../../../components/BackOffice/CompanyDrawer';
// import JobDrawer from '../partials/JobDrawer';
import { ColumnsType } from 'antd/es/table';
import { useDispatch, useSelector } from 'react-redux';
import { JobsState } from 'store/jobs/types';
import {
  DeleteJob,
  StoreJob,
  StoreJobs,
  StoreNewCandidates,
} from '../../../../store/jobs/actions';
import moment from 'moment';
import { company } from 'faker';
import {
  ActiveJobsTable,
  PendingJobsTable,
  RejectedJobsTable,
} from './Components';
import ArchiveJobsTable from './Components/ArchivedJobsTable';
import { numberWithCommas } from 'helpers/numberConvert';
import TopSearchbar from 'components/SearchComponent/TopSearch';
// import './JobsList.scss';

interface Jobs {
  key: number;
  id: string;
  logo: string;
  company: string;
  link: JSX.Element;
  description: string;
  location: any;
  experience: any;
  skills: any;
  leadRecruiter: string;
  jobFeilds: any;
  contractType: string;
  salaryPackage: string;
  targetCompanies: string;
  recruiter: any;
  status: any;
  Candidates: number;
  createdAt: string;
}
interface DataSearch {
  searchText: any;
  searchType: any;
}
const { TabPane } = Tabs;

const JobsList: React.FC = () => {
  const history = useHistory();
  const [archiveVisible, setArchiveVisible] = React.useState(false);
  const [deleteVisible, setDeleteVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [hasError, setHasError] = useState(false);
  const [activeJobs, setActiveJobs] = useState<any>([]);
  const [archivedJobs, setArchivedJobs] = useState([]);
  const [pendingJobs, setPendingJobs] = useState([]);
  const [rejectedJobs, setRejectedJobs] = useState([]);
  const [jobsCount, setJobsCount] = useState<any>();
  const [searchData, setSearchData] = useState<any>();
  const [activeTab, setActiveTab] = useState<any>('Active Jobs');
  const [activePageOption, setActivePageOption] = useState<any>({
    size: 10,
    current: 1,
  });
  const [countParsedInt, setCountParsedInt] = useState<any>(null);
  const dispatch = useDispatch();
  const { url } = useRouteMatch();

  // const [loading, setLoading] = useState(true);
  const [fakeData, setFakeData] = useState<any>({
    jobRecords: [],
  });
  const [searchText, setSearchText] = useState('');
  const [displayCompanyDrawer, setDisplayCompanyDrawer] = useState(false);
  const [displayJobDrawer, setDisplayJobDrawer] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState('');
  const [AllJobsCount, setAllJobsCount] = useState<any>({});
  const AllJobsCountHandler = (count: any) => {
    const AllCounts = { ...AllJobsCount, ...count };
    setAllJobsCount(AllCounts);
  };
  const [updateJobStatusMutation, UpdateJobStatus] =
    useUpdateJobStatusMutation();
  const [updateJobIsFavouriteMutation] = useUpdateJobIsFavouriteMutation();
  const [deleteJobStatusMutation, deleteJobStatus] = useDeleteJobMutation();

  const { data, loading, error }: any = useAllJobsQuery({
    fetchPolicy: 'network-only',
  });

  const [
    getJobsCount,
    { data: jobsCountData, loading: loadingJobsCount },
  ]: any = useGetJobsCountLazyQuery({
    fetchPolicy: 'network-only',
  });
  const { data: currentUser } = useGetCurrentUserQuery({
    fetchPolicy: 'network-only',
  });

  const currentUserId = currentUser?.currentUser?._id;

  const jobs = useSelector((state: JobsState) => state.jobs?.jobs);

  useEffect(() => {
    getJobsCount({ variables: { ...searchData } });
  }, [searchData?.searchText, searchData?.searchType]);

  useEffect(() => {
    if (jobs) {
      const activeJobs_ = jobs
        .filter((job: any) => job.status == 'active')
        .sort((a: any, b: any) => (a.createdAt > b.createdAt && -1) || 1)
        .sort(
          (a: any, b: any) =>
            (a.favouriteBy?.includes(currentUserId) && -1) || 1
        );
      const archivedJobs_ = jobs
        .filter((job: any) => job.status == 'archive')
        .sort((a: any, b: any) => (a.createdAt > b.createdAt && -1) || 1)
        .sort(
          (a: any, b: any) =>
            (a.favouriteBy?.includes(currentUserId) && -1) || 1
        );
      const pendingJobs_ = jobs
        .filter((job: any) => job.status == 'pending')
        .sort((a: any, b: any) => (a.createdAt > b.createdAt && -1) || 1)
        .sort(
          (a: any, b: any) =>
            (a.favouriteBy?.includes(currentUserId) && -1) || 1
        );
      const rejectedJobs_ = jobs
        .filter((job: any) => job.status == 'rejected')
        .sort((a: any, b: any) => (a.createdAt > b.createdAt && -1) || 1)
        .sort(
          (a: any, b: any) =>
            (a.favouriteBy?.includes(currentUserId) && -1) || 1
        );
      setActiveJobs(activeJobs_);
      setArchivedJobs(archivedJobs_);
      setPendingJobs(pendingJobs_);
      setRejectedJobs(rejectedJobs_);
    }
  }, [jobs, currentUser, dispatch]);

  useEffect(() => {
    if (!loadingJobsCount && jobsCountData) {
      setJobsCount(jobsCountData.GetJobsCount);
      const jobsCountInNumbers: any = {
        active: parseInt(jobsCountData.GetJobsCount.activeJobsCount),
        pending: parseInt(jobsCountData.GetJobsCount.pendingJobsCount),
        rejected: parseInt(jobsCountData.GetJobsCount.rejectedJobsCount),
        archived: parseInt(jobsCountData.GetJobsCount.archivedJobsCount),
      };
      setCountParsedInt(jobsCountInNumbers);
    }
  }, [jobsCountData]);
  const realTimeCountHandler = (action = '') => {
    const activeToArchived: any = {
      active: countParsedInt?.active,
      archived: countParsedInt?.archived,
      pending: countParsedInt?.pending,
      rejected: countParsedInt?.rejected,
    };

    switch (action) {
      case 'archiveFromActiveTable':
        activeToArchived.active--;
        activeToArchived.archived++;
        break;
      case 'activeFromPendingTable':
        activeToArchived.active++;
        activeToArchived.pending--;
        break;
      case 'rejectedFromPendingTable':
        activeToArchived.pending--;
        activeToArchived.rejected++;
        break;
      case 'archiveFromPendingTable':
        activeToArchived.archived++;
        activeToArchived.pending--;
        break;
      case 'activeFromRejectedTable':
        activeToArchived.active++;
        activeToArchived.rejected--;
        break;
      case 'archiveFromRejectedTable':
        activeToArchived.archived++;
        activeToArchived.rejected--;
        break;
      case 'activateFromPendingTable':
        activeToArchived.archived++;
        activeToArchived.rejected--;
        break;
      default:
    }
    setCountParsedInt(activeToArchived);
  };

  const showArchivePopconfirm = () => {
    setArchiveVisible(true);
  };

  const showDeletePopconfirm = () => {
    setDeleteVisible(true);
  };

  const updateJobStatusHandler = (jobId: any, status: string) => {
    setConfirmLoading(true);
    updateJobStatusMutation({
      variables: {
        jobId: jobId,
        status: status,
      },
    })
      .then(({ data }) => {
        dispatch(StoreJob({ ...data?.updateJobStatus }));
        notification['success']({
          message: 'Job status updated successfully.',
          // onClose: () =>
          //   history.push(`/job-listings/edit/${jobId}`),
          description: `Your Job status has been to "${status}" now.`,
        });
        setArchiveVisible(false);
        setConfirmLoading(false);
      })
      .catch((err) => {
        const errors = err.graphQLErrors.map((el: any) => el.message);
        notification['error']({
          message: 'Unable to create job',
          description: errors.map((er: any) => <div key={er}>{er}</div>),
          duration: 3,
          onClose: () => setHasError(false),
        });
      });
  };

  const deleteJobStatusHandler = (jobId: any) => {
    setConfirmLoading(true);

    deleteJobStatusMutation({
      variables: {
        jobId: jobId,
      },
    })
      .then(({ data }) => {
        dispatch(DeleteJob(jobId));
        notification['success']({
          message: 'Status updated successfully.',
          description: `Your Job has been deleted.`,
        });
        setDeleteVisible(false);
        setConfirmLoading(false);
      })
      .catch((err) => {
        const errors = err.graphQLErrors.map((el: any) => el.message);
        notification['error']({
          message: 'Unable to create job',
          description: errors.map((er: any) => <div key={er}>{er}</div>),
          duration: 3,
          onClose: () => setHasError(false),
        });
      });
  };

  const handleArchiveCancel = () => {
    setArchiveVisible(false);
  };

  const handleDeleteCancel = () => {
    setDeleteVisible(false);
  };

  let searchInput: any;

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: any, record: any) => {
      if (dataIndex === 'link') {
        return record[dataIndex]?.props?.children
          ?.toString()
          ?.toLowerCase()
          ?.includes(value.toLowerCase());
      } else if (dataIndex === 'recruiters' || dataIndex === 'countries') {
        const foundResults = record[dataIndex]?.props?.children?.filter(
          (el: any) => {
            return el.key
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase());
          }
        );
        return foundResults?.length ? record[dataIndex] : '';
      } else {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    },
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
    render: (text: any) => {
      if (searchedColumn === dataIndex) {
        if (searchedColumn === 'link') {
          // for name its a <Link /> element
          const textHighlight = text?.props?.children;
          return (
            <Link to={text?.props?.to}>
              <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={textHighlight ? textHighlight.toString() : ''}
              />
            </Link>
          );
        } else if (
          searchedColumn === 'recruiters' ||
          searchedColumn === 'countries'
        ) {
          // also jsx elements
          return text?.props?.children?.map((el: any) => {
            // Need to highlight text inside the tag
            return (
              <Tag key={el.key} style={{ marginBottom: 5 }}>
                <Highlighter
                  highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                  searchWords={[searchText]}
                  autoEscape
                  textToHighlight={el.key ? el.key.toString() : ''}
                />
              </Tag>
            );
          });
        } else {
          // for default text only cases
          return (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          );
        }
      } else {
        return text;
      }
    },
  });
  const handleSearchTop = (searchText: string, searchField: string) => {
    // Do something with the search text and field
    // console.log(searchText, searchField, 'data in job table');
    setSearchData({
      ...searchData,
      searchText: searchText,
      searchType: searchField,
    });
  };
  const onChange = (key: string) => {
    if (key == '1' || key == undefined) {
      setActiveTab('Active Jobs');
    } else if (key == '2') {
      setActiveTab('Archieve Jobs');
    } else if (key == '3') {
      setActiveTab('Pending Jobs');
    } else if (key == '4') {
      setActiveTab('Rejected Jobs');
    }
  };
  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
    setSearchText('');
  };

  const isFavouriteJob = (jobId: string) => {
    const isFav = jobs?.find((job: any) => job._id === jobId);
    return isFav && isFav?.favouriteBy?.includes(currentUserId);
  };
  const handleToggleFavouriteJob = (jobId: string) => {
    setConfirmLoading(true);
    updateJobIsFavouriteMutation({
      variables: {
        jobId: jobId,
        isFavourite: !isFavouriteJob(jobId),
      },
    })
      .then(({ data }) => {
        dispatch(StoreJob({ ...data?.updateJobIsFavourite }));
        notification['success']({
          message: 'Job updated successfully.',
          // description: `udpated isFavourite`,
        });
        setArchiveVisible(false);
        setConfirmLoading(false);
      })
      .catch((err) => {
        const errors = err.graphQLErrors.map((el: any) => el.message);
        notification['error']({
          message: 'Unable to update job',
          description: errors.map((er: any) => <div key={er}>{er}</div>),
          duration: 3,
          onClose: () => setHasError(false),
        });
      });
  };

  const actionsHandler = (_id: any) => {
    return (
      <Space>
        <Buttons
          toolTipTitle={isFavouriteJob(_id) ? 'unfavourite' : 'favourite'}
          type={isFavouriteJob(_id) ? 'btn-unfavourite' : 'btn-favourite'}
          onClick={() => handleToggleFavouriteJob(_id)}
        />
        {/* <Tooltip title={isFavouriteJob(_id) ? 'unfavourite' : 'favourite'}>
          <Button
            className="border-primary"
            shape="circle"
            onClick={() => handleToggleFavouriteJob(_id)}
            icon={
              <StarTwoTone
                className="text-primary"
                twoToneColor={isFavouriteJob(_id) ? '#dab238' : '#2e4a79'}
              />
            }
          />
        </Tooltip> */}

        <Tooltip title="reject">
          <Popconfirm
            title="Are you sure reject this job?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => updateJobStatusHandler(_id, 'rejected')}
            okButtonProps={{ loading: confirmLoading }}
            onCancel={handleDeleteCancel}
          >
            <div className="btn-reject"></div>
          </Popconfirm>
        </Tooltip>
        <Tooltip title="archive">
          <Popconfirm
            title="Are you sure archive this job?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => updateJobStatusHandler(_id, 'archive')}
            okButtonProps={{ loading: confirmLoading }}
            onCancel={handleArchiveCancel}
          >
            <div className="btn-archive"></div>
          </Popconfirm>
        </Tooltip>
        <Buttons
          toolTipTitle={'edit'}
          type={'btn-edit'}
          onClick={() => history.push(`${url}/edit/${_id}`)}
        />
        {/* <Tooltip title="edit">
          <Button
            className="border-primary"
            shape="circle"
            onClick={() => history.push(`${url}/edit/${_id}`)}
            icon={<EditOutlined className="text-primary" />}
          />
        </Tooltip> */}
      </Space>
    );
  };

  const archiveActionsHandler = (_id: any) => {
    return (
      <Space>
        <Buttons
          toolTipTitle={isFavouriteJob(_id) ? 'unfavourite' : 'favourite'}
          type={isFavouriteJob(_id) ? 'btn-unfavourite' : 'btn-favourite'}
          onClick={() => handleToggleFavouriteJob(_id)}
        />
        {/* <Tooltip title={isFavouriteJob(_id) ? 'unfavourite' : 'favourite'}>
          <Button
            className="border-primary"
            shape="circle"
            onClick={() => handleToggleFavouriteJob(_id)}
            icon={
              <StarTwoTone
                className="text-primary"
                twoToneColor={isFavouriteJob(_id) ? '#dab238' : '#2e4a79'}
              />
            }
          />
        </Tooltip> */}
        <Buttons
          toolTipTitle={'edit'}
          type={'btn-edit'}
          onClick={() => history.push(`${url}/edit/${_id}`)}
        />
        {/* <Tooltip title="edit">
          <Button
            className="border-primary"
            shape="circle"
            onClick={() => history.push(`${url}/edit/${_id}`)}
            icon={<EditOutlined className="text-primary" />}
          />
        </Tooltip> */}
        <Tooltip title="Active job">
          <Popconfirm
            title="Are you sure activate this job?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => updateJobStatusHandler(_id, 'active')}
            okButtonProps={{ loading: confirmLoading }}
            onCancel={handleArchiveCancel}
          >
            <div className="btn-revert"></div>
          </Popconfirm>
        </Tooltip>
        <Tooltip title="delete">
          <Popconfirm
            title="Are you sure delete this recruiter?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => deleteJobStatusHandler(_id)}
            okButtonProps={{ loading: confirmLoading }}
            onCancel={handleDeleteCancel}
          >
            <div className="btn-reject"></div>
          </Popconfirm>
        </Tooltip>
      </Space>
    );
  };

  const pendingActionsHandler = (_id: any) => {
    return (
      <Space>
        <Tooltip title="Active job">
          <Popconfirm
            title="Are you sure you want to activate this job?"
            okText="Yes"
            cancelText="No"
            // visible={archiveVisible}
            onConfirm={() => updateJobStatusHandler(_id, 'active')}
            okButtonProps={{ loading: confirmLoading }}
            onCancel={handleArchiveCancel}
          >
            <div className="btn-approve"></div>
          </Popconfirm>
        </Tooltip>
        <Tooltip title="reject">
          <Popconfirm
            title="Are you sure you want to reject this job?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => updateJobStatusHandler(_id, 'rejected')}
            okButtonProps={{ loading: confirmLoading }}
            onCancel={handleDeleteCancel}
          >
            <div className="btn-dislike"></div>
          </Popconfirm>
        </Tooltip>
        <Tooltip title="archive">
          <Popconfirm
            title="Are you sure archive this job?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => updateJobStatusHandler(_id, 'archive')}
            okButtonProps={{ loading: confirmLoading }}
            onCancel={handleArchiveCancel}
          >
            <div className="btn-archive"></div>
          </Popconfirm>
        </Tooltip>
      </Space>
    );
  };

  const rejectedActionsHandler = (_id: any) => {
    return (
      <Space>
        <Tooltip title="Active job">
          <Popconfirm
            title="Are you sure activate this job?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => updateJobStatusHandler(_id, 'active')}
            okButtonProps={{ loading: confirmLoading }}
            onCancel={handleArchiveCancel}
          >
            <div className="btn-revert"></div>
          </Popconfirm>
        </Tooltip>
        <Tooltip title="archive">
          <Popconfirm
            title="Are you sure archive this job?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => updateJobStatusHandler(_id, 'archive')}
            okButtonProps={{ loading: confirmLoading }}
            onCancel={handleArchiveCancel}
          >
            <div className="btn-archive"></div>
          </Popconfirm>
        </Tooltip>
        <Tooltip title="delete">
          <Popconfirm
            title="Are you sure delete this recruiter?"
            okText="Yes"
            cancelText="No"
            visible={deleteVisible}
            onConfirm={() => deleteJobStatusHandler(_id)}
            okButtonProps={{ loading: confirmLoading }}
            onCancel={handleDeleteCancel}
          >
            <div className="btn-dislike"></div>
          </Popconfirm>
        </Tooltip>
      </Space>
    );
  };

  const candidatesHanlder = (id: any, jobCount: any) => {
    return (
      <Button
        className="ant-btn-teal"
        shape="circle"
        onClick={() => setDisplayJobDrawer(true)}
      >
        {jobCount}
      </Button>
    );
  };
  const matchesHanlder = (id: any, jobCount: any) => {
    return (
      <Button
        className="ant-btn-burgundy"
        shape="circle"
        onClick={() => setDisplayJobDrawer(true)}
      >
        {jobCount}
      </Button>
    );
  };

  const fLCapital = (s: any) => s?.charAt(0)?.toUpperCase() + s.slice(1);

  const generateArchiveJobs = () => {
    if (archivedJobs) {
      const jobsData = archivedJobs.map((job: any) => {
        return {
          key: job._id,
          _id: job._id,
          // logoUrl: job.logoUrl,
          name: job.title,
          link: <Link to={`${url}/${job._id}`}>{job.title}</Link>,
          companyName: job.company.name,
          countries: job.countries ? (
            <>
              {job.countries.map((el: any) => {
                return <Tag key={el.name}>{el?.name?.toUpperCase()}</Tag>;
              })}
            </>
          ) : (
            0
          ),
          // title: job.owner.title,
          // email: job.createdBy.email,
          // jobCount: job.totalJobs,
          // recruiterCount: job.users.length,
          createdAt: job.createdAt,
        };
      });
      return jobsData;
    }
  };

  const generatePendingJobs = () => {
    if (pendingJobs) {
      const jobsData = pendingJobs.map((job: any) => {
        return {
          key: job._id,
          _id: job._id,
          // logoUrl: job.logoUrl,
          name: job.title,
          link: <Link to={`${url}/${job._id}`}>{job.title}</Link>,
          companyName: job.company.name,
          recruiters: job.recruiters ? (
            <>
              {job.recruiters.map((el: any) => {
                return (
                  <Tag key={el.email} style={{ marginBottom: 5 }}>
                    {el.email}
                  </Tag>
                );
              })}
            </>
          ) : (
            0
          ),
          countries: job.countries ? (
            <>
              {job.countries.map((el: any) => {
                return <Tag key={el.name}>{el?.name?.toUpperCase()}</Tag>;
              })}
            </>
          ) : (
            0
          ),
          // title: job.owner.title,
          // email: job.createdBy.email,
          // jobCount: job.totalJobs,
          // recruiterCount: job.users.length,
          createdAt: job.createdAt,
        };
      });
      return jobsData;
    }
  };

  const generateRejectedJobs = () => {
    if (rejectedJobs) {
      const jobsData = rejectedJobs.map((job: any) => {
        return {
          key: job._id,
          _id: job._id,
          // logoUrl: job.logoUrl,
          name: job.title,
          link: <Link to={`${url}/${job._id}`}>{job.title}</Link>,
          companyName: job.company.name,
          recruiters: job.recruiters ? (
            <>
              {job.recruiters.map((el: any) => {
                return (
                  <Tag key={el.email} style={{ marginBottom: 5 }}>
                    {el.email}
                  </Tag>
                );
              })}
            </>
          ) : (
            0
          ),
          countries: job.countries ? (
            <>
              {job.countries.map((el: any) => {
                return <Tag key={el.name}>{el?.name?.toUpperCase()}</Tag>;
              })}
            </>
          ) : (
            0
          ),
          // title: job.owner.title,
          // email: job.createdBy.email,
          // jobCount: job.totalJobs,
          // recruiterCount: job.users.length,
          createdAt: job.createdAt,
          status: fLCapital(job.status) || 'Rejected',
        };
      });
      return jobsData;
    }
  };

  const columnsArchive: ColumnsType<any> = [
    {
      title: 'Job title',
      dataIndex: 'link',
      key: 'link',
      fixed: 'left',
      // width: 400,
      showSorterTooltip: false,
      // sorter: (a: any, b: any) => a?.link.localeComapre(b?.link),
      // sortDirections: ['ascend', 'descend', 'ascend'],
      // defaultSortOrder: 'ascend',
      ...getColumnSearchProps('link'),
    },
    {
      title: 'Company name',
      dataIndex: 'companyName',
      key: 'companyName',
      ...getColumnSearchProps('companyName'),
    },
    // {
    //   title: 'Location',
    //   key: 'countries',
    //   dataIndex: 'countries',
    //   ...getColumnSearchProps('countries'),
    // },
    {
      title: 'New candidates',
      dataIndex: 'id',
      // width: 170,
      key: 'candidates',
      render: (_id, row: any) => (
        <>
          <Link to={`/candidate-listings/${_id}`}>
            <Button className="ant-btn-teal">
              {/* {candidates} Candidates */}
              {row.candidates ? row.candidates.length : 0} Candidates
            </Button>
          </Link>
        </>
      ),
    },
    {
      title: 'Created on',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt, row) =>
        moment(parseInt(createdAt)).format('DD MMM YYYY'),
    },
    {
      title: 'Action',
      key: '_id',
      dataIndex: '_id',
      fixed: 'right',
      width: 200,
      render: archiveActionsHandler,
    },
  ];

  const columnsPending: ColumnsType<any> = [
    {
      title: 'Job title',
      dataIndex: 'link',
      key: 'link',
      fixed: 'left',
      width: 400,
      showSorterTooltip: false,
      // sorter: (a: any, b: any) => a.link?.length - b.link?.length,
      // sortDirections: ['ascend', 'descend', 'ascend'],
      // defaultSortOrder: 'ascend',
      ...getColumnSearchProps('link'),
    },
    {
      title: 'Company name',
      dataIndex: 'companyName',
      key: 'companyName',
      ...getColumnSearchProps('companyName'),
    },
    // {
    //   title: 'Location',
    //   key: 'countries',
    //   dataIndex: 'countries',
    //   ...getColumnSearchProps('countries'),
    // },
    // {
    //   title: 'Candidates',
    //   dataIndex: 'id',
    //   width: 170,
    //   key: 'candidates',
    //   render: (_id, row: any) => (
    //     <>
    //       <Link to={`/candidate-listings/${_id}`}>
    //         <Button className="ant-btn-teal">
    //           {row.candidates ? row.candidates.length : 0} Candidates
    //         </Button>
    //       </Link>
    //     </>
    //   ),
    // },
    {
      title: 'Recruiters',
      key: 'recruiters',
      dataIndex: 'recruiters',
      width: 450,
      ...getColumnSearchProps('recruiters'),
    },
    {
      title: 'Created on',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt, row) =>
        moment(parseInt(createdAt)).format('DD MMM YYYY'),
    },
    {
      title: 'Action',
      key: '_id',
      dataIndex: '_id',
      fixed: 'right',
      width: 180,
      render: pendingActionsHandler,
    },
  ];

  const columnsRejected: ColumnsType<any> = [
    {
      title: 'Job title',
      dataIndex: 'link',
      key: 'link',
      fixed: 'left',
      width: 400,
      showSorterTooltip: false,
      // sorter: (a: any, b: any) => a.link?.length - b.link?.length,
      // sortDirections: ['ascend', 'descend', 'ascend'],
      // defaultSortOrder: 'ascend',
      ...getColumnSearchProps('link'),
    },
    {
      title: 'Company name',
      dataIndex: 'companyName',
      key: 'companyName',
      ...getColumnSearchProps('companyName'),
    },
    // {
    //   title: 'Location',
    //   key: 'countries',
    //   dataIndex: 'countries',
    //   ...getColumnSearchProps('countries'),
    // },
    // {
    //   title: 'New candidates',
    //   dataIndex: 'id',
    //   width: 170,
    //   key: 'candidates',
    //   render: (_id, row: any) => (
    //     <>
    //       <Link to={`/candidate-listings/${_id}`}>
    //         <Button className="ant-btn-teal">
    //           {row.candidates ? row.candidates.length : 0} Candidates
    //         </Button>
    //       </Link>
    //     </>
    //   ),
    // },
    {
      title: 'Recruiters',
      key: 'recruiters',
      dataIndex: 'recruiters',
      width: 450,
      ...getColumnSearchProps('recruiters'),
    },
    {
      title: 'Created on',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt, row) =>
        moment(parseInt(createdAt)).format('DD MMM YYYY'),
    },
    {
      title: 'Action',
      key: '_id',
      dataIndex: '_id',
      fixed: 'right',
      width: 180,
      render: rejectedActionsHandler,
    },
    // {
    //   title: 'Status',
    //   key: 'status',
    //   dataIndex: 'status',
    //   // fixed: 'right',
    //   width: 180,
    //   render: (status, row) => (
    //     <Button
    //       className="ant-btn-teal"
    //       style={{
    //         border: '1px solid rgba(0, 33, 84, 0.7)',
    //         background: 'rgba(0, 33, 84, 0.13)',
    //         borderRadius: '2px',
    //         color: '#990000',
    //       }}
    //     >
    //       {status}
    //     </Button>
    //   ),
    // },
  ];

  return (
    <>
      <PageTitle
        // back
        title="List of jobs"
        btnText="Add job"
        to={`${url}/add`}
        btnActive={true}
      />
      {!loading && !data?.AllJobs ? (
        <ErrorCard />
      ) : (
        <>
          <TopSearchbar onSearch={handleSearchTop} isJob={true} />
          <Row justify="center" className="pb-4" gutter={16}>
            <Col span={24}>
              <Fade>
                <Card>
                  {jobsCount && (
                    <Tabs defaultActiveKey="1" onChange={onChange}>
                      <TabPane
                        tab={
                          <span>
                            <span style={{ fontSize: '16px' }}>
                              Active jobs
                            </span>
                            {AllJobsCount?.ActiveJobs && (
                              <span className="tab-pane-count">
                                {/* {numberWithCommas(AllJobsCount?.ActiveJobs)} */}
                                {countParsedInt?.active}
                                {/* setCountParsedInt */}
                              </span>
                            )}
                          </span>
                        }
                        key="1"
                      >
                        {activeJobs && (
                          <ActiveJobsTable
                            realtimeCountHandler={realTimeCountHandler}
                            count={jobsCount}
                            searchParams={searchData}
                            currentTab={activeTab}
                            AllJobsCountHandler={AllJobsCountHandler}
                          />
                        )}
                      </TabPane>
                      <TabPane
                        tab={
                          <span>
                            <span style={{ fontSize: '16px' }}>
                              Pending jobs
                            </span>
                            {AllJobsCount && (
                              <span className="tab-pane-count">
                                {/* {numberWithCommas(
                                  AllJobsCount?.PendingJobs
                                    ? AllJobsCount?.PendingJobs
                                    : jobsCount?.pendingJobsCount
                                )} */}
                                {countParsedInt?.pending}
                              </span>
                            )}
                          </span>
                        }
                        key="3"
                      >
                        {pendingJobs && (
                          <PendingJobsTable
                            realtimeCountHandler={realTimeCountHandler}
                            count={jobsCount}
                            currentTab={activeTab}
                            searchParams={searchData}
                            AllJobsCountHandler={AllJobsCountHandler}
                          />
                        )}
                      </TabPane>
                      <TabPane
                        tab={
                          <span>
                            <span style={{ fontSize: '16px' }}>
                              Rejected jobs
                            </span>
                            {AllJobsCount && (
                              <span className="tab-pane-count">
                                {/* {numberWithCommas(
                                  AllJobsCount?.RejectedJobs
                                    ? AllJobsCount?.RejectedJobs
                                    : jobsCount?.rejectedJobsCount
                                )} */}
                                {countParsedInt?.rejected}
                              </span>
                            )}
                          </span>
                        }
                        key="4"
                      >
                        {rejectedJobs && (
                          <RejectedJobsTable
                            realtimeCountHandler={realTimeCountHandler}
                            count={jobsCount}
                            currentTab={activeTab}
                            searchParams={searchData}
                            AllJobsCountHandler={AllJobsCountHandler}
                          />
                        )}
                      </TabPane>
                      <TabPane
                        tab={
                          <span>
                            <span style={{ fontSize: '16px' }}>
                              Archived jobs
                            </span>
                            {AllJobsCount && (
                              <span className="tab-pane-count">
                                {/* {numberWithCommas(
                                  AllJobsCount?.ArchivedJobs
                                    ? AllJobsCount?.ArchivedJobs
                                    : jobsCount?.archivedJobsCount
                                )} */}
                                {countParsedInt?.archived}
                              </span>
                            )}
                          </span>
                        }
                        key="2"
                      >
                        {archivedJobs && (
                          <ArchiveJobsTable
                            count={jobsCount}
                            currentTab={activeTab}
                            searchParams={searchData}
                            AllJobsCountHandler={AllJobsCountHandler}
                          />
                        )}
                      </TabPane>
                    </Tabs>
                  )}
                </Card>
              </Fade>
            </Col>
          </Row>
        </>
      )}
      {/* <Drawer
        title="Recruiters"
        placement="right"
        closable={true}
        onClose={() => setDisplayCompanyDrawer(false)}
        visible={displayCompanyDrawer}
        className="menu-drawer"
      >
        test
      </Drawer> */}
      {/* <RecruiterDrawer
        show={displayCompanyDrawer}
        hide={() => setDisplayCompanyDrawer(false)}
      />
      <JobDrawer
        show={displayJobDrawer}
        hide={() => setDisplayJobDrawer(false)}
      /> */}
    </>
  );
};

export default JobsList;
