import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthState } from '../../store/auth/types';
import { auth } from '../../store/auth/actions';
import { Link, Redirect } from 'react-router-dom';
import Logo from '../../components/UI/Logo/Logo';
import Footer from '../../components/Common/Footer/Footer';
// import Reveal from 'react-reveal/Reveal';
import {
  Card,
  Row,
  Col,
  Form,
  Input,
  Button,
  Checkbox,
  notification,
} from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { LockOutlined } from '@ant-design/icons';
import { useAdminLoginMutation } from '../../graphql/generated/graphql';
import './login.scss';

interface Rememered {
  email: string;
  password: string;
}
const Login = () => {
  //error state to control form
  const [hasError, setHasError] = useState(false);
  const [loginMutation, { loading }] = useAdminLoginMutation();

  const remembered = JSON.parse(localStorage.getItem('remember') || '{}');

  //check authentication
  const isAuthenticated = useSelector(
    (state: AuthState) => state.auth.accessToken !== null
  );

  const { error } = useSelector((state: AuthState) => state.auth);

  useEffect(() => {
    if (error) {
      setHasError(true);
      notification['error']({
        message: 'Unable to Login',
        description: error,
        duration: 3,
        onClose: () => setHasError(false),
      });
    }
  }, [error]);
  useEffect(() => {
    localStorage.removeItem('fpsDisplay');
  }, []);

  const { authRedirectPath } = useSelector((state: AuthState) => state.auth);

  // http actions
  const dispatch = useDispatch();

  //on submit login form
  const onFinish = async (values: any) => {
    console.log('run');

    if (values.remember) {
      const rememberMe = {
        email: values.emailAddress,
        password: values.password,
      };
      localStorage.setItem('remember', JSON.stringify(rememberMe));
    }

    loginMutation({
      variables: {
        email: values.emailAddress,
        password: values.password,
      },
    })
      .then(({ data }) => {
        const accessToken: string = data?.adminLogin?.token || '';
        const fullName: string = data?.adminLogin?.fullName || '';
        dispatch(auth(accessToken, fullName));
      })
      .catch((err) => {
        const errors = err.graphQLErrors.map((el: any) => el.message);
        notification['error']({
          message: 'Unable to Login',
          description: errors.map((er: any) => <div key={er}>{er}</div>),
          duration: 3,
          onClose: () => setHasError(false),
        });
      });
  };

  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {isAuthenticated && <Redirect to={authRedirectPath} />}
      <div style={{ width: '1500px' }}>
        <div className="" style={{ marginBottom: '20px' }}>
          <h3 className="text-center">
            <Logo height="40" />
          </h3>
        </div>
        <Row justify="center">
          <Col span={10}>
            <Card>
              <div className="py-3 card-alignment login-wrapper">
                <div className="pb-4" style={{ position: 'relative' }}>
                  {/* <p className="text-muted text-center pt-0 mtp0">
                      Don't have an account ?{' '}
                      <Link to="register" className="text-primary">
                        Register now
                      </Link>
                      </p> */}
                  <Form
                    name="normal_login"
                    className="login-form pt-3"
                    layout="vertical"
                    onFieldsChange={() => setHasError(false)}
                    initialValues={{
                      remember: true,
                      emailAddress: remembered && remembered.email,
                      password: remembered && remembered.password,
                    }}
                    onFinish={onFinish}
                  >
                    <Form.Item
                      name="emailAddress"
                      label={
                        <label
                          style={{
                            fontWeight: 400,
                            fontSize: '14px',
                            padding: '0px',
                            lineHeight: '0px',
                          }}
                        >
                          Email address
                        </label>
                      }
                      rules={[
                        {
                          type: 'email',
                          message: 'The input is not valid E-mail!',
                        },
                        {
                          required: true,
                          message: 'Please input your E-mail!',
                        },
                      ]}
                    >
                      <Input
                        prefix={<i className="far fa-envelope font-14" />}
                        placeholder="Email address"
                      />
                    </Form.Item>
                    <Form.Item
                      name="password"
                      label={
                        <label
                          style={{
                            fontWeight: 400,
                            fontSize: '14px',
                            padding: '0px',
                            lineHeight: '0px',
                          }}
                        >
                          Password
                        </label>
                      }
                      rules={[
                        {
                          required: true,
                          message: 'Please input your password!',
                        },
                        {
                          whitespace: true,
                          message: 'No whitespace allowed',
                        },
                        {
                          min: 4,
                          message: 'Please input your valid password!',
                        },
                      ]}
                    >
                      <Input.Password
                        prefix={
                          <LockOutlined className="site-form-item-icon" />
                        }
                        type="password"
                        placeholder="Password"
                      />
                    </Form.Item>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginTop: '0px',
                      }}
                    >
                      <Form.Item
                        name="remember"
                        valuePropName="checked"
                        noStyle
                      >
                        <Checkbox style={{ color: '#012653' }}>
                          <b
                            style={{
                              fontWeight: 500,
                              fontSize: '14px',
                              paddingLeft: '6px',
                            }}
                          >
                            Remember me
                          </b>
                        </Checkbox>
                      </Form.Item>
                      <Form.Item noStyle>
                        <Button
                          className="login-btn"
                          // type="amber"
                          type="primary"
                          htmlType="submit"
                          shape="round"
                          // disabled={error}
                          loading={loading}
                        >
                          Log in
                        </Button>
                      </Form.Item>
                    </div>

                    <Form.Item>
                      <Link
                        to="recover-password"
                        className="text-muted m-t-10 login-link"
                      >
                        <span className="login-link-span">
                          Forgot your password?
                        </span>
                      </Link>
                    </Form.Item>
                  </Form>
                </div>
              </div>
              <Footer footer={false} />
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Login;
