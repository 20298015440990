export interface Recruiters {
  recruiters: [];
}

export interface RecruitersState {
  recruiters: Recruiters;
}

// Describing the different ACTION NAMES available
export const STORE_RECRUITERS = 'STORE_RECRUITERS';
export const STORE_RECRUITER = 'STORE_RECRUITER';

interface storeRecruiters {
  type: typeof STORE_RECRUITERS;
  recruiters: Array<Object>
}
interface storeRecruiter {
  type: typeof STORE_RECRUITER;
  recruiter: Object
}


export type recruitersActionTypes =
  | storeRecruiters
  | storeRecruiter