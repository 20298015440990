import React, { useContext } from 'react';
import { Upload, message } from 'antd';
import { UploadIcon, ViewIcon, DeleteIcon } from 'assets/icons/DetailedProfile';
import { EditContext } from './Context/EditContext';
import './styles.scss';

interface PropsTypes {
  fileLink?: string;
  updateAction?: any;
  title?: any;
  candidateId?: string;
}

const Styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  btn: {
    cursor: 'pointer',
    marginLeft: 5,
  },
};

const UVDButton: React.FC<PropsTypes> = ({
  fileLink,
  updateAction,
  title,
  candidateId,
}) => {
  const editContext = useContext(EditContext);
  const { setData, setUpdatedData }: any = editContext;

  const viewResume = () => {
    window.open(
      fileLink?.includes('.pdf')
        ? `${fileLink}#toolbar=0&scrollbar=0&view=fitH&view=fitV`
        : `https://docs.google.com/gview?url=${fileLink}&embedded=true`
    );
  };

  const deleteResume = () => {
    if (title === 'Attachment') {
      setData({
        ...editContext?.candidate,
        coverLetter: null,
      });
      setUpdatedData({
        ...editContext?.updatedData,
        coverLetter: null,
      });
    } else if (title === 'Resume') {
      setData({
        ...editContext?.candidate,
        resume: null,
      });
      setUpdatedData({
        ...editContext?.updatedData,
        resume: null,
      });
    }
  };

  const beforeUpload = (file: File) => {
    const isValidType =
      file.type === 'application/pdf' ||
      file.type ===
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

    if (!isValidType) {
      message.error('You can only upload PDF or DOCX files!');
    }

    return isValidType; // Prevent upload if file type is not valid
  };

  return (
    <div style={Styles.root}>
      {editContext?.edit && (
        <div className="uploader-wrapper">
          <Upload
            style={{ width: '100%', height: 95, borderRadius: 10 }}
            action={`${process.env.REACT_APP_BASE_REST_URL}/upload/candidates/${candidateId}`}
            onChange={updateAction}
            listType="text"
            data={{
              upload_preset: 'cqdbrmkw',
            }}
            accept=".docx,.pdf"
            multiple={false}
            beforeUpload={beforeUpload} // Add the beforeUpload function here
          >
            <img style={Styles.btn} src={UploadIcon} alt="Upload" />
          </Upload>
        </div>
      )}
      {fileLink && (
        <img
          onClick={viewResume}
          style={Styles.btn}
          src={ViewIcon}
          alt="View"
        />
      )}
      {editContext?.edit && fileLink && (
        <img
          onClick={deleteResume}
          style={Styles.btn}
          src={DeleteIcon}
          alt="Delete"
        />
      )}
    </div>
  );
};

export default UVDButton;
