import { Typography, Radio, message } from 'antd';
import { useActivateCandidateMutation } from 'graphql/generated/graphql';
import React, { useContext, useEffect, useState } from 'react';
import { EditContext } from '../../Context/EditContext';
import './styles.scss';
import { StoreActiveJobs } from 'store/jobs/actions';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
interface PropsType {
  candidate: any;
  match: any;
  refetch?: any;
}

const { Title } = Typography;

const Styles = {
  flexDefault: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    paddingLeft: 2,
  },
  btn: {
    width: 28,
    height: 28,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 36,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    backgroundColor: '#F5F7F9',
    marginTop: -5,
    marginRight: 20,
    cursor: 'pointer',
  },
  radio: {
    marginRight: 10,
    borderRadius: 40,
    padding: 5,
    borderWidth: 1,
    height: 30,
    width: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    backgroundColor: '#F5F7F9',
  },
  radioGroup: {
    display: 'flex',
    gap: '6px',
  },
};

const Activation: React.FC<PropsType> = ({ candidate, match, refetch }) => {
  const editContext = useContext(EditContext);
  const { matchId, jobId } = useParams<{ matchId: string; jobId: string }>();
  const jobs = useSelector((state: any) => state.jobs?.activeJobs);

  const [score, setScore] = useState<any>(match?.activationScore);
  const [addActivateCandidateMutation] = useActivateCandidateMutation();
  useEffect(() => {
    if (editContext?.match?.activationScore != score) {
      setScore(editContext?.match?.activationScore);
    }
  }, [editContext?.match?.activationScore]);
  const dispatch = useDispatch();
  const updateJobsCandidate = (id: any, value: any) => {
    const jobToUpdate = jobs?.map((job: any) => {
      if (job?._id === jobId) {
        job.newCandidates.map((candidate: any) => {
          if (candidate._id === id) {
            console.log(candidate);
            candidate.activationScore = value;
          }
          return candidate;
        });
      }
      return job;
    });
    dispatch(StoreActiveJobs(jobToUpdate));
  };
  const handleChangeScore = (e: any) => {
    addActivateCandidateMutation({
      variables: {
        matchId: editContext?.match?._id || match?._id,
        activationScore: parseInt(e.target.value),
      },
    })
      .then((res) => {
        setScore(e.target.value);
        updateJobsCandidate(matchId, e.target.value);
        if (refetch) refetch();
        message.success({ content: 'Activation score updated!', duration: 2 });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setScore(match?.activationScore);
  }, [match?.activationScore]);

  return (
    <div style={Styles.flexDefault}>
      <Title
        style={{ fontSize: 16, margin: 0, marginTop: -8, width: 110 }}
        className="titleStatus"
      >
        Activation
      </Title>
      <Radio.Group
        onChange={handleChangeScore}
        className="activation-radio"
        style={Styles.radioGroup}
        value={
          match?.activationScore
          // || score
        }
        buttonStyle="solid"
      >
        <Radio.Button style={Styles.radio} value={-1}>
          -1
        </Radio.Button>
        <Radio.Button style={Styles.radio} value={0}>
          0
        </Radio.Button>
        <Radio.Button style={Styles.radio} value={1}>
          1
        </Radio.Button>
        <Radio.Button style={Styles.radio} value={2}>
          2
        </Radio.Button>
      </Radio.Group>
    </div>
  );
};

export default Activation;
