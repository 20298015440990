import React, { useContext, useEffect, useRef, useState } from 'react';
import { Drawer, Form, Tag, Select, Button, Space, Row, Col } from 'antd';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { useSectorsQuery } from 'graphql/generated/graphql';
import { EditContext } from '../Context/EditContext';

interface PropsType {
  candidate: any;
  open: boolean;
  handleClose?: any;
  onUpdate?: (data: any) => void;
}

const Styles = {
  tagsWrapper: {
    marginTop: 20,
  },
  submitBtn: {
    width: 101,
    float: 'right',
    backgroundColor: '#BC9623',
    borderRadius: 20,
    marginTop: 20,
    border: 'none',
    color: 'white',
  },
};

const ProfessionalInterestsDrawer: React.FC<PropsType> = ({
  candidate,
  open,
  handleClose,
  onUpdate,
}) => {
  const [tags, setTags] = useState<any>([]);
  const editContext = useContext(EditContext);
  const { setData, setUpdatedData }: any = editContext;
  const ref = useRef<any>(null);
  const { data, loading, error } = useSectorsQuery();
  const [targetSectors, setTargetSectors] = useState<any>([
    {
      _id: '61eecf80f21816aa12cd6878',
      title: 'Art',
      label1: '&',
      label2: 'Entertainment',
      selected: false,
    },
    {
      _id: '5fff12254a28d2476b41ecda',
      title: 'Comms',
      label1: '&',
      label2: 'Marketing',
      selected: false,
    },
    { _id: '61eecf42f21816aa12cd6877', title: 'Construction', selected: false },
    {
      _id: '5fff12254a28d2476b41ecdd',
      title: 'Defence',
      label1: '&',
      label2: 'Aerospace',
      selected: false,
    },
    { _id: '5fff12254a28d2476b41ece1', title: 'Education', selected: false },
    {
      _id: '5fff12254a28d2476b41ecde',
      title: 'Energy',
      label1: '&',
      label2: 'Environment',
      selected: false,
    },
    {
      _id: '5fff12254a28d2476b41ed34',
      title: 'Finance',
      label1: '&',
      label2: 'Banking',
      selected: false,
    },
    {
      _id: '5fff12254a28d2476b41ecd6',
      title: 'Healthcare',
      label1: '&',
      label2: 'Biotech',
      selected: false,
    },
    { _id: '5fff12254a28d2476b41ecd7', title: 'ITC', selected: false },
    { _id: '5fff12254a28d2476b41ece2', title: 'Other', selected: false },
    {
      _id: '5fff12254a28d2476b41ece0',
      title: 'Professional',
      label1: 'Services',
      selected: false,
    },
    { _id: '5fff12254a28d2476b41ecdb', title: 'Public', selected: false },
  ]);
  const [selectedTagIds, setSelectedTagIds] = useState<string[]>([]);
  useEffect(() => {
    if (!loading && data) {
      setTags(data?.sectors);
    }
  }, [data]);
  useEffect(() => {
    setSelectedTagIds(candidate?.sectors?.map((obj: any) => obj?._id) || []);
  }, [candidate]);
  const SingleTag = ({ title, color, id }: any) => {
    const isMatch = selectedTagIds?.includes(id);
    const backgroundColor = isMatch ? '#990000' : '#FFFFFF';
    const matchColor = isMatch ? '#FFFFFF' : '#990000';
    const fontSize = isMatch ? 14 : 15;

    const handleClick = (id: any) => {
      setSelectedTagIds((prevSelectedTagIds) => {
        if (!prevSelectedTagIds.includes(id) && prevSelectedTagIds.length < 5) {
          return [...prevSelectedTagIds, id];
        } else if (prevSelectedTagIds.includes(id)) {
          return prevSelectedTagIds.filter((tagId) => tagId !== id);
        } else {
          return prevSelectedTagIds;
        }
      });
    };

    return (
      <div
        onClick={() => handleClick(id)}
        style={{
          display: 'flex',
          backgroundColor,
          color: matchColor,
          maxWidth: '120px',
          padding: '15px',
          border: '1px solid #990000',
          borderRadius: '8px',
          marginBottom: '10px',
          height: '80px',
          cursor: 'pointer',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <span
          style={{
            lineHeight: 1.4,
            margin: '0 auto',
            wordWrap: 'break-word',
            width: '150%', // Set a specific width for the span
          }}
        >
          <span
            style={{
              fontSize: fontSize,
              fontWeight: 400,
              fontFamily: 'Poppins',
            }}
          >
            {title}
          </span>
          <br />
        </span>
      </div>
    );
  };

  const handleData = () => {
    if (candidate?.sectors.length <= 5) {
      const newTags = tags.filter((tag: any) =>
        selectedTagIds.includes(tag._id)
      ); // for update data in real time
      setData({
        ...editContext?.candidate,
        sectors: [...newTags],
      });
      setUpdatedData({
        ...editContext?.updatedData,
        sectors: newTags,
      });
    }
    handleClose();
  };
  const handleCloseDrawer = () => {
    setSelectedTagIds(candidate?.sectors?.map((obj: any) => obj?._id) || []);
    handleClose();
  };
  return (
    <div>
      <Drawer
        title="Edit target sectors"
        className="Ant-D-Title"
        placement={'right'}
        visible={open}
        onClose={handleCloseDrawer}
        extra={
          <Space onClick={handleData} style={{ cursor: 'pointer' }}>
            <CloseOutlined />
          </Space>
        }
      >
        <div style={{ marginTop: 8 }}>
          <Row gutter={8}>
            {targetSectors?.map((tag: any) => (
              <Col span={8} key={tag._id}>
                <SingleTag
                  key={tag._id}
                  id={tag._id}
                  title={`${tag.title}${' '}${
                    tag?.label1 !== undefined ? tag?.label1 : ''
                  }${' '}${tag?.label2 !== undefined ? tag?.label2 : ''}`}
                />
              </Col>
            ))}
          </Row>
        </div>
      </Drawer>
    </div>
  );
};

export default ProfessionalInterestsDrawer;
