import { useState, useEffect } from 'react';
import { useGetCandidatesQuery } from '../graphql/generated/graphql';

const useCandidates = () => {
  const [candidates, setCandidates]: any = useState([]);
  const { data, loading, error } = useGetCandidatesQuery();

  const loadcandidates = () => {
    if (!loading && data) {
      setCandidates([...data.allCandidates]);
    }
  };

  useEffect(() => {
    loadcandidates();
  }, [data]);

  return candidates;
};

export default useCandidates;
