import React, { useState, useEffect } from 'react';
import {
  Input,
  Select,
  Button,
  Modal,
  Drawer,
  Form,
  Space,
  List,
  notification,
} from 'antd';
import Icon, {
  FolderOpenOutlined,
  SearchOutlined,
  ExclamationCircleOutlined,
  DeleteFilled,
  CheckCircleOutlined,
  EditOutlined,
} from '@ant-design/icons';
import type { DrawerProps } from 'antd/es/drawer';
import {
  useGetTagsQuery,
  useCreateTagMutation,
  useDeleteTagMutation,
  useEditTagMutation,
} from '../../../../graphql/generated/graphql';
import './styles.css';
import './TagsWidget.scss';

interface Props {
  onClose: any;
  visibleEditTags?: boolean;
  handleUpdateTags: any;
  updateTagsList: any;
  handleUpdateCandidatesTags: any;
}

interface ConfirmModalProps {
  tagName: string;
  tagId: any;
  tagColor?: string;
}

const TagsDrawer: React.FC<Props> = ({
  onClose,
  visibleEditTags,
  handleUpdateTags,
  updateTagsList,
  handleUpdateCandidatesTags,
}) => {
  const [drawerPlacement, setDrawerPlacement] =
    useState<DrawerProps['placement']>('right');
  const [tags, setTags] = React.useState<any[]>([]);
  const [editModalVisible, setEditModalVisible] =
    React.useState<boolean>(false);
  const [editTagName, setEditTagName] = React.useState<string>();
  const [editTagID, setEditTagID] = React.useState<string>();
  const [editTagColor, setEditTagColor] = React.useState<string>();
  const [allTags, setAllTags] = React.useState<any[]>([]);
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();
  const { confirm } = Modal;
  const { data: tag, loading: tagsLoading }: any = useGetTagsQuery({
    fetchPolicy: 'network-only',
  });

  // GraphQL Mutations
  const [
    createTagMutation,
    { data: tagData, loading: tagLoading, error: tagError },
  ] = useCreateTagMutation();
  const [
    deleteTagMutation,
    { data: deleteTagData, loading: deleteTagLoading, error: deleteError },
  ] = useDeleteTagMutation();
  const [editTagMutation] = useEditTagMutation();

  useEffect(() => {
    if (tag?.getTags) {
      setTags(tag.getTags);
      setAllTags(tag.getTags);
    }
  }, [tag]);

  // Searching the tag on keyDown event
  const handleTagSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.value != null && e.target.value != '') {
      console.log(e.target.value);
      let searchable = allTags.filter(
        (tag: any) =>
          tag.name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
      );
      setTags(searchable);
    } else {
      setTags(allTags);
    }
    return false;
  };

  // Creating New Tag
  const handleFormFinish = (values: any) => {
    const tagExist = allTags.some(
      (tag) => tag.name.toLowerCase() == values['tag-name'].toLowerCase()
    );
    if (!tagExist) {
      createTagMutation({
        variables: {
          name: [values['tag-name']],
        },
      }).then(({ data }) => {
        let newTag = {
          _id: data?.createTag[0]._id,
          name: data?.createTag[0].name,
          color: data?.createTag[0].color,
        };
        let newTags = [newTag, ...tags];
        setTags(newTags);
        form.resetFields();
        setAllTags(newTags);
        updateTagsList(newTag);
        showNotification('New tag created successfuly!');
      });
    } else {
      showNotification("Duplicate tags aren't allowed!", 'red');
    }
  };

  // Show confirmation pop-up on before deletion
  const showDeleteConfirm = (props: ConfirmModalProps) => {
    const { tagName, tagId } = props;
    confirm({
      title: 'Are you sure you want to delete "' + tagName + '"?',
      icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDeleteTag(tagId);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  // Pop-up for editing a tag
  const EditTagModal = () => {
    return (
      <Modal
        // title="Edit a tag"
        width={400}
        style={{ borderRadius: 0 }}
        centered
        // visible={editModalVisible}
        open={editModalVisible}
        footer={null}
        onCancel={() => setEditModalVisible(false)}
        className="edit-tag-modal"
      >
        <div
          className=""
          style={{
            border: 0,
            borderBottom: 1,
            borderColor: 'black',
            borderStyle: 'solid',
          }}
        >
          <h4 style={{ marginLeft: 27, marginTop: 0, fontSize: '18px' }}>
            Edit a tag
          </h4>
        </div>
        <Form
          validateMessages={{ required: 'Tag name is required' }}
          form={editForm}
          onFinish={handleUpdateTag}
        >
          <Form.Item
            className="custom-body-modal"
            name="edit-tag-name"
            rules={[{ required: true }]}
            style={{ marginTop: 30 }}
          >
            <Input
              style={{ borderRadius: 10, height: 50 }}
              placeholder="Tag name"
              maxLength={50}
            />
          </Form.Item>
          <Form.Item className="custom-footer-modal" style={{ margin: 0 }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                onClick={() => setEditModalVisible(false)}
                style={{
                  height: 44,
                  backgroundColor: 'white',
                  borderColor: '#5B626B',
                  color: '#5B626B',
                  textShadow: 'none',
                  borderRadius: 5,
                  marginRight: 5,
                  boxShadow: 'none',
                  fontSize: '16px',
                }}
                type="primary"
                htmlType="submit"
              >
                Cancel
              </Button>
              <Button
                style={{
                  height: 44,
                  backgroundColor: '#53946F',
                  borderColor: '#53946F',
                  boxShadow: 'none',
                  borderRadius: 5,
                  color: 'white',
                  fontSize: '16px',
                }}
                type="primary"
                htmlType="submit"
              >
                <span style={{ color: 'white' }}> Update</span>
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  // Edit the tag
  const handleEditTag = (props: ConfirmModalProps) => {
    const { tagId, tagName, tagColor } = props;
    setEditTagID(tagId);
    setEditTagColor(tagColor);
    setEditTagName(tagName);
    editForm.setFieldsValue({
      'edit-tag-name': tagName,
    });
    setEditModalVisible(true);
  };

  // Update the tag in backend
  const handleUpdateTag = (values: any) => {
    let newName = values['edit-tag-name'];
    setEditModalVisible(false);
    editTagMutation({
      variables: {
        tagId: editTagID,
        name: newName,
      },
    }).then((res) => {
      let updatedTags = tags.map((tag) => {
        if (tag._id == editTagID) return { ...tag, name: newName };

        return tag;
      });
      setAllTags(updatedTags);
      setTags(updatedTags);
      handleUpdateTags(updatedTags);
      handleUpdateCandidatesTags({
        _id: editTagID,
        name: newName,
        color: editTagColor,
      });
      showNotification('Tag updated successfully!');
    });
  };

  // Delete the tag
  const handleDeleteTag = (tagId: string) => {
    deleteTagMutation({
      variables: {
        tagId: tagId,
      },
    }).then(({ data }) => {
      setAllTags(allTags.filter((tag) => tag._id != tagId));
      setTags(tags.filter((tag) => tag._id != tagId));
      showNotification('Tag deleted successfuly!');
    });
  };

  // Show success notification
  const showNotification = (message: string, color = 'green') => {
    const args = {
      message: message,
      duration: 5,
      icon: <CheckCircleOutlined style={{ color: color }} />,
    };
    notification.open(args);
  };

  return (
    <Drawer
      title={<span style={{ fontSize: '18px' }}>Manage tags</span>}
      placement={drawerPlacement}
      width={500}
      closable
      onClose={onClose}
      visible={visibleEditTags}
      className="edit-drawer"
    >
      <>
        <Form
          validateMessages={{ required: 'Tag name is required' }}
          layout="inline"
          form={form}
          onFinish={handleFormFinish}
        >
          <Form.Item
            style={{ flex: 1, height: 30, marginRight: 5 }}
            name="tag-name"
            rules={[{ required: true }]}
          >
            <Input
              style={{ borderRadius: 10, minHeight: 50 }}
              maxLength={50}
              placeholder="Type a new tag"
            />
          </Form.Item>
          <Form.Item style={{ marginRight: 0, height: 35 }}>
            <Button
              style={{
                height: 50,
                backgroundColor: '#53946F',
                borderColor: '#53946F',
                boxShadow: 'none',
                borderRadius: 10,
                textShadow: 'none',
                fontSize: '16px',
              }}
              type="primary"
              htmlType="submit"
            >
              Create a tag
            </Button>
          </Form.Item>
        </Form>
        {/* <h3 style={{ marginTop: 30, marginBottom: 10 }}>Tags</h3> */}
        <Form.Item style={{ marginTop: 40 }}>
          <Input
            placeholder="Enter a tag"
            allowClear
            style={{ borderRadius: 10, minHeight: 50 }}
            onChange={handleTagSearch}
            className="filter-search-full"
          />
        </Form.Item>
        <List
          style={{
            marginTop: 10,
            boxShadow: '1px 1px 3px 1px rgba(0, 0, 0, 0.25)',
            boxSizing: 'border-box',
          }}
          dataSource={tags}
          renderItem={(item: any, index: any) => (
            <List.Item
              className="list-item"
              style={{ border: 'none', padding: 5 }}
              key={index}
            >
              <List.Item.Meta title={item.name} />
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <DeleteFilled
                  className="delete-btn"
                  style={{ color: '#990000', marginRight: 5 }}
                  onClick={() =>
                    showDeleteConfirm({ tagName: item.name, tagId: item._id })
                  }
                />
                <EditOutlined
                  className="delete-btn"
                  style={{ color: '#BC9623' }}
                  onClick={() =>
                    handleEditTag({
                      tagName: item.name,
                      tagId: item._id,
                      tagColor: item.color,
                    })
                  }
                />
              </div>
            </List.Item>
          )}
        />
      </>
      <EditTagModal />
    </Drawer>
  );
};

export default TagsDrawer;
