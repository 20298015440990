import React, { useState, useEffect, useContext } from 'react';
import { useParams, useLocation, useHistory, Link } from 'react-router-dom';

import PageTitle from 'components/UI/PageTitle/PageTitle';
import TopSearchbar from 'components/SearchComponent/TopSearch';
import TableActivity from '../ActivityTableComponent/Table';
import { useGetAllMatchQuery } from 'graphql/generated/graphql';
import { Button, Col, Row, Typography } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import icon from './Icon/Vector.svg';
import { EditContext } from '../Profile/Context/EditContext';
import './activityTable.scss';
import { stat } from 'fs';
import { from } from '@apollo/client';
interface listingId {
  locationUrl?: any;
  candidateId?: any;
  candidateName?: any;
  candidateMatchId?: any;
  candidateArray?: any;
  fromProfile?: any;
  candidateIDs?: any;
  previousLocation?: any;
}
interface DataSearch {
  searchText: any;
  searchType: any;
}
const { Title } = Typography;

export default function ActivityTable() {
  const editContext: any = useContext(EditContext);
  const { setActivityDatas, activityData }: any = editContext;

  const history = useHistory();
  const { state } = useLocation<listingId>();

  const [searchData, setSearchData] = useState<DataSearch>();
  const candidateName = state?.candidateName;
  console.log(activityData);

  const candidateListingId: any = state?.candidateId;
  // console.log(candidateListingId, 'candidateParam');
  const [loadingMatches, setLoadingMatches] = useState(true);
  const [getAllMatches, setGetAllMatches]: any = useState([]);
  function handleClick() {
    let formAnotherJob: any = state?.previousLocation?.split('/Activity')[0];
    history.push({
      pathname: state?.previousLocation
        ? formAnotherJob
        : activityData?.fromURL,
      state: {
        candidateArray: !activityData?.candidateIDs
          ? activityData?.candidateArray
          : activityData?.candidateIDs,
        candidateId: activityData?.candidateId,
        fromURL: activityData?.locationUrl
          ? activityData?.locationUrl
          : activityData?.fromURL,
      },
    });
  }
  useEffect(() => {
    if (
      !state?.locationUrl?.includes('Activity') &&
      state?.fromProfile == true &&
      state?.locationUrl !== undefined
    ) {
      let obj = {
        candidateArray: state?.candidateArray?.candidateArray,
        candidateId: state?.candidateId,
        fromURL: state?.locationUrl,
      };
      setActivityDatas(obj);
    }
    if (state?.candidateArray?.candidateIDs) {
      let obj = {
        candidateIDs: state?.candidateArray?.candidateIDs,
        candidateId: state?.candidateId,
        fromURL: state?.locationUrl,
      };
      setActivityDatas(obj);
    }
  }, [state?.locationUrl]);

  const handleSearch = (searchText: string, searchField: string) => {
    // Do something with the search text and field
    // console.log(searchText, searchField, 'data in activity table');
    setSearchData({
      ...searchData,
      searchText: searchText ? searchText : '',
      searchType: searchField ? searchField : 'All',
    });
  };

  const { data, loading, error } = useGetAllMatchQuery({
    variables: {
      ListingId: candidateListingId,
      searchField: searchData ? searchData?.searchType : 'All',
      searchText: searchData?.searchText ? searchData?.searchText : '',
    },
  });

  const loadgetAllMatches = () => {
    if (!loading && data) {
      setGetAllMatches(data);
      setLoadingMatches(false);
    }
  };

  useEffect(() => {
    loadgetAllMatches();
    setLoadingMatches(false);
  }, [data]);
  return (
    <>
      <div className="page-title-box">
        <Row justify="space-between" gutter={16}>
          <Col span={4} style={{ marginTop: 6 }}>
            <Button
              className="ant-btn-teal"
              shape="round"
              size="middle"
              icon={<ArrowLeftOutlined />}
              onClick={handleClick}
            >
              Back
            </Button>
          </Col>
          <Col span={8}>
            <div className="text-center">
              <Title level={4} className="title">
                {candidateName}'s activity and status
              </Title>
            </div>
          </Col>
          <Col span={4}></Col>
        </Row>
      </div>
      <TopSearchbar onSearch={handleSearch} />
      <TableActivity
        candidateName={candidateName}
        data={getAllMatches}
        activityData={activityData}
        loadingMatches={loadingMatches}
        listingId={candidateListingId}
      />
    </>
  );
}
