import React, { useContext, useState } from 'react';
import { EditContext } from '../../../Context/EditContext';

interface PropsType {
  comment: any;
}

const Styles = {
  root: {},
  commentBox: {
    paddingLeft: 25,
    paddingRight: 25,
    paddingTop: 8,
    paddingBottom: 8,
    backgroundColor: 'rgba(188, 150, 35, 0.03)',
    marginTop: 10,
    borderRadius: 9,
    marginBottom: 17,
    boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.14)',
    border: 'solid 0.5px #DADADA',
  },
  body: {
    margin: 0,
    color: '#495057',
    fontSize: 14,
    fontWeight: 400,
    fontStyle: 'italic',
  },
  heading: {
    margin: 0,
    color: '#012653',
    fontSize: 14,
    fontWeight: 500,
  },
};

const EducationComment: React.FC<PropsType> = ({ comment }) => {
  const formattedComment = comment?.replace(/\n/g, '<br />');
  const [commentValue, setCommentValue] = useState(formattedComment);
  const editContext = useContext(EditContext);
  const handleChange = (e: any) => {
    setCommentValue(e.target.value);
    editContext?.setUpdatedData({
      ...editContext?.updatedData,
      educationComment: e.target.value,
    });
    editContext?.setData({
      ...editContext?.candidate,
      educationComment: e.target.value,
    });
  };
  return (
    <div style={Styles.commentBox}>
      <p style={Styles.heading}>Comments:</p>
      {editContext?.edit ? (
        <textarea
          name=""
          id=""
          value={commentValue}
          style={{
            width: '100%',
            fontStyle: 'italic',
            fontSize: '14px',
            border: 'none',
            resize: 'none',
            backgroundColor: 'transparent',
            outline: 'none',
          }}
          onChange={handleChange}
        ></textarea>
      ) : (
        <div
          style={Styles.body}
          dangerouslySetInnerHTML={{ __html: formattedComment }}
        />
      )}
    </div>
  );
};

export default EducationComment;
