import React, { useState, useEffect } from 'react';
import {
  Modal,
  Form,
  Input,
  Button,
  notification,
  Tooltip,
  Popconfirm,
  Space,
  Table,
} from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { uniqBy } from 'lodash';
import { SkillsState } from 'store/skills/types';
import { useDispatch, useSelector } from 'react-redux';
import { ColumnsType } from 'antd/es/table';

import moment from 'moment';

import { StoreActiveSkills } from '../../../../../store/skills/actions';

import {
  useSkillsQuery,
  useUpdateSkillMutation,
} from '../../../../../graphql/generated/graphql';

interface Props {
  actionHandler: Function;
}

const ActiveSkillList: React.FC<Props> = ({ actionHandler }) => {
  const [showEditPopup, setShowEditopup] = useState(false);
  const [form] = Form.useForm();
  const [updateData, setUpdateData] = useState<any>(null);

  const dispatch = useDispatch();
  const activeSkillListFromStore = useSelector(
    (state: SkillsState) => state.skills?.activeSkills
  );

  const [updateSkillMutation] = useUpdateSkillMutation();

  const { data, loading, error } = useSkillsQuery({
    variables: {
      status: 'Approved',
      isArchived: false,
      isDeleted: false,
      isBO: true,
    },
  });

  useEffect(() => {
    if (!loading && data) {
      const sortedData = data.skills.sort((a: any, b: any) => {
        a = new Date(parseInt(a.createdAt));
        b = new Date(parseInt(b.createdAt));
        return b - a;
      });
      // sortedData = uniqBy(sortedData, '_id')
      dispatch(StoreActiveSkills([...sortedData]));
    }
  }, [dispatch, data]);

  const updateSkills = () => {
    const { skill } = form.getFieldsValue();
    form.validateFields();

    updateSkillMutation({
      variables: {
        skillId: updateData._id,
        title: skill,
      },
    })
      .then(({ data }) => {
        if (data?.updateSkill) {
          notification['success']({
            message: 'Skills updated successfully.',
            duration: 3,
            description: `Skill has been updated successfully`,
          });
          actionHandler(
            'edit',
            data?.updateSkill._id,
            data?.updateSkill,
            'active'
          );
          form.resetFields();
          setShowEditopup(false);
          setUpdateData(null);
        }
      })
      .catch((err) => {
        const errors = err.graphQLErrors.map((el: any) => el.message);
        notification['error']({
          message: 'Unable to update skill',
          description: errors.map((er: any) => <div key={er}>{er}</div>),
          duration: 3,
        });
        return;
      });
  };

  const actionsHandler = (data: any) => {
    return (
      <Space>
        <Tooltip title="edit">
          <Button
            className="border-primary"
            shape="circle"
            icon={
              <EditOutlined
                className="text-primary"
                onClick={() => {
                  form.resetFields();
                  setShowEditopup(true);
                  setUpdateData(data);
                  form.setFieldsValue({
                    skill: data.title,
                  });
                }}
              />
            }
          />
        </Tooltip>
        <Tooltip title="archive">
          <Popconfirm
            title="Are you sure archive this skill?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => {
              actionHandler('archive', data._id, data);
              return;
            }}
          >
            <Button className="border-amber" shape="circle">
              <i className="dripicons-archive text-amber" />
            </Button>
          </Popconfirm>
        </Tooltip>
        <Tooltip title="delete">
          <Popconfirm
            title="Are you sure delete this skill?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => {
              actionHandler('delete', data._id, data, 'active');
              return;
            }}
          >
            <Button
              className="border-burgundy"
              shape="circle"
              icon={<DeleteOutlined className="text-burgundy" />}
            />
          </Popconfirm>
        </Tooltip>
      </Space>
    );
  };

  const noOfCandidates = (data: any) => {
    return (
      <p style={{ color: '#2e4a79', fontSize: '15px', fontWeight: 500 }}>{`${
        data?.length ?? 0
      }`}</p>
    );
  };

  const noOfJobs = (data: any) => {
    return (
      <p style={{ color: '#2e4a79', fontSize: '15px', fontWeight: 500 }}>{`${
        data?.length ?? 0
      }`}</p>
    );
  };

  const showName = (data: any, record: any) => {
    let name = '';
    const admins: any = localStorage.getItem('admins');
    const Admin = JSON.parse(admins);
    const recruiters: any = localStorage.getItem('recruiters');
    const Recruiter = JSON.parse(recruiters);
    const candidates: any = localStorage.getItem('candidates');
    const Candidate = JSON.parse(candidates);
    const { userType } = record;
    switch (userType) {
      case 'Candidate':
        const candidate: any = Candidate.find((e: any) => data == e.userId);
        name = `${candidate?.firstName} ${candidate?.lastName}`;
        break;
      case 'Recruiter':
        const recruiter: any = Recruiter.find((e: any) => data == e.userId);
        name = recruiter?.fullName;
        break;
      case 'Admin':
        const admin: any = Admin.find((e: any) => data == e.userId);
        name = admin?.fullName;
        break;
      default:
        return;
    }
    return (
      <p style={{ color: '#2e4a79', fontSize: '15px', fontWeight: 500 }}>
        {name}
      </p>
    );
  };

  const showTitle = (data: any) => {
    return <p style={{ fontSize: '15px', fontWeight: 500 }}>{data}</p>;
  };

  const showUserType = (data: any) => {
    return <p style={{ fontSize: '15px', fontWeight: 500 }}>{data}</p>;
  };

  const showDate = (data: any) => {
    return (
      <p style={{ fontSize: '15px', fontWeight: 500 }}>
        {moment(parseInt(data)).format('MMMM DD, YYYY')}
      </p>
    );
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Skills',
      dataIndex: 'title',
      key: 'title',
      // sorter: (a: any, b: any) => a.title.length - b.title.length,
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'ascend',
      render: showTitle,
    },
    {
      title: 'Candidates',
      dataIndex: 'candidatesCount',
      key: 'candidatesCount',
      showSorterTooltip: false,
      sorter: (a: any, b: any) =>
        a.candidatesCount?.length - b.candidatesCount?.length,
      sortDirections: ['descend', 'ascend', 'descend'],
      defaultSortOrder: 'descend',
      render: noOfCandidates,
    },
    {
      title: 'Jobs',
      dataIndex: 'jobsCount',
      key: 'jobsCount',
      showSorterTooltip: false,
      sorter: (a: any, b: any) => a.jobsCount?.length - b.jobsCount?.length,
      sortDirections: ['descend', 'ascend', 'descend'],
      defaultSortOrder: 'descend',
      render: noOfJobs,
    },
    {
      title: 'Creator',
      dataIndex: 'userType',
      key: 'userType',
      render: showUserType,
    },
    {
      title: 'Created by',
      dataIndex: 'createdBy',
      key: 'createdBy',
      render: showName,
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: showDate,
    },
    {
      title: 'Actions',
      key: 'id',
      fixed: 'right',
      width: 120,
      render: actionsHandler,
    },
  ];

  return (
    <>
      {showEditPopup && (
        <Modal
          centered={true}
          style={{ borderRadius: 30 }}
          title={[<h2>Update Skill</h2>]}
          visible={showEditPopup}
          onOk={() => {
            form.resetFields();
            setShowEditopup(false);
            setUpdateData(null);
          }}
          onCancel={() => {
            form.resetFields();
            setShowEditopup(false);
            setUpdateData(null);
          }}
          footer={[
            <Button
              key="back"
              style={{ borderRadius: '1.25rem' }}
              onClick={() => {
                form.resetFields();
                setShowEditopup(false);
                setUpdateData(null);
              }}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              htmlType="submit"
              style={{
                backgroundColor: '#bc9623',
                borderRadius: '1.25rem',
                border: 'none',
              }}
              onClick={updateSkills}
            >
              Submit
            </Button>,
          ]}
        >
          <Form form={form} name="update-skill">
            <Form.Item
              name="skill"
              label="Skill name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      )}
      <Table
        className="table-striped"
        columns={columns}
        loading={loading}
        dataSource={activeSkillListFromStore}
        size="small"
        pagination={{ pageSize: 20 }}
        scroll={{ x: 1300 }}
      />
    </>
  );
};

export default ActiveSkillList;
