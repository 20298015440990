import { UserIcon } from 'assets/icons/DetailedProfile';
import React, { useContext, useState } from 'react';
import { EditContext } from '../Context/EditContext';
import { PersonalDetailsDrawer } from '../Edit';
import { SectionTitle } from '../Shared';
import { Switch } from 'antd';
import { useCandidateHidePersonalDetailsMutation } from 'graphql/generated/graphql';

interface PropsType {
  candidate: any;
  match?: any;
}

const Styles = {
  root: {
    padding: 15,
    // border: 'solid 1px #364972',
    boxShadow: '0px 0px 4px #00000040 ',
    borderRadius: 10,
    paddingBottom: 5,
  },
  body: {
    paddingLeft: 24,
    marginTop: 10,
  },
};

const PersonalDetails: React.FC<PropsType> = ({ candidate, match }) => {
  const [open, setOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(!!match?.hidePersonalDetails);
  const editContext = useContext(EditContext);
  const { edit }: any = editContext;
  const [candidateHidePersonalDetails, { data, loading, error }] =
    useCandidateHidePersonalDetailsMutation();

  const handleClose = () => {
    setOpen(false);
  };

  const editInfo = () => {
    setOpen(true);
  };

  const handleChange = (value: any) => {
    setIsChecked(!isChecked);
    candidateHidePersonalDetails({
      variables: {
        matchId: match?._id,
        hidePersonalDetails: value,
      },
    }).then(({ data }) => {
      console.log(data);
    });
  };

  return (
    <>
      <PersonalDetailsDrawer
        open={open}
        handleClose={handleClose}
        candidate={candidate}
      />
      {(candidate?.personalInformation || editContext?.edit) && (
        <div style={Styles.root}>
          <SectionTitle
            actionHandler={editInfo}
            title="Personal details"
            icon={UserIcon}
            fontSize="16px"
            fontWeight={'600'}
            actionType="edit"
          />
          <div style={Styles.body}>
            <div
              style={{ marginBottom: 10, fontSize: 14, color: '#495057' }}
              dangerouslySetInnerHTML={{
                __html: candidate?.personalInformation,
              }}
            />
          </div>
          {match && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: 20,
              }}
            >
              <span
                style={{
                  fontSize: 14,
                  fontWeight: '500',
                  color: '#495057',
                  fontFamily: 'poppins',
                }}
              >
                Hide personal details
              </span>
              <Switch
                size="small"
                onChange={(e) => {
                  handleChange(e);
                }}
                checked={isChecked}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PersonalDetails;
