export interface Jobs {
  jobs: any;
  activeJobs: any;
  archivedJobs: any;
  pendingJobs: any;
  rejectedJobs: any;
  newCandidates: any;
}
export interface ActiveJobs {
  activeJobs: any;
  newCandidates: any;
}
export interface ArchivedJobs {
  archivedJobs: any;
  newCandidates: any;
}
export interface PendingJobs {
  pendingJobs: any;
  newCandidates: any;
}
export interface RejectedJobs {
  rejectedJobs: any;
  newCandidates: any;
}
export interface JobsState {
  jobs: Jobs;
  activeJobs: Jobs;
  archivedJobs: Jobs;
  pendingJobs: Jobs;
  rejectedJobs: Jobs;
}

// Describing the different ACTION NAMES available
export const STORE_JOBS = 'STORE_JOBS';
export const STORE_JOB = 'STORE_JOB';
export const STORE_ARCHIVED_JOBS = 'STORE_ARCHIVED_JOBS';
export const STORE_PENDING_JOBS = 'STORE_PENDING_JOBS';
export const STORE_REJECTED_JOBS = 'STORE_REJECTED_JOBS';
export const STORE_ACTIVE_JOBS = 'STORE_ACTIVE_JOBS';
export const DELETE_JOB = 'DELETE_JOB';
export const NEW_CANDIDATES_AGAINT_JOB = 'NEW_CANDIDATES_AGAINT_JOB';
interface storeJobs {
  type: typeof STORE_JOBS;
  jobs: Array<object>;
}

interface storeCandidates {
  type: typeof NEW_CANDIDATES_AGAINT_JOB;
  candidates: Array<object>;
}

interface storeJobs {
  type: typeof STORE_JOBS;
  jobs: Array<object>;
}

interface storeJob {
  type: typeof STORE_JOB;
  job: object;
}
interface storeActiveJob {
  type: typeof STORE_ACTIVE_JOBS;
  activeJobs: Array<object>;
}
interface storeArchivedJob {
  type: typeof STORE_ARCHIVED_JOBS;
  archivedJobs: Array<object>;
}
interface storePendingJob {
  type: typeof STORE_PENDING_JOBS;
  pendingJobs: Array<object>;
}
interface storeRejectedJob {
  type: typeof STORE_REJECTED_JOBS;
  rejectedJobs: Array<object>;
}
interface deleteJob {
  type: typeof DELETE_JOB;
  jobId: String;
}

export type jobsActionTypes =
  | storeJobs
  | storeJob
  | storeActiveJob
  | storeArchivedJob
  | storePendingJob
  | storeRejectedJob
  | deleteJob
  | storeCandidates;
