import React from 'react';
import { EditIcon } from 'assets/icons/DetailedProfile';

interface PropsTypes {
  handler: () => void;
}

const EditButton: React.FC<PropsTypes> = ({ handler }) => {
  return (
    <>
      <img
        onClick={() => handler()}
        style={{ cursor: 'pointer' }}
        src={EditIcon}
      />
    </>
  );
};

export default EditButton;
