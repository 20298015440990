import React, { useEffect, useContext } from 'react';
import { Typography } from 'antd';
import { ActivityIcon } from 'assets/icons/DetailedProfile';
import { Link, useLocation } from 'react-router-dom';
interface PropsType {
  candidate: any;
  match: any;
}

const { Title } = Typography;

const Styles = {
  flexDefault: {
    display: 'flex',
    alignItems: 'center',
  },
};

const Activity: React.FC<PropsType> = ({ candidate, match }) => {
  const name = `${candidate?.firstName} ${candidate?.lastName}`;
  const location = useLocation();
  return (
    <div style={Styles.flexDefault}>
      <Title
        style={{ fontSize: 16, margin: 0, marginTop: -8, width: 110 }}
        className="titleStatus"
      >
        Activity
      </Title>
      <div style={Styles.flexDefault}>
        <Link
          to={{
            pathname: `${location?.pathname}/Activity`,
            state: {
              candidateId: candidate?._id,
              locationUrl: location?.pathname,
              candidateName: name,
              candidateArray: location?.state,
              fromProfile: true,
            },
          }}
        >
          <img style={{ cursor: 'pointer' }} src={ActivityIcon} />
        </Link>
      </div>
    </div>
  );
};

export default Activity;
