import React, { useEffect, useRef, useState } from 'react';
import Banner from './components/Banner';
import './styles.scss';
import { useParams } from 'react-router-dom';
import { useGetSingleJobQuery } from 'graphql/generated/graphql';
import axios from 'axios';
import ShowCompleteCdp from './pages/ShowCompleteCdp';
import { Button } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';

const JobPdf: React.FC = () => {
  const { jobId } = useParams<any>();
  const [canadidates, setCandidates] = useState<any>([]);

  const {
    data: jobData,
    loading,
    error,
  }: any = useGetSingleJobQuery({
    variables: {
      jobId: jobId,
    },
    fetchPolicy: 'network-only',
  });
  const divToPrintRef = useRef<HTMLDivElement>(null);

  const handlePrint = () => {
    if (divToPrintRef.current) {
      // Create a new div for printing
      const printDiv = document.createElement('div');
      printDiv.style.position = 'absolute';
      printDiv.style.width = '100%';
      printDiv.style.height = '100%';
      printDiv.style.top = '0';
      printDiv.style.left = '-270px';
      printDiv.style.overflow = 'hidden';
      printDiv.style.visibility = 'hidden';
      printDiv.style.marginLeft = '-70px';
      printDiv.innerHTML = divToPrintRef.current.innerHTML;

      const bodyHtml = document.body.innerHTML;
      document.body.innerHTML = `
            <html>
                <head>
                    <title>Print</title>
                </head>
               <body style="margin-left: -70px;">
                    ${divToPrintRef.current.innerHTML}
                </body>
            </html>
        `;
      window.addEventListener(
        'afterprint',
        () => {
          window.location.reload();
        },
        { once: true }
      );

      window.print();

      document.body.innerHTML = bodyHtml;
    }
  };

  useEffect(() => {
    const getData = async () => {
      const apiUrl = `${process.env.REACT_APP_BASE_REST_URL}/get-job?jobId=${jobId}`;
      const response = await axios.get(apiUrl);
      setCandidates(response?.data);
    };
    getData();
  }, []);

  useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href =
      'https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css';
    link.integrity =
      'sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC';
    link.crossOrigin = 'anonymous';
    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, []);

  return (
    <div
      style={{ margin: '0px -16px 0px -16px' }}
      ref={divToPrintRef}
      className="margin-on-print"
    >
      <div className="">
        <Banner
          jobData={jobData?.getJobDetails}
          // candidate={canadidates[posIndex]?.candidates[posIndex]?.candidate}
        />

        {canadidates?.map((pos: any, posInd: any) => {
          return pos?.candidates?.map((cand: any, ind: any) => {
            if (canadidates[posInd]?.candidates[ind]?.activationScore == 2) {
              return (
                <ShowCompleteCdp
                  iconToShow={pos?.type}
                  key={`${posInd}-${ind}`}
                  posIndex={posInd}
                  index={ind}
                  jobData={jobData}
                  canadidates={canadidates}
                />
              );
            } else {
              return null;
            }
          });
        })}

        {/* <Button
          className="ant-btn-amber hide-in-print"
          shape="round"
          icon={<PrinterOutlined />}
          size="large"
          onClick={handlePrint}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            bottom: '60px',
            right: '60px',
          }}
        >
          Print PDF
        </Button> */}
      </div>
    </div>
  );
};

export default JobPdf;
