import {
  Skills,
  skillsActionTypes,
  STORE_ACTIVE_SKILLS,
  STORE_ARCHIVED_SKILLS,
  STORE_DELETED_SKILLS,
  STORE_PENDING_SKILLS,
  UPDATE_ACTIVE_SKILL_TITLE,
  UPDATE_PENDING_SKILL_TITLE,
  MOVED_TO_ACTIVE_SKILLS_FROM_ARCHIVED_SKILLS,
  MOVED_TO_ACTIVE_SKILLS_FROM_NEW_SKILLS,
  MOVED_TO_ACTIVE_SKILLS_FROM_PENDING_SKILLS,
  MOVED_TO_ARCHIVED_SKILLS_FROM_ACTIVE_SKILLS,
  MOVED_TO_DELETED_SKILLS_FROM_ACTIVE_SKILLS,
  MOVED_TO_DELETED_SKILLS_FROM_ARCHIVED_SKILLS,
  MOVED_TO_DELETED_SKILLS_FROM_PENDING_SKILLS,
} from './types';

import { updateObject } from '../../helpers/updateObject';

const initialState: Skills = {
  activeSkills: [],
  pendingSkills: [],
  archivedSkills: [],
  deletedSkills: [],
};

function containsObject(obj: any, list: any) {
  let i;

  for (i = 0; i < list.length; i++) {
    if (list[i] === obj) {
      return true;
    }
  }

  return false;
}

const storeActiveskills = (state: Skills, action: any) => {
  return updateObject(state, { activeSkills: action.activeSkills });
};

const storePendingskills = (state: Skills, action: any) => {
  return updateObject(state, { pendingSkills: action.pendingSkills });
};

const storeArchivedskills = (state: Skills, action: any) => {
  return updateObject(state, { archivedSkills: action.archivedSkills });
};

const storeDeletedskills = (state: Skills, action: any) => {
  return updateObject(state, { deletedSkills: action.deletedSkills });
};

const updateActiveSkillTitle = (state: Skills, action: any) => {
  const activeSkills = [...state.activeSkills];
  const skillIndex = activeSkills.findIndex(
    (c: any) => c._id === action.skillId
  );

  activeSkills[skillIndex] = {
    ...activeSkills[skillIndex],
    title: action.title,
  };

  return updateObject(state, {
    activeSkills: activeSkills,
  });
};

const updatePendingSkillTitle = (state: Skills, action: any) => {
  const pendingSkills = [...state.pendingSkills];
  const skillIndex = pendingSkills.findIndex(
    (c: any) => c._id === action.skillId
  );

  pendingSkills[skillIndex] = {
    ...pendingSkills[skillIndex],
    title: action.title,
  };

  return updateObject(state, {
    pendingSkills: pendingSkills,
  });
};

const moveToActiveFromArchivedSkills = (state: Skills, action: any) => {
  let activeSkills = [...state.activeSkills];
  let archivedSkills = [...state.archivedSkills];

  const skill = archivedSkills.find((c: any) => c._id === action.skillId);

  archivedSkills = archivedSkills
    .filter((c: any) => c._id !== action.skillId)
    .sort((a: any, b: any) => {
      a = new Date(parseInt(a.createdAt));
      b = new Date(parseInt(b.createdAt));
      return b - a;
    });

  const index = activeSkills.findIndex((c: any) => c._id === action.skillId);

  if (index === -1) {
    activeSkills.push(skill);
  }

  activeSkills = activeSkills.sort((a: any, b: any) => {
    a = new Date(parseInt(a.createdAt));
    b = new Date(parseInt(b.createdAt));
    return b - a;
  });

  return updateObject(state, {
    archivedSkills: archivedSkills,
    activeSkills: activeSkills,
  });
};

const moveToActiveFromNewSkills = (state: Skills, action: any) => {
  let activeSkills = [...state.activeSkills];
  activeSkills = [...activeSkills, ...action.skill];

  activeSkills = activeSkills.sort((a: any, b: any) => {
    a = new Date(parseInt(a.createdAt));
    b = new Date(parseInt(b.createdAt));
    return b - a;
  });

  return updateObject(state, {
    activeSkills: activeSkills,
  });
};

const moveToActiveFromPendingSkills = (state: Skills, action: any) => {
  let activeSkills = [...state.activeSkills];
  let pendingSkills = [...state.pendingSkills];
  const skill = pendingSkills.find((c: any) => c._id === action.skillId);

  if (skill.userType == 'Candidate') {
    skill.candidatesCount = 1;
  }
  if (skill.userType == 'Recruiter') {
    skill.jobsCount = 1;
  }

  pendingSkills = pendingSkills
    .filter((c: any) => c._id !== action.skillId)
    .sort((a: any, b: any) => {
      a = new Date(parseInt(a.createdAt));
      b = new Date(parseInt(b.createdAt));
      return b - a;
    });

  const index = activeSkills.findIndex((c: any) => c._id === action.skillId);

  if (index === -1) {
    activeSkills.push(skill);
  }

  activeSkills = activeSkills.sort((a: any, b: any) => {
    a = new Date(parseInt(a.createdAt));
    b = new Date(parseInt(b.createdAt));
    return b - a;
  });

  return updateObject(state, {
    pendingSkills: pendingSkills,
    activeSkills: activeSkills,
  });
};

const moveToArchivedFromActiveSkills = (state: Skills, action: any) => {
  let archivedSkills = [...state.archivedSkills];
  let activeSkills = [...state.activeSkills];
  const skill = activeSkills.find((c: any) => c._id === action.skillId);
  activeSkills = activeSkills
    .filter((c: any) => c._id !== action.skillId)
    .sort((a: any, b: any) => {
      a = new Date(parseInt(a.createdAt));
      b = new Date(parseInt(b.createdAt));
      return b - a;
    });

  const index = archivedSkills.findIndex((c: any) => c._id === action.skillId);

  if (index === -1) {
    archivedSkills.push(skill);
  }

  archivedSkills = archivedSkills.sort((a: any, b: any) => {
    a = new Date(parseInt(a.createdAt));
    b = new Date(parseInt(b.createdAt));
    return b - a;
  });

  return updateObject(state, {
    archivedSkills: archivedSkills,
    activeSkills: activeSkills,
  });
};

const moveToDeletedFromActiveSkills = (state: Skills, action: any) => {
  let deletedSkills = [...state.deletedSkills];
  let activeSkills = [...state.activeSkills];
  const skill = activeSkills.find((c: any) => c._id === action.skillId);
  activeSkills = activeSkills
    .filter((c: any) => c._id !== action.skillId)
    .sort((a: any, b: any) => {
      a = new Date(parseInt(a.createdAt));
      b = new Date(parseInt(b.createdAt));
      return b - a;
    });

  const index = deletedSkills.findIndex((c: any) => c._id === action.skillId);

  if (index === -1) {
    deletedSkills.push(skill);
  }

  deletedSkills = deletedSkills.sort((a: any, b: any) => {
    a = new Date(parseInt(a.createdAt));
    b = new Date(parseInt(b.createdAt));
    return b - a;
  });

  return updateObject(state, {
    activeSkills: activeSkills,
    deletedSkills: deletedSkills,
  });
};

const moveToDeletedFromArchivedSkills = (state: Skills, action: any) => {
  let deletedSkills = [...state.deletedSkills];
  let archivedSkills = [...state.archivedSkills];
  const skill = archivedSkills.find((c: any) => c._id === action.skillId);
  archivedSkills = archivedSkills
    .filter((c: any) => c._id !== action.skillId)
    .sort((a: any, b: any) => {
      a = new Date(parseInt(a.createdAt));
      b = new Date(parseInt(b.createdAt));
      return b - a;
    });

  const index = deletedSkills.findIndex((c: any) => c._id === action.skillId);

  if (index === -1) {
    deletedSkills.push(skill);
  }

  deletedSkills = deletedSkills.sort((a: any, b: any) => {
    a = new Date(parseInt(a.createdAt));
    b = new Date(parseInt(b.createdAt));
    return b - a;
  });

  return updateObject(state, {
    archivedSkills: archivedSkills,
    deletedSkills: deletedSkills,
  });
};

const moveToDeletedFromPendingSkills = (state: Skills, action: any) => {
  let deletedSkills = [...state.deletedSkills];
  let pendingSkills = [...state.pendingSkills];
  const skill = pendingSkills.find((c: any) => c._id === action.skillId);
  pendingSkills = pendingSkills
    .filter((c: any) => c._id !== action.skillId)
    .sort((a: any, b: any) => {
      a = new Date(parseInt(a.createdAt));
      b = new Date(parseInt(b.createdAt));
      return b - a;
    });

  const index = deletedSkills.findIndex((c: any) => c._id === action.skillId);

  if (index === -1) {
    deletedSkills.push(skill);
  }

  deletedSkills = deletedSkills.sort((a: any, b: any) => {
    a = new Date(parseInt(a.createdAt));
    b = new Date(parseInt(b.createdAt));
    return b - a;
  });

  return updateObject(state, {
    pendingSkills: pendingSkills,
    deletedSkills: deletedSkills,
  });
};

const skillsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case STORE_ACTIVE_SKILLS:
      return storeActiveskills(state, action);
    case STORE_ARCHIVED_SKILLS:
      return storeArchivedskills(state, action);
    case STORE_DELETED_SKILLS:
      return storeDeletedskills(state, action);
    case STORE_PENDING_SKILLS:
      return storePendingskills(state, action);
    case UPDATE_ACTIVE_SKILL_TITLE:
      return updateActiveSkillTitle(state, action);
    case UPDATE_PENDING_SKILL_TITLE:
      return updatePendingSkillTitle(state, action);
    case MOVED_TO_ACTIVE_SKILLS_FROM_ARCHIVED_SKILLS:
      return moveToActiveFromArchivedSkills(state, action);
    case MOVED_TO_ACTIVE_SKILLS_FROM_NEW_SKILLS:
      return moveToActiveFromNewSkills(state, action);
    case MOVED_TO_ACTIVE_SKILLS_FROM_PENDING_SKILLS:
      return moveToActiveFromPendingSkills(state, action);
    case MOVED_TO_ARCHIVED_SKILLS_FROM_ACTIVE_SKILLS:
      return moveToArchivedFromActiveSkills(state, action);
    case MOVED_TO_DELETED_SKILLS_FROM_ACTIVE_SKILLS:
      return moveToDeletedFromActiveSkills(state, action);
    case MOVED_TO_DELETED_SKILLS_FROM_ARCHIVED_SKILLS:
      return moveToDeletedFromArchivedSkills(state, action);
    case MOVED_TO_DELETED_SKILLS_FROM_PENDING_SKILLS:
      return moveToDeletedFromPendingSkills(state, action);
    default:
      return state;
  }
};

export default skillsReducer;
