import React, { useContext, useEffect, useRef, useState } from 'react';
import { Drawer, Form, Tag, Select, Button, Space } from 'antd';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { useGetTagsQuery } from 'graphql/generated/graphql';
import { EditContext } from '../Context/EditContext';
import { useUpdateCandidateMutation } from 'graphql/generated/graphql';
interface PropsType {
  candidate: any;
  open: boolean;
  handleClose?: any;
  onUpdate: (data: any) => void;
}

const Styles = {
  tagsWrapper: {
    marginTop: 20,
  },
  submitBtn: {
    width: 101,
    float: 'right',
    backgroundColor: '#BC9623',
    borderRadius: 20,
    marginTop: 20,
    border: 'none',
    color: 'white',
  },
};

const TagsDrawer: React.FC<PropsType> = ({
  candidate,
  open,
  handleClose,
  onUpdate,
}) => {
  const editContext = useContext(EditContext);
  const { setData, setUpdatedData, updatedData }: any = editContext;
  const [customTags, setCustomTags] = useState<string[]>([]);
  const [tags, setTags] = useState<any>([]);
  const ref = useRef<any>(null);
  const { data, loading, error } = useGetTagsQuery();
  const [form] = Form.useForm();
  useEffect(() => {
    setCustomTags(updatedData?.customTags ? updatedData?.customTags : []);
  }, [updatedData?.customTags]);
  useEffect(() => {
    if (!loading && data) {
      setTags(data?.getTags);
    }
  }, [data]);

  const removeTag = (value: string) => {
    setData({
      ...editContext?.candidate,
      tags: editContext?.candidate.tags.filter(
        (skill: any) => skill._id != value
      ),
      customTags: editContext?.updatedData?.customTags?.filter(
        (tag: any) => tag != value
      ),
    });
    let tagsFilter = updatedData?.customTags
      ? [
          ...editContext?.updatedData?.customTags
            .filter((tag: any) => tag != value)
            .map((tag: any) => tag),
        ]
      : [];
    setUpdatedData({
      ...editContext?.updatedData,
      customTags: tagsFilter,
    });
    // console.log(value)
  };

  const SingleTag = ({ title, color, id }: any) => {
    return (
      <Tag
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
        onClose={(e) => {
          e.preventDefault();
          removeTag(id);
        }}
        style={{
          border: 'none',
          marginRight: 10,
          marginBottom: 15,
          backgroundColor: color ? color : 'rgba(18, 100, 121, 0.8)',
          borderRadius: 20,
          color: 'white',
          paddingTop: 5,
          paddingBottom: 5,
          paddingLeft: 10,
          paddingRight: 10,
        }}
        closable
      >
        {title}
      </Tag>
    );
  };
  const [
    updateCandidateMutation,
    { data: mdata, loading: mloading, error: merror },
  ] = useUpdateCandidateMutation();
  const onFinish = (values: any) => {
    console.log(values);
    const uniqueArray = customTags.filter(
      (item, index) => customTags.indexOf(item) === index
    );
    const newTags = tags.filter((tag: any) =>
      values.tags ? values.tags.includes(tag._id) : null
    );
    const filteredTags = newTags?.filter(
      (tag: any) => !candidate?.tags?.some((t: any) => t?._id === tag?._id)
    );
    const updatedTags = [
      ...filteredTags.map((tag: any) => tag._id),
      ...editContext?.candidate.tags.map((tag: any) => tag._id),
    ];

    const updatedCandidate = {
      ...editContext?.candidate,
      tags: [...editContext?.candidate.tags, ...filteredTags],
      customTags: uniqueArray,
    };

    const updatedData = {
      ...editContext?.updatedData,
      tags: updatedTags,
      customTags: uniqueArray, // Include the custom tags in the updated data
    };

    setData(updatedCandidate);
    setUpdatedData(updatedData);
    if (!editContext?.edit) {
      updateCandidateMutation({
        variables: { candidateId: candidate._id, ...updatedData },
      }).then((res) => {
        console.log(res);
      });
    }
    form.resetFields();
    onUpdate(null);
  };
  const [lengthTags, setLengthTags] = useState<any>(0);
  const handleTagChange = (value: any) => {
    setLengthTags(value?.length);
    const customTag = value.filter(
      (tag: string) => !tags.some((t: any) => t._id === tag)
    );

    if (customTag?.length >= 1) {
      setCustomTags([...customTag, ...customTags]);
    }
  };
  const options: any = tags.map((tag: any) => {
    return { label: tag.name, value: tag._id };
  });
  const handleCloseDrawer = () => {
    form.resetFields();
    handleClose();
  };
  return (
    <div>
      <Drawer
        title="Candidate’s tags"
        placement={'right'}
        // closable={true}
        onClose={handleCloseDrawer}
        visible={open}
        extra={
          <Space onClick={handleCloseDrawer} style={{ cursor: 'pointer' }}>
            <CloseOutlined />
          </Space>
        }
      >
        <Form
          form={form}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            name="tags"
            rules={[
              {
                required: true,
                validator: (_, value) => {
                  // if (!value) {
                  //   return Promise.reject('Field is required!');
                  // }
                  if (lengthTags + candidate?.tags?.length > 15) {
                    return Promise.reject('Max 15!');
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            {/* <label
              style={{
                marginBottom: '7px',
                lineHeight: 1,
                fontSize: '16px',
                fontWeight: 400,
              }}
            >
              Add a tag
            </label> */}
            <Select
              suffixIcon={<SearchOutlined />}
              mode="tags"
              placeholder="Select tag"
              onChange={handleTagChange}
              options={options}
              optionFilterProp="label"
              style={{ width: '100%' }}
            />
          </Form.Item>
          <div>
            <Form.Item>
              <Button
                style={{
                  width: 80,
                  float: 'right',
                  backgroundColor: '#BC9623',
                  borderRadius: 20,
                  marginTop: 10,
                  border: 'none',
                  color: 'white',
                }}
                htmlType="submit"
              >
                Add
              </Button>
            </Form.Item>
          </div>
        </Form>
        <div style={Styles.tagsWrapper}>
          {candidate?.tags.map((tag: any) => (
            <SingleTag
              key={tag._id}
              color={tag.color}
              id={tag._id}
              title={tag.name}
            />
          ))}
          {updatedData?.customTags?.map((tag: any, index: any) => (
            <SingleTag key={index} id={tag} title={tag} />
          ))}
        </div>
      </Drawer>
    </div>
  );
};

export default TagsDrawer;
