import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Tag, Tooltip } from 'antd';
import { TagsIcon } from 'assets/icons/DetailedProfile';
import React, { useContext, useState } from 'react';
import { EditContext } from '../../Context/EditContext';
import { SectionTitle } from '../../Shared';
import '../../styles.scss';
import { useUpdateCandidateMutation } from 'graphql/generated/graphql';
import { remove } from 'lodash';

interface PropsType {
  candidate: any;
  onAdd: any;
}

const Tags: React.FC<PropsType> = ({ candidate, onAdd }) => {
  const [isSeeMore, setIsSeeMore] = useState(false);
  const editContext = useContext(EditContext);
  const { edit }: any = editContext;
  const { setData, setUpdatedData, updatedData }: any = editContext;
  const SingleTag = ({ title, color, id }: any) => {
    return (
      <Tag
        closeIcon={
          // editContext?.edit &&
          <CloseOutlined
            style={{ color: 'white' }}
            onClick={() => removeTag(id)}
          />
        }
        style={{
          border: 'none',
          marginRight: 10,
          marginBottom: 15,
          backgroundColor: color ? color : 'rgba(18, 100, 121, 0.8)',
          borderRadius: 20,
          color: 'white',
          paddingTop: 5,
          paddingBottom: 5,
          paddingLeft: 10,
          paddingRight: 10,
          fontSize: 14,
          fontWeight: 500,
        }}
        onClose={() => removeTag(id)}
        // closable={editContext?.edit && true}
        closable={true}
      >
        {title}
      </Tag>
    );
  };
  const [UpdateCandidateMutation, { data, loading, error }] =
    useUpdateCandidateMutation();

  const removeTag = (value: string) => {
    const updatedTags = editContext?.candidate?.tags?.filter(
      (tag: any) => tag._id !== value
    );
    if (!editContext?.edit) {
      UpdateCandidateMutation({
        variables: {
          candidateId: candidate?._id,
          tags: updatedTags?.map((tag: any) => tag._id),
        },
      }).then((res) => {
        console.log(res);
      });
    }
    setData({
      ...editContext?.candidate,
      tags: editContext?.candidate.tags.filter((tag: any) => tag._id != value),
      customTags: editContext?.updatedData?.customTags?.filter(
        (tag: any) => tag != value
      ),
    });

    let tagsFilter = updatedData?.customTags
      ? [
          ...editContext?.updatedData?.customTags
            .filter((tag: any) => tag != value)
            .map((tag: any) => tag),
        ]
      : [];

    setUpdatedData({
      ...editContext?.updatedData,
      tags: [
        ...editContext?.candidate.tags
          .filter((tag: any) => tag._id != value)
          .map((tag: any) => tag._id),
      ],
      customTags: tagsFilter,
    });
  };

  const SeeMore = () => {
    return (
      <Tag onClick={() => setIsSeeMore(!isSeeMore)} className="see-more-btn">
        {isSeeMore ? 'See less' : 'See more'}
      </Tag>
    );
  };

  return (
    <div>
      {
        <>
          <SectionTitle title={'Tags'} fontSize={18} fontWeight={600} />
          <div
            style={{
              // paddingLeft: 25,
              paddingTop: 10,
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
            }}
          >
            {candidate?.tags != null &&
              candidate?.tags.map(
                (tag: any, index: any) => (
                  // index < 2 || isSeeMore ? (
                  <SingleTag
                    key={tag._id}
                    color={tag.color}
                    id={tag._id}
                    title={tag.name}
                  />
                )
                // ) : null
              )}
            {updatedData?.customTags?.map((tag: string, index: number) => (
              <SingleTag key={`custom_${index}`} title={tag} id={tag} />
            ))}
            {/* {candidate?.tags.length > 2 && <SeeMore />} */}

            {/* {editContext?.edit && ( */}
            <Tooltip placement="top" title="Add more tags">
              <span onClick={onAdd} className="addIcon">
                <PlusOutlined style={{ fontSize: 14 }} />
              </span>
            </Tooltip>
            {/* )} */}
          </div>
        </>
      }
    </div>
  );
};

export default Tags;
