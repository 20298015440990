import React, { useContext, useEffect, useState } from 'react';
import { Button, Drawer, Form, Space } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Editor } from '@tinymce/tinymce-react';
import { EditContext } from '../Context/EditContext';
import { CloseOutlined } from '@ant-design/icons';
import './PersonalDetailsDrawer.scss';
interface PropsType {
  candidate: any;
  open: boolean;
  handleClose?: any;
}

const PersonalDetailsDrawer: React.FC<PropsType> = ({
  candidate,
  open,
  handleClose,
}) => {
  const [form] = Form.useForm();
  const ref = React.useRef<any>();
  const [info, setInfo] = useState<any>(candidate?.personalInformation);
  const editContext = useContext(EditContext);
  const { setData, setUpdatedData }: any = editContext;
  useEffect(() => {
    if (open) {
      setInfo(candidate?.personalInformation);
    }
  }, [candidate, open]);

  const handleSubmit = () => {
    setUpdatedData({
      ...editContext?.updatedData,
      personalInformation: info,
    });
    setData({
      ...editContext?.candidate,
      personalInformation: info,
    });
    handleClose();
  };
  const handleCloseDrawer = () => {
    setInfo(candidate?.personalInformation);
    handleClose();
  };
  useEffect(() => {
    form.resetFields();

    // Reset the Editor component's value when the candidate is changed
    if (ref && ref.current && ref.current.editor) {
      const content = candidate?.personalInformation || ''; // Use nullish coalescing to provide a default value
      ref.current.editor.setContent(content);
    }
  }, [candidate, form, ref]);

  return (
    <div>
      <Drawer
        title="Candidate’s personal details"
        placement={'right'}
        // closable={true}
        onClose={handleClose}
        visible={open}
        extra={
          <Space onClick={handleCloseDrawer} style={{ cursor: 'pointer' }}>
            <CloseOutlined />
          </Space>
        }
      >
        {/* <TextArea ref={ref} defaultValue={candidate?.personalInformation} placeholder="Add personal details" autoSize={{ minRows: 7 }} /> */}
        <div
          style={{
            border: 'solid 1px gray',
            borderRadius: 10,
            padding: 10,
            // paddingLeft: 15,
          }}
        >
          <div className="personal-details-editor">
            <Editor
              value={info}
              ref={ref}
              apiKey="bgow6d7d89zjmvftzkn8p8gzzhkzyd551egy09ik6j400toe"
              inline
              init={{
                minHeight: 400,
                menubar: false,
                placeholder: 'Add personal details',
                plugins: [
                  'advlist autolink link image lists charmap print preview hr anchor pagebreak spellchecker',
                  'searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking',
                  'save table contextmenu directionality emoticons template paste textcolor',
                ],
                inline: false,
                toolbar:
                  'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print preview media fullpage | forecolor backcolor emoticons',
                smart_paste: false,
                force_br_newlines: true,
                force_p_newlines: false,
                forced_root_block: 'div',
              }}
              outputFormat="html"
              onEditorChange={(newValue, editor) => setInfo(newValue)}
            />
          </div>
        </div>
        <Button
          onClick={handleSubmit}
          style={{
            width: 97,
            float: 'right',
            backgroundColor: '#BC9623',
            borderRadius: 20,
            marginTop: 20,
            border: 'none',
            color: 'white',
          }}
        >
          Add
        </Button>
      </Drawer>
    </div>
  );
};

export default PersonalDetailsDrawer;
