import React, { useContext, useEffect, useState } from 'react';
import { EditContext } from '../../../../src/containers/BackOffice/Candidates/Profile/Context/EditContext';
import './LeftSideBar.scss';
import { NavLink } from 'react-router-dom';

const LeftSideBar: React.FC = () => {
  const editContext = useContext<any>(EditContext);

  //THIS FUNCTION ENABLES EDIT BUTTON
  //AND  DISABLES "CANCLE AND SAVE BUTTONS"
  const ChangesConfirmHandler = () => {
    if (editContext && editContext.isDataChanged) {
      editContext.confirmPopUpOpeneHandler();
    } else {
      editContext.cancelEditProfile();
    }
  };
  //THIS FUNCTION DISABLES "CANCLE AND SAVE BUTTONS"
  //AND ENABLES EDIT BUTTON
  const ChangesConfirmerCancleHandler = () => {
    editContext && editContext.cancelEditProfile();
  };

  const NAV_LINKS = [
    { to: '/', text: 'Dashboard', icon: 'mdi mdi-view-dashboard', exact: true },
    {
      to: '/backoffice',
      text: 'Backoffice',
      icon: 'mdi mdi-apps',
      exact: false,
    },
    {
      to: '/mobile-marketing',
      text: 'Mobile Marketing App',
      icon: 'mdi mdi-bullhorn',
      exact: false,
    },
    {
      to: '/crawl-composer',
      text: 'Crawl Composer',
      icon: 'mdi mdi-account-multiple',
      exact: false,
    },
  ];

  return (
    <div className="side-menu">
      <ul>
        {' '}
        {NAV_LINKS.map((link, index) => (
          <li key={index}>
            {/* DISPLAYING DISCARD POP UP IF CHANGES ARE MADE */}
            {
              editContext?.isDataChanged ? (
                <p className="ripple" onClick={ChangesConfirmHandler}>
                  <i className={link.icon}></i>
                  <span> {link.text} </span>
                </p>
              ) : (
                //  <NavLink to={link.to} className="ripple" exact={link.exact}>
                <a
                  href={link.to}
                  className="ripple"
                  style={{ textDecoration: 'none' }}
                  onClick={ChangesConfirmerCancleHandler}
                >
                  <i className={link.icon}></i>
                  <span> {link.text} </span>
                </a>
              )
              // </NavLink>
            }
          </li>
        ))}
      </ul>
      <div className="clearfix"></div>
    </div>
  );
};

export default LeftSideBar;
