import React from 'react';
import blonkLogo from '../../../../../../assets/icons/blonk-white.png';

interface BannerProps {
  jobData: any;
}

const Banner: React.FC<BannerProps> = ({ jobData }) => {
  const getDate = () => {
    if (jobData?.createdAt) {
      // Check if createdAt is a valid timestamp (either in milliseconds or seconds)
      const isTimestamp = /^\d+$/.test(jobData.createdAt);

      if (isTimestamp) {
        console.log(jobData.createdAt);
        jobData.createdAt = Number(jobData.createdAt);

        const date = new Date(jobData.createdAt);
        const months = [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ];

        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();

        jobData.createdAt = `${day} ${month} ${year}`;
      }

      return jobData.createdAt;
    }
  };

  return (
    <div style={{ backgroundColor: '#111b2d' }}>
      <div
        className="p-5"
        style={{
          height: '97vh',
          backgroundColor: '#111b2d',
          padding: '10px 20px 20px 20px',
          position: 'relative',
          color: 'white',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div className="d-flex align-items-center justify-content-between">
          <img
            src={blonkLogo}
            alt="logo"
            style={{ height: '100px', width: '100px', objectFit: 'contain' }}
          />
          {jobData?.company?.logoUrl ? (
            <img
              src={jobData?.company?.logoUrl}
              alt="CompanyLogo"
              style={{ height: '100px', width: '100px', objectFit: 'contain' }}
            />
          ) : (
            <h6>{jobData?.company?.name}</h6>
          )}
        </div>
        <div className="container" style={{ marginTop: '140px' }}>
          <div className="row d-flex align-items-center justify-content-center">
            <div className="col-6">
              <h4 style={{ fontWeight: 400, color: 'white' }}>
                {jobData?.title}
              </h4>
              <br />
              <p className="mb-0" style={{ fontWeight: 600 }}>
                Candidate report
              </p>
              <p className="mb-0" style={{ fontWeight: 600 }}></p>
              <br />
              <p
                className="mb-3"
                style={{ fontWeight: 500, wordSpacing: '3px' }}
              >
                {jobData?.createdAt && getDate()}
                {/* {jobDate} */}
              </p>
              <p className="mb-0" style={{ fontWeight: 500 }}>
                Project lead: {jobData?.leadRecruiter?.fullName}
              </p>
            </div>
            <div className="col-6 d-flex justify-content-end">
              <img
                src={jobData?.banner}
                alt="banner"
                style={{
                  width: '430px',
                  height: 'auto',
                  objectFit: 'contain',
                  borderRadius: '50px',
                }}
              />
            </div>
          </div>
        </div>
        {/* Footer */}
        {/* <div
          className="d-flex justify-content-center align-items-center flex-column bottom-bar-wrapper hide-in-browser"
          style={{
            padding: '0px 85px',
            position: 'absolute',
            left: '0',
            bottom: '30px',
            width: '100%',
          }}
        >
          <div
            className="bottom-bar hide-in-browser"
            style={{
              boxSizing: 'border-box',
              backgroundColor: 'gold',
              height: '15px',
              width: '100%',
              marginBottom: '8px',
            }}
          ></div>
          <p className="job-title hide-in-browser">
            Strictly confidential - {jobData?.company?.name} - {jobData?.title}
          </p>
        </div> */}
      </div>
      <div className="page-break"></div>
    </div>
  );
};

export default Banner;
