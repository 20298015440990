import React, { useContext, useEffect } from 'react';
import { message, Tooltip, Typography } from 'antd';
import { InfoIcon } from 'assets/icons/DetailedProfile';
// import { EditContext } from './EditContext';
import { useParams } from 'react-router-dom';
import { useCandidateBenchmarkMutation } from '../../../../../../graphql/generated/graphql';
import '../pages/components.scss';
interface PropsType {
  candidate: any;
  match: any;
}

interface BenchmarkProps {
  title: any;
  isActive: boolean;
  onClick?: any;
}

const Styles = {
  root: {
    padding: '10.5px 10.5px 0 10.5px',
    borderRadius: 10,
    backgroundColor: 'white',
    marginRight: '14px',
    marginTop: '-68px',
  },
  border: {
    border: 'solid 1px #012653',
    borderRadius: 10,
    padding: '5px 10px 2px 10px',
  },
  benchmark: {
    width: 148,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'solid 1px #012653',
    borderRadius: 10,
    marginBottom: 6,
  },
  benchmarkActive: {
    width: 148,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'solid 1px #012653',
    borderRadius: 10,
    marginBottom: 6,
    color: 'white',
    backgroundColor: '#2E4A79',
  },
};


const benchMarks = ['Plug & play', 'Step up', 'Best athlete'];


const BenchmarkInfo = () => {
  return (
    <div>
      <p>Definitions for benchmark</p>

      <p>
        Plug & play: A candidate in a company from a similar/related industry
        with a similar business model considered to be ready to take on this
        role (can hit the ground running).
      </p>

      <p>
        Step up: A candidate in a company from either a similar/related or
        different industry for which the role would be a next career step.
      </p>

      <p>
        Best Athlete: A candidate in a company from a different industry with a
        different business model but has experience leading a similar size
        responsibility.
      </p>
    </div>
  );
};

const Benchmark: React.FC<PropsType> = ({ candidate, match }) => {
  const { matchId, jobId } = useParams<{
    matchId: string;
    jobId: string;
    id: string;
  }>();
  //   const editContext = useContext(EditContext);
  //   const { edit, setData, setCandidateMatch }: any = editContext;

  const [candidateBenchmark] = useCandidateBenchmarkMutation();

  const BenchMarkBtn = ({ title, isActive, onClick }: BenchmarkProps) => {
    return (
      <div
        style={isActive ? Styles.benchmarkActive : Styles.benchmark}
        onClick={onClick}
      >
        <Typography
          style={{
            fontSize: 12,
            fontWeight: 500,
            color: isActive ? 'white' : '#2E4A79',
            cursor: 'pointer',
          }}
        >
          {title}
        </Typography>
      </div>
    );
  };

  return (
    <div style={{ ...Styles.root }}>
      <div style={Styles.border} className="shadow-bench">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          <Typography
            style={{
              textAlign: 'center',
              fontSize: 12,
              marginBottom: 8,
              fontWeight: '600',
              color: '#012653',
            }}
          >
            BENCHMARK
          </Typography>
          <div style={{ position: 'absolute', right: 0 }}>
            <Tooltip
              overlayStyle={{ width: 300 }}
              arrowPointAtCenter={false}
              overlayInnerStyle={{ width: 300 }}
              placement="bottomRight"
              title={<BenchmarkInfo />}
            >
              <img src={InfoIcon} style={{ width: '18px' }} />
            </Tooltip>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {benchMarks.map((benchMark: any, index: any) => (
            <BenchMarkBtn
              key={index}
              title={benchMark}
              isActive={match?.benchmark == benchMark}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Benchmark;
