import { Popover, Tag } from 'antd';
import React, { useState } from 'react';

interface PopoverTextProps {
  country: string;
  isTag: Boolean;
  event?: string;
}

const PopoverText: React.FC<PopoverTextProps> = ({ country, isTag, event }) => {
  const content = <div style={{ maxWidth: '400px' }}>{country}</div>;
  return (
    // <div style={{ position: 'relative', display: 'inline-block' }}>
    //   {isTag ? <Tag
    //     style={{
    //       borderRadius: 10,
    //       marginTop: 2,
    //       maxWidth: '85px',
    //       overflow: 'hidden',
    //       textOverflow: 'ellipsis',
    //       whiteSpace: 'nowrap',
    //       cursor: 'pointer',
    //     }}
    //     color="#bc9623"
    //     onClick={toggleCustomContent}
    //   >
    //     {country}
    //   </Tag>
    //     :
    //     <div
    //       style={{
    //         borderRadius: 10,
    //         marginTop: 2,
    //         maxWidth: '180px',
    //         overflow: 'hidden',
    //         textOverflow: 'ellipsis',
    //         whiteSpace: 'nowrap',
    //         cursor: 'pointer',
    //       }}
    //       onClick={toggleCustomContent}>
    //       {country}
    //     </div>
    //   }
    //   {showCustomContent && (
    //     <Popover
    //       title="Popover Title"
    //       content={country}
    //       visible={isPopoverVisible}
    //       onVisibleChange={isVisible => setIsPopoverVisible(isVisible)}
    //       trigger="click"
    //     >
    //       {country}
    //     </Popover>

    //   )}
    // </div>
    <>
      <Popover
        placement="top"
        title={''}
        content={content}
        trigger={event == 'hover' ? 'hover' : 'click'}
        style={{ width: '100%' }}
        className="Popover-long-location"
      >
        {isTag ? (
          <Tag
            style={{
              borderRadius: 10,
              marginTop: 2,
              maxWidth: '85px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              cursor: 'pointer',
            }}
            color="#bc9623"
          >
            {country}
          </Tag>
        ) : (
          <div
            style={{
              marginTop: 2,
              maxWidth: '240px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              cursor: 'pointer',
            }}
          >
            {country}
          </div>
        )}
      </Popover>
    </>
  );
};

export default PopoverText;
