import {
  Recruiters,
  recruitersActionTypes,
  STORE_RECRUITERS,
  STORE_RECRUITER
} from "./types";
import _ from 'lodash';

import { updateObject } from "../../helpers/updateObject";

const initialState: Recruiters = {
  recruiters: []
};

const storeRecruiters = (state: Recruiters, action: any) => {
  return updateObject(state, { recruiters: action.recruiters });
};

const storeRecruiter = (state: Recruiters, action: any) => {
  const findIndex = state.recruiters.findIndex(((recruiter: any) => recruiter._id == action.recruiter._id))
  if (findIndex > -1) {
    const updatedObject = _.merge(state.recruiters[findIndex], action.recruiter);
    console.log('Updated : ', updatedObject);
    const recruiters = state.recruiters;
    recruiters[findIndex] = updatedObject;
    return updateObject(state, { recruiters: recruiters });
  }
  else 
    return state
};


const recruitersReducer = (state = initialState, action: recruitersActionTypes) => {
  switch (action.type) {
    case STORE_RECRUITERS:
      return storeRecruiters(state, action);
    case STORE_RECRUITER:
      return storeRecruiter(state, action);
    default:
      return state;
  }
};

export default recruitersReducer;
