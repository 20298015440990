import {
  jobsActionTypes,
  STORE_JOBS,
  STORE_ACTIVE_JOBS,
  STORE_ARCHIVED_JOBS,
  STORE_PENDING_JOBS,
  STORE_REJECTED_JOBS,
  STORE_JOB,
  DELETE_JOB,
  NEW_CANDIDATES_AGAINT_JOB,
} from './types';

export const StoreJobs = (jobs: Array<Object>): jobsActionTypes => {
  return {
    type: STORE_JOBS,
    jobs,
  };
};
export const StoreActiveJobs = (activeJobs: Array<Object>): jobsActionTypes => {
  return {
    type: STORE_ACTIVE_JOBS,
    activeJobs,
  };
};
export const StoreArchivedJobs = (
  archivedJobs: Array<Object>
): jobsActionTypes => {
  return {
    type: STORE_ARCHIVED_JOBS,
    archivedJobs,
  };
};
export const StorePendingJobs = (
  pendingJobs: Array<Object>
): jobsActionTypes => {
  return {
    type: STORE_PENDING_JOBS,
    pendingJobs,
  };
};
export const StoreRejectedJobs = (
  rejectedJobs: Array<Object>
): jobsActionTypes => {
  return {
    type: STORE_REJECTED_JOBS,
    rejectedJobs,
  };
};

export const StoreNewCandidates = (
  candidates: Array<Object>
): jobsActionTypes => {
  return {
    type: NEW_CANDIDATES_AGAINT_JOB,
    candidates,
  };
};

export const StoreJob = (job: Object): jobsActionTypes => {
  return {
    type: STORE_JOB,
    job: job,
  };
};

export const DeleteJob = (jobId: String): jobsActionTypes => {
  return {
    type: DELETE_JOB,
    jobId: jobId,
  };
};
