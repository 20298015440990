import { CheckOutlined, CloseOutlined, EditFilled } from '@ant-design/icons';
import { Button } from 'antd';
import PageTitle from 'components/UI/PageTitle/PageTitle';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { EditContext } from '../Context/EditContext';
import '../styles.scss';
import CancelModal from '../../../../../components/CancelModal/cancelModal';
interface PropsType {
  handleSave: () => void;
  initialData: any;
  refetch: any;
  to?: any;
  matchData?: any;
}

const PageHeader: React.FC<PropsType> = ({
  handleSave,
  initialData,
  matchData,
  refetch,
  to,
}) => {
  const editContext = useContext(EditContext);
  const { confirmPopUp }: any = useContext(EditContext);
  const [open, setOpen] = useState(confirmPopUp);
  // const [cancelConfirm, setCancelConfirm] = useState<any>(false);
  const { jobId } = useParams<{ matchId: string; jobId: string }>();
  // console.log(matchData);
  const history = useHistory();
  const location: any = useLocation();
  const { setActivityDatas }: any = editContext;
  useEffect(() => {
    const obj: any = {
      candidateArray: location?.state?.candidateArray,
      candidateId: location?.state?.candidateId,
      fromURL: location?.pathname,
      previousLocation: location?.state?.fromURL,
    };
    setActivityDatas(obj);
  }, []);
  const name = `${matchData?.candidateListing?.firstName} ${matchData?.candidateListing?.lastName}`;

  const goBack = () => {
    if (location?.state?.fromURL && location?.state?.isActivty) {
      history.push({
        pathname: location?.state?.fromURL,
        state: {
          candidateId: matchData?.candidateListing?._id,
          locationUrl: location?.pathname,
          previousLocation: location?.state?.fromURL,
          candidateName: name,
          candidateArray: location?.state,
          fromProfile: true,
        },
      });
    } else if (to) {
      history.push({ pathname: '/backoffice/candidates' });
    } else {
      history.push({ pathname: `/backoffice/jobs/${jobId}` });
    }
  };

  //THIS FUNCTION ENABLES DISCARD CHANGES POP UP IF CHANGES MADE
  //ELSE  DISABLES "CANCLE AND SAVE BUTTONS" ONLY
  const handleCancle = () => {
    if (editContext && editContext.isDataChanged) {
      editContext.confirmPopUpTrigger();
    } else {
      if (editContext) {
        editContext.cancelEditProfile(matchData);
        editContext.setData(initialData);
        refetch();
      }
    }
  };

  //THIS FUNCTION IS PASSED AS PROP TO THE DISCARD MODAL COMPONENT TO DISCARD CHANGES
  const discard = () => {
    editContext && editContext.confirmPopUpTrigger();
    if (editContext) {
      editContext.cancelEditProfile(matchData);
      editContext.setData(initialData);
      refetch();
    }
  };

  //THIS FUNCTION IS PASSED AS PROP TO THE DISCARD MODAL COMPONENT TO SAVE CHANGES
  const afterSave = () => {
    handleSave();
    editContext && editContext.confirmPopUpCloseHandler();
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <PageTitle
        title={''}
        btnActive={false}
        back
        handleGoBack={goBack}
        fromProfile={true}
        isCancel={true}
        handleCancel={handleCancle}
      />
      {editContext && editContext.confirmPopUp && (
        <CancelModal
          discard={discard}
          isOpen={editContext?.confirmPopUp}
          refetch={refetch}
          save={afterSave}
          reopener={handleCancle}
        />
      )}
      {editContext?.edit ? (
        <div>
          <Button
            className="cancel-btn"
            onClick={handleCancle}
            style={{ fontSize: '16px' }}
          >
            Cancel <CloseOutlined style={{ marginLeft: 10 }} />
          </Button>
          <Button
            className="save-btn"
            onClick={handleSave}
            style={{ fontSize: '16px' }}
          >
            Save <CheckOutlined style={{ marginLeft: 10 }} />
          </Button>
        </div>
      ) : (
        <Button
          className="edit-btn"
          onClick={() => editContext?.editProfile()}
          style={{ fontSize: '16px' }}
        >
          Edit <EditFilled style={{ marginLeft: 10 }} />
        </Button>
      )}
    </div>
  );
};

export default PageHeader;
