import React, { useState } from 'react';
import { Button, Drawer, Form, Input, Space, Tooltip } from 'antd';
import {
  VisibleIcon,
  PasswordIcon,
  MailIcon,
} from 'assets/icons/DetailedProfile';
import {
  CloseOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from '@ant-design/icons';
import { useGenericResetPasswordMutation } from '../../../../../graphql/generated/graphql';

interface PropsType {
  candidate: any;
  open: boolean;
  handleClose?: any;
}

const ResetPassword: React.FC<PropsType> = ({
  candidate,
  open,
  handleClose,
}) => {
  const [form] = Form.useForm();
  const [genericResetPasswordMutation, { data, loading, error }] =
    useGenericResetPasswordMutation();

  const onFinish = (values: any) => {
    const { password } = values;
    genericResetPasswordMutation({
      variables: {
        email: candidate?.user.email,
        password: password,
        resetCode: 0,
        bypassResetCode: true,
      },
    })
      .then(({ data }: any) => {
        handleClose();
        form.resetFields();
      })
      .catch((err: any) => {});
  };
  const handleCloseDrawer = () => {
    form.resetFields();
    handleClose();
  };
  return (
    <div>
      <Drawer
        title="Reset password"
        placement={'right'}
        // closable={true}
        onClose={handleCloseDrawer}
        visible={open}
        extra={
          <Space onClick={handleCloseDrawer} style={{ cursor: 'pointer' }}>
            <CloseOutlined />
          </Space>
        }
      >
        <Form
          onFinish={onFinish}
          autoComplete="off"
          initialValues={{ remember: true }}
          form={form}
        >
          <label style={{ fontWeight: 500, marginBottom: 0, color: '#495057' }}>
            Email address
          </label>
          <Form.Item name="email" initialValue={candidate?.user?.email}>
            <Input
              placeholder="Email"
              disabled
              prefix={<img src={MailIcon} />}
            />
          </Form.Item>
          {/* Password */}
          <label style={{ fontWeight: 500, marginBottom: 0, color: '#495057' }}>
            Password
          </label>
          <Form.Item
            name="password"
            rules={[
              // {required : true , message : "Password is required"},
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value?.length == 0 || !value) {
                    return Promise.reject(new Error('Password is required!'));
                  } else if (value?.length < 8) {
                    return Promise.reject(
                      new Error('Password must be at least 8 characters long!')
                    );
                  }
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  // if (value?.length < 8) {
                  //   return Promise.reject(
                  //     new Error('Password must be at least 8 characters long!')
                  //   );
                  // }
                  return Promise.reject(new Error('passwords do not match!'));
                },
              }),
            ]}
          >
            <Input.Password
              placeholder="Password"
              prefix={<img src={PasswordIcon} />}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>
          {/* Confirm Password */}
          <label style={{ fontWeight: 500, marginBottom: 0, color: '#495057' }}>
            Confirm password
          </label>
          <Form.Item
            name="confirmPassword"
            dependencies={['password']}
            rules={[
              {
                // required: true,
                // message: "Password must be atleast 8 characters long!",
              },
              // {
              //   min: 8,
              //   message: 'Password must be at least 8 characters long!',
              // },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value?.length == 0 || !value) {
                    return Promise.reject(new Error('Password is required!'));
                  } else if (value?.length < 8) {
                    return Promise.reject(
                      new Error('Password must be at least 8 characters long!')
                    );
                  }
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  // if (value?.length < 8) {
                  //   return Promise.reject(
                  //     new Error('Password must be at least 8 characters long!')
                  //   );
                  // }
                  return Promise.reject(new Error('Passwords do not match!'));
                },
              }),
            ]}
          >
            <Input.Password
              placeholder="Confirm password"
              prefix={<img src={PasswordIcon} />}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Form.Item style={{ margin: 0 }}>
              <Button
                htmlType="submit"
                style={{
                  height: 31,
                  width: 101,
                  backgroundColor: '#BC9623',
                  borderRadius: 40,
                  color: 'white',
                  border: 'none',
                  marginTop: 10,
                }}
              >
                Submit
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Drawer>
    </div>
  );
};

export default ResetPassword;
