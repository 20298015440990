import {
  recruitersActionTypes,
  STORE_RECRUITERS,
  STORE_RECRUITER,
} from "./types";

export const StoreRecruiters = (recruiters: Array<Object>): recruitersActionTypes => {
  return {
    type: STORE_RECRUITERS,
    recruiters
  };
};

export const StoreRecruiter = (recruiter: Object): recruitersActionTypes => {
  return {
    type: STORE_RECRUITER,
    recruiter
  };
};

