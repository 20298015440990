import React, { useContext, useEffect, useState } from 'react';
import { Drawer, Space, Row, Col, Form } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useObjectivesQuery } from 'graphql/generated/graphql';
import { EditContext } from '../Context/EditContext';

interface PropsType {
  candidate: any;
  open: boolean;
  handleClose?: any;
  onUpdate?: (data: any) => void;
}

const MyobjectiveDrawer: React.FC<PropsType> = ({
  candidate,
  open,
  handleClose,
  onUpdate,
}) => {
  const [form] = Form.useForm();
  const [tags, setTags] = useState<any>([]);
  const editContext = useContext(EditContext);
  const { setData, setUpdatedData }: any = editContext;
  const { data, loading } = useObjectivesQuery();
  const [selectedTagIds, setSelectedTagIds] = useState<string[]>([]);

  useEffect(() => {
    if (!loading && data) {
      setTags(data?.getObjectives);
    }
  }, [data]);

  useEffect(() => {
    setSelectedTagIds(candidate?.objectives?.map((obj: any) => obj?._id) || []);
  }, [candidate]);

  const SingleTag = ({ title, id }: any) => {
    const isMatch = selectedTagIds?.includes(id);
    const backgroundColor = isMatch ? '#BC9623' : '#FFFFFF';
    const matchColor = isMatch ? '#FFFFFF' : '#BC9623';
    const matchSize = isMatch ? 14 : 16;

    const handleClick = (id: any) => {
      setSelectedTagIds((prevSelectedTagIds) => {
        if (!prevSelectedTagIds.includes(id)) {
          return [...prevSelectedTagIds, id];
        } else {
          return prevSelectedTagIds.filter((tagId) => tagId !== id);
        }
      });
    };

    return (
      <span
        onClick={() => handleClick(id)}
        style={{
          display: 'flex',
          backgroundColor,
          color: matchColor,
          padding: '15px',
          border: '1px solid #BC9623',
          borderRadius: '8px',
          marginBottom: '10px',
          whiteSpace: 'normal',
          wordWrap: 'break-word',
          maxWidth: '120px',
          height: '80px',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          cursor: 'pointer',
        }}
      >
        <span>
          <span style={{ fontSize: matchSize, fontWeight: 400 }}>
            {title.replace(title.split(' ')[title.split(' ').length - 1], '')}
          </span>
          <span style={{ fontSize: 14, fontWeight: 700 }}>
            {title.split(' ')[title.split(' ').length - 1]}
          </span>
        </span>
      </span>
    );
  };

  const handleData = () => {
    if (candidate?.objectives.length <= 5) {
      let newTags = tags.filter((tag: any) => selectedTagIds.includes(tag._id));
      setData({
        ...editContext?.candidate,
        objectives: [...newTags],
      });
      setUpdatedData({
        ...editContext?.updatedData,
        objectives: newTags,
      });
    }
    handleClose();
  };
  const handleCloseDrawer = () => {
    form.resetFields();
    setSelectedTagIds(candidate?.objectives?.map((obj: any) => obj?._id) || []);
    handleClose();
  };

  return (
    <div>
      <Drawer
        title="Edit objectives"
        className="Ant-D-Title"
        placement="right"
        visible={open}
        onClose={handleCloseDrawer}
        extra={
          <Space onClick={handleData} style={{ cursor: 'pointer' }}>
            <CloseOutlined />
          </Space>
        }
      >
        <Form form={form}>
          <div style={{ marginTop: 20 }}>
            <Row gutter={8}>
              {tags?.map((tag: any) => (
                <Col span={8} key={tag._id}>
                  <SingleTag id={tag._id} title={tag.title} />
                </Col>
              ))}
            </Row>
          </div>
        </Form>
      </Drawer>
    </div>
  );
};

export default MyobjectiveDrawer;
