import {
  skillsActionTypes,
  STORE_ACTIVE_SKILLS,
  STORE_ARCHIVED_SKILLS,
  STORE_DELETED_SKILLS,
  STORE_PENDING_SKILLS,
  UPDATE_ACTIVE_SKILL_TITLE,
  UPDATE_PENDING_SKILL_TITLE,
  MOVED_TO_ACTIVE_SKILLS_FROM_ARCHIVED_SKILLS,
  MOVED_TO_ACTIVE_SKILLS_FROM_NEW_SKILLS,
  MOVED_TO_ACTIVE_SKILLS_FROM_PENDING_SKILLS,
  MOVED_TO_ARCHIVED_SKILLS_FROM_ACTIVE_SKILLS,
  MOVED_TO_DELETED_SKILLS_FROM_ACTIVE_SKILLS,
  MOVED_TO_DELETED_SKILLS_FROM_ARCHIVED_SKILLS,
  MOVED_TO_DELETED_SKILLS_FROM_PENDING_SKILLS,
} from './types';

export const StoreActiveSkills = (
  activeSkills: Array<Object>
): skillsActionTypes => {
  return {
    type: STORE_ACTIVE_SKILLS,
    activeSkills,
  };
};

export const StorePendingSkills = (
  pendingSkills: Array<Object>
): skillsActionTypes => {
  return {
    type: STORE_PENDING_SKILLS,
    pendingSkills,
  };
};

export const StoreArchivedSkills = (
  archivedSkills: Array<Object>
): skillsActionTypes => {
  return {
    type: STORE_ARCHIVED_SKILLS,
    archivedSkills,
  };
};

export const StoreDeletedSkills = (
  deletedSkills: Array<Object>
): skillsActionTypes => {
  return {
    type: STORE_DELETED_SKILLS,
    deletedSkills,
  };
};

export const UpdateActiveSkillTitle = (
  skillId: string,
  title: string
): skillsActionTypes => {
  return {
    type: UPDATE_ACTIVE_SKILL_TITLE,
    skillId,
    title,
  };
};

export const UpdatePendingSkillTitle = (
  skillId: string,
  title: string
): skillsActionTypes => {
  return {
    type: UPDATE_PENDING_SKILL_TITLE,
    skillId,
    title,
  };
};

export const MoveToActiveSkillsFromArchivedSkills = (
  skillId: string
): skillsActionTypes => {
  return {
    type: MOVED_TO_ACTIVE_SKILLS_FROM_ARCHIVED_SKILLS,
    skillId,
  };
};

export const MoveToActiveSkillsFromNewSkills = (
  skill: object
): skillsActionTypes => {
  return {
    type: MOVED_TO_ACTIVE_SKILLS_FROM_NEW_SKILLS,
    skill,
  };
};

export const MoveToActiveSkillsFromPendingSkills = (
  skillId: string
): skillsActionTypes => {
  return {
    type: MOVED_TO_ACTIVE_SKILLS_FROM_PENDING_SKILLS,
    skillId,
  };
};

export const MoveToArchivedSkillsFromActiveSkills = (
  skillId: string
): skillsActionTypes => {
  return {
    type: MOVED_TO_ARCHIVED_SKILLS_FROM_ACTIVE_SKILLS,
    skillId,
  };
};

export const MoveToDeletedSkillsFromActiveSkills = (
  skillId: string
): skillsActionTypes => {
  return {
    type: MOVED_TO_DELETED_SKILLS_FROM_ACTIVE_SKILLS,
    skillId,
  };
};

export const MoveToDeletedSkillsFromArchivedSkills = (
  skillId: string
): skillsActionTypes => {
  return {
    type: MOVED_TO_DELETED_SKILLS_FROM_ARCHIVED_SKILLS,
    skillId,
  };
};

export const MoveToDeletedSkillsFromPendingSkills = (
  skillId: string
): skillsActionTypes => {
  return {
    type: MOVED_TO_DELETED_SKILLS_FROM_PENDING_SKILLS,
    skillId,
  };
};
