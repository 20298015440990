import React from 'react';
import faker from 'faker';

import { Drawer, List, Avatar, Button, Divider, Col, Row } from 'antd';
import { Link } from 'react-router-dom';

interface Props {
  company: any,
  jobs: any,
  show: boolean;
  hide: any;
}

const JobDrawer: React.FC<Props> = ({ show, hide, company, jobs }: any) => {
  return (
    <>
    {console.log('Show ... ', show, company, jobs)}
      <Drawer
        title="Jobs"
        placement="right"
        closable={true}
        onClose={hide}
        visible={show}
        width={600}
        className="menu-drawer"
      >
        {/* {faker.image.image()}
        {faker.image.imageUrl()}
        {faker.image.avatar()} */}
        {jobs && jobs.length > 0 && <List
          // dataSource={[
          //   {
          //     name: 'React native developer',
          //   },
          //   {
          //     name: 'Full stack web developer',
          //   },
          // ]}
          dataSource={[
            ...jobs
          ]}
          bordered
          renderItem={(item) => (
            <List.Item
              key={item._id}
              actions={[
                <a key="list-loadmore-edit">
                  <Link to={`/backoffice/jobs/${item._id}`}>View</Link>
                  </a>,
                // <a key="list-loadmore-more">edit</a>,
              ]}
            >
              <List.Item.Meta
                title={<a href={`/backoffice/jobs/${item._id}`}>{item.title}</a>}
                // description="Progresser XTech"
              />
            </List.Item>
          )}
        />}
        {jobs && jobs.length ==0 && <p style={{display: "flex", justifyContent: "center"}}>There are no jobs posted against the company</p>}

        {/* <div
          style={{
            textAlign: 'center',
            marginTop: 12,
            height: 32,
            lineHeight: '32px',
          }}
        >
          <Button>Add new job</Button>
        </div> */}
      </Drawer>
    </>
  );
};

export default JobDrawer;
