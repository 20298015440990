import React, { useEffect } from 'react';
import { Table, Button, Space, Tooltip, Popconfirm } from 'antd';
import { DeleteOutlined, RollbackOutlined } from '@ant-design/icons';
import { uniqBy } from 'lodash';
import { SkillsState } from 'store/skills/types';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';

import { StoreArchivedSkills } from '../../../../../store/skills/actions';

import { useSkillsQuery } from '../../../../../graphql/generated/graphql';

import { ColumnsType } from 'antd/es/table';

interface Props {
  actionHandler: Function;
}

const ArchivedSkillList: React.FC<Props> = ({ actionHandler }) => {
  const dispatch = useDispatch();
  const archivedSkillListFromStore = useSelector(
    (state: SkillsState) => state.skills?.archivedSkills
  );

  const { data, loading, error } = useSkillsQuery({
    variables: {
      status: 'All',
      isArchived: true,
      isDeleted: false,
      isBO: true,
    },
  });

  useEffect(() => {
    if (!loading && data) {
      const sortedData = data.skills.sort((a: any, b: any) => {
        a = new Date(parseInt(a.createdAt));
        b = new Date(parseInt(b.createdAt));
        return b - a;
      });
      // sortedData = uniqBy(sortedData, '_id')
      dispatch(StoreArchivedSkills([...sortedData]));
    }
  }, [dispatch, data]);

  const actionsHandler = (data: any) => {
    return (
      <Space>
        <Tooltip title="rollback">
          <Button
            className="border-primary"
            shape="circle"
            icon={<RollbackOutlined className="text-primary" />}
            onClick={() => {
              actionHandler('rollback', data._id, data);
            }}
          />
        </Tooltip>
        <Tooltip title="delete">
          <Popconfirm
            title="Are you sure delete this skill?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => {
              actionHandler('delete', data._id, data, 'archived');
              return;
            }}
          >
            <Button
              className="border-burgundy"
              shape="circle"
              icon={<DeleteOutlined className="text-burgundy" />}
            />
          </Popconfirm>
        </Tooltip>
      </Space>
    );
  };

  const showTitle = (data: any) => {
    return <p style={{ fontSize: '15px', fontWeight: 500 }}>{data}</p>;
  };

  const noOfCandidates = (data: any) => {
    return (
      <p style={{ color: '#2e4a79', fontSize: '15px', fontWeight: 500 }}>{`${
        data?.length ?? 0
      }`}</p>
    );
  };

  const noOfJobs = (data: any) => {
    return (
      <p style={{ color: '#2e4a79', fontSize: '15px', fontWeight: 500 }}>{`${
        data?.length ?? 0
      }`}</p>
    );
  };

  const showDate = (data: any) => {
    return (
      <p style={{ fontSize: '15px', fontWeight: 500 }}>
        {moment(parseInt(data)).format('MMMM DD, YYYY')}
      </p>
    );
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Skills',
      dataIndex: 'title',
      key: 'title',
      showSorterTooltip: false,
      sorter: (a: any, b: any) => a.title.length - b.title.length,
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'ascend',
      render: showTitle,
    },
    {
      title: 'Candidates',
      dataIndex: 'candidatesCount',
      key: 'candidatesCount',
      showSorterTooltip: false,
      sorter: (a: any, b: any) =>
        a.candidatesCount?.length - b.candidatesCount?.length,
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'ascend',
      render: noOfCandidates,
    },
    {
      title: 'Jobs',
      dataIndex: 'jobsCount',
      key: 'jobsCount',
      showSorterTooltip: false,
      sorter: (a: any, b: any) => a.jobsCount?.length - b.jobsCount?.length,
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'ascend',
      render: noOfJobs,
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: showDate,
    },
    {
      title: 'Actions',
      key: 'id',
      fixed: 'right',
      width: 120,
      render: actionsHandler,
    },
  ];

  return (
    <Table
      className="table-striped"
      columns={columns}
      loading={loading}
      dataSource={archivedSkillListFromStore}
      size="small"
      pagination={{ pageSize: 20 }}
      scroll={{ x: 1300 }}
    />
  );
};

export default ArchivedSkillList;
