import React, { useContext, useEffect, useRef, useState } from 'react';
import { Drawer, Form, Tag, Select, Button, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { useInspiringCompaniesQuery } from 'graphql/generated/graphql';
import { EditContext } from '../Context/EditContext';

interface PropsType {
  candidate: any;
  open: boolean;
  handleClose?: any;
  onUpdate?: (data: any) => void;
}

const Styles = {
  tagsWrapper: {
    marginTop: 20,
  },
  submitBtn: {
    width: 101,
    float: 'right',
    backgroundColor: '#BC9623',
    borderRadius: 20,
    marginTop: 20,
    border: 'none',
    color: 'white',
  },
};

const MyinspiringcompanyDrawer: React.FC<PropsType> = ({
  candidate,
  open,
  handleClose,
  onUpdate,
}) => {
  const [form] = useForm();

  const [tags, setTags] = useState<any>([]);
  const editContext = useContext(EditContext);
  const { setData, setUpdatedData }: any = editContext;
  const ref = useRef<any>(null);
  const { data, loading, error } = useInspiringCompaniesQuery();
  const [val, setVal] = useState<any>([]);
  const [isDisable, SetIsDisable] = useState<boolean>(false);
  useEffect(() => {
    if (!loading && data) {
      setTags(data?.inspiringCompanies);
    }
  }, [data]);
  const candidateinspiringCompaniesLimit =
    candidate?.inspiringCompanies.length + val.length;

  const removeTag = (value: string) => {
    setData({
      ...editContext?.candidate,
      inspiringCompanies: editContext?.candidate.inspiringCompanies.filter(
        (skill: any) => skill._id != value
      ),
    });
    // console.log(value)
  };

  const SingleTag = ({ name, color, id }: any) => {
    return (
      <Tag
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
        onClose={(e) => {
          e.preventDefault();
          removeTag(id);
        }}
        style={{
          border: 'none',
          marginRight: 10,
          marginBottom: 15,
          backgroundColor: 'rgba(18, 100, 121, 0.8)',
          borderRadius: 20,
          color: 'white',
          paddingTop: 5,
          paddingBottom: 5,
          paddingLeft: 10,
          paddingRight: 10,
        }}
        closable
      >
        {name}
      </Tag>
    );
  };

  const onFinish = (values: any) => {
    if (candidate?.inspiringCompanies.length <= 4) {
      let newTags = tags.filter((tag: any) =>
        val ? val.includes(tag._id) : null
      );

      const newTagsCountToBeAdded = 5 - candidate?.inspiringCompanies.length;
      let alreadyAddedTagsIds: any = [];
      alreadyAddedTagsIds = candidate?.inspiringCompanies.map(
        (tag: any) => tag._id
      );
      newTags = newTags.filter(
        (tag: any) => !alreadyAddedTagsIds.includes(tag._id)
      );
      let newUpdatedTags: any = [];
      for (let index = 0; index < newTagsCountToBeAdded; index++) {
        if (newTags.length > 0 && newTags[index]) {
          newUpdatedTags = [...newUpdatedTags, newTags[index]];
        }
      }
      newTags = newUpdatedTags;

      const updatedTags = [
        ...newTags.map((tag: any) => {
          return { name: tag.name, _id: tag._id };
        }),
        ...editContext?.candidate.inspiringCompanies.map((tag: any) => {
          return { name: tag.name, _id: tag._id };
        }),
      ];
      setData({
        ...editContext?.candidate,
        inspiringCompanies: [
          ...editContext?.candidate.inspiringCompanies,
          ...newTags,
        ],
      });
      setUpdatedData({
        ...editContext?.updatedData,
        inspiringCompanies: updatedTags,
      });
    }

    setVal([]);
    handleClose();
    form.resetFields();
  };

  const handleTagChange = (value: any) => {
    val.length < 5 && setVal(value);
    value.length === 5 && SetIsDisable(true);
  };

  const options: any = tags.map((tag: any) => {
    return { label: tag.name, value: tag._id };
  });
  const handleCloseDrawer = () => {
    form.resetFields();
    handleClose();
  };
  return (
    <div>
      <Drawer
        title="Edit inspiring companies"
        className="Ant-D-Title"
        placement={'right'}
        // closable={true}
        onClose={handleCloseDrawer}
        visible={open}
        extra={
          <Space onClick={handleClose} style={{ cursor: 'pointer' }}>
            <CloseOutlined />
          </Space>
        }
      >
        <Form
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
          form={form}
        >
          <Form.Item name="tags">
            <label
              style={{
                marginBottom: '7px',
                lineHeight: 1,
                fontSize: '16px',
                fontWeight: 400,
              }}
            >
              Add a company
            </label>
            <Select
              suffixIcon={<SearchOutlined />}
              mode="tags"
              placeholder="Select a company"
              onChange={handleTagChange}
              options={options}
              optionFilterProp="label"
              style={{ width: '100%' }}
              disabled={isDisable}
            />
            <span style={{ marginLeft: '10px' }}>
              {candidateinspiringCompaniesLimit > 5 ? 'Max Limit 5' : ''}
            </span>
          </Form.Item>
          <div>
            <Form.Item>
              <Button
                disabled={candidateinspiringCompaniesLimit > 5 ? true : false}
                style={{
                  width: 80,
                  float: 'right',
                  backgroundColor: '#BC9623',
                  borderRadius: 20,
                  marginTop: 10,
                  border: 'none',
                  color: 'white',
                }}
                htmlType="submit"
              >
                Add
              </Button>
            </Form.Item>
          </div>
        </Form>
        <div style={Styles.tagsWrapper}>
          {candidate?.inspiringCompanies.map((tag: any) => (
            <SingleTag key={tag._id} id={tag._id} name={tag.name} />
          ))}
        </div>
      </Drawer>
    </div>
  );
};

export default MyinspiringcompanyDrawer;
