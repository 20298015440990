import React, { useContext, useEffect, useRef, useState } from 'react';
import { Drawer, Form, Tag, Select, Button, Space } from 'antd';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { useSkillsQuery } from 'graphql/generated/graphql';
import { EditContext } from '../Context/EditContext';
import { update } from 'lodash';

interface PropsType {
  candidate: any;
  open: boolean;
  handleClose?: any;
  onUpdate: (data: any) => void;
}

const Styles = {
  skillsWrapper: {
    marginTop: 20,
  },
  submitBtn: {
    width: 101,
    float: 'right',
    backgroundColor: '#BC9623',
    borderRadius: 20,
    marginTop: 20,
    border: 'none',
    color: 'white',
  },
};

const SkillsDrawer: React.FC<PropsType> = ({
  candidate,
  open,
  handleClose,
  onUpdate,
}) => {
  const editContext = useContext(EditContext);
  const { setData, setUpdatedData, updatedData }: any = editContext;
  const [skills, setSkills] = useState<any>([]);
  const [customSkills, setCustomSkills] = useState<string[]>([]);
  //get Value on Onchange select
  useEffect(() => {
    setCustomSkills(updatedData?.customSkills ? updatedData?.customSkills : []);
  }, [updatedData?.customSkills]);
  const [form] = Form.useForm();
  const userSkills = Form.useWatch('skills', form);
  const ref = useRef<any>(null);
  const { data, loading, error } = useSkillsQuery({
    variables: {
      status: 'Approved',
      isArchived: false,
      isDeleted: false,
      isBO: true,
    },
  });

  useEffect(() => {
    if (!loading && data) {
      setSkills(data?.skills);
    }
  }, [data]);

  const removeSkill = (value: string) => {
    console.log(value);

    setData({
      ...editContext?.candidate,
      skills: editContext?.candidate.skills.filter(
        (skill: any) => skill._id != value
      ),
      customSkills: editContext?.updatedData?.customSkills?.filter(
        (skill: any) => skill != value
      ),
    });
    let customSkillsFilter = updatedData?.customSkills
      ? [
          ...editContext?.updatedData?.customSkills
            .filter((skill: any) => skill != value)
            .map((skill: any) => skill),
        ]
      : [];
    setUpdatedData({
      ...editContext?.updatedData,
      customSkills: customSkillsFilter,
    });
    // console.log(value)
  };
  //total skills count for limit
  let skillsLimitCount = candidate?.skills?.length + userSkills?.length;

  const Skill = ({ title, id }: any) => {
    return (
      <Tag
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
        onClose={(e) => {
          e.preventDefault();
          removeSkill(id);
        }}
        style={{
          border: 'none',
          marginRight: 10,
          marginBottom: 15,
          backgroundColor: 'rgba(18, 100, 121, 0.8)',
          borderRadius: 20,
          color: 'white',
          paddingTop: 5,
          paddingBottom: 5,
          paddingLeft: 10,
          paddingRight: 10,
        }}
        closable
      >
        {title}
      </Tag>
    );
  };

  const onFinish = (values: any) => {
    console.log(values);
    const uniqueArraySkills = customSkills.filter(
      (item, index) => customSkills.indexOf(item) === index
    );

    const selectedSkills = values.skills || [];
    const newSkills = skills.filter((skill: any) =>
      selectedSkills.includes(skill._id)
    );
    const filteredSkills = newSkills?.filter(
      (skill: any) =>
        !candidate?.skills?.some((s: any) => s?._id === skill?._id)
    );
    console.log(filteredSkills);

    const updatedSkills = [
      ...filteredSkills.map((skill: any) => skill._id),
      ...editContext?.candidate.skills.map((skill: any) => skill._id),
    ];

    const updatedCandidate = {
      ...editContext?.candidate,
      skills: [...editContext?.candidate.skills, ...filteredSkills],
      customSkills: uniqueArraySkills,
    };

    const updatedData = {
      ...editContext?.updatedData,
      skills: updatedSkills,
      customSkills: uniqueArraySkills, // Include the custom skills in the updated data
    };

    setData(updatedCandidate);
    setUpdatedData(updatedData);
    setCustomSkills([]);
    form.resetFields();
    onUpdate(null);
  };

  const handleSkillChange = (value: any) => {
    const customSkill = value.filter(
      (skill: string) => !skills.some((s: any) => s._id === skill)
    );
    if (customSkill?.length >= 1) {
      setCustomSkills([...customSkill, ...customSkills]);
    }
  };

  const options: any = skills.map((skill: any) => {
    return { label: skill.title, value: skill._id };
  });
  const handleCloseDrawer = () => {
    form.resetFields();
    // setCustomSkills([]);
    handleClose();
  };

  return (
    <div>
      <Drawer
        title="Candidate’s skills"
        placement={'right'}
        // closable={true}
        onClose={handleCloseDrawer}
        visible={open}
        extra={
          <Space onClick={handleCloseDrawer} style={{ cursor: 'pointer' }}>
            <CloseOutlined />
          </Space>
        }
      >
        <Form
          form={form}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            name="skills"
            className="marginSkills"
            rules={[
              {
                required: false,
                message: 'This field is required!',
              },
            ]}
          >
            {/* <label
              style={{
                marginBottom: '7px',
                lineHeight: 1,
                fontSize: '16px',
                fontWeight: 400,
              }}
            >
              Add a skill
            </label> */}
            <Select
              suffixIcon={<SearchOutlined />}
              mode="tags"
              placeholder="Select skill"
              onChange={handleSkillChange}
              options={options}
              optionFilterProp="label"
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Space
            style={{ marginLeft: '10px', marginTop: '-20px', color: '#ec536c' }}
          >
            {skillsLimitCount > 15 ? 'Max Limit 15' : ''}
          </Space>

          <div>
            <Form.Item>
              <Button
                disabled={skillsLimitCount > 15 ? true : false}
                style={{
                  width: 80,
                  float: 'right',
                  backgroundColor: '#BC9623',
                  borderRadius: 20,
                  marginTop: '25px',
                  border: 'none',
                  color: 'white',
                }}
                htmlType="submit"
              >
                Add
              </Button>
            </Form.Item>
          </div>
        </Form>
        <div style={Styles.skillsWrapper}>
          {candidate?.skills.map((skill: any, index: any) => (
            <Skill key={skill._id} id={skill._id} title={skill.title} />
          ))}
          {updatedData?.customSkills?.map((skill: any, index: any) => (
            <Skill key={index} id={skill} title={skill} />
          ))}
        </div>
      </Drawer>
    </div>
  );
};

export default SkillsDrawer;
