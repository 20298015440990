import React, { useEffect, useState } from 'react';
import { useParams, Link, useHistory, useLocation } from 'react-router-dom';
import IconCard from '../../../../components/UI/IconCard/IconCard';
import PageTitle from '../../../../components/UI/PageTitle/PageTitle';
import ErrorCard from '../../../../components/UI/ErrorCard/ErrorCard';
import styled from 'styled-components';
import gql from 'graphql-tag';
import UsersGroupIcon from '../../../../assets/icons/icons/fa-solid_users.svg';
import './ViewJob.module.scss';
import { useTableState } from '../../Matches/partials/MatchesTable/MatchTableContext';

import {
  Row,
  Col,
  Card,
  Skeleton,
  Empty,
  Button,
  Avatar,
  Typography,
  List,
  Tooltip,
  Space,
  Table,
  Tag,
  Divider,
  Spin,
  Tabs,
} from 'antd';
import { Fade } from 'react-awesome-reveal';
import {
  UserOutlined,
  CalendarOutlined,
  LoadingOutlined,
  FolderOpenOutlined,
  GroupOutlined,
} from '@ant-design/icons';
import {
  useGetSingleJobQuery,
  useCreatedMatchSubscription,
} from '../../../../graphql/generated/graphql';
import moment from 'moment';
import MatchesTable from '../../Matches/partials/MatchesTable/MatchesTable';
import useMatchesForAJobQuery from '../../../../hooks/useMatchesForAJob';
import { useSubscription } from '@apollo/react-hooks';
import AssessmentTable from './AssessmentTable';
import YearlyCompensation from './YearlyCompensation';
import { JobDetailIcon, ViewIcon2, pdf } from 'assets/icons/DetailedProfile';
import JobCard from './JobCard';
import ProgressReport from './ProgressReport';
import './ViewJob.scss';
import AddCandidate from './AddCandidate';
import Axios from 'axios';
import TabPane from 'antd/lib/tabs/TabPane';

const { Title } = Typography;

const SUBSCRIBE_CREATE_MATCH = gql`
  subscription onCreatedMatch {
    createdMatch {
      _id
      candidateListing {
        _id
        userId
        firstName
        lastName
        resume
        position
        user {
          email
        }
        countries {
          _id
          name
          code
        }
        currentLocation {
          coordinates
          country
          city
          code
        }
        isProfileCompleted
      }
      score
      jobScore
      activationScore
      recruiterAction
      candidateAction
      bypass
      media
      isDeleted
      createdAt
      updatedAt
    }
  }
`;

const ViewJob: React.FC = () => {
  const { jobId } = useParams<any>();

  //handle view
  const [hasData, setHasData] = useState(true);
  const [loadingMatches, setLoadingMatches] = useState(true);
  const [matchs, setMatchs]: any = useState([]);
  const [candidatesCount, setCandidatesCount]: any = useState(0);

  const location: any = useLocation();

  const history = useHistory();

  const { data, loading, error }: any = useGetSingleJobQuery({
    variables: {
      jobId: jobId,
    },
    fetchPolicy: 'network-only',
  });

  const matchsList = useMatchesForAJobQuery(jobId);
  const [PDFloading, setPDFLoading] = useState(false);

  const {
    data: data1,
    loading: loading1,
    error: error1,
  } = useSubscription(SUBSCRIBE_CREATE_MATCH);
  // const { data: data1, loading: loading1, error: error1 } = useCreatedMatchSubscription();

  const GenPDF = async () => {
    setPDFLoading(true);
    try {
      const url = `http://35.180.103.242:9001/get-profile?jobId=${jobId}`;
      const headers = { env: `${process.env.REACT_APP_ENV}` };

      const response = await Axios.get(url, {
        responseType: 'blob', // Specify response type as blob for binary data
        headers,
        timeout: 1000000,
      });

      if (response.status !== 200) {
        // Handle non-200 status codes
        console.error('Error fetching PDF:', response.statusText);
      } else {
        const blob = response.data;
        const fileName = `profile_${jobId}.pdf`; // Customize filename

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', fileName);
        link.style.display = 'none'; // Hide the link (optional)

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url); // Clean up temporary URL
      }
    } catch (error) {
      console.error('Error fetching PDF:', error);
    } finally {
      setPDFLoading(false); // Ensure loading state is reset even on errors
    }
  };

  useEffect(() => {
    data && data?.getJobDetails === null && setHasData(false);
  }, [data]);

  useEffect(() => {
    if (matchsList) {
      setMatchs(matchsList);
      setLoadingMatches(false);
    }
  }, [matchsList]);

  useEffect(() => {
    if (matchsList) {
      setMatchs([data1.createdMatch, ...matchs]);
      setLoadingMatches(loading1);
    }
  }, [data1]);

  return (
    <>
      <PageTitle title="Job information" btnActive={false} back jobId={jobId} />
      {!loading && !data ? (
        <ErrorCard />
      ) : (
        <>
          <Row justify="center" className="pb-4" gutter={24}>
            <Col span={17}>
              <Fade>
                <JobContainer className="directory-card-job">
                  <Skeleton
                    active
                    avatar
                    paragraph={{ rows: 3 }}
                    className="p-4"
                    loading={loading}
                  >
                    {data && data.getJobDetails !== null ? (
                      // <>
                      //   <Row justify="center" className="pb-4" gutter={16}>
                      //     <Col span={24}>
                      //       <Title
                      //         level={3}
                      //         style={{ marginBottom: '0px' }}
                      //         className="text-navy"
                      //       >
                      //         {data && data.getJobDetails?.title}
                      //         {/* <Tooltip placement="top" title="Edit summary">
                      //           <span
                      //             className="ml-2 text-burgundy clickable-span font-18"
                      //             //   onClick={() => setDisplaySummaryDrawer(true)}
                      //           >
                      //             <i className="fas fa-edit" />
                      //           </span>
                      //         </Tooltip> */}
                      //       </Title>
                      //       <div className="companyConatiner pt-2 pb-4 border-bottom-grey">
                      //         <Avatar size={32} icon={<UserOutlined />} />

                      //         <span className="pl-1 font-16 font-500 text-black">
                      //           {data && data.getJobDetails?.company?.name}
                      //         </span>
                      //       </div>
                      //     </Col>
                      //   </Row>
                      //   <Row>
                      //     <Col>
                      //       <p
                      //         className="text-muted font-12"
                      //         dangerouslySetInnerHTML={{
                      //           __html: data.getJobDetails?.description,
                      //         }}
                      //       ></p>
                      //     </Col>
                      //   </Row>
                      // </>
                      <JobCard job={data?.getJobDetails} />
                    ) : (
                      <div className="p-4 m-t-40 m-b-40">
                        <Empty description={<span>No company found</span>}>
                          <Link to={'/companies/new'}>
                            <Button
                              className="ant-btn-amber"
                              shape="round"
                              size="middle"
                            >
                              Create new company
                            </Button>
                          </Link>
                        </Empty>
                      </div>
                    )}
                  </Skeleton>
                </JobContainer>
              </Fade>
            </Col>
            <Col span={7}>
              {/* <Button
                block
                type="primary"
                shape="round"
                className="ant-btn-amber"
                onClick={() =>
                  history.push(`/backoffice/joblandingpage/${jobId}`)
                }
                style={{
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  display: 'flex',
                  // width: "90%",
                  margin: '0px auto 10px auto',

                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: 'normal',
                }}
              >
                Job page
                <img src={ViewIcon2} alt="job icon" />
              </Button> */}
              <Row
                style={{
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  display: 'flex',
                  background: '#BC9623',
                  minHeight: '38px',
                  color: '#ffffff',
                  width: '100%',
                  margin: '0px auto 10px auto',
                  padding: ' 8.5px 34px',
                  borderRadius: '18px',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: 'normal',
                  cursor: 'pointer',
                }}
                onClick={() =>
                  history.push(`/backoffice/joblandingpage/${jobId}`)
                }
              >
                Manage job page
                <img
                  src={ViewIcon2}
                  alt="job icon"
                  style={{ cursor: 'pointer' }}
                />
              </Row>

              {/* Progress Report */}
              <ProgressReport job={data} />
              {/* Progress Report */}

              <Row
                style={{
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  display: 'flex',
                  background: '#2E4A79',
                  minHeight: '38px',
                  color: '#ffffff',
                  width: '100%',
                  margin: '0px auto 10px auto',
                  padding: ' 8.5px 34px',
                  borderRadius: '18px',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: 'normal',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  if (!PDFloading) {
                    window.open(`/backoffice/jobs/${jobId}/pdf`, '_blank');
                  }
                }}
              >
                {PDFloading
                  ? 'Loading Candidates profiles...'
                  : 'Candidates profiles'}
                {PDFloading ? (
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{ fontSize: 14, color: '#fff' }}
                        spin
                      />
                    }
                  />
                ) : (
                  <img src={pdf} alt="job icon" style={{ cursor: 'pointer' }} />
                )}
              </Row>

              <IconCard
                icon="far fa-calendar-alt"
                color="primary"
                loading={false}
                label="Date of job posting"
              >
                {data?.getJobDetails &&
                  moment(
                    parseInt(data?.getJobDetails?.createdAt || '0')
                  ).format('DD/MM/YY')}
              </IconCard>
              {/* <IconCard
                icon="fas fa-map-marker-alt"
                color="teal"
                loading={false}
                label="Location"
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <RowWithTagsStyle>
                    {data?.getJobDetails &&
                      data?.getJobDetails.countries.map((country: any) => (
                        <Tag
                          key={country._id}
                          title={country?.name}
                          color="#2e4a79"
                          style={{
                            maxWidth: '200px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {country.name.charAt(0).toUpperCase() +
                            country.name.substr(1)}
                        </Tag>
                      ))}
                  </RowWithTagsStyle>
                  <Tooltip placement="top" title="Edit summary">
                    <span
                      className="ml-2 text-burgundy clickable-span font-16"
                      //   onClick={() => setDisplaySummaryDrawer(true)}
                    >
                      <i className="fas fa-edit" />
                    </span>
                  </Tooltip>
                </div>
              </IconCard> */}
              {/* <IconCard
                icon="fas fa-sun"
                color="amber"
                loading={false}
                label="Experience required"
              >
                {data?.getJobDetails && data?.getJobDetails?.experience}

               
              </IconCard> */}
              <Card className="mb-2 displayCards">
                <Skeleton active loading={loading} avatar>
                  <Row justify="center" gutter={[0, 16]}>
                    <Col span={24} className="align-self-center">
                      <Title
                        className="mt-0 text-primary"
                        style={{ fontSize: '18px', fontWeight: '600' }}
                      >
                        {/* <img
                          src={JobDetailIcon}
                          alt="job icon"
                          style={{ marginRight: '10px' }}
                        /> */}
                        Job details
                      </Title>
                      <MyDivider></MyDivider>
                      <OverviewSection>
                        <Row>
                          <OverviewSubHeadingWithIcon>
                            <i className={'dripicons-location'}></i>
                            <p>Job location:</p>
                          </OverviewSubHeadingWithIcon>
                        </Row>

                        {data?.getJobDetails &&
                          data?.getJobDetails?.countries &&
                          data?.getJobDetails.countries.map((country: any) => (
                            // <Tag key={country._id} color="#fff">
                            //   {country.name?.charAt(0).toUpperCase() +
                            //     country.name?.substr(1)}
                            // </Tag>
                            <RowWithTagsStyle
                              key={country?._id}
                              fontColor=" #313131;"
                            >
                              {formatedLocation(country.name)}
                            </RowWithTagsStyle>
                          ))}
                      </OverviewSection>
                      <OverviewSection>
                        <Row>
                          <OverviewSubHeadingWithIcon>
                            <i className={'dripicons-lightbulb'}></i>
                            <p>Required skills:</p>
                          </OverviewSubHeadingWithIcon>
                        </Row>
                        <RowWithTagsStyle>
                          {data?.getJobDetails &&
                            data?.getJobDetails.requiredSkills.map(
                              (skill: any) => (
                                <Tag key={skill?._id} color="#418394">
                                  {skill?.title}
                                </Tag>
                              )
                            )}
                        </RowWithTagsStyle>
                      </OverviewSection>
                      <OverviewSection>
                        <Row>
                          <OverviewSubHeadingWithIcon>
                            <i className={'dripicons-map'}></i>
                            <p>Job fields:</p>
                          </OverviewSubHeadingWithIcon>
                        </Row>
                        <RowWithTagsStyle>
                          {data?.getJobDetails &&
                            data?.getJobDetails.fields.map(
                              (field: any, index: any) => (
                                <Tag key={index} color="#2e4a79">
                                  {field.title}
                                </Tag>
                              )
                            )}
                        </RowWithTagsStyle>
                      </OverviewSection>
                      <OverviewSection>
                        <Row>
                          <OverviewSubHeadingWithIcon>
                            <i className={'dripicons-user'}></i>
                            <p>Lead recruiter:</p>
                          </OverviewSubHeadingWithIcon>
                        </Row>
                        <RowWithTagsStyle fontColor=" #313131;">
                          {data?.getJobDetails &&
                            data?.getJobDetails.leadRecruiter && (
                              <Tag color="#dcdcdc;">
                                {data?.getJobDetails.leadRecruiter.email}
                              </Tag>
                            )}
                        </RowWithTagsStyle>
                      </OverviewSection>
                      <OverviewSection>
                        <Row>
                          <OverviewSubHeadingWithIcon>
                            <i className={'dripicons-user-group'}></i>
                            <p>Recruiting team:</p>
                          </OverviewSubHeadingWithIcon>
                        </Row>
                        <RowWithTagsStyle fontColor=" #313131;">
                          {data?.getJobDetails &&
                            data?.getJobDetails?.recruiters.map(
                              (recruiter: any, index: any) => (
                                <Tag key={index} color="#dcdcdc;">
                                  {recruiter.email}
                                </Tag>
                              )
                            )}
                        </RowWithTagsStyle>
                      </OverviewSection>
                    </Col>
                  </Row>
                </Skeleton>
              </Card>
              {/* <Card className="mb-3 icon-card">
                <Skeleton active loading={loading} avatar>
                  <Row justify="center" gutter={16}>
                    <Col span={24} className="align-self-center">
                      <Title level={4} className="mt-0 text-primary">
                        Contract details{' '}
                      </Title>
                      <MyDivider></MyDivider>
                      <OverviewSection>
                        <Row>
                          <OverviewSubHeadingWithIcon>
                            <i className={'dripicons-lightbulb'}></i>
                            <p>Job fields:</p>
                          </OverviewSubHeadingWithIcon>
                        </Row>
                        <RowWithTagsStyle>
                          {data?.getJobDetails &&
                            data?.getJobDetails.fields.map((field: any) => (
                              <Tag key={field?._id} color="#2e4a79">
                                {field?.title}
                              </Tag>
                            ))}
                        </RowWithTagsStyle>
                      </OverviewSection>
                      <OverviewSection>
                        <Row>
                          <OverviewSubHeadingWithIcon>
                            <i className={'dripicons-user'}></i>
                            <p>Contract type:</p>
                          </OverviewSubHeadingWithIcon>
                        </Row>
                        <RowWithTagsStyle fontColor=" #313131;">
                          {data?.getJobDetails &&
                            data?.getJobDetails.contractType && (
                              <Tag color="#dcdcdc;">
                                {data?.getJobDetails.contractType.name}
                              </Tag>
                            )}
                        </RowWithTagsStyle>
                      </OverviewSection>
                      <OverviewSection>
                        <Row>
                          <OverviewSubHeadingWithIcon>
                            <i className={'dripicons-user'}></i>
                            <p>Salary package: </p>
                          </OverviewSubHeadingWithIcon>
                        </Row>
                        <RowWithTagsStyle fontColor=" #313131;">
                          {data?.getJobDetails &&
                            data?.getJobDetails.salaryPackage && (
                              <Tag color="#a59a9a;">
                                {data?.getJobDetails.salaryPackage}
                              </Tag>
                            )}
                        </RowWithTagsStyle>
                      </OverviewSection>
                      <OverviewSection>
                        <Row>
                          <OverviewSubHeadingWithIcon>
                            <i className={'dripicons-user-group'}></i>
                            <p>Target companies:</p>
                          </OverviewSubHeadingWithIcon>
                        </Row>
                        <RowWithTagsStyle fontColor=" #313131;">
                          {data?.getJobDetails &&
                            data?.getJobDetails?.targetCompanies.map(
                              (targetCompany: any, index: any) => (
                                <Tag key={index} color="#dcdcdc;">
                                  {targetCompany.name}
                                </Tag>
                              )
                            )}
                        </RowWithTagsStyle>
                      </OverviewSection>
                    </Col>
                  </Row>
                </Skeleton>
              </Card> */}
              {data?.getJobDetails?.yearlyCompensation && (
                <YearlyCompensation
                  showIcon={false}
                  nocomments={location?.state?.comments}
                  candidate={data?.getJobDetails?.yearlyCompensation}
                />
              )}

              {data?.getJobDetails?.assessment &&
                (data?.getJobDetails?.assessment.experience.length > 0 ||
                  data?.getJobDetails?.assessment.traits.length > 0) && (
                  <AssessmentTable
                    assessmentData={data?.getJobDetails?.assessment}
                  />
                )}
            </Col>
          </Row>
          <Row justify="center" className="pb-4" gutter={16}>
            <Col span={24}>
              <Fade>
                <AddCandidate jobId={jobId} />
                <Card className="tabs-parent">
                  <Skeleton
                    active
                    avatar
                    paragraph={{ rows: 3 }}
                    className="p-4"
                    loading={loading}
                  >
                    <Tabs defaultActiveKey="1" style={{ minWidth: '100%' }}>
                      <TabPane
                        style={{ minWidth: '100%' }}
                        tab={
                          <div
                            style={{
                              minWidth: '100%',
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <span>
                              <img
                                src={UsersGroupIcon}
                                alt="logo"
                                style={{
                                  height: '18px',
                                  width: '22.5px',
                                  marginRight: '10px',
                                  marginTop: '-5px',
                                }}
                              />{' '}
                              <span style={{ fontSize: '16px' }}>
                                List of Preselected candidates
                              </span>
                              {/* Need to subtract hardcoded 7, because if we run aggregation on candidatelist
                            with respect to different tables , we wont get the total count, and db has 7 bogus entries, this is only for staging */}
                              {matchs && (
                                <span
                                  className="tab-pane-count"
                                  style={{
                                    marginLeft: '14px',
                                    maxHeight: '19px',
                                    fontSize: '10px',
                                  }}
                                >
                                  <span
                                    style={{
                                      padding: '0px 8px',
                                      fontSize: '10px',
                                    }}
                                  >
                                    {candidatesCount
                                      ? candidatesCount
                                      : matchs?.length > 0
                                      ? matchs?.length
                                      : 0}
                                  </span>
                                </span>
                              )}
                            </span>
                          </div>
                        }
                        key="1"
                      >
                        {/* Need to subtract hardcoded 7, because if we run aggregation on candidatelist
                        with respect to different tables , we wont get the total count, and db has 7 bogus entries, this is only for staging */}
                        <MatchesTable
                          jobId={jobId}
                          matchs={matchs}
                          loadingMatches={loadingMatches}
                          setCandidatesCount={setCandidatesCount}
                        />
                      </TabPane>
                    </Tabs>
                    {/* <Title level={4} className="mb-2">
                      Pre selected candidates
                    </Title>
                    <MatchesTable
                      jobId={jobId}
                      matchs={matchs}
                      loadingMatches={loadingMatches}
                    /> */}
                  </Skeleton>
                </Card>
              </Fade>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

const OverviewSubHeadingWithIcon = styled.div`
  display: flex;
  flex-direction: 'row';
  color: #bc9623 !important;
  font-weight: 400;
  margin: 0px;
  padding: 0px;

  i {
    margin-right: 5px;
    margin-bottom: 5px;
  }
  p {
    margin-bottom: 5px;
  }
`;
const MyDivider = styled(Divider)`
  margin: 10px 0px;
`;

const RowWithTagsStyle: any = styled(Row)`
  span {
    border-radius: 15px;
    padding: 0.2vh 1vw;
    color: ${(props: any) => props.fontColor || 'white'};
    margin: 3px;
    overflow: hidden;
  }
`;
const RowWithTransparentBorderTagsStyle: any = styled(RowWithTagsStyle)`
  span {
    border: 1px solid #2e4a79;
    margin: 3px;
  }
`;

const OverviewSection = styled.div`
  margin-bottom: 30px;
`;

const JobContainer: any = styled(Card)`
  border-radius: 16px !important;
  margin-bottom: 20;
  box-shadow: none;
`;

const formatedLocation = (cityAndCountry: any) => {
  if (cityAndCountry) {
    const [city, country] = cityAndCountry?.split(', ');
    const formattedCountry = country
      ? country.charAt(0).toUpperCase() + country.slice(1)
      : '';

    const fontWeight = !formattedCountry ? '500' : '0';
    return (
      <Typography
        style={{
          marginLeft: '8px',
          color: '#495057',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: 'normal',
        }}
      >
        <span style={{ padding: '0px', margin: '0px', fontWeight: fontWeight }}>
          {city}
          {formattedCountry && ','}{' '}
        </span>
        <span
          style={{
            padding: '0px',
            margin: '0px',
            fontWeight: '500',
            color: '#000000',
          }}
        >
          {formattedCountry}
        </span>
      </Typography>
    );
  } else {
    return <span></span>;
  }
};

export default ViewJob;
