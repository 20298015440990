import React from 'react';
// import NoDataImage from "../../../assets/NoDataImage.svg";
import { Empty } from 'antd';

interface Props {
  message: string;
}

const NoData: React.FC<Props> = ({ message }) => {
  return (
    <div
      style={{
        width: '100%',
        maxWidth: 'auto',
        textAlign: 'center',
      }}
    >
      <div style={{ height: '200px', margin: 'auto', paddingTop: '45px' }}>
        {/* <img
          src={NoDataImage}
          alt=""
          style={{ margin: "20px", paddingTop: "45px" }}
        /> */}
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={message} />
      </div>
    </div>
  );
};

export default NoData;
