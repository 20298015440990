import React, { useContext, useEffect, useRef, useState } from 'react';
import { Drawer, Form, Tag, Select, Button, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { Typography } from 'antd';
import {
  CloseOutlined,
  ConsoleSqlOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { EditContext } from '../Context/EditContext';
import { update } from 'lodash';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { log } from 'console';
const { Option } = Select;
const { Title } = Typography;

interface PropsType {
  candidate: any;
  open: boolean;
  handleClose?: any;
  onUpdate: () => void;
}

const Styles = {
  locationsWrapper: {
    marginTop: 20,
  },
  submitBtn: {
    width: 101,
    float: 'right',
    backgroundColor: '#BC9623',
    borderRadius: 20,
    marginTop: 20,
    border: 'none',
    color: 'white',
  },
};

const TargetLocationDrawer: React.FC<PropsType> = ({
  candidate,
  open,
  handleClose,
  onUpdate,
}) => {
  const [locations, setLocations] = useState<any>([]);
  const editContext = useContext<any>(EditContext);
  const { setData, setUpdatedData } = editContext;
  const [form] = Form.useForm();
  const locationRef = useRef<any>();
  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    useGoogle({
      apiKey: 'AIzaSyACXA9qapZNvq51WpF69eIH908sX_efNMs',
      options: {
        types: ['geocode'],
      },
    });
  const [value, setValue] = useState([]);
  const [isDisable, SetIsDisable] = useState<boolean>(false);

  const removeLocation = (value: string) => {
    setData({
      ...editContext?.candidate,
      targetLocations: editContext?.candidate?.targetLocations.filter(
        (location: any) => location.city + location.name != value
      ),
    });
    setUpdatedData({
      ...editContext?.updatedData,
      targetLocations: editContext?.candidate?.targetLocations.filter(
        (location: any) => location.city + location.name != value
      ),
    });
  };

  const SingleLocation = ({ title, id, country }: any) => {
    return (
      <Tag
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
        onClose={(e: any) => {
          e.preventDefault();
          removeLocation(id);
        }}
        style={{
          border: 'none',
          marginRight: 10,
          marginBottom: 15,
          backgroundColor: 'rgba(18, 100, 121, 0.8)',
          borderRadius: 20,
          color: 'white',
          paddingTop: 5,
          paddingBottom: 5,
          paddingLeft: 10,
          paddingRight: 10,
        }}
        closable
      >
        {title && <>{title}, </>}
        {country}
      </Tag>
    );
  };

  const onFinish = (values: any) => {
    const maxLocations = 5;
    const { targetLocations } = candidate || {};

    if (targetLocations && targetLocations.length < maxLocations) {
      const locationsToAdd = values?.targetLocations?.map((location: any) => {
        const locationParts = location
          .split(',')
          .map((item: any) => item.trim());
        return {
          name: locationParts.length > 1 ? locationParts[1] : locationParts[0],
          city: locationParts.length >= 2 ? locationParts[0] : '',
        };
      });

      // Create an array of complete location strings for existing locations
      const existingLocations = targetLocations.map(
        (location: any) => `${location.name} ${location.city}`
      );

      // Filter new locations based on complete location strings
      const newLocations = locationsToAdd
        .filter((location: any) => {
          const fullLocation = `${location.name} ${location.city}`;
          return !existingLocations.includes(fullLocation);
        })
        .slice(0, maxLocations - targetLocations.length);
      // console.log('New Locations to Add:', newLocations);

      const updatedCandidate = {
        ...editContext?.candidate,
        targetLocations: [...targetLocations, ...newLocations],
      };

      setData(updatedCandidate);
      setUpdatedData({
        ...editContext?.updatedData,
        targetLocations: updatedCandidate.targetLocations,
      });
    }

    form.resetFields();
    handleClose();
    setValue([]);

    return false;
  };

  const handleCloseDrawer = () => {
    form.resetFields();
    handleClose();
  };
  return (
    <div>
      <Drawer
        className="Ant-D-Title"
        title="Edit target locations"
        placement={'right'}
        // closable={true}
        onClose={handleCloseDrawer}
        visible={open}
        extra={
          <Space onClick={handleCloseDrawer} style={{ cursor: 'pointer' }}>
            <CloseOutlined />
          </Space>
        }
      >
        <Form
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
          form={form}
        >
          <label
            style={{
              marginBottom: '7px',
              lineHeight: 1,
              fontSize: '16px',
              fontWeight: 400,
            }}
          >
            Add a target locations
          </label>
          <Form.Item
            name="targetLocations"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  let isSameLocation = false;

                  for (const target of value) {
                    const array = candidate?.targetLocations || [];
                    for (const item of array) {
                      let loc = '';
                      if (item.city && !item.name) {
                        loc = item.city;
                      } else if (!item.city && item.name) {
                        loc = item.name;
                      } else if (item.city && item.name) {
                        const key = Object.keys(item)[0];
                        loc =
                          key === 'name'
                            ? `${item.name}, ${item.city}`
                            : `${item.city}, ${item.name}`;
                      }

                      console.log('VALUE : ', target, ' array : ', loc);
                      if (target === loc) {
                        isSameLocation = true;
                        break;
                      }
                    }
                  }

                  if (isSameLocation) {
                    console.log('SAME LOCATION');
                    return Promise.reject(
                      new Error('Please add a different location')
                    );
                  }

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Select
              showSearch
              placeholder="&nbsp; Edit target locations"
              mode="multiple"
              ref={locationRef}
              disabled={isDisable}
              onSearch={(value: any) => {
                getPlacePredictions({ input: value });
                setValue(value);
                console.log('SEARCH : ', value);
              }}
              onChange={(val: any) => {
                console.log('value', val);

                value.length < 5 && setValue(val);
                val.length === 5 && SetIsDisable(true);
              }}
              loading={isPlacePredictionsLoading}
              filterOption={(input, option: any) =>
                option.children?.toLowerCase().indexOf(input?.toLowerCase()) >=
                0
              }
            >
              {placePredictions &&
                placePredictions.map((company: any) => {
                  return (
                    <Option
                      key={company.description}
                      value={company.description}
                    >
                      {company.description}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
          <div>
            <Form.Item>
              <Button
                style={{
                  width: 80,
                  float: 'right',
                  backgroundColor: '#BC9623',
                  borderRadius: 20,
                  marginTop: 5,
                  border: 'none',
                  color: 'white',
                }}
                htmlType="submit"
              >
                Add
              </Button>
            </Form.Item>
          </div>
        </Form>
        <div style={Styles.locationsWrapper}>
          {candidate?.targetLocations.map((location: any) => (
            <SingleLocation
              key={location.city}
              id={location.city + location.name}
              title={location.city}
              country={location.name}
            />
          ))}
        </div>
      </Drawer>
    </div>
  );
};

export default TargetLocationDrawer;
