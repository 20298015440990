import React from 'react';
import Facebook from 'assets/icons/social/Facebook.svg';
import Github from 'assets/icons/social/Github.svg';
import Linkdin from 'assets/icons/social/linkedIn.svg';
import Twitter from 'assets/icons/social/Twitter.svg';
import Icon from '@ant-design/icons';
import { Link } from 'react-router-dom';

interface PropsType {
  candidate: any;
}

const SocialLink = ({ icon, url }: any) => {
  return (
    <a href={url?.includes('http') ? url : `//${url}`} target="_blank">
      <img src={icon} />
    </a>
  );
};

const Social: React.FC<PropsType> = ({ candidate }) => {
  const { facebook, github, linkedIn, twitter } = candidate;
  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'start',
          gap: '18px',
          marginLeft: '1em',
          marginTop: '0.5em',
        }}
      >
        {linkedIn != null && linkedIn != '' && (
          <SocialLink icon={Linkdin} url={linkedIn} />
        )}
        {github != null && github != '' && (
          <SocialLink icon={Github} url={github} />
        )}
        {twitter != null && twitter != '' && (
          <SocialLink icon={Twitter} url={twitter} />
        )}
        {facebook != null && facebook != '' && (
          <SocialLink icon={Facebook} url={facebook} />
        )}
      </div>
    </div>
  );
};

export default Social;
