export interface Matchs {
  newCandidates: any;
  likedCandidates: any;
  disLikedCandidates: any;
  matchedCandidates: any;
}
export interface MatchsState {
  matchs: Matchs;
}

// Describing the different ACTION NAMES available
export const STORE_NEW_CANDIDATES = 'STORE_NEW_CANDIDATES';
export const STORE_LIKED_CANDIDATES = 'STORE_LIKED_CANDIDATES';
export const MOVED_TO_LIKED_CANDIDATES = 'MOVED_TO_LIKED_CANDIDATES';
export const MOVED_TO_MATCHED_CANDIDATES = 'MOVED_TO_MATCHED_CANDIDATES';
export const MOVED_TO_DISLIKED_CANDIDATES = 'MOVED_TO_DISLIKED_CANDIDATES';
export const MOVED_TO_DISLIKED_FROM_LIKED_CANDIDATES = 'MOVED_TO_DISLIKED_FROM_LIKED_CANDIDATES';
export const MOVED_TO_LIKED_FROM_DISLIKED_CANDIDATES = 'MOVED_TO_LIKED_FROM_DISLIKED_CANDIDATES';
export const MOVED_TO_DISLIKED_FROM_MATCHED_CANDIDATES = 'MOVED_TO_DISLIKED_FROM_MATCHED_CANDIDATES';
export const STORE_DISLIKED_CANDIDATES = 'STORE_DISLIKED_CANDIDATES';
export const STORE_MATCHED_CANDIDATES = 'STORE_MATCHED_CANDIDATES';

interface storeNewCandidates {
  type: typeof STORE_NEW_CANDIDATES;
  newCandidates: Array<object>;
}

interface storeLikedCandidates {
  type: typeof STORE_LIKED_CANDIDATES;
  likedCandidates: Array<object>;
}
interface movedToLikedCandidates {
  type: typeof MOVED_TO_LIKED_CANDIDATES;
  candidateId: String;
}
interface movedToMatchedCandidates {
  type: typeof MOVED_TO_MATCHED_CANDIDATES;
  candidateId: String;
}
interface movedToDisLikedCandidates {
  type: typeof MOVED_TO_DISLIKED_CANDIDATES;
  candidateId: String;
}
interface movedToDisLikedFromLikedCandidates {
  type: typeof MOVED_TO_DISLIKED_FROM_LIKED_CANDIDATES;
  candidateId: String;
}
interface movedToLikedFromDisLikedCandidates {
  type: typeof MOVED_TO_LIKED_FROM_DISLIKED_CANDIDATES;
  candidateId: String;
}
interface movedToDisLikedFromMatchedCandidates {
  type: typeof MOVED_TO_DISLIKED_FROM_MATCHED_CANDIDATES;
  candidateId: String;
}
interface storeDislikedCandidates {
  type: typeof STORE_DISLIKED_CANDIDATES;
  disLikedCandidates: Array<object>;
}

interface storeMatchedCandidates {
  type: typeof STORE_MATCHED_CANDIDATES;
  matchedCandidates: Array<object>;
}

export type matchsActionTypes = storeLikedCandidates 
 | storeDislikedCandidates 
 | storeNewCandidates 
 | storeMatchedCandidates 
 | movedToLikedCandidates 
 | movedToMatchedCandidates
 | movedToDisLikedCandidates
 | movedToDisLikedFromLikedCandidates
 | movedToLikedFromDisLikedCandidates
 | movedToDisLikedFromMatchedCandidates