import React, { useEffect, useState } from 'react';
import {
  Table,
  Button,
  Space,
  Tooltip,
  Popconfirm,
  Form,
  Modal,
  Input,
  notification,
} from 'antd';
import { DeleteOutlined, CheckOutlined, EditOutlined } from '@ant-design/icons';
import { uniqBy } from 'lodash';
import { SkillsState } from 'store/skills/types';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';

import { StorePendingSkills } from '../../../../../store/skills/actions';

import {
  useSkillsQuery,
  useUpdateSkillMutation,
} from '../../../../../graphql/generated/graphql';

import { ColumnsType } from 'antd/es/table';

interface Props {
  actionHandler: Function;
}

const PendingSkillList: React.FC<Props> = ({ actionHandler }) => {
  const [showPEditPopup, setShowPEditpopup] = useState(false);
  const [form] = Form.useForm();
  const [updateSkill, setUpdateSkill] = useState<any>(null);
  const [updateSkillMutation] = useUpdateSkillMutation();
  const dispatch = useDispatch();
  const pendingSkillListFromStore = useSelector(
    (state: SkillsState) => state.skills?.pendingSkills
  );

  const { data, loading, error } = useSkillsQuery({
    variables: {
      status: 'Pending',
      isArchived: false,
      isDeleted: false,
      isBO: false,
    },
  });

  useEffect(() => {
    if (!loading && data) {
      const sortedData = data.skills.sort((a: any, b: any) => {
        a = new Date(parseInt(a.createdAt));
        b = new Date(parseInt(b.createdAt));
        return b - a;
      });
      dispatch(StorePendingSkills([...sortedData]));
    }
  }, [dispatch, data]);

  const actionsHandler = (data: any) => {
    return (
      <Space>
        <Tooltip title="edit">
          <Button
            className="border-primary"
            shape="circle"
            icon={
              <EditOutlined
                className="text-primary"
                onClick={() => {
                  form.resetFields();
                  setShowPEditpopup(true);
                  setUpdateSkill(data);
                  form.setFieldsValue({
                    skillP: data.title,
                  });
                }}
              />
            }
          />
        </Tooltip>
        <Tooltip title="approve">
          <Button
            className="border-primary"
            shape="circle"
            icon={
              <CheckOutlined
                className="text-primary"
                onClick={() => {
                  actionHandler('approve', data._id, data);
                }}
              />
            }
          />
        </Tooltip>
        <Tooltip title="delete">
          <Popconfirm
            title="Are you sure delete this skill?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => {
              actionHandler('delete', data._id, data, 'pending');
              return;
            }}
          >
            <Button
              className="border-burgundy"
              shape="circle"
              icon={<DeleteOutlined className="text-burgundy" />}
            />
          </Popconfirm>
        </Tooltip>
      </Space>
    );
  };

  const showTitle = (data: any) => {
    return <p style={{ fontSize: '15px', fontWeight: 500 }}>{data}</p>;
  };

  const updateSkills = () => {
    const { skillP } = form.getFieldsValue();
    form.validateFields();

    updateSkillMutation({
      variables: {
        skillId: updateSkill._id,
        title: skillP,
      },
    })
      .then(({ data }) => {
        if (data?.updateSkill) {
          notification['success']({
            message: 'Skill updated successfully.',
            duration: 3,
            description: `Skill has been updated successfully`,
          });
          actionHandler(
            'edit',
            data?.updateSkill._id,
            data?.updateSkill,
            'pending'
          );
          form.resetFields();
          setShowPEditpopup(false);
          setUpdateSkill(null);
        }
      })
      .catch((err) => {
        const errors = err.graphQLErrors.map((el: any) => el.message);
        notification['error']({
          message: 'Unable to update skill',
          description: errors.map((er: any) => <div key={er}>{er}</div>),
          duration: 3,
        });
        return;
      });
  };

  const showName = (data: any, record: any) => {
    let name = '';
    const candidates: any = localStorage.getItem('candidates');
    const Candidate = JSON.parse(candidates);

    const candidate: any = Candidate.find((e: any) => data == e.userId);
    name = `${candidate?.firstName} ${candidate?.lastName}`;
    return (
      <p style={{ color: '#2e4a79', fontSize: '15px', fontWeight: 500 }}>
        {name}
      </p>
    );
  };

  const showDate = (data: any) => {
    return (
      <p style={{ fontSize: '15px', fontWeight: 500 }}>
        {moment(parseInt(data)).format('MMMM DD, YYYY')}
      </p>
    );
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Skills',
      dataIndex: 'title',
      key: 'title',
      showSorterTooltip: false,
      sorter: (a: any, b: any) => a.title.length - b.title.length,
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'ascend',
      render: showTitle,
    },
    {
      title: 'Submitted by',
      dataIndex: 'createdBy',
      key: 'createdBy',
      render: showName,
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: showDate,
    },
    {
      title: 'Actions',
      key: 'id',
      fixed: 'right',
      width: 120,
      render: actionsHandler,
    },
  ];

  return (
    <>
      {showPEditPopup && (
        <Modal
          key={updateSkill._id}
          centered={true}
          style={{ borderRadius: 30 }}
          title={[<h2>Update Skill</h2>]}
          visible={showPEditPopup}
          onOk={() => {
            form.resetFields();
            setShowPEditpopup(false);
            setUpdateSkill(null);
          }}
          onCancel={() => {
            form.resetFields();
            setShowPEditpopup(false);
            setUpdateSkill(null);
          }}
          footer={[
            <Button
              key="back"
              style={{ borderRadius: '1.25rem' }}
              onClick={() => {
                form.resetFields();
                setShowPEditpopup(false);
                setUpdateSkill(null);
              }}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              htmlType="submit"
              style={{
                backgroundColor: '#bc9623',
                borderRadius: '1.25rem',
                border: 'none',
              }}
              onClick={updateSkills}
            >
              Submit
            </Button>,
          ]}
        >
          <Form form={form} name="update-skill-pending">
            <Form.Item
              name="skillP"
              label="Skill name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      )}
      <Table
        className="table-striped"
        columns={columns}
        dataSource={pendingSkillListFromStore}
        loading={loading}
        size="small"
        pagination={{ pageSize: 20 }}
        scroll={{ x: 1300 }}
      />
    </>
  );
};

export default PendingSkillList;
