import React, { useEffect, useState, useContext } from 'react';
import { useParams, Link, useRouteMatch, useHistory } from 'react-router-dom';
import IconCard from '../../../../components/UI/IconCard/IconCard';
import PageTitle from '../../../../components/UI/PageTitle/PageTitle';
import ErrorCard from '../../../../components/UI/ErrorCard/ErrorCard';
import {
  Row,
  Col,
  Card,
  Skeleton,
  Empty,
  Button,
  Avatar,
  Typography,
  List,
  Tooltip,
  Space,
  Table,
  Tag,
} from 'antd';
import { Fade } from 'react-awesome-reveal';
import {
  useGetSingleCompanyQuery,
  useCompanyJobsQuery,
} from '../../../../graphql/generated/graphql';
import { UserOutlined, CalendarOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import moment from 'moment';
import useCompanyRecruiters from '../../../../hooks/useCompanyRecruiters';
import RecruiterSection from './RecruiterSection';
import { EditContext } from 'containers/BackOffice/Candidates/Profile/Context/EditContext';
const { Title, Paragraph } = Typography;
const { Column, ColumnGroup } = Table;

interface Jobs {
  key: number;
  title: string;
  jobfields: any;
  location: any;
  contract: any;
  candidates: number;
  status: any;
  createdAt: string;
}

const ViewCompany: React.FC = () => {
  let { companyId } = useParams<{ companyId: string }>();
  console.log('Company ID: ', companyId);
  const editContext: any = useContext(EditContext);
  const history = useHistory();

  const { url } = useRouteMatch();
  //handle view
  const [hasData, setHasData] = useState(true);
  const [company, setCompany]: any = useState(null);
  const [recruiters, setRecruiters]: any = useState([]);

  const { data, loading, error }: any = useGetSingleCompanyQuery({
    variables: {
      companyId: companyId,
    },
    fetchPolicy: 'network-only',
  });

  const companyJobs: any = useCompanyJobsQuery({
    variables: {
      companyId: companyId,
    },
    fetchPolicy: 'network-only',
  });
  console.log(companyJobs?.data?.getCompanyJobs);

  const handleNavigation = (url: string) => {
    history.push(url);
  };

  // useEffect(() => {
  //   console.log(data)
  //   data && data?.getSingleCompany === null && setHasData(false);
  //   if (data?.getSingleCompany)
  //     setCompany(data?.getSingleCompany)
  // }, [data]);

  // useEffect(() => {
  //   if (companyRecruiters) {
  //     console.log('adsa ', companyRecruiters)
  //     setRecruiters(companyRecruiters);
  //   }
  // }, [companyRecruiters]);

  // if (company && !companyRecruiters) {
  //   companyRecruiters = useCompanyRecruiters(company.users.map((c:any) => c._id))
  // }

  const tableData: any = [
    {
      key: 1,
      title: 'Fullstack Javascript developer',
      jobfields: ['IT', 'Software development'],
      location: ['Singapore', 'Malaysia'],
      contract: 'full-time',
      candidates: 23,
      status: 'active',
      createdAt: '22 Jan 2020',
    },
    {
      key: 1,
      title: 'Social media campaign manager',
      jobfields: ['Marketing', 'Social media'],
      location: ['Pakistan'],
      contract: 'full-time',
      candidates: 103,
      status: 'active',
      createdAt: '01 Mar 2020',
    },
  ];

  // console.log(data);

  return (
    <>
      <PageTitle
        title="Company information"
        btnText="Edit company"
        to={`/companies/edit/${companyId}`}
        btnActive={false}
        back
        // handleGoBack={()=>{history.goBack()}}
      />
      {!loading && !data ? (
        <ErrorCard />
      ) : (
        <>
          <Row justify="center" className="pb-4" gutter={16}>
            <Col span={16}>
              <Fade>
                <Card>
                  <Skeleton
                    active
                    avatar
                    paragraph={{ rows: 3 }}
                    className="p-4"
                    loading={loading}
                  >
                    {data && data.getSingleCompany !== null ? (
                      <>
                        <Row justify="center" className="pb-4" gutter={16}>
                          <Col span={4}>
                            <Avatar size={92} icon={<UserOutlined />} />
                          </Col>
                          <Col span={20}>
                            <Title level={4} style={{ marginBottom: '0px' }}>
                              {data &&
                                data.getSingleCompany &&
                                data.getSingleCompany.name}
                            </Title>
                            <p
                              style={{
                                fontSize: '12px',
                                color: '#ababab',
                                marginBottom: '0px',
                              }}
                            >
                              <CalendarOutlined />{' '}
                              {data &&
                                data?.getSingleCompany &&
                                moment(
                                  parseInt(data?.getSingleCompany?.createdAt)
                                ).format('MMMM DD, YYYY')}
                            </p>
                            <div>
                              <Tooltip placement="left" title="Company size">
                                <span className="font-10 px-2 mt-2 mr-2 badge badge-light badge-pill">
                                  {data &&
                                    data?.getSingleCompany &&
                                    data?.getSingleCompany.companySize}
                                </span>
                              </Tooltip>
                              <Tooltip placement="left" title="Sector">
                                <span className="font-10 px-2 mt-2  badge badge-amber badge-pill">
                                  {data &&
                                    data?.getSingleCompany &&
                                    data?.getSingleCompany?.sector &&
                                    data?.getSingleCompany?.sector.title}
                                </span>
                              </Tooltip>
                              <Space style={{ float: 'right' }}>
                                {data && data?.getSingleCompany.website && (
                                  <Tooltip title="website">
                                    <a
                                      target="_blank"
                                      href={data?.getSingleCompany.website}
                                    >
                                      <Button
                                        className="ant-btn-burgundy"
                                        shape="circle"
                                        icon={<i className="fas fa-link" />}
                                      />
                                    </a>
                                  </Tooltip>
                                )}
                                {data && data?.getSingleCompany.facebook && (
                                  <Tooltip title="facebook">
                                    <a
                                      target="_blank"
                                      href={data?.getSingleCompany.facebook}
                                    >
                                      <Button
                                        className="ant-btn-burgundy"
                                        shape="circle"
                                        icon={
                                          <i className="fab fa-facebook-f" />
                                        }
                                      />
                                    </a>
                                  </Tooltip>
                                )}
                                {data && data?.getSingleCompany.twitter && (
                                  <Tooltip title="twitter">
                                    <a
                                      target="_blank"
                                      href={data?.getSingleCompany.twitter}
                                    >
                                      <Button
                                        className="ant-btn-burgundy"
                                        shape="circle"
                                        icon={<i className="fab fa-twitter" />}
                                      />
                                    </a>
                                  </Tooltip>
                                )}
                                {data && data?.getSingleCompany.linkedIn && (
                                  <Tooltip title="linkedIn">
                                    <a
                                      target="_blank"
                                      href={data?.getSingleCompany.linkedIn}
                                    >
                                      <Button
                                        className="ant-btn-burgundy"
                                        shape="circle"
                                        icon={
                                          <i className="fab fa-linkedin-in" />
                                        }
                                      />
                                    </a>
                                  </Tooltip>
                                )}
                              </Space>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            {/* <Paragraph
                              // ellipsis={{
                              //   rows: 2,
                              //   expandable: true,
                              //   symbol: 'more',
                              // }}
                            >
                              {data?.getSingleCompany?.description}
                            </Paragraph> */}
                            <p
                              className="text-muted font-12"
                              dangerouslySetInnerHTML={{
                                __html: data?.getSingleCompany.description,
                              }}
                            ></p>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <div className="p-4 m-t-40 m-b-40">
                        <Empty description={<span>No company found</span>}>
                          <Link to={'/companies/new'}>
                            <Button
                              className="ant-btn-amber"
                              shape="round"
                              size="middle"
                            >
                              Create new company
                            </Button>
                          </Link>
                        </Empty>
                      </div>
                    )}
                  </Skeleton>
                </Card>
              </Fade>
            </Col>
            <Col span={8}>
              {data?.getSingleCompany && (
                <RecruiterSection
                  userIds={data?.getSingleCompany?.users?.map(
                    (c: any) => c._id
                  )}
                />
              )}
            </Col>
          </Row>
          <Row justify="center" className="pb-4" gutter={16}>
            <Col span={24}>
              <Fade>
                <Card>
                  <Skeleton
                    active
                    avatar
                    paragraph={{ rows: 3 }}
                    className="p-4"
                    loading={companyJobs.loading}
                  >
                    <Title level={4}>List of jobs</Title>
                    {/* {console.log(companyJobs.loading)} */}
                    {companyJobs.data && companyJobs.data.getCompanyJobs && (
                      <Table
                        sortDirections={['ascend', 'descend', 'ascend']}
                        dataSource={companyJobs?.data?.getCompanyJobs}
                        size="small"
                        pagination={{ pageSize: 10 }}
                        className="table-striped"
                        onRow={(record, rowIndex) => {
                          return {
                            onClick: (event) => {
                              // Handle navigation here, for example, redirect to a specific URL
                              handleNavigation(
                                `/backoffice/jobs/${record._id}`
                              );
                              // window.location.href = ; // Change this according to your route
                            },
                            style: { cursor: 'pointer' },
                          };
                        }}
                      >
                        <Column title="Title" dataIndex="title" key="title" />
                        <Column
                          title="Location"
                          dataIndex="countries"
                          key="countries._id"
                          render={(location) => (
                            <>
                              {location.map((loc: any) => (
                                // <span
                                //   className='font-10 px-2 m-2 badge badge-light badge-pill'
                                //   key={loc._id}
                                // >
                                //   {loc.name}
                                // </span>
                                <Tag key={loc._id} color="blue">
                                  {loc.name}
                                </Tag>
                              ))}
                            </>
                          )}
                        />
                        <Column
                          title="Contract"
                          dataIndex="contractType"
                          key="contractType"
                          render={(contractType) => (
                            <>
                              <span
                                className="font-10 px-2 m-2 badge badge-light badge-pill"
                                key={contractType._id}
                              >
                                {contractType.name}
                              </span>
                            </>
                          )}
                        />
                        <Column
                          title="Job Fields"
                          dataIndex="fields"
                          key="fields"
                          render={(fields) => (
                            <>
                              {/* {fields.map((jobfield: any) => (
                              <span
                                className='font-10 px-2 m-2 badge badge-light badge-pill'
                                key={jobfield._id}
                              >
                                {jobfield.title}
                              </span>
                            ))} */}
                              {fields.map((jobfield: any) => (
                                <Tag key={jobfield._id} color="geekblue">
                                  {jobfield.title}
                                </Tag>
                              ))}
                            </>
                          )}
                        />
                        <Column
                          title="Lead recruiter"
                          dataIndex="leadRecruiter"
                          key="leadRecruiter"
                          render={(leadRecruiter) => (
                            <>
                              <span
                                className="font-10 px-2 m-2 badge badge-amber badge-pill text-white"
                                key={leadRecruiter._id}
                              >
                                {leadRecruiter.email}
                              </span>
                            </>
                          )}
                        />
                        {/* <Column
                        title='Candidates'
                        dataIndex='candidates'
                        key='candidates'
                        render={candidates => (
                          <>
                            <span
                              className='font-10 px-2 m-2 badge badge-amber badge-pill text-white'
                              key={candidates}
                            >
                              {candidates}
                            </span>
                          </>
                        )}
                      /> */}
                        <Column
                          title="Posted on"
                          dataIndex="createdAt"
                          key="createdAt"
                          render={(createdAt) => (
                            <>
                              {moment(parseInt(createdAt)).format(
                                'MMMM DD, YYYY'
                              )}
                            </>
                          )}
                        />
                        <Column
                          title="Status"
                          dataIndex="status"
                          key="status"
                          render={(status) => (
                            <>
                              <span
                                className="font-10 px-2 m-2 badge badge-primary badge-teal text-white"
                                key={status}
                              >
                                {status}
                              </span>
                            </>
                          )}
                        />
                      </Table>
                    )}
                  </Skeleton>
                </Card>
              </Fade>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default ViewCompany;
