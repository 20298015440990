import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HttpState } from '../../../store/http/types';
import { AuthState } from '../../../store/auth/types';
import { register } from '../../../store/http/actions';
import { Redirect } from 'react-router-dom';
import { Card, Row, Col, notification } from 'antd';

import RegistrationForm from './partials/RegistrationForm';
import RegistrationSuccess from './partials/RegistrationSuccess';
import Logo from '../../../components/UI/Logo/Logo';
import Footer from '../../../components/Common/Footer/Footer';
import { useAdminSignupMutation } from '../../../graphql/generated/graphql';

const Registration: React.FC = () => {
  const isAuthenticated = useSelector(
    (state: AuthState) => state.auth.accessToken !== null
  );

  const dispatch = useDispatch();
  const [signupMutation, { data, loading, error }] = useAdminSignupMutation();
  const [hasError, setHasError] = useState(false);
  const [fetchData, setFetchData] = useState(Object);

  const onFinishHandler = (values: any) => {
    signupMutation({
      variables: {
        fullName: values.fullName,
        email: values.emailAddress,
        password: values.password,
        title: values.title,
      },
    })
      .then(({ data }) => {
        setFetchData(data?.adminSignup?._id);
      })
      .catch((err) => {
        const errors = err.graphQLErrors.map((el: any) => el.message);
        notification['error']({
          message: 'Unable to Login',
          description: errors.map((er: any) => <div key={er}>{er}</div>),
          duration: 3,
          onClose: () => setHasError(false),
        });
      });
  };

  return (
    <>
      {isAuthenticated && <Redirect to="/" />}
      <div>
        <div className="wrapper">
          <div className="pb-3">
            <h3 className="text-center m-t-40">
              <Logo height="40" />
            </h3>
          </div>
          <Row justify="center">
            <Col span={12}>
              <Card>
                <div className="pb-4">
                  <div className="p-3">
                    {data && data.adminSignup?._id ? (
                      <RegistrationSuccess
                        fullName={data.adminSignup?.fullName}
                      />
                    ) : (
                      <RegistrationForm onFinish={onFinishHandler} />
                    )}
                  </div>
                </div>
                <Footer footer={false} />
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Registration;
