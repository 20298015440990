import React, { useContext, useEffect, useState } from 'react';
import {
  Drawer,
  Select,
  Input,
  Form,
  Button,
  InputNumber,
  Space,
  Switch,
} from 'antd';
import { EditContext } from '../../Profile/Context/EditContext';
import { CloseOutlined } from '@ant-design/icons';
import { log } from 'console';
import { useCandidateShowCompensationMutation } from 'graphql/generated/graphql';
const { TextArea } = Input;
interface PropsType {
  candidate: any;
  open: boolean;
  handleClose?: any;
  match?: any;
}

interface InputPropsType {
  placeholder: string;
  value: string;
  label: string;
  name: string;
  required: boolean;
  textarea: boolean;
  isNumber: boolean;
  disabled: Boolean;
}

const Styles = {
  dpWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dpContainer: {
    height: 130,
    width: 130,
    backgroundSize: 'cover',
    marginTop: 15,
  },
  fieldWrapper: {
    marginBottom: 15,
  },
  fieldsContainer: {
    marginTop: 10,
  },
};

const InputField = (Props: InputPropsType) => {
  const { placeholder, value, label, name, required, textarea, isNumber } =
    Props;
  const formatNumber = (value: any) => {
    if (!value && value !== 0) return '';
    return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  // Custom parser to remove currency symbol and commas
  const parseNumber = (value: any) => {
    if (!value) return '';
    return value!.replace(/\$\s?|(,*)/g, '');
  };

  return (
    <div style={Styles.fieldWrapper}>
      <label style={{ marginBottom: 0, fontSize: 13, fontWeight: 400 }}>
        <span style={{ color: '#990000' }}>{required && '*'}</span>
        {label}
      </label>
      <Form.Item
        name={name}
        rules={[{ required: required, message: 'This field is required!' }]}
        initialValue={value}
      >
        {!textarea ? (
          isNumber ? (
            <InputNumber
              style={{ borderRadius: 50, width: '100%' }}
              min={0}
              max={99999}
              formatter={formatNumber}
              parser={parseNumber}
              // parser={(value) => value!.replace(/\$\s?|(,*)/g, ',')}
              // value={parseInt(value)}
              // disabled={name == 'currentTotal' && true}
            />
          ) : (
            <Input
              placeholder={placeholder}
              maxLength={65}
              // disabled={name == 'expectedTotal' && true}
            />
          )
        ) : (
          <TextArea
            style={{ borderRadius: 10 }}
            autoSize={{ minRows: 3 }}
            placeholder={placeholder}
          />
        )}
      </Form.Item>
    </div>
  );
};

const YearlyCompensationDrawer: React.FC<PropsType> = ({
  candidate,
  open,
  handleClose,
  match,
}) => {
  const [form] = Form.useForm();
  const [isSwitchChecked, setIsSwitchChecked] = useState(
    !match?.showExpectedCompensation
  );
  useEffect(() => {
    form.resetFields();
  }, [candidate]);

  const editContext = useContext(EditContext);
  const {
    showExpCompensation,
    setShowExpCompensation,
    setData,
    setUpdatedData,
  }: any = editContext;
  const Current = [
    {
      name: 'currentTotal',
      required: false,
      placeholder: 'Total',
      label: 'Total',
      disabled: true,
      value: candidate?.yearlyCompensation?.current?.total,
    },
    {
      name: 'currentBase',
      required: false,
      placeholder: 'Base',
      label: 'Base',
      disabled: false,
      value: candidate?.yearlyCompensation?.current?.base,
    },
    {
      name: 'currentOnTargetEarnings',
      required: false,
      placeholder: 'On target earnings',
      label: 'On target earnings',
      disabled: false,
      value: candidate?.yearlyCompensation?.current?.onTargetEarnings,
    },
    {
      name: 'currentShortTermIncentives',
      required: false,
      placeholder: 'Short term incentives',
      label: 'Short term incentives',
      disabled: false,
      value: candidate?.yearlyCompensation?.current?.shortTermIncentives,
    },
    {
      name: 'currentLongTermIncentives',
      required: false,
      placeholder: 'Long term incentives',
      label: 'Long term incentives',
      disabled: false,
      value: candidate?.yearlyCompensation?.current?.longTermIncentives,
    },
    {
      name: 'currentOther',
      required: false,
      placeholder: 'Other',
      label: 'Other',
      disabled: false,
      value: candidate?.yearlyCompensation?.current?.other,
    },
  ];
  const Expected = [
    {
      name: 'expectedTotal',
      required: false,
      placeholder: 'Total',
      label: 'Total',
      value: candidate?.yearlyCompensation?.expected?.total,
      disable: true,
    },
    {
      name: 'expectedBase',
      required: false,
      placeholder: 'Base',
      label: 'Base',
      value: candidate?.yearlyCompensation?.expected?.base,
      disable: false,
    },
    {
      name: 'expectedOnTargetEarnings',
      required: false,
      placeholder: 'On target earnings',
      label: 'On target earnings',
      value: candidate?.yearlyCompensation?.expected?.onTargetEarnings,
      disable: false,
    },
    {
      name: 'expectedShortTermIncentives',
      required: false,
      placeholder: 'Short term incentives',
      label: 'Short term incentives',
      value: candidate?.yearlyCompensation?.expected?.shortTermIncentives,
      disable: false,
    },
    {
      name: 'expectedLongTermIncentives',
      required: false,
      placeholder: 'Long term incentives',
      label: 'Long term incentives',
      value: candidate?.yearlyCompensation?.expected?.longTermIncentives,
      disable: false,
    },
    {
      name: 'expectedOther',
      required: false,
      placeholder: 'Other',
      label: 'Other',
      value: candidate?.yearlyCompensation?.expected?.other,
      disable: false,
    },
  ];
  const [showCompensationMutation, { data, loading, error }] =
    useCandidateShowCompensationMutation();
  const [showExpectedCompensation, setShowExpectedCompensation] = useState(
    !match?.showExpectedCompensation
  );
  useEffect(() => {
    setShowExpectedCompensation(!match?.showExpectedCompensation);
  }, [match?.showExpectedCompensation]);

  const compensationExpectedHandler = () => {
    setIsSwitchChecked((s: any) => !s);
    const updatedShowCompensation = !showExpectedCompensation;
    setShowExpectedCompensation(updatedShowCompensation);
    setShowExpCompensation(updatedShowCompensation);
    showCompensationMutation({
      variables: {
        matchId: match?._id,
        showExpectedCompensation: isSwitchChecked,

      },
    }).then(({ data }) => {
      console.log(data);
    });
  };
  useEffect(() => {
    if (showExpCompensation != null) {
      setShowExpectedCompensation(!match?.showExpectedCompensation);
    } else {
      setShowExpectedCompensation(!match?.showExpectedCompensation);
    }
  }, [match, showExpCompensation]);
  const onFinish = (values: any) => {
    // console.log(values);

    let other =
      /[a-zA-Z]/.test(values?.currentOther) || values?.currentOther === ''
        ? 0
        : parseInt(values?.currentOther) ?? 0;

    let totalValCurrent =
      (parseInt(values?.currentBase) || 0) +
      (parseInt(values?.currentOnTargetEarnings) || 0) +
      (parseInt(values?.currentShortTermIncentives) || 0) +
      (parseInt(values?.currentLongTermIncentives) || 0) +
      (other || 0);

    console.log(totalValCurrent);

    const current = {
      base: values?.currentBase?.toString(),
      onTargetEarnings: values?.currentOnTargetEarnings?.toString(),
      shortTermIncentives: values?.currentShortTermIncentives?.toString(),
      longTermIncentives: values?.currentLongTermIncentives?.toString(),
      other: values?.currentOther?.toString(),
      // other: values?.currentOther,
      total: values?.currentTotal?.toString(),
    };
    let totalExpected =
      /[a-zA-Z]/.test(values?.expectedOther) == true ||
      values?.expectedOther === ''
        ? 0
        : parseInt(values?.expectedOther) ?? 0;

    let totalValExpected =
      (parseInt(values?.expectedBase) || 0) +
      (parseInt(values?.expectedOnTargetEarnings) || 0) +
      (parseInt(values?.expectedShortTermIncentives) || 0) +
      (parseInt(values?.expectedLongTermIncentives) || 0) +
      (totalExpected || 0);

    const expected = {
      base: values?.expectedBase?.toString(),
      onTargetEarnings: values?.expectedOnTargetEarnings?.toString(),
      shortTermIncentives: values?.expectedShortTermIncentives?.toString(),
      longTermIncentives: values?.expectedLongTermIncentives?.toString(),
      other: values?.expectedOther?.toString(),
      // other: values?.expectedOther,
      total: values?.expectedTotal?.toString(),
    };
    // totalValExpected === 0 ? null : totalValExpected?.toString()
    const update = {
      yearlyCompensation: {
        current: current,
        expected: expected,
        comments: values?.comments,
        currency: values?.currency,
        currentComments: values?.currentComments,
      },
    };
    console.log({ ...update });
    setUpdatedData({
      ...editContext?.updatedData,
      yearlyCompensation: update.yearlyCompensation,
    });
    setData({ ...editContext?.candidate, ...update });
    handleClose();
  };
  const onChange = (value: number | string) => {
    console.log('changed', value);
  };
  const handleCloseDrawer = () => {
    form.resetFields();
    handleClose();
  };
  return (
    <div>
      <Drawer
        title="Candidate’s yearly compensation"
        placement={'right'}
        // closable={true}
        onClose={handleCloseDrawer}
        visible={open}
        extra={
          <Space onClick={handleClose} style={{ cursor: 'pointer' }}>
            <CloseOutlined />
          </Space>
        }
      >
        <Form
          name="basic"
          onFinish={onFinish}
          autoComplete="off"
          scrollToFirstError={true}
          initialValues={{ remember: true }}
          form={form}
        >
          <div style={Styles.fieldWrapper}>
            <label style={{ marginBottom: 0, fontSize: 13, fontWeight: 400 }}>
              <span style={{ color: '#990000' }}>*</span>Select currency
            </label>
            <Form.Item
              initialValue={candidate?.yearlyCompensation?.currency}
              name="currency"
              rules={[{ required: true, message: 'This field is required!' }]}
            >
              <Select>
                <Select.Option value="USD">USD</Select.Option>
                <Select.Option value="EUR">EUR</Select.Option>
                <Select.Option value="HKD">HKD</Select.Option>
                <Select.Option value="SGD">SGD</Select.Option>
                <Select.Option value="THB">THB</Select.Option>
              </Select>
            </Form.Item>
          </div>
          {/* Current Compensation */}
          <div style={Styles.fieldsContainer}>
            <p style={{ fontSize: 14, fontWeight: 500, marginBottom: 5 }}>
              Current / Last compensation
            </p>

            {Current.map((field: any, index: any) => (
              <InputField
                key={index}
                isNumber={field.placeholder != 'Other' ? true : false}
                // isNumber={true}
                textarea={false}
                required={field.required}
                placeholder={field.placeholder}
                value={field.value}
                label={field.label}
                name={field.name}
                disabled={field.disabled}
              />
            ))}
          </div>
          <InputField
            textarea={true}
            required={false}
            placeholder={'Add comments'}
            value={candidate?.yearlyCompensation?.currentComments}
            label={'Comments'}
            name={'currentComments'}
            isNumber={false}
            disabled={false}
          />
          {/* Expected compensation */}
          <div style={Styles.fieldsContainer}>
            <p style={{ fontSize: 14, fontWeight: 500, marginBottom: 5 }}>
              Expected compensation
            </p>
            {Expected.map((field: any, index: any) => (
              <InputField
                key={index}
                isNumber={field.placeholder != 'Other' ? true : false}
                // isNumber={true}
                textarea={false}
                required={field.required}
                placeholder={field.placeholder}
                value={field.value}
                label={field.label}
                name={field.name}
                disabled={field.disable}
              />
            ))}
          </div>
          {/* Comments */}
          <InputField
            textarea={true}
            required={false}
            placeholder={'Add comments'}
            value={candidate?.yearlyCompensation?.comments}
            label={'Comments'}
            name={'comments'}
            isNumber={false}
            disabled={false}
          />
          {/* Switch For Hide Or Show Compensation*/}

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: 20,
            }}
          >
            <span
              style={{
                fontSize: 14,
                fontWeight: '500',
                color: '#495057',
                fontFamily: 'poppins',
              }}
            >
              Hide compensation details
            </span>
            <Switch
              size="small"
              onChange={compensationExpectedHandler}
              checked={isSwitchChecked}
            />
          </div>
          {/* Submit Button */}
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'space-between',
            }}
          >
            <label style={{ marginBottom: 0, fontSize: 14, fontWeight: 300 }}>
              <span style={{ color: '#990000' }}>*</span>Mandatory fields
            </label>
            <Form.Item style={{ margin: 0 }}>
              <Button
                htmlType="submit"
                style={{
                  height: 31,
                  width: 101,
                  backgroundColor: '#BC9623',
                  borderRadius: 40,
                  color: 'white',
                  border: 'none',
                }}
              >
                Submit
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Drawer>
    </div>
  );
};

export default YearlyCompensationDrawer;
