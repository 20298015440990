import React, { useRef, useState } from 'react';
import { Modal } from 'antd';

interface PropsType {
  src: any;
  handleClose: any;
  open: boolean;
}

const ProfileVideo: React.FC<PropsType> = ({ src, handleClose, open }) => {
  const [videoKey, setVideoKey] = useState<number>(0);
  const ref = useRef<any>();
  const close = () => {
    ref.current.pause();
    handleClose();
  };

  React.useEffect(() => {
    setVideoKey((prevKey) => prevKey + 1);
  }, [src]);

  return (
    <Modal className="" onCancel={close} open={open} footer={null}>
      <video
        key={videoKey}
        ref={ref}
        style={{ width: '100%', height: '50vh', marginTop: '5px' }}
        controls
        autoPlay
      >
        <source src={src} />
      </video>
    </Modal>
  );
};

export default ProfileVideo;
