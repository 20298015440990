import React, { useEffect, useState } from 'react';
import { Typography, Tooltip } from 'antd';
import {
  ApproveIcon,
  ChatIcon,
  LikedIcon,
  DislikedIcon,
  HiredIcon,
  SelectedIcon,
  NewCandidateIcon,
  MatchedIcon,
  RejectedIcon,
} from 'assets/icons/DetailedProfile';

const { Title } = Typography;

interface PropsType {
  candidate?: any;
  match: any;
  isInTable: boolean;
}

const Styles = {
  flexDefault: {
    display: 'flex',
    alignItems: 'center',
  },
};

const recruiterActions = {
  dislike: DislikedIcon,
  like: LikedIcon,
};

const Status: React.FC<PropsType> = ({
  candidate,
  match,
  isInTable = false,
}) => {
  const [status, setStatus] = useState<any>();
  const [textHover, setHoverText] = useState<any>();

  useEffect(() => {
    const { recruiterAction, candidateAction } = match;

    if (
      recruiterAction === null &&
      (candidateAction === 'like' || candidateAction === null)
    ) {
      setStatus(NewCandidateIcon);
    } else if (recruiterAction === 'dislike') {
      setStatus(DislikedIcon);
    } else if (recruiterAction === 'like' && candidateAction === 'like') {
      setStatus(MatchedIcon);
    } else if (
      (recruiterAction === 'selected' || recruiterAction === 'Reselected') &&
      candidateAction === 'like'
    ) {
      setStatus(SelectedIcon);
    } else if (recruiterAction === 'rejected' && candidateAction === 'like') {
      setStatus(RejectedIcon);
    } else if (recruiterAction === 'hired' && candidateAction === 'like') {
      setStatus(HiredIcon);
    }

    if (recruiterAction) {
      setHoverText(
        recruiterAction === 'like'
          ? 'Liked'
          : recruiterAction === 'dislike'
          ? 'Disliked'
          : recruiterAction === 'hired'
          ? 'Hired'
          : recruiterAction === 'rejected'
          ? 'Rejected'
          : recruiterAction === 'selected'
          ? 'Selected'
          : recruiterAction
      );
    } else {
      setHoverText('New candidate');
    }
  }, [match]);

  if (isInTable) {
    return (
      <img
        style={{ marginTop: '6px', width: '36px', height: '36px' }}
        src={status}
        alt="icons"
        className="shadow-status-logos"
      />
    );
  }

  return (
    <div style={Styles.flexDefault}>
      <Title
        style={{ fontSize: 16, margin: 0, marginTop: -8, width: 110 }}
        className="titleStatus"
      >
        Status
      </Title>
      <div style={Styles.flexDefault}>
        <Tooltip title={textHover}>
          <img
            style={{
              marginRight: 20,
              cursor: 'pointer',
              width: 29,
              marginLeft: '2px',
            }}
            src={status}
            className="shadow-status-logos"
          />
        </Tooltip>

        {/* <img style={{ cursor: 'pointer' }} src={ChatIcon} /> */}
      </div>
    </div>
  );
};

export default Status;
