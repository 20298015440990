import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Row, Col, Card, notification, Form, Input, Button } from 'antd';
import PageTitle from '../../../../components/UI/PageTitle/PageTitle';
import { LockOutlined } from '@ant-design/icons';
import { useRecruiterResetPasswordMutation } from '../../../../graphql/generated/graphql';
import { RecruitersState } from 'store/recruiters/types';

interface Props {}

const ResetPassword: React.FC<Props> = () => {
  const [form] = Form.useForm();
  const [hasError, setHasError] = useState(false);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  console.log(id);

  const recruiters = useSelector(
    (state: RecruitersState) => state.recruiters?.recruiters
  );

  const [updateRecruiterResetPasswordMutation, recruiterResetPasswordMutation] =
    useRecruiterResetPasswordMutation();

  const onFinish = (values: any) => {
    console.log('Submitting: ', values);

    updateRecruiterResetPasswordMutation({
      variables: {
        email: values.emailAddress,
        password: values.password,
      },
    })
      .then(({ data }: any) => {
        console.log('Response: ', data);
        if (data.recruiterResetPassword.response.status == 200)
          notification['success']({
            message: data.recruiterResetPassword.response.message,
            onClose: () => history.push(`/backoffice/recruiters`),
            duration: 3,
            description: `You has successfully updated the password.`,
          });
        form.resetFields();
      })
      .catch((err) => {
        const errors = err.graphQLErrors.map((el: any) => el.message);
        notification['error']({
          message: 'Unable to update the password',
          description: errors.map((er: any) => <div key={er}>{er}</div>),
          duration: 3,
          onClose: () => setHasError(false),
        });
      });
  };

  useEffect(() => {
    if (id) {
      console.log('UseEffect JOB ID ==> ', id, recruiters);
      const recruiterObj: any = recruiters.find((r: any) => r._id == id);
      console.log('Job found ', recruiterObj);
      if (recruiterObj) {
        form.setFieldsValue({
          emailAddress: recruiterObj?.user?.email,
        });
      }
    }
  }, [id, recruiters]);

  return (
    <>
      <PageTitle title="Add recruiter to a job" back />
      <Row justify="center" gutter={16}>
        <Col span={16}>
          <Card className="mb-4">
            <Form
              name="normal_login"
              className="login-form pt-3"
              layout="vertical"
              onFieldsChange={() => setHasError(false)}
              form={form}
              onFinish={onFinish}
            >
              <Form.Item name="emailAddress" label="Email address">
                <Input
                  prefix={<i className="far fa-envelope font-14" />}
                  placeholder="Email address"
                  disabled={true}
                />
              </Form.Item>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                  {
                    whitespace: true,
                    message: 'No whitespace allowed',
                  },
                  {
                    min: 6,
                    message: 'Please input min 6 characters',
                  },
                ]}
                hasFeedback
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder="Enter Password"
                />
              </Form.Item>

              <Form.Item
                name="confirm"
                label="Confirm Password"
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                  {
                    whitespace: true,
                    message: 'No whitespace allowed',
                  },
                  {
                    min: 6,
                    message: 'Please input min 6 characters',
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        'The two passwords that you entered do not match!'
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder="Enter confirm password"
                />
              </Form.Item>

              <Form.Item>
                <div className="text-center">
                  <Button
                    // type="amber"
                    type="primary"
                    htmlType="submit"
                    shape="round"
                    // disabled={error}
                    // loading={loading}
                  >
                    change password
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ResetPassword;
