import React, { useState, useEffect, useContext } from 'react';
import Logo from '../../UI/Logo/Logo';
import './TopBar.scss';
import { Link, useLocation } from 'react-router-dom';
import NotificationDropdown from './partials/NotificationDropdown/NotificationDropdown';
import ProfileDropdown from './partials/ProfileDropdown/ProfileDropdown';
import { Menu, Button, Drawer } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  setDrawerMenuDisplay,
  setSidbarExpand,
} from '../../../store/settings/actions';
import { GeneralStates } from '../../../store/settings/types';

import { EditContext } from 'containers/BackOffice/Candidates/Profile/Context/EditContext';

const TopBar: React.FC = () => {
  const { pathname } = useLocation();
  const editContext = useContext<any>(EditContext);

  const { sidebarExpanded } = useSelector(
    (state: GeneralStates) => state.settings
  );

  const dispatch = useDispatch();
  const toggleSidebar = () => dispatch(setSidbarExpand(!sidebarExpanded));

  //THIS FUNCTION ENABLES EDIT BUTTON
  //AND  DISABLES "CANCLE AND SAVE BUTTONS" IF CHANGES ARE MADE
  const handleUnSavedChangesModal = () => {
    editContext &&
      editContext.isDataChanged &&
      editContext.confirmPopUpOpeneHandler();
  };
  //THIS FUNCTION DISABLES "CANCLE AND SAVE BUTTONS"
  //AND ENABLES EDIT BUTTON IF NO CHANGES ARE MADE
  const handleUnSavedChangesModalForNavigation = () => {
    editContext &&
      !editContext.isDataChanged &&
      editContext.cancelEditProfile();
  };

  // THIS FUNCTION DISPLAYS TAB OPTIONS OF ACTION MENU IF NO CHANGES ARE MADE
  const actionTabOptionClickHandler = () => {
    handleUnSavedChangesModalForNavigation();
    dispatch(setDrawerMenuDisplay(true));
  };

  return (
    <>
      <div className="topbar">
        <div className="topbar-left">
          {editContext && editContext?.isDataChanged ? (
            <p
              className="logo"
              style={{ cursor: 'pointer' }}
              onClick={handleUnSavedChangesModal}
            >
              <i>
                <img
                  src="https://res.cloudinary.com/blonk-group/image/upload/v1676550863/blonk/logo/Asset_5_1.png"
                  alt=""
                  height="22"
                />
              </i>
            </p>
          ) : (
            <a
              href="/backoffice/jobs"
              className="logo"
              onClick={handleUnSavedChangesModalForNavigation}
            >
              <i>
                <img
                  src="https://res.cloudinary.com/blonk-group/image/upload/v1676550863/blonk/logo/Asset_5_1.png"
                  alt=""
                  height="22"
                />
              </i>
            </a>
          )}
        </div>
        <Menu className="navbar-custom" mode="horizontal">
          {editContext && editContext?.isDataChanged ? (
            <Menu.Item
              className="button-menu-mobile"
              onClick={handleUnSavedChangesModal}
            >
              <i className="mdi mdi-menu"></i>
            </Menu.Item>
          ) : (
            <Menu.Item className="button-menu-mobile" onClick={toggleSidebar}>
              <i className="mdi mdi-menu"></i>
            </Menu.Item>
          )}

          <Menu.Item className="logo">
            <Logo height="25" />
          </Menu.Item>
          <Menu.Item style={{ float: 'right' }}>
            {/* IF DATA IS CHANGED THEN DISPLAYING DISCARD MODAL AND OTHERWISE OPENING ACTION TAB */}
            {editContext && editContext?.isDataChanged ? (
              <Button
                className="ant-btn-amber"
                shape="round"
                icon={<MenuOutlined />}
                size="middle"
                onClick={handleUnSavedChangesModal}
                style={{ fontSize: '14px' }}
              >
                Action menu
              </Button>
            ) : (
              <Button
                className="ant-btn-amber"
                shape="round"
                icon={<MenuOutlined />}
                size="middle"
                style={{ fontSize: '14px' }}
                onClick={actionTabOptionClickHandler}
              >
                Action menu
              </Button>
            )}
          </Menu.Item>
          <ProfileDropdown />
        </Menu>
      </div>
    </>
  );
};

export default TopBar;
