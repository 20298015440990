import * as React from 'react';

export const CrawliesContext = React.createContext<any | null>({});

function CrawliesContextProvider(props: any) {
  const [candidatesListing, setCandidatesListing] = React.useState<any>(null);
  const [selectedCrawlies, setSelectedCrawlies] = React.useState<any>([]);
  const [crawliesTags, setCrawliesTags] = React.useState<any>([]);
  return (
    <CrawliesContext.Provider
      value={{
        candidatesListing,
        setCandidatesListing,
        selectedCrawlies,
        setSelectedCrawlies,
        crawliesTags,
        setCrawliesTags,
      }}
    >
      {props.children}
    </CrawliesContext.Provider>
  );
}
export default CrawliesContextProvider;
