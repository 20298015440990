import { useState, useEffect } from 'react';
import { useCompanyRecruitersQuery } from '../graphql/generated/graphql';

const useCompanyRecruiters = (userIds: any) => {
  const [companyRecruiter, setCompanyRecruiter]: any = useState(null);
  const { data, loading, error } = useCompanyRecruitersQuery({
    variables: {
      userIds: userIds,
    },
  });
  const loadCompany = () => {
    if (!loading && data) {
      console.log(data.getAllCompanyRecruiters);
      setCompanyRecruiter(data.getAllCompanyRecruiters);
    }
  };

  useEffect(() => {
    loadCompany();
  }, [data]);

  return companyRecruiter;
};

export default useCompanyRecruiters;
