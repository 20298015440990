import React, { useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Tag } from 'antd';

export const Skill = ({ title, id, removeSkill }: any) => {
  return (
    <Tag
      closeIcon={
        <CloseOutlined
          style={{ color: 'white' }}
          onClick={() => removeSkill(id)}
        />
      }
      onClose={() => removeSkill(id)}
      style={{
        border: 'none',
        marginRight: 10,
        marginBottom: 10,
        backgroundColor: 'rgba(18, 100, 121, 0.8)',
        borderRadius: 20,
        color: 'white',
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 10,
        paddingRight: 10,
        fontSize: 14,
        fontWeight: 500,
      }}
      closable={true}
    >
      {title}
    </Tag>
  );
};
