import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Drawer,
  Form,
  Tag,
  Select,
  Button,
  Input,
  DatePicker,
  Switch,
  Upload,
  message,
  Space,
} from 'antd';
import type { DatePickerProps } from 'antd';
import type { UploadChangeParam } from 'antd/es/upload';
import moment from 'moment';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import {
  CloseOutlined,
  ConsoleSqlOutlined,
  LoadingOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { EditContext } from '../Context/EditContext';
import { update } from 'lodash';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import './edit.scss';
import TextArea from 'antd/lib/input/TextArea';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import RequiredField from 'helpers/RequiredField';
import { log } from 'console';

dayjs.extend(customParseFormat);
const { Option } = Select;
interface PropsType {
  candidate: any;
  open: boolean;
  handleClose?: any;
  onUpdate: () => void;
  data: any;
  index: any;
  candidateID: string;
  comments: string;
}

interface InputPropsType {
  placeholder: string;
  value: string;
  label: string;
  name: string;
  required: boolean;
  type?: string;
}

const Styles = {
  locationsWrapper: {
    marginTop: 20,
  },
  submitBtn: {
    width: 101,
    float: 'right',
    backgroundColor: '#BC9623',
    borderRadius: 20,
    marginTop: 20,
    border: 'none',
    color: 'white',
  },
  fieldWrapper: {
    marginBottom: 20,
  },
  fieldHalfWrapper: {
    width: '46%',
    marginBottom: 20,
  },
  fieldsContainer: {
    marginTop: 10,
  },
};

const EducationEditDrawer: React.FC<PropsType> = ({
  candidate,
  open,
  handleClose,
  onUpdate,
  data,
  index,
  comments,
  candidateID,
}) => {
  const [locations, setLocations] = useState<any>([]);
  const editContext = useContext<any>(EditContext);
  const { setData, setUpdatedData } = editContext;
  const [form] = Form.useForm();
  const [startDateChanged, setStartDateChanged] = useState(false);
  const [endDateChanged, setEndDateChanged] = useState(false);
  const locationRef = useRef<any>();
  const [loading, setLoading] = useState(false);
  const [imgLoad, setImgLoad] = useState(false);
  const [current, setCurrent] = useState<any>(false);
  const [imageUrl, setImageUrl] = useState<string>(candidate?.logo);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    useGoogle({
      apiKey: 'AIzaSyACXA9qapZNvq51WpF69eIH908sX_efNMs',
      options: {
        types: ['geocode'],
      },
    });
  const [value, setValue] = useState([]);
  useEffect(() => {
    setCurrent('');
    candidate?.current !== '' &&
      candidate?.current !== null &&
      setCurrent(candidate?.current);
  }, [data?.current, candidate]);
  // console.log(current);
  // console.log(candidate?.current);

  useEffect(() => {
    setImageUrl('');
    setFileList([]);
    candidate?.logo &&
      candidate?.logo != null &&
      setFileList([
        {
          uid: '-1',
          name: 'xxx.png',
          status: 'done',
          url: candidate?.logo,
        },
      ]);
    candidate?.logo && candidate?.logo != null && setImageUrl(candidate?.logo);
  }, [candidate]);
  useEffect(() => {
    form.resetFields();
  }, [candidate]);
  const onRemove: any = (e: any) => {
    setFileList([]);
    setImageUrl('');
  };

  const onChange: UploadProps['onChange'] = (e: any) => {
    if (imgLoad) {
      setFileList(e.fileList);
    }
    if (e.file.status === 'done') {
      setImageUrl(e.file.response.secure_url);
      setIsUploading(false);
      setImgLoad(false);
    }
  };
  const beforeUpload = (file: any) => {
    setImgLoad(true);
    const img = ['image/jpeg', 'image/png', 'image/jpg'];
    const isImg = img.includes(file.type);
    if (!isImg) {
      setImgLoad(false);
      message.error('You can only upload images file!');
      return Upload.LIST_IGNORE;
    }
    const isLt5M = file.size / 1024 / 1024 < 2;
    if (!isLt5M) {
      setImgLoad(false);
      message.error('Image must be smaller than 2MB!');
      return Upload.LIST_IGNORE;
    }
    return isImg && isLt5M;
  };

  const onFinish = (values: any) => {
    if (values.current != undefined) {
      values.current = current;
    }
    if (!values?.location && candidate?.location) {
      values.location =
        candidate?.location?.city + ',' + candidate?.location?.country;
    }
    const locationObject = values?.location?.split(',');
    if (locationObject) {
      const obj: any = {
        city: locationObject[0],
        country:
          locationObject.length > 1
            ? locationObject[locationObject.length - 1]
            : '',
      };
      values.location = obj;
    }
    values.startYear = values?.startYear
      ? new Date(startDateChanged ? values.startYear : data?.startYear)
          .getFullYear()
          .toString()
      : data?.startYear;

    values.endYear = values.current
      ? null
      : values.endYear
      ? new Date(endDateChanged ? values.endYear : data?.endYear)
          .getFullYear()
          .toString()
      : null;

    values.location = values.location ? values.location : candidate?.location;

    const educationComment = values.educationComment;
    values.logo = imageUrl;
    const updatedEducation = editContext?.candidate.education;
    updatedEducation[index] = values;
    delete values.educationComment;
    setData({
      ...editContext?.candidate,
      education: updatedEducation,
      educationComment: educationComment,
    });
    setUpdatedData({
      ...editContext?.updatedData,
      education: updatedEducation,
      educationComment: educationComment,
    });

    handleClose();

    // onUpdate();
    form.resetFields();
    // setFileList([]);
  };

  const InputField = (Props: InputPropsType) => {
    const { placeholder, value, label, name, required, type } = Props;
    return (
      <div style={Styles.fieldWrapper}>
        <label style={{ marginBottom: 0, fontSize: 14, fontWeight: 400 }}>
          <RequiredField required={required} />
          {label}
        </label>
        <Form.Item
          name={name}
          rules={[{ required: required, message: 'This field is required!' }]}
          initialValue={value}
        >
          {type == 'textarea' ? (
            <TextArea
              style={{ borderRadius: 10 }}
              autoSize={{ minRows: 2, maxRows: 6 }}
              defaultValue={value}
            />
          ) : (
            <Input />
          )}
        </Form.Item>
      </div>
    );
  };

  const Fields = [
    {
      name: 'degree',
      required: true,
      placeholder: 'Degree',
      label: 'Degree',
    },
    {
      name: 'schoolName',
      required: true,
      placeholder: 'University',
      label: 'University',
    },
  ];

  const uploadButton = (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {loading ? (
        <LoadingOutlined />
      ) : (
        <PlusOutlined style={{ fontSize: 18 }} />
      )}
      <div style={{ marginLeft: 10, fontSize: 16 }}>Upload logo</div>
    </div>
  );
  const handleCloseDrawer = () => {
    handleClose();
    setCurrent(candidate?.current);
    candidate?.logo &&
      candidate?.logo != null &&
      setFileList([
        {
          uid: '-1',
          name: 'xxx.png',
          status: 'done',
          url: candidate?.logo,
        },
      ]);
    candidate?.logo && candidate?.logo != null && setImageUrl(candidate?.logo);
    form.resetFields();
  };

  return (
    <div>
      <Drawer
        title="Edit education"
        placement={'right'}
        visible={open}
        onClose={handleCloseDrawer}
        extra={
          <Space onClick={handleCloseDrawer} style={{ cursor: 'pointer' }}>
            <CloseOutlined />
          </Space>
        }
      >
        <div className="experience-drawer-fields">
          <Form
            form={form}
            initialValues={{ remember: false }}
            onFinish={onFinish}
            autoComplete="off"
          >
            {Fields.map((field: any, index: any) => (
              <InputField
                key={index}
                required={field.required}
                placeholder={field.placeholder}
                value={candidate ? candidate[field.name] : ''}
                label={field.label}
                name={field.name}
              />
            ))}
            <div style={Styles.fieldWrapper}>
              <label style={{ marginBottom: 0, fontSize: 14, fontWeight: 400 }}>
                Location
              </label>
              <Form.Item
                className="edit-edu"
                name="location"
                rules={[
                  {
                    required: false,
                    message: 'This field is required!',
                  },
                ]}
                initialValue={
                  data?.location?.country != null &&
                  data?.location?.country != '' &&
                  data?.location?.city != null &&
                  data?.location?.city != ''
                    ? data?.location?.city + ',' + data?.location?.country
                    : (data?.location?.country == null ||
                        data?.location?.country == '') &&
                      data?.location?.city
                    ? data?.location?.city
                    : ''
                }
              >
                <Select
                  showSearch
                  className="experience-location-selector"
                  ref={locationRef}
                  onSearch={(value: any) => {
                    getPlacePredictions({ input: value });
                    setValue(value);
                  }}
                  onChange={(value: any) => {
                    setValue(value);
                  }}
                  loading={isPlacePredictionsLoading}
                  filterOption={(input, option: any) =>
                    option.children
                      ?.toLowerCase()
                      .indexOf(input?.toLowerCase()) >= 0
                  }
                >
                  {placePredictions &&
                    placePredictions.map((company: any) => {
                      return (
                        <Option
                          key={company.description}
                          value={company.description}
                        >
                          {company.description}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </div>

            <div
              style={{
                display: 'flex',
                // alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: 15,
              }}
            >
              <div className="year-selector" style={Styles.fieldHalfWrapper}>
                <label
                  style={{ marginBottom: 0, fontSize: 14, fontWeight: 400 }}
                >
                  Start
                </label>
                <Form.Item
                  name="startYear"
                  rules={[
                    {
                      // required: true,
                      validator: (_, value) => {
                        // if (!value) {
                        //   return Promise.reject('Start date is required!');
                        // }
                        if (!value) {
                          return Promise.resolve();
                        }
                        if (moment(value).isAfter(moment())) {
                          return Promise.reject(
                            'Start date cannot be a future date!'
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                  initialValue={
                    candidate?.startYear &&
                    candidate?.startYear != null &&
                    candidate?.startYear != 'NaN' &&
                    candidate?.endYear != '' &&
                    moment(candidate?.startYear, 'YYYY')
                  }
                >
                  <DatePicker
                    format={'YYYY'}
                    picker={'year'}
                    onChange={() => setStartDateChanged(true)}
                  />
                </Form.Item>
              </div>
              <div className="year-selector" style={Styles.fieldHalfWrapper}>
                <label
                  style={{ marginBottom: 0, fontSize: 14, fontWeight: 400 }}
                >
                  End
                </label>
                <Form.Item
                  name="endYear"
                  dependencies={['current', 'startYear']}
                  // dependencies={['endYear']}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const endDate = moment(value);
                        const startDate = moment(getFieldValue('startYear'));
                        // if (current == false) {
                        //   return Promise.reject('End date is required!');
                        // }
                        // if (!value && !current) {
                        //   return Promise.reject('End date is required!');
                        // }
                        if (endDate.isBefore(startDate) && !current) {
                          return Promise.reject(
                            'End date cannot be Before the Start date!'
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                  // dependencies={['current']}
                  // rules={[
                  //   {
                  //     required: current == false,
                  //     message: 'End date is required',
                  //   },
                  // ]}
                  initialValue={
                    candidate?.endYear &&
                    candidate?.endYear != null &&
                    candidate?.endYear != '' &&
                    candidate?.endYear != 'NaN'
                      ? moment(candidate?.endYear, 'YYYY')
                      : null
                  }
                >
                  <DatePicker
                    disabled={current ? true : false}
                    onChange={() => setEndDateChanged(true)}
                    picker={'year'}
                    format="YYYY"
                  />
                </Form.Item>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
              }}
            >
              <div className="year-selector" style={Styles.fieldHalfWrapper}>
                <label
                  style={{ marginBottom: 0, fontSize: 14, fontWeight: 400 }}
                >
                  Currently studying there
                </label>
                <Form.Item name="current">
                  <Switch
                    onChange={(e) => {
                      form.setFieldValue('endYear', null);
                      setCurrent(e);
                    }}
                    checked={current}
                  />
                </Form.Item>
              </div>
              <div className="logo-selector" style={Styles.fieldHalfWrapper}>
                <Form.Item name="logo" initialValue={fileList}>
                  <Upload
                    style={{ width: '100%', height: 100, borderRadius: 10 }}
                    action={`${process.env.REACT_APP_BASE_REST_URL}/upload/candidates/${candidateID}`}
                    listType="picture-card"
                    fileList={fileList}
                    onChange={onChange}
                    onRemove={onRemove}
                    data={{
                      upload_preset: 'cqdbrmkw',
                    }}
                    progress={{
                      strokeWidth: 2,
                      showInfo: false,
                    }}
                    accept="image/png, image/jpeg, image/jpg"
                    beforeUpload={beforeUpload}
                  >
                    {fileList.length == 0 && uploadButton}
                  </Upload>
                  {isUploading && (
                    <p style={{ textAlign: 'center', marginTop: 5 }}>
                      Please wait...
                    </p>
                  )}
                </Form.Item>
              </div>
            </div>

            <InputField
              key={'educationComment'}
              required={false}
              placeholder={''}
              value={comments}
              label={'Comments'}
              name={'educationComment'}
              type={'textarea'}
            />

            <div>
              <Form.Item>
                <Button
                  disabled={imgLoad ? true : false}
                  style={{
                    width: 80,
                    float: 'right',
                    backgroundColor: '#BC9623',
                    borderRadius: 20,
                    marginTop: 0,
                    border: 'none',
                    color: 'white',
                  }}
                  htmlType="submit"
                >
                  Update
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </Drawer>
    </div>
  );
};

export default EducationEditDrawer;
