import React, {
  useState,
  useEffect,
  SyntheticEvent,
  useRef,
  useContext,
} from 'react';
import { useRouteMatch } from 'react-router-dom';
import {
  Row,
  Col,
  Tabs,
  Card,
  Form,
  Input,
  Select,
  Button,
  DatePicker,
  Drawer,
  Space,
  List,
  Popover,
  Modal,
  notification,
} from 'antd';
import { CrawliesContext } from './Context/crawliesContext';
import type { InputRef } from 'antd';
import Image from '../../../assets/icons/icons/crawlies.svg';

import Icon, {
  FolderOpenOutlined,
  SearchOutlined,
  TagsFilled,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import PageTitle from 'components/UI/PageTitle/PageTitle';
import { Fade } from 'react-awesome-reveal';
import ErrorCard from 'components/UI/ErrorCard/ErrorCard';
import {
  useCountAllCandidatesInBoQuery,
  useAllCandidatesInBoLazyQuery,
  useAllCandidatesInBoQuery,
  useCrawliesLazyQuery,
  useGetTagsQuery,
  useUntagCrawliesMutation,
  useGetTagsLazyQuery,
  // useUntagCandidateMutation,
} from '../../../../src/graphql/generated/graphql';

// import { gql, useMutation } from '@apollo/client';

import './CrawliesLists';
import './CrawliesList.scss';

// import './partials/TagsWidget';

import CandidatesTable from './partials/CrawliesTable';
import moment from 'moment';
import TagsWidget from './partials/TagsWidget';
import { useSelector } from 'react-redux';
import { Candidates } from 'store/candidates/types';
import TagsDrawer from './partials/TagsDrawer';
import { update } from 'lodash';
import FilterOpen from 'assets/icons/filterOpen.svg';
import searchIcon from '../../../assets/icons/icons/searchIcon.svg';
import { numberWithCommas } from 'helpers/numberConvert';
// const UNTAG_CRAWLIE_MUTATION = gql`
//   mutation untagCrawlies($tagid: ID!, $candidateid: ID!) {
//     untagCrawlies(tagId: tagid, candidateId: candidateid) {
//       message
//       status
//     }
//   }
// `;

interface ConfirmUntagProps {
  tagId: string;
  candidateId: string;
  tagName: string;
}

interface pageOption {
  page: number;
  pageSize: number;
}

const { Option } = Select;
const { TabPane } = Tabs;
const { confirm } = Modal;
const filterSvg = () => (
  <svg
    width="18"
    height="11"
    viewBox="0 0 18 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 9.75C0 9.55109 0.0790178 9.36032 0.21967 9.21967C0.360322 9.07902 0.551088 9 0.75 9H5.25C5.44891 9 5.63968 9.07902 5.78033 9.21967C5.92098 9.36032 6 9.55109 6 9.75C6 9.94891 5.92098 10.1397 5.78033 10.2803C5.63968 10.421 5.44891 10.5 5.25 10.5H0.75C0.551088 10.5 0.360322 10.421 0.21967 10.2803C0.0790178 10.1397 0 9.94891 0 9.75ZM0 5.25C0 5.05109 0.0790178 4.86032 0.21967 4.71967C0.360322 4.57902 0.551088 4.5 0.75 4.5H11.25C11.4489 4.5 11.6397 4.57902 11.7803 4.71967C11.921 4.86032 12 5.05109 12 5.25C12 5.44891 11.921 5.63968 11.7803 5.78033C11.6397 5.92098 11.4489 6 11.25 6H0.75C0.551088 6 0.360322 5.92098 0.21967 5.78033C0.0790178 5.63968 0 5.44891 0 5.25ZM0 0.75C0 0.551088 0.0790178 0.360322 0.21967 0.21967C0.360322 0.0790178 0.551088 0 0.75 0H17.25C17.4489 0 17.6397 0.0790178 17.7803 0.21967C17.921 0.360322 18 0.551088 18 0.75C18 0.948912 17.921 1.13968 17.7803 1.28033C17.6397 1.42098 17.4489 1.5 17.25 1.5H0.75C0.551088 1.5 0.360322 1.42098 0.21967 1.28033C0.0790178 1.13968 0 0.948912 0 0.75Z"
      fill="#5B626B"
    />
  </svg>
);

const openedFilterSvg = () => (
  <svg
    width="18"
    height="11"
    viewBox="0 0 18 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M-0.000183105 1.24976C-0.000183105 1.44867 0.0788347 1.63943 0.219487 1.78009C0.360139 1.92074 0.550904 1.99976 0.749817 1.99976H5.24982C5.44873 1.99976 5.63949 1.92074 5.78015 1.78009C5.9208 1.63943 5.99982 1.44867 5.99982 1.24976C5.99982 1.05084 5.9208 0.860078 5.78015 0.719426C5.63949 0.578773 5.44873 0.499756 5.24982 0.499756H0.749817C0.550904 0.499756 0.360139 0.578773 0.219487 0.719426C0.0788347 0.860078 -0.000183105 1.05084 -0.000183105 1.24976ZM-0.000183105 5.74976C-0.000183105 5.94867 0.0788347 6.13943 0.219487 6.28009C0.360139 6.42074 0.550904 6.49976 0.749817 6.49976L11.2498 6.49976C11.4487 6.49976 11.6395 6.42074 11.7801 6.28009C11.9208 6.13943 11.9998 5.94867 11.9998 5.74976C11.9998 5.55084 11.9208 5.36008 11.7801 5.21943C11.6395 5.07877 11.4487 4.99976 11.2498 4.99976L0.749817 4.99976C0.550904 4.99976 0.360139 5.07877 0.219487 5.21943C0.0788347 5.36008 -0.000183105 5.55084 -0.000183105 5.74976ZM-0.000183105 10.2498C-0.000183105 10.4487 0.0788347 10.6394 0.219487 10.7801C0.360139 10.9207 0.550904 10.9998 0.749817 10.9998L17.2498 10.9998C17.4487 10.9998 17.6395 10.9207 17.7801 10.7801C17.9208 10.6394 17.9998 10.4487 17.9998 10.2498C17.9998 10.0508 17.9208 9.86008 17.7801 9.71943C17.6395 9.57877 17.4487 9.49976 17.2498 9.49976L0.749817 9.49976C0.550904 9.49976 0.360139 9.57877 0.219487 9.71943C0.0788347 9.86008 -0.000183105 10.0508 -0.000183105 10.2498Z"
      fill="white"
    />
  </svg>
);

const dateFormat = 'YYYY-MM-DD';

const CrawliesLists: React.FC = () => {
  const searchTextRef = useRef<InputRef>(null);
  const searchFormRef = useRef<any>(null);
  const searchTagRef = useRef<InputRef>();
  const [visibleEditTags, setVisibleEditTags] = useState(false);
  const { url } = useRouteMatch();
  const [untagCrawlies] = useUntagCrawliesMutation();
  // const [untagCrawlie] = useMutation(UNTAG_CRAWLIE_MUTATION);
  const [form] = Form.useForm();
  const {
    data: crawliesCount,
    loading,
    error,
  }: any = useCountAllCandidatesInBoQuery({
    fetchPolicy: 'no-cache',
  });

  const [totalCrawlies, setTotalCrawlies]: any = useState(0);

  const [searchTxtFld, setSearchTxtFld] = useState({
    searchTxt: '',
    searchFld: '',
  });

  const [
    getTags,
    {
      data: tagsData,
      loading: tagsLoading,
      error: tagsError,
      refetch: refetchTags,
    },
  ] = useGetTagsLazyQuery({
    fetchPolicy: 'network-only',
  });
  const [tagsList, setTagsList] = useState<any>([]);

  const [
    fetchCrawliesList,
    { data: crawliesList, loading: candidateLoading, error: candidateError },
  ]: any = useCrawliesLazyQuery();
  const [fetchResetCrawliesList, { data: resetCrawliesList }]: any =
    useCrawliesLazyQuery();

  const [
    fetchCrawliesSearchList,
    {
      data: crawliesSearchList,
      loading: candidateSearchLoading,
      error: candidateSearcgError,
    },
  ]: any = useCrawliesLazyQuery({
    fetchPolicy: 'network-only',
  });

  console.log('crawliesList', crawliesList);
  console.log('crawliesSearchList', crawliesSearchList);

  const selectedCandidates = useSelector((state: any) => state?.candidates);

  const [pageOptions, setPageOptions] = useState<pageOption>({
    page: 1,
    pageSize: 20,
  });

  const { candidatesListing, setCandidatesListing } =
    useContext(CrawliesContext); //context
  console.log(candidatesListing);

  let totalCountWithCommas = candidatesListing?.crawliesPersonalDetails[0]
    ?.count
    ? numberWithCommas(candidatesListing?.crawliesPersonalDetails[0]?.count)
    : '';
  const { selectedCrawlies, setSelectedCrawlies } = useContext(CrawliesContext); //context
  // const [onSelect, setOnSelect] = useState(null);
  // const [onUnSelect, setOnUnSelect] = useState(null);

  const [searchOptions, setSearchOptions] = useState({
    searchText: '',
    searchField: 'All',
    tags: [],
  });
  const [showAdvanceFilters, setShowAdvanceFilters] = useState(false);

  const [advanceFilters, setAdvanceFilters] = useState({
    source: '',
    dtFrom: moment().format(dateFormat),
    dtFromMoment: moment(),
    dtTo: moment().format(dateFormat),
    dtToMoment: moment(),
  });

  useEffect(() => {
    getTags();
  }, []);

  useEffect(() => {
    fetchCrawliesList({
      variables: {
        searchText: searchOptions.searchText,
        searchField: searchOptions.searchField,
        pageSize: pageOptions.pageSize,
        page: pageOptions.page,
      },
    });
    if (crawliesList && crawliesList?.crawliesPersonalDetails) {
      console.log(
        'crawliesList?.crawliesPersonalDetails',
        crawliesList?.crawliesPersonalDetails
      );
      setCandidatesListing(crawliesList);
    }
  }, [crawliesList, pageOptions]);

  // useEffect(() => {
  //   fetchCrawliesSearchList({
  //     variables: {
  //       searchText: searchOptions.searchText,
  //       searchField: searchOptions.searchField,
  //       size: pageOptions.size,
  //       page: pageOptions.page,
  //     },
  //   });
  //   if (crawliesSearchList && crawliesSearchList?.crawliesPersonalDetails) {
  //     // console.log(crawliesSearchList?.crawliesPersonalDetails);
  //     console.log('crawliesSearchList?.crawliesPersonalDetails', crawliesSearchList?.crawliesPersonalDetails);
  //     setCandidatesListing(crawliesSearchList);
  //   }
  // }, [crawliesSearchList]);

  // useEffect(() => {
  //   console.log(searchOptions.searchText)
  //   fetchCrawliesList({
  //     variables: {
  //       search: searchOptions.searchText,
  //       searchField: searchOptions.searchField,
  //       size: pageOptions.size,
  //       page: pageOptions.page,
  //     },
  //   });
  //   if (crawliesList && crawliesList?.crawliesPersonalDetails) {
  //     setCandidatesListing(crawliesList);
  //   }
  // }, [pageOptions]);

  useEffect(() => {
    if (crawliesSearchList && crawliesSearchList?.crawliesPersonalDetails) {
      setCandidatesListing(crawliesSearchList);
    }
  }, [crawliesSearchList]);

  const onClose = () => {
    setVisibleEditTags(false);
  };

  const handleOpenEditTags = () => {
    setVisibleEditTags(true);
  };

  const handleUnTagCandidate = (candidateId: any, tagId: any, tagName: any) => {
    // untagCrawlie({
    //   variables: {
    //     tagid: tagId,
    //     candidateid: candidateId,
    //   },
    // }).then((res: any) => {
    //   updateCandidatesList({
    //     tagId: tagId,
    //     candidateId: candidateId,
    //     tagName: tagName,
    //   });
    //   showNotification('Un-tagged successfully!');
    //   console.log('untagged-successfuly');
    // });
    console.log('tagId', tagId, 'candidateId', candidateId);

    untagCrawlies({
      variables: {
        tagId: tagId,
        candidateId: candidateId,
      },
    }).then((res: any) => {
      updateCandidatesList({
        tagId: tagId,
        candidateId: candidateId,
        tagName: tagName,
      });

      showNotification(res?.data?.untagCrawlies?.message);
    });
  };

  const showNotification = (message: string) => {
    const args = {
      message: message,
      duration: 10,
      icon: <CheckCircleOutlined style={{ color: 'green' }} />,
    };
    notification.open(args);
  };

  const updateCandidatesList = (props: ConfirmUntagProps) => {
    // eslint-disable-next-line react/prop-types
    const { tagId, candidateId } = props;
    const updatedCandidates = candidatesListing.crawliesPersonalDetails.map(
      (candidate: any) => {
        if (candidate._id == candidateId) {
          return {
            ...candidate,
            tags: candidate.tags.filter((tag: any) => tag._id != tagId),
          };
        } else {
          return candidate;
        }
      }
    );
    setCandidatesListing({
      crawliesPersonalDetails: updatedCandidates,
    });
  };

  const confirmUnTagCandidate = (props: ConfirmUntagProps) => {
    // eslint-disable-next-line react/prop-types
    const { candidateId, tagId, tagName } = props;
    confirm({
      title: (
        <p>
          Are you sure you want to remove the following tag:<p>{tagName}</p>
        </p>
      ),
      icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleUnTagCandidate(candidateId, tagId, tagName);
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onCancel() {},
    });
  };

  const refreshCrawlies = (tags: any, candidates: any[]) => {
    const candidateslistData: any = candidatesListing?.crawliesPersonalDetails;
    const updatedList = candidateslistData.map((candidate: any) => {
      if (candidates?.includes(candidate._id)) {
        const newAddedTags = [...candidate?.tags, ...tags?.tagCandidates];
        const uniqueTagIds: any = [];
        const unique = newAddedTags.filter((tag) => {
          const isDuplicate = uniqueTagIds.includes(tag._id);

          if (!isDuplicate) {
            uniqueTagIds.push(tag._id);

            return true;
          }

          return false;
        });

        return {
          ...candidate,
          tags: unique,
        };
      } else {
        return candidate;
      }
    });
    setCandidatesListing({
      crawliesPersonalDetails: updatedList,
    });
  };

  const handleUpdateCandidatesTags = (updatedTag: any) => {
    const candidateslistData: any = candidatesListing?.crawliesPersonalDetails;
    const updatedList = candidateslistData.map((candidate: any) => {
      const newTags = candidate.tags.map((tag: any) => {
        if (tag._id != updatedTag._id) return tag;
        return { ...tag, name: updatedTag.name };
      });
      return { ...candidate, tags: newTags };
    });
    setCandidatesListing({
      crawliesPersonalDetails: updatedList,
    });
  };

  const onFinish = (values: any) => {
    // console.log('values?.searchText', values?.searchText);
    // console.log('values?.searchField', values?.searchField);
    // console.log('values?.tags', values?.tags);

    setSearchOptions({
      ...searchOptions,
      searchText: values.searchText ? values.searchText : '',
      searchField: values.searchField ? values.searchField : 'All',
    });
    // console.log('searchOptions', searchOptions);
    // console.log('pageOptions', pageOptions);
    // console.log('searchOptions.tags', searchOptions.tags);

    fetchCrawliesSearchList({
      variables: {
        searchText: values.searchText,
        searchField: values.searchField,
        pageSize: pageOptions.pageSize,
        page: pageOptions.page,
        tags: values.tags,
      },
    });
    // console.log('searchOptions', searchOptions);
    // console.log('crawliesSearchList', crawliesSearchList);
  };

  const onPageChange = (size: any, page: any) => {
    fetchCrawliesSearchList({
      variables: {
        searchText: searchOptions.searchText,
        searchField: searchOptions.searchField,
        size,
        pageNumber: page,
      },
    });
  };

  useEffect(() => {
    console.log('crawliesSearchList', crawliesSearchList);
  }, [crawliesSearchList]);

  useEffect(() => {
    if (tagsData?.getTags) {
      setTagsList(tagsData.getTags);
    }
  }, [tagsData]);

  const resetSearch = () => {
    fetchCrawliesSearchList({
      variables: {
        searchText: '',
        searchField: 'All',
        size: pageOptions.pageSize,
        page: pageOptions.page,
      },
    });
    setSearchOptions({
      searchText: '',
      searchField: 'All',
      tags: [],
    });
    form.resetFields();
  };

  const onKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      form.submit();
    }
  };

  const handleUpdateTags = (tags: any) => {
    setTagsList(tags);
    console.log('tags:', tags);
  };

  const updateTagsList = (newTag: any) => {
    console.log(newTag);
    setTagsList([...tagsList, newTag]);
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleSetSearchOption = (e: any) => {};

  useEffect(() => {
    console.log(resetCrawliesList);
    if (resetCrawliesList && resetCrawliesList?.crawliesPersonalDetails) {
      console.log(
        'crawliesList?.crawliesPersonalDetails',
        resetCrawliesList?.crawliesPersonalDetails
      );
      // setCandidatesListing(crawliesList);
      setCandidatesListing(resetCrawliesList);
    }
  }, [resetCrawliesList]);

  const onReset = (e: any) => {
    if (e.target.value === '') {
      fetchCrawliesSearchList({
        variables: {
          searchText: '',
          searchField: 'All',
          pageSize: 20,
          page: 1,
        },
      });
      setSearchOptions({
        ...searchOptions,
        searchText: '',
      });
      console.log(crawliesCount);
    }
  };

  console.log(
    'crawliesCount?.countAllCandidatesInBO?.count',
    crawliesCount?.countAllCandidatesInBO?.count
  );
  console.log(
    'crawliesList?.crawliesPersonalDetails[0]',
    crawliesList?.crawliesPersonalDetails[0]
  );
  console.log(
    'candidatesListing?.crawliesPersonalDetails?.length',
    candidatesListing?.crawliesPersonalDetails?.length
  );

  return (
    <>
      <PageTitle back title="List of crawlies" />
      {!loading && !crawliesCount?.countAllCandidatesInBO ? (
        <ErrorCard />
      ) : (
        <>
          <Row justify="center" gutter={16}>
            <Col span={24}>
              <Fade>
                <Card>
                  <Form
                    ref={searchFormRef}
                    form={form}
                    name="control-hooks"
                    onFinish={onFinish}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      className="container-crawlies"
                    >
                      <div style={{ display: 'flex' }}>
                        <Form.Item name="searchText">
                          <Input
                            style={{
                              minHeight: '50px',
                              borderRadius: '35px',
                              maxWidth: '370px',
                            }}
                            placeholder="Enter key words"
                            allowClear
                            ref={searchTextRef}
                            prefix={<img src={searchIcon} alt="searchIcon" />}
                            onChange={onReset}
                            // onPressEnter={() => form.submit()}
                            className="filter-search"
                          />
                        </Form.Item>
                        <Form.Item name="searchField" className="search-type">
                          <Select
                            style={{
                              minHeight: '50px',
                              borderRadius: '35px',
                              backgroundColor: 'transparent',
                            }}
                            placeholder="Select field"
                            allowClear
                            onKeyDown={onKeyDown}
                            // defaultValue={searchOptions.searchField}
                            onChange={(value) =>
                              setSearchOptions({
                                ...searchOptions,
                                searchField: value,
                              })
                            }
                            className="tiltle-select"
                          >
                            <Option value="All">All</Option>
                            <Option value="firstName">First name</Option>
                            <Option value="lastName">Last name</Option>
                            <Option value="email">Email</Option>
                            <Option value="title">Title</Option>
                            <Option value="location">Location</Option>
                            <Option value="company">Company</Option>
                            {/* <Option value="position">Position</Option> */}
                          </Select>
                        </Form.Item>
                        <Button
                          disabled={showAdvanceFilters ? true : false}
                          className="search-button"
                          htmlType="submit"
                          onClick={() => form.submit()}
                        >
                          Search
                        </Button>
                      </div>
                      <div>
                        <Button
                          style={{
                            marginRight: 5,
                            borderRadius: 10,
                            backgroundColor: '#53946F',
                            color: 'white',
                            borderColor: '#53946F',
                            height: 50,
                            width: 73,
                          }}
                          size="middle"
                          onClick={handleOpenEditTags}
                        >
                          <TagsFilled
                            style={{
                              color: '#fffff',
                              fontSize: 19,
                              transform: 'rotate(-90deg)',
                            }}
                          />
                        </Button>
                        <Button
                          icon={
                            showAdvanceFilters ? (
                              <Icon component={openedFilterSvg} />
                            ) : (
                              <Icon component={filterSvg} />
                            )
                          }
                          onClick={() =>
                            setShowAdvanceFilters(!showAdvanceFilters)
                          }
                          className={
                            showAdvanceFilters
                              ? 'filter-btn-active'
                              : 'filter-btn'
                          }
                          style={{
                            height: 50,
                            border: 0,
                            width: 100,
                            marginLeft: 10,
                            backgroundColor: !showAdvanceFilters
                              ? '#E6E6E6'
                              : '#5B626B',
                            borderRadius: 10,
                            fontSize: '16px',
                          }}
                        >
                          Filters
                        </Button>
                      </div>
                    </div>
                    {showAdvanceFilters && (
                      <>
                        <Row
                          gutter={16}
                          className="filters-wrapper"
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '35px',
                          }}
                        >
                          {/* <Col span={4}>
                          <Form.Item label="From" name="dtFrom">
                            <DatePicker
                              defaultValue={advanceFilters.dtFromMoment}
                              format={dateFormat}
                              onKeyDown={onKeyDown}
                              onChange={(date, dateString) =>
                                setAdvanceFilters({
                                  ...advanceFilters,
                                  dtFrom: dateString,
                                })
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item label="To" name="dtTO">
                            <DatePicker
                              defaultValue={advanceFilters.dtToMoment}
                              format={dateFormat}
                              onKeyDown={onKeyDown}
                              onChange={(date, dateString) =>
                                setAdvanceFilters({
                                  ...advanceFilters,
                                  dtTo: dateString,
                                })
                              }
                            />
                          </Form.Item>
                        </Col> */}
                          <Col span={10}>
                            <div>
                              <label style={{ fontSize: '16px' }}>Tags</label>
                              <Form.Item className="hide-label" name="tags">
                                <Select
                                  placeholder="Select tags"
                                  allowClear
                                  style={{ height: 50, maxWidth: '370px' }}
                                  showSearch
                                  optionFilterProp="label"
                                  mode="tags"
                                  className="source-select"
                                  maxTagCount="responsive"
                                  onChange={(tags) => {
                                    setSearchOptions({
                                      ...searchOptions,
                                      tags: tags,
                                    });
                                  }}
                                >
                                  {tagsList.map((tag: any) => (
                                    <Option
                                      label={tag.name}
                                      key={tag._id}
                                      value={tag._id}
                                    >
                                      {tag.name}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </div>
                          </Col>
                          {/* <Col span={4}> */}
                          {/* <div>
                              <label>Source</label>
                              <Form.Item className="hide-label" name="source">
                                <Select
                                  placeholder="Select source"
                                  allowClear
                                  onKeyDown={onKeyDown}
                                  onChange={(value) => {
                                    if (value) {
                                      setAdvanceFilters({
                                        ...advanceFilters,
                                        source: value,
                                      });
                                    } else {
                                      setAdvanceFilters({
                                        ...advanceFilters,
                                        source: '',
                                      });
                                    }
                                  }}
                                  className="source-select"
                                >
                                  <Option value="web">Web</Option>
                                  <Option value="mobile">Mobile</Option>
                                  <Option value="migrated">Migrated</Option>
                                </Select>
                              </Form.Item>
                            </div> */}
                          {/* </Col> */}
                          {/* </Row>
                        <Row justify="end" gutter={16}> */}
                          <Col style={{ textAlign: 'end' }}>
                            <Button
                              onClick={resetSearch}
                              size="middle"
                              style={{
                                borderRadius: 10,
                                backgroundColor: '#E6E6E6',
                                border: 'none',
                                marginRight: 10,
                                width: 120,
                                height: 40,
                              }}
                            >
                              Reset
                            </Button>
                            <button
                              // onClick={onFinish}
                              type="submit"
                              style={{
                                borderRadius: 10,
                                backgroundColor: '#BC9623',
                                color: 'white',
                                border: 'none',
                                width: 120,
                                height: 40,
                                cursor: 'pointer',
                              }}
                            >
                              Show result
                            </button>
                          </Col>
                        </Row>
                      </>
                    )}
                  </Form>
                </Card>
              </Fade>
            </Col>
          </Row>
          <Row justify="center" className="pb-4" gutter={16}>
            <Col span={24}>
              <Fade>
                <Card>
                  <div className="candidate-list-wrapper">
                    <div
                      style={{
                        float: 'right',
                        textAlign: 'right',
                        marginBottom: -47,
                        zIndex: 300,
                      }}
                    >
                      <TagsWidget
                        refetchTags={refetchTags}
                        data={tagsList}
                        isEnable={selectedCrawlies?.length}
                        refreshCandidates={refreshCrawlies}
                      />
                    </div>
                    <Tabs defaultActiveKey="1">
                      {crawliesList?.crawliesPersonalDetails[0]?.count && (
                        <TabPane
                          tab={
                            <div
                              style={{
                                width: '80%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}
                            >
                              <img
                                src={Image}
                                alt="countJPG"
                                style={{ paddingRight: '10px' }}
                              />
                              <span style={{ fontSize: '16px' }}>
                                Number of crawlies
                                {candidatesListing?.crawliesPersonalDetails[0]
                                  ?.count && (
                                  <span className="tab-pane-count">
                                    {candidatesListing?.crawliesPersonalDetails
                                      ?.length && totalCountWithCommas}
                                  </span>
                                )}
                              </span>
                              {/* {crawliesList?.crawliesPersonalDetails[0]?.count} */}
                            </div>
                          }
                          key="1"
                        >
                          {/* Need to subtract hardcoded 7, because if we run aggregation on crawliesList
                        with respect to different tables , we wont get the total count, and db has 7 bogus entries, this is only for staging */}
                          <CandidatesTable
                            loading={candidateLoading || candidateSearchLoading}
                            data={candidatesListing}
                            confirmUnTagCandidate={confirmUnTagCandidate}
                            error={candidateError}
                            totalCount={
                              crawliesCount?.countAllCandidatesInBO?.count - 7
                            }
                            url={url}
                            pageOptions={pageOptions}
                            setPageOptions={setPageOptions}
                            searchOptions={searchOptions}
                            showAdvanceFilters={showAdvanceFilters}
                            advanceFilters={advanceFilters}
                          />
                        </TabPane>
                      )}
                    </Tabs>
                  </div>
                </Card>
              </Fade>
            </Col>
          </Row>
          <TagsDrawer
            handleUpdateCandidatesTags={handleUpdateCandidatesTags}
            handleUpdateTags={handleUpdateTags}
            updateTagsList={updateTagsList}
            onClose={onClose}
            visibleEditTags={visibleEditTags}
          />
        </>
      )}
    </>
  );
};

export default CrawliesLists;
