import React, { useEffect, useState } from 'react';
import { useParams, Link, useRouteMatch, useHistory } from 'react-router-dom';
import IconCard from '../../../../components/UI/IconCard/IconCard';
import PageTitle from '../../../../components/UI/PageTitle/PageTitle';
import ErrorCard from '../../../../components/UI/ErrorCard/ErrorCard';
import styled from 'styled-components';
import { Editor } from '@tinymce/tinymce-react';

import {
  Row,
  Col,
  Card,
  Skeleton,
  Empty,
  Button,
  Avatar,
  Typography,
  List,
  Tooltip,
  Space,
  Table,
  Tag,
  Divider,
  Form,
  Input,
  Select,
  Popconfirm,
  notification,
} from 'antd';
import { Fade } from 'react-awesome-reveal';
import {
  UserOutlined,
  CalendarOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import {
  useGetSingleJobQuery,
  useCreateJobLandingPageMutation,
  useGetJobLandingPageQuery,
} from '../../../../graphql/generated/graphql';
import { encrypt } from 'helpers/crypto';
const { Title } = Typography;

interface Jobs {
  key: number;
  title: string;
  jobfields: any;
  location: any;
  contract: any;
  candidates: number;
  status: any;
  createdAt: string;
}

const ManageJobPage: React.FC = () => {
  const { jobId } = useParams<any>();
  const { TextArea }: any = Input;
  const userName: any = localStorage.getItem('userName');

  //handle view
  const [form] = Form.useForm();
  const [editMode, setEditMode] = useState(false);
  const [jobLandingPageId, setJobLandingPageId] = useState('');
  const [metaTitle, setMetaTitle] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [header, setHeader] = useState('');
  const [customCSS, setCustomCSS] = useState('');
  const [cSSLink, setCSSLink] = useState('');
  const [pageTitle, setPageTitle] = useState('');
  const [faviconUrl, setFaviconUrl] = useState('');
  const [metaImageUrl, setMetaImageUrl] = useState('');
  const [content, setContent] = useState('');
  const [leftContent, setleftContent] = useState('');
  const [rightContent, setrightContent] = useState('');
  const [footer, setFooter] = useState('');

  const history = useHistory();

  const { data, loading, error }: any = useGetSingleJobQuery({
    variables: {
      jobId: jobId,
    },
    fetchPolicy: 'network-only',
  });

  const getInitials = (name: any) =>
    name &&
    name
      .replace(/[^a-zA-Z- ]/g, '')
      .match(/\b\w/g)
      .join('');

  const {
    data: data2,
    loading: loading2,
    error: error2,
  }: any = useGetJobLandingPageQuery({
    variables: {
      jobId: jobId,
    },
    fetchPolicy: 'network-only',
  });

  const [createJobLandingPageMutation, { loading: loading1 }] =
    useCreateJobLandingPageMutation();

  const handleEditorChange = (contentInput: any, editor: any) => {
    console.log('Content was updated:', contentInput);
    setContent(contentInput);
  };

  const handleLeftContentEditorChange = (contentInput: any, editor: any) => {
    console.log('Content was updated:', contentInput);
    setleftContent(contentInput);
  };

  const handleRightContentEditorChange = (contentInput: any, editor: any) => {
    console.log('Content was updated:', contentInput);
    setrightContent(contentInput);
  };

  const handleFooterContentEditorChange = (contentInput: any, editor: any) => {
    console.log('Content was updated:', contentInput);
    setFooter(contentInput);
  };

  const onFinish = (values: any) => {
    console.log('Submitting: ', values);
    // debugger;

    createJobLandingPageMutation({
      variables: {
        jobId: jobId,
        header: values.header,
        content: values.content,
        leftContent: values.leftContent,
        rightContent: values.rightContent,
        metaTitle: values.metaTitle,
        metaDescription: values.metaDescription,
        customCss: values.customCss,
        cssLink: values.cssLink,
        footer: values.footer,
        pageTitle: values.pageTitle,
        faviconUrl: values.faviconUrl,
        metaImageUrl: values.metaImageUrl,
        jobLandingPageId: jobLandingPageId,
      },
    })
      .then(({ data }: any) => {
        console.log('Response: ', data);
        if (data.createJobLandingPage)
          notification['success']({
            message: data.createJobLandingPage.message,
            // onClose: () =>
            //   history.push(`/recruiters/companies/jobs/add`),
            duration: 3,
            description: `You has successfully create the landing page.`,
          });
        // form.resetFields();
      })
      .catch((err) => {
        const errors = err.graphQLErrors.map((el: any) => el.message);
        notification['error']({
          message: 'Unable to create job landing page',
          description: errors.map((er: any) => <div key={er}>{er}</div>),
          duration: 3,
          // onClose: () => setHasError(false),
        });
      });
  };

  useEffect(() => {
    if (data2 && data2.getJobLandingPage) {
      // const [metaTitle, setMetaTitle] = useState('')
      // const [metaDescription, setMetaDescription] = useState('')
      // const [header, setHeader] = useState('')
      // const [customCSS, setCustomCSS] = useState('')
      // const [cSSLink, setCSSLink] = useState('')
      // const [pageTitle, setPageTitle] = useState('')
      // const [faviconUrl, setFaviconUrl] = useState('')
      // const [metaImageUrl, setMetaImageUrl] = useState('')
      // const [content, setContent] = useState('')
      // const [leftContent, setleftContent] = useState('')
      // const [rightContent, setrightContent] = useState('')
      // const [footer, setFooter] = useState('')
      console.log('Setting values in form ');
      const {
        _id,
        metaTitle,
        metaDescription,
        header,
        customCSS,
        cSSLink,
        pageTitle,
        faviconUrl,
        metaImageUrl,
        content,
        leftContent,
        rightContent,
        footer,
      } = data2.getJobLandingPage;
      setMetaTitle(metaTitle);
      setMetaDescription(metaDescription);
      setHeader(header);
      setCustomCSS(customCSS);
      setCSSLink(cSSLink);
      setPageTitle(pageTitle);
      setFaviconUrl(faviconUrl);
      setMetaImageUrl(metaImageUrl);
      setContent(content);
      setleftContent(leftContent);
      setrightContent(rightContent);
      setFooter(footer);
      setJobLandingPageId(_id);

      setEditMode(true);
      form.setFieldsValue({
        ...data2.getJobLandingPage,
      });
    }
  }, [data2]);

  return (
    <>
      <PageTitle
        title="Manage Landing Page"
        btnActive={false}
        back
        handleGoBack={() => {
          history.goBack();
        }}
      />
      {!loading && !data ? (
        <ErrorCard />
      ) : (
        <>
          <Row justify="center" className="pb-4" gutter={16}>
            <Col span={24}>
              <Fade>
                <Card>
                  <Skeleton
                    active
                    avatar
                    paragraph={{ rows: 3 }}
                    className="p-4"
                    loading={loading}
                  >
                    {data && data.getJobDetails !== null ? (
                      <>
                        <Row>
                          <Col offset={18} span={6}>
                            <Tooltip placement="top" title="View job page">
                              <Button
                                block
                                type="primary"
                                shape="round"
                                className="mb-2"
                                // onClick={() => history.push(`/backoffice/joblandingpage/${jobId}`)}
                              >
                                View job page
                              </Button>
                            </Tooltip>
                          </Col>
                        </Row>

                        <>
                          <Form
                            name="registration-form"
                            layout="vertical"
                            form={form}
                            onFinish={onFinish}
                            scrollToFirstError
                          >
                            <>
                              <Row
                                justify="center"
                                className="pb-4"
                                gutter={16}
                              >
                                <Col span={24}>
                                  <Title
                                    level={4}
                                    style={{ marginBottom: '0px' }}
                                    className="text-navy"
                                  >
                                    {'SEO settings'}
                                  </Title>
                                  <hr />
                                </Col>
                              </Row>
                              <Row gutter={16}>
                                <Col span={12}>
                                  <Form.Item
                                    name="metaTitle"
                                    label="Meta Title (max 100 characters)"
                                  >
                                    <Input
                                      type="text"
                                      placeholder="Enter meta title"
                                      onChange={(e: any) =>
                                        setMetaTitle(e.target.value)
                                      }
                                      value={metaTitle}
                                    />
                                  </Form.Item>

                                  <Form.Item
                                    name="metaDescription"
                                    label="Meta Description (max 200 characters)"
                                  >
                                    <TextArea
                                      rows={5}
                                      showCount
                                      maxLength={200}
                                      onChange={(e: any) =>
                                        setMetaDescription(e.target.value)
                                      }
                                      value={metaDescription}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    name="pageTitle"
                                    label="Page Title"
                                  >
                                    <Input
                                      type="text"
                                      placeholder="Enter Page Title"
                                      onChange={(e: any) =>
                                        setPageTitle(e.target.value)
                                      }
                                      value={pageTitle}
                                      // defaultValue={job && job.name}
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    name="faviconUrl"
                                    label="Favicon URL"
                                  >
                                    <Input
                                      type="text"
                                      placeholder="Enter Favicon URL"
                                      onChange={(e: any) =>
                                        setFaviconUrl(e.target.value)
                                      }
                                      value={faviconUrl}
                                      // defaultValue={job && job.name}
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    name="metaImageUrl"
                                    label="Meta Image URL"
                                  >
                                    <Input
                                      type="text"
                                      placeholder="Enter Meta Image URL"
                                      onChange={(e: any) =>
                                        setMetaImageUrl(e.target.value)
                                      }
                                      value={metaImageUrl}
                                      // defaultValue={job && job.name}
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    name="cSSLink"
                                    label="External Css Link"
                                  >
                                    <Input
                                      type="text"
                                      placeholder="Enter external Css Link"
                                      onChange={(e: any) =>
                                        setCSSLink(e.target.value)
                                      }
                                      value={cSSLink}
                                      // defaultValue={job && job.name}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </>
                            {/* Page Setting settings */}
                            <>
                              <Row
                                justify="center"
                                className="pb-4"
                                gutter={16}
                              >
                                <Col span={24}>
                                  <Title
                                    level={4}
                                    style={{ marginBottom: '0px' }}
                                    className="text-navy"
                                  >
                                    {'Page Setting settings'}
                                  </Title>
                                  <hr />
                                </Col>
                              </Row>
                              <Row gutter={16}>
                                <Col span={12}>
                                  <Form.Item
                                    name="header"
                                    label="Header content"
                                  >
                                    <TextArea
                                      rows={5}
                                      showCount
                                      onChange={(e: any) =>
                                        setHeader(e.target.value)
                                      }
                                      value={header}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    name="customCSS"
                                    label="Custom css"
                                  >
                                    <TextArea
                                      rows={5}
                                      showCount
                                      onChange={(e: any) =>
                                        setCustomCSS(e.target.value)
                                      }
                                      value={customCSS}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row gutter={16}>
                                <Col span={24}>
                                  <Form.Item
                                    name="content"
                                    label="Job page content"
                                  >
                                    <Editor
                                      apiKey="eq00uprdu298m9u3p9817qwl3qjfowmtibhxbaf02eh23bzl"
                                      init={{
                                        height: 500,
                                        menubar: true,
                                        plugins: [
                                          'advlist autolink link image lists charmap print preview hr anchor pagebreak spellchecker',
                                          'searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking',
                                          'save table contextmenu directionality emoticons template paste textcolor',
                                        ],
                                        toolbar:
                                          'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print preview media fullpage | forecolor backcolor emoticons',
                                      }}
                                      onEditorChange={handleEditorChange}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row gutter={16}>
                                <Col span={12}>
                                  <Form.Item
                                    name="leftContent"
                                    label="Left content"
                                  >
                                    <Editor
                                      apiKey="eq00uprdu298m9u3p9817qwl3qjfowmtibhxbaf02eh23bzl"
                                      init={{
                                        height: 500,
                                        menubar: true,
                                        plugins: [
                                          'advlist autolink link image lists charmap print preview hr anchor pagebreak spellchecker',
                                          'searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking',
                                          'save table contextmenu directionality emoticons template paste textcolor',
                                        ],
                                        toolbar:
                                          'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print preview media fullpage | forecolor backcolor emoticons',
                                      }}
                                      onEditorChange={
                                        handleLeftContentEditorChange
                                      }
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    name="rightContent"
                                    label="Right content"
                                  >
                                    <Editor
                                      apiKey="eq00uprdu298m9u3p9817qwl3qjfowmtibhxbaf02eh23bzl"
                                      init={{
                                        height: 500,
                                        menubar: true,
                                        plugins: [
                                          'advlist autolink link image lists charmap print preview hr anchor pagebreak spellchecker',
                                          'searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking',
                                          'save table contextmenu directionality emoticons template paste textcolor',
                                        ],
                                        toolbar:
                                          'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print preview media fullpage | forecolor backcolor emoticons',
                                      }}
                                      onEditorChange={
                                        handleRightContentEditorChange
                                      }
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row gutter={16}>
                                <Col span={24}>
                                  <Form.Item
                                    name="footer"
                                    label="Footer content"
                                  >
                                    <Editor
                                      apiKey="eq00uprdu298m9u3p9817qwl3qjfowmtibhxbaf02eh23bzl"
                                      init={{
                                        height: 500,
                                        menubar: true,
                                        plugins: [
                                          'advlist autolink link image lists charmap print preview hr anchor pagebreak spellchecker',
                                          'searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking',
                                          'save table contextmenu directionality emoticons template paste textcolor',
                                        ],
                                        toolbar:
                                          'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print preview media fullpage | forecolor backcolor emoticons',
                                      }}
                                      onEditorChange={
                                        handleFooterContentEditorChange
                                      }
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>

                              {/* <Row gutter={16}>
                                <Col span={12}>
                                  <Form.Item name="media" label="Media">
                                    <Input
                                      type="text"
                                      placeholder="add referral links"
                                    />
                                  </Form.Item>
                                </Col>
                              </Row> */}
                              {/* Buttons */}
                              <Row>
                                <Col offset={18} span={6}>
                                  <Form.Item>
                                    <div className="text-right">
                                      <Space>
                                        <Button
                                          className="ant-btn-amber"
                                          htmlType="submit"
                                          shape="round"
                                          // disabled={hasError}
                                          // loading={isLoading}
                                        >
                                          {`${
                                            editMode ? 'Update' : 'Create'
                                          } job page`}
                                        </Button>
                                      </Space>
                                    </div>
                                  </Form.Item>
                                </Col>
                              </Row>
                            </>
                          </Form>
                          <Row justify="center" className="pb-4" gutter={16}>
                            <Col span={24}>
                              <Title
                                level={4}
                                style={{ marginBottom: '0px' }}
                                className="text-navy"
                              >
                                {'BLINKS'}
                              </Title>
                              <hr />
                              <Space>
                                <a
                                  href={`${
                                    process.env.REACT_APP_WOB_URL
                                  }/job/${jobId}?media=${encrypt(
                                    `Link_Pro_${getInitials(userName)}`
                                  )}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >{`${
                                  process.env.REACT_APP_WOB_URL
                                }/job/${jobId}?media=${encrypt(
                                  `Link_Pro_${getInitials(userName)}`
                                )}`}</a>
                                <Tooltip title="delete">
                                  <Popconfirm
                                    title="Are you sure delete this recruiter?"
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <Button
                                      className="border-burgundy"
                                      shape="circle"
                                      // onClick={showDeletePopconfirm}
                                      icon={
                                        <DeleteOutlined className="text-burgundy" />
                                      }
                                    />
                                  </Popconfirm>
                                </Tooltip>
                              </Space>
                            </Col>
                          </Row>
                        </>
                      </>
                    ) : (
                      <div className="p-4 m-t-40 m-b-40">
                        <Empty description={<span>No company found</span>}>
                          <Link to={'/companies/new'}>
                            <Button
                              className="ant-btn-amber"
                              shape="round"
                              size="middle"
                            >
                              Create new company
                            </Button>
                          </Link>
                        </Empty>
                      </div>
                    )}
                  </Skeleton>
                </Card>
              </Fade>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

const OverviewSubHeadingWithIcon = styled.div`
  display: flex;
  flex-direction: 'row';
  color: #bc9623 !important;
  font-weight: 500;
  margin: 0px;
  padding: 0px;

  i {
    margin-right: 5px;
    margin-bottom: 5px;
  }
  p {
    margin-bottom: 5px;
  }
`;
const MyDivider = styled(Divider)`
  margin: 10px 0px;
`;

const RowWithTagsStyle: any = styled(Row)`
  span {
    border-radius: 15px;
    padding: 2px 20px;
    color: ${(props: any) => props.fontColor || 'white'};
    margin: 3px;
  }
`;
const RowWithTransparentBorderTagsStyle: any = styled(RowWithTagsStyle)`
  span {
    border: 1px solid #2e4a79;
    margin: 3px;
  }
`;

const OverviewSection = styled.div`
  margin-bottom: 10px;
`;

export default ManageJobPage;
