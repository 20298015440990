import React, { useContext, useRef, useState } from 'react';
import { StarIcon } from 'assets/icons/DetailedProfile';
import { SectionTitle } from '../../Shared';
import { Tag, Tooltip, Typography } from 'antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import '../../styles.scss';
import { EditContext } from '../../Context/EditContext';
import { useUpdateCandidateMutation } from 'graphql/generated/graphql';
interface PropsType {
  candidate: any;
  inDrawer: any;
  onAdd?: any;
}

const Skills: React.FC<PropsType> = ({ candidate, inDrawer, onAdd }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isSeeMore, setIsSeeMore] = useState(false);
  const [wrapperHeight, setWrapperHeight] = React.useState<any>(0);
  const editContext = useContext(EditContext);
  const { setData, setUpdatedData, edit, updatedData }: any = editContext;

  const Skill = ({ title, id }: any) => {
    return (
      <Tag
        closeIcon={
          <CloseOutlined
            style={{ color: 'white' }}
            onClick={() => removeSkill(id)}
          />
        }
        // onClose={() => removeSkill(id)}
        style={{
          border: 'none',
          marginRight: 10,
          marginBottom: 10,
          backgroundColor: 'rgba(18, 100, 121, 0.8)',
          borderRadius: 20,
          color: 'white',
          paddingTop: 5,
          paddingBottom: 5,
          paddingLeft: 10,
          paddingRight: 10,
          fontSize: 14,
          fontWeight: 500,
        }}
        closable={true}
      >
        {title}
      </Tag>
    );
  };
  const [UpdateCandidateMutation, { data, loading, error }] =
    useUpdateCandidateMutation();
  const removeSkill = (value: string) => {
    const updatedSkills = editContext?.candidate?.skills?.filter(
      (skill: any) => skill._id !== value
    );
    if (!editContext?.edit) {
      UpdateCandidateMutation({
        variables: {
          candidateId: candidate?._id,
          skills: updatedSkills?.map((skill: any) => skill._id),
        },
      }).then((res) => {
        console.log(res);
      });
    }

    setData({
      ...editContext?.candidate,
      skills: editContext?.candidate.skills.filter(
        (skill: any) => skill._id != value
      ),
      customSkills: editContext?.updatedData?.customSkills?.filter(
        (skill: any) => skill != value
      ),
    });
    let customSkillsFilter = updatedData?.customSkills
      ? [
          ...editContext?.updatedData?.customSkills
            .filter((skill: any) => skill != value)
            .map((skill: any) => skill),
        ]
      : [];
    setUpdatedData({
      ...editContext?.updatedData,
      skills: [
        ...editContext?.candidate.skills
          .filter((skill: any) => skill._id != value)
          .map((skill: any) => skill._id),
      ],
      customSkills: customSkillsFilter,
    });
  };

  const SeeMore = () => {
    return (
      <Tag onClick={() => setIsSeeMore(!isSeeMore)} className="see-more-btn">
        {isSeeMore ? 'See less' : 'See more'}
      </Tag>
    );
  };

  React.useEffect(() => {
    setWrapperHeight(wrapperRef.current?.clientHeight);
  });
  return (
    <>
      {(candidate?.skills?.length > 0 || edit) && (
        <>
          <SectionTitle title={'Skills'} fontSize={16} fontWeight={600} />
          <div
            ref={wrapperRef}
            style={{
              // paddingLeft: 25,
              paddingTop: 10,
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
            }}
          >
            {candidate?.skills != null && candidate?.skills?.length > 0
              ? candidate?.skills.map(
                  (skill: any, index: any) => (
                    // index < 4 || isSeeMore ? (
                    <Skill key={index} title={skill.title} id={skill._id} />
                  )
                  // ) : null
                )
              : null}
            {updatedData?.customSkills?.map((skill: string, index: number) => (
              <Skill key={`custom_${index}`} title={skill} id={skill} />
            ))}
            {/* {candidate?.skills.length > 4 && !inDrawer ? <SeeMore /> : null} */}
            {editContext?.edit && (
              <Tooltip placement="top" title="Add more skills">
                <span onClick={onAdd} className="addIcon">
                  <PlusOutlined style={{ fontSize: 14 }} />
                </span>
              </Tooltip>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Skills;
