import { useState, useEffect } from 'react';
import { useGetAdminsQuery } from '../graphql/generated/graphql';

const useAdmins = () => {
  const [admins, setAdmins]: any = useState([]);
  const { data, loading, error } = useGetAdminsQuery();

  const loadadmins = () => {
    if (!loading && data) {
      setAdmins([...data.allAdmins]);
    }
  };

  useEffect(() => {
    loadadmins();
  }, [data]);

  return admins;
};

export default useAdmins;
