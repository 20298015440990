import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Drawer,
  Form,
  Tag,
  Select,
  Button,
  Input,
  DatePicker,
  Switch,
  Upload,
  message,
  Space,
} from 'antd';
import type { DatePickerProps } from 'antd';
import type { UploadChangeParam } from 'antd/es/upload';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import {
  CloseOutlined,
  ConsoleSqlOutlined,
  LoadingOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { EditContext } from '../Context/EditContext';
import { update } from 'lodash';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import './edit.scss';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import RequiredField from 'helpers/RequiredField';
const { Option } = Select;
interface PropsType {
  candidate: any;
  open: boolean;
  handleClose?: any;
  onUpdate: () => void;
}

interface InputPropsType {
  placeholder: string;
  value: string;
  label: string;
  name: string;
  required: boolean;
  type?: string;
}

const Styles = {
  locationsWrapper: {
    marginTop: 20,
  },
  submitBtn: {
    width: 101,
    float: 'right',
    backgroundColor: '#BC9623',
    borderRadius: 20,
    marginTop: 20,
    border: 'none',
    color: 'white',
  },
  fieldWrapper: {
    marginBottom: 20,
  },
  fieldHalfWrapper: {
    width: '46%',
    marginBottom: 20,
  },
  fieldsContainer: {
    marginTop: 10,
  },
};

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const ExperienceDrawer: React.FC<PropsType> = ({
  candidate,
  open,
  handleClose,
  onUpdate,
}) => {
  const [locations, setLocations] = useState<any>([]);
  const [current, setCurrent] = useState<any>(false);
  const editContext = useContext<any>(EditContext);
  const { setData, setUpdatedData } = editContext;
  const [form] = Form.useForm();
  const [imgLoad, setImgLoad] = useState(false);
  const locationRef = useRef<any>();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    useGoogle({
      apiKey: 'AIzaSyACXA9qapZNvq51WpF69eIH908sX_efNMs',
      options: {
        types: ['geocode'],
      },
    });
  const [value, setValue] = useState([]);
  useEffect(() => {
    setFileList([]);
  }, []);
  const onChange: UploadProps['onChange'] = (e: any) => {
    if (imgLoad) {
      setFileList(e.fileList);
    }
    if (e.file.status === 'done') {
      setImageUrl(e.file.response.secure_url);
    }
  };

  useEffect(() => {
    setCurrent(false);
  }, [open]);

  const beforeUpload = (file: any) => {
    setImgLoad(true);
    const img = ['image/jpeg', 'image/png', 'image/jpg'];
    const isImg = img.includes(file.type);
    if (!isImg) {
      setImgLoad(false);
      message.error('You can only upload images file!');
      return Upload.LIST_IGNORE;
    }
    const isLt5M = file.size / 1024 / 1024 < 2;
    if (!isLt5M) {
      setImgLoad(false);
      message.error('Image must be smaller than 2MB!');
      return Upload.LIST_IGNORE;
    }
    return isImg && isLt5M;
  };

  const onFinish = (values: any) => {
    const locationObject = values?.location.split(',');
    const obj: any = {
      city: locationObject[0],
      country:
        locationObject.length > 1
          ? locationObject[locationObject.length - 1]
          : '',
      code: '0',
      coordinates: { lat: 0.0, lng: 0.0 },
    };
    values.location = obj;
    values.startYear = new Date(values.startYear).getFullYear().toString();
    values.endYear = new Date(values.endYear).getFullYear().toString();
    values.logo = imageUrl;

    setUpdatedData({
      ...editContext?.updatedData,
      experiences: [...editContext?.candidate.experiences, values],
    });
    setData({
      ...editContext?.candidate,
      experiences: [...editContext?.candidate.experiences, values],
    });
    form.resetFields();
    handleClose();
    onUpdate();
    setFileList([]);
    setImageUrl('');
  };

  const InputField = (Props: InputPropsType) => {
    const { placeholder, value, label, name, required, type } = Props;
    return (
      <div style={Styles.fieldWrapper}>
        <label style={{ marginBottom: 0, fontSize: 14, fontWeight: 400 }}>
          <RequiredField required={required} />
          {label}
        </label>
        <Form.Item
          name={name}
          rules={[{ required: required, message: 'This field is required!' }]}
          initialValue={value}
        >
          {type == 'textarea' ? (
            <TextArea
              style={{ borderRadius: 10 }}
              autoSize={{ minRows: 3, maxRows: 6 }}
            />
          ) : (
            <Input />
          )}
        </Form.Item>
      </div>
    );
  };

  const Fields = [
    {
      name: 'position',
      required: true,
      placeholder: 'Job title',
      label: 'Present or last job title',
    },
    {
      name: 'company',
      required: true,
      placeholder: 'Company',
      label: 'Present or last company name',
    },
  ];

  const uploadButton = (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {loading ? (
        <LoadingOutlined />
      ) : (
        <PlusOutlined style={{ fontSize: 18 }} />
      )}
      <div style={{ marginLeft: 10, fontSize: 16 }}>Upload logo</div>
    </div>
  );

  const handleCurrent = (value: any) => {
    setCurrent(value);
  };
  const handleCloseDrawer = () => {
    //reset field onClose
    form.resetFields();
    handleClose();
    //reset logo onClose
    setFileList([]);
    setImageUrl(null);
    //current working field reset
    setCurrent(false);
  };
  return (
    <div>
      <Drawer
        title="Add experience"
        placement={'right'}
        // closable={true}
        onClose={handleCloseDrawer}
        visible={open}
        extra={
          <Space onClick={handleCloseDrawer} style={{ cursor: 'pointer' }}>
            <CloseOutlined />
          </Space>
        }
      >
        <div className="experience-drawer-fields">
          <Form
            form={form}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
          >
            {Fields.map((field: any, index: any) => (
              <InputField
                key={index}
                required={field.required}
                placeholder={field.placeholder}
                value={field.value}
                label={field.label}
                name={field.name}
              />
            ))}
            <div style={Styles.fieldWrapper}>
              <label style={{ marginBottom: 0, fontSize: 14, fontWeight: 400 }}>
                <RequiredField required={true} />
                Location
              </label>
              <Form.Item
                className="add-exp"
                name="location"
                rules={[
                  {
                    required: true,
                    message: 'This field is required!',
                  },
                ]}
              >
                <Select
                  showSearch
                  className="experience-location-selector"
                  // placeholder="&nbsp; Add location"

                  ref={locationRef}
                  onSearch={(value: any) => {
                    getPlacePredictions({ input: value });
                    setValue(value);
                  }}
                  onChange={(value: any) => {
                    setValue(value);
                  }}
                  loading={isPlacePredictionsLoading}
                  filterOption={(input, option: any) =>
                    option.children
                      ?.toLowerCase()
                      .indexOf(input?.toLowerCase()) >= 0
                  }
                >
                  {placePredictions &&
                    placePredictions.map((company: any) => {
                      return (
                        <Option
                          key={company.description}
                          value={company.description}
                        >
                          {company.description}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: 15,
              }}
            >
              <div className="year-selector" style={Styles.fieldHalfWrapper}>
                <label
                  style={{ marginBottom: 0, fontSize: 14, fontWeight: 400 }}
                >
                  <RequiredField required={true} />
                  Start
                </label>
                <Form.Item
                  name="startYear"
                  rules={[
                    {
                      required: true,
                      message: 'This field is required!                      ',
                    },
                  ]}
                >
                  <DatePicker picker="year" />
                </Form.Item>
              </div>
              <div className="year-selector" style={Styles.fieldHalfWrapper}>
                <label
                  style={{ marginBottom: 0, fontSize: 14, fontWeight: 400 }}
                >
                  End
                </label>
                <Form.Item
                  name="endYear"
                  dependencies={['current', 'startYear']}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const endDate = moment(value);
                        const startDate = moment(getFieldValue('startYear'));
                        if (!value && !current) {
                          return Promise.reject('End date is required!');
                        }
                        if (endDate.isBefore(startDate) && !current) {
                          return Promise.reject(
                            'End date cannot be Before the Start date!'
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                  // rules={[
                  //   {
                  //     required: current == false,
                  //     message: 'End date is required',
                  //   },
                  // ]}
                >
                  <DatePicker picker="year" disabled={current ? true : false} />
                </Form.Item>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
              }}
            >
              <div className="year-selector" style={Styles.fieldHalfWrapper}>
                <label
                  style={{ marginBottom: 0, fontSize: 14, fontWeight: 400 }}
                >
                  Currently working there
                </label>
                <Form.Item name="current">
                  <Switch onChange={handleCurrent} />
                </Form.Item>
              </div>
              <div className="logo-selector" style={Styles.fieldHalfWrapper}>
                <Form.Item name="logo">
                  <Upload
                    style={{ width: '100%', height: 95, borderRadius: 10 }}
                    action={`${process.env.REACT_APP_BASE_REST_URL}/upload/candidates/${candidate?._id}`}
                    listType="picture-card"
                    // fileList={fileList}
                    onChange={onChange}
                    data={{
                      upload_preset: 'cqdbrmkw',
                    }}
                    // onPreview={onPreview}
                    accept="image/png, image/jpeg, image/jpg"
                    multiple={false}
                    beforeUpload={beforeUpload}
                  >
                    {fileList.length == 0 && uploadButton}
                  </Upload>
                </Form.Item>
              </div>
            </div>

            <InputField
              key={'comments'}
              required={false}
              placeholder={''}
              value={''}
              label={'Comments'}
              name={'comments'}
              type={'textarea'}
            />

            <InputField
              key={'reasonForChange'}
              required={false}
              placeholder={''}
              value={''}
              label={'Reason for change'}
              name={'reasonForChange'}
              type={'textarea'}
            />

            <div>
              <Form.Item>
                <Button
                  style={{
                    width: 80,
                    float: 'right',
                    backgroundColor: '#BC9623',
                    borderRadius: 20,
                    marginTop: 0,
                    border: 'none',
                    color: 'white',
                  }}
                  htmlType="submit"
                >
                  Add
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </Drawer>
    </div>
  );
};

export default ExperienceDrawer;
