import React, { useRef, useState, useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import { Row, Col, Card, Form, Input, Select, Button } from 'antd';
import { Fade } from 'react-awesome-reveal';
import './search.scss';
const { Option } = Select;

export default function TopSearchbar(param: any) {
  const searchTextRef = useRef<InputRef>(null);
  const searchFormRef = useRef<any>(null);
  const [form] = Form.useForm();
  const [matchs, setMatchs]: any = useState(null);
  const [searchOptions, setSearchOptions] = useState<any>({
    searchText: '',
    searchType: 'All',
  });

  const onFinish = (values: any) => {
    const newSearchOptions = {
      searchText: values.searchText ? values.searchText : '',
      searchType: values.searchField ? values.searchField : 'All',
    };
    setSearchOptions(newSearchOptions);
    param.onSearch(
      newSearchOptions.searchText || '',
      newSearchOptions.searchType
    );
  };
  const onKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      form.submit();
    }
  };
  const onReset = (values: any) => {
    if (values.target?.value.length <= 0) {
      param.onSearch('', 'All');
    }
    const newSearchOptions = {
      searchText: values.searchText,
      searchType: values.searchField,
    };
    setSearchOptions(newSearchOptions);
  };

  return (
    <Row justify="center" gutter={16}>
      <Col span={24}>
        <Fade>
          <Card style={{ paddingBottom: '24px' }}>
            <Form
              ref={searchFormRef}
              form={form}
              name="control-hooks"
              onFinish={onFinish}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignContent: 'center',
                }}
                className="container-Search"
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Form.Item name="searchText">
                    <Input
                      style={{ minHeight: 50 }}
                      placeholder="Enter key words"
                      allowClear
                      ref={searchTextRef}
                      prefix={<SearchOutlined />}
                      onChange={onReset}
                      onPressEnter={() => form.submit()}
                      className="filter-search"
                    />
                  </Form.Item>
                  <Form.Item name="searchField" className="search-type">
                    <Select
                      placeholder=""
                      allowClear
                      onKeyDown={onKeyDown}
                      defaultValue={searchOptions.searchType}
                      onChange={(value) =>
                        setSearchOptions({
                          ...searchOptions,
                          searchField: value,
                        })
                      }
                      style={{ fontSize: '16px' }}
                      className="tiltle-select"
                    >
                      {!param.isJob ? (
                        <>
                          <Option value="jobTitle">Job</Option>
                          <Option value="companyName">Company</Option>
                        </>
                      ) : param?.isCompanies ? (
                        <>
                          <Option value="All">All</Option>
                          <Option value="name">Name</Option>
                          <Option value="owner.fullName">Recruiter</Option>
                        </>
                      ) : (
                        <>
                          <Option value="All">All</Option>
                          <Option value="title">Job title</Option>
                          <Option value="companyName">Company</Option>
                          <Option value="location">Location</Option>
                        </>
                      )}
                      {/* <Option value="Location">Location</Option> */}
                    </Select>
                  </Form.Item>
                  <Button
                    className="search-button"
                    htmlType="submit"
                    onClick={() => form.submit()}
                  >
                    Search
                  </Button>
                </div>
              </div>
            </Form>
          </Card>
        </Fade>
      </Col>
    </Row>
  );
}
