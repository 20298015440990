import React, { useEffect, useState } from 'react'
import { useParams, Link, useRouteMatch } from 'react-router-dom'
import IconCard from '../../../../components/UI/IconCard/IconCard'
import PageTitle from '../../../../components/UI/PageTitle/PageTitle'
import ErrorCard from '../../../../components/UI/ErrorCard/ErrorCard'
import {
  Row,
  Col,
  Card,
  Skeleton,
  Empty,
  Button,
  Avatar,
  Typography,
  List,
  Tooltip,
  Space,
  Table,
} from 'antd'
import { Fade } from 'react-awesome-reveal'
import {
  useGetSingleCompanyQuery,
  useCompanyJobsQuery,
} from '../../../../graphql/generated/graphql'
import { UserOutlined, CalendarOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import moment from 'moment'
import useCompanyRecruiters from '../../../../hooks/useCompanyRecruiters'
import { HttpState } from '../../../../store/http/types'
const { Title, Paragraph } = Typography
const { Column, ColumnGroup } = Table

interface Props {
  userIds: any
}

const RecruiterSection: React.FC<Props> = ({ userIds }) => {
  const companyRecruiters = useCompanyRecruiters(userIds)
  console.log(' 9999 ', companyRecruiters)

  return (
    <>
      {companyRecruiters && (
        <Card>
          <List
            dataSource={companyRecruiters}
            renderItem={(item: any) => (
              <List.Item key={1}>
                <Skeleton
                  loading={!companyRecruiters}
                  active
                  avatar
                  paragraph={{ rows: 1 }}
                >
                  <List.Item.Meta
                    avatar={
                      <Avatar src='https://gw.alipayobjects.com/zos/rmsportal/BiazfanxmamNRoxxVxka.png' />
                    }
                    title={
                      <Link to={`/recruiters/123451`}>{item.fullName}</Link>
                    }
                    description={item.email}
                  />
                </Skeleton>
              </List.Item>
            )}
          />
        </Card>
      )}
    </>
  )
}

export default RecruiterSection
