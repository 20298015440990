import React, { useState } from 'react';
import { Activation, Activity, BasicInfo, Status } from './Index';
import { Avatar, Col, Divider, Image, Modal, Row, Skeleton } from 'antd';
import TopBanner from '../../../../../assets/topBanner.png';
import { PlayIcon, viewProfile } from 'assets/icons/DetailedProfile';
import { SectionTitle } from '../Shared';
import { PersonalInfoDrawer } from '../Edit';
import ProfileVideo from './Component/ProfileVideo';
import Social from './Component/Social';
import Benchmark from '../Summary/Components/Benchmark';
import { Summary } from '../Summary/Index';
import SkeletonProfile from './Component/Skeleton';

const Styles = {
  root: {
    height: '100%',
    borderRadius: 10,
    // border: 'solid 1px #5B626B',
    boxShadow: '0px 0px 4px #00000040 ',
    overflow: 'hidden',
  },
  basicInfo: {},
  horizontalBar: {
    marginTop: 10,
    marginBottom: 12,
  },
  topWrapper: {
    height: '120px',
    backgroundSize: 'cover',
    paddingRight: 15,
    paddingTop: 15,
  },
  avatar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: -33,
    width: 156.8,
    height: 156.8,
    // position: 'relative'
  },
  playIcon: {
    bottom: 17,
    right: 7,
    cursor: 'pointer',
  },
  actionsWrapper: {},
};

interface PropsType {
  candidate: any;
  showActions: boolean;
  match?: any;
  showBenchmark?: Boolean;
  showActivation?: Boolean;
  loading?: any;
  refetch?: any;
}

const PersonalInfo: React.FC<PropsType> = ({
  candidate,
  showActions,
  match,
  showBenchmark,
  showActivation,
  loading,
  refetch,
}) => {
  const [open, setOpen] = useState(false);
  const [openVideo, setOpenVideo] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [imagePreview, setImagePreview] = useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  }, [candidate]);

  React.useEffect(() => {
    setIsLoading(loading);
  }, [loading]);
  const handleEdit = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const closeVideoModal = () => {
    setOpenVideo(false);
  };

  const openVideoModal = () => {
    setOpenVideo(true);
  };
  const handleImageClick = () => setImagePreview(true);
  const imagePreviewCancel = () => setImagePreview(false);
  const github = candidate?.github == '' ? null : candidate?.github;
  const facebook = candidate?.facebook == '' ? null : candidate?.facebook;
  const linkedIn = candidate?.linkedIn == '' ? null : candidate?.linkedIn;
  const twitter = candidate?.twitter == '' ? null : candidate?.twitter;
  return (
    <>
      <PersonalInfoDrawer
        candidate={candidate}
        open={open}
        handleClose={handleClose}
      />

      {candidate?.profileVideo && (
        <ProfileVideo
          open={openVideo}
          src={candidate?.profileVideo}
          handleClose={closeVideoModal}
        />
      )}
      {!isLoading ? (
        <div
          style={
            showActions == true
              ? Styles.root
              : { ...Styles.root, minHeight: '378px' }
          }
        >
          <div>
            <div
              style={{
                ...Styles.topWrapper,
                backgroundImage: `url(${
                  candidate?.coverPhoto ? candidate?.coverPhoto : TopBanner
                })`,
              }}
            >
              <SectionTitle actionHandler={handleEdit} actionType="edit" />
            </div>
            <div style={{ display: 'flex', marginLeft: '2em' }}>
              <div style={{ ...Styles.avatar, position: 'relative' }}>
                {candidate?.profilePicture ? (
                  <div
                    style={{
                      border: '8px solid white',
                      borderRadius: '80px',
                      width: '100%',
                      height: '100%',
                      backgroundSize: 'cover',
                      overflow: 'hidden',
                      backgroundPosition: 'center',
                      backgroundImage: `url(${candidate?.profilePicture})`,
                    }}
                    className="img-preview-set"
                    onClick={handleImageClick}
                  >
                    <Image
                      style={{
                        borderRadius: 220,
                        backgroundSize: 'cover',
                        overflow: 'hidden',
                        visibility: 'hidden',
                        height: '100%',
                        width: '100%',
                      }}
                      preview={false}
                      width={156.8}
                      height={156.8}
                      src={candidate?.profilePicture}
                    />
                    <div className="overlay"></div>{' '}
                    {/* Overlay element for darkness */}
                    <div className="preview-text">
                      {' '}
                      <img src={viewProfile} alt="" /> Preview
                    </div>{' '}
                    {/* Text element for "Preview" */}
                  </div>
                ) : (
                  <Avatar
                    src={
                      'https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255532-stock-illustration-profile-placeholder-male-default-profile.jpg'
                    }
                    size={124.8}
                  />
                )}
                <div style={{ ...Styles.playIcon, position: 'absolute' }}>
                  {candidate?.profileVideo && (
                    <img onClick={openVideoModal} src={PlayIcon} />
                  )}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: '2em',
                  marginTop: '1em',
                  marginRight: '10px',
                  width: '330px',
                }}
              >
                <BasicInfo candidate={candidate} />
                {candidate && <Social candidate={candidate} />}
              </div>
              <div style={{ marginTop: '2em' }}>
                {showActions && (
                  <div style={{ paddingLeft: 18, paddingRight: 18 }}>
                    {/* <hr color="#F0F0F0" style={Styles.horizontalBar} /> */}
                    {match && (
                      <div style={{ marginBottom: 10 }}>
                        <Status
                          match={match}
                          candidate={candidate}
                          isInTable={false}
                        />
                      </div>
                    )}
                    <div style={{ marginBottom: 10 }}>
                      {showActivation && (
                        <Activation
                          candidate={candidate}
                          match={match}
                          refetch={refetch}
                        />
                      )}
                    </div>
                    <div style={{ marginBottom: 0 }}>
                      <Activity candidate={candidate} match={match} />
                    </div>
                  </div>
                )}
              </div>

              {showBenchmark && (
                <Benchmark
                  candidate={candidate}
                  match={match}
                  refetch={refetch}
                />
              )}
            </div>
          </div>
          <div>
            <div
              style={{
                marginTop: '2em',
                paddingLeft: '2.5em',
              }}
            >
              <Summary candidate={candidate} isBenchmark />
            </div>
          </div>
        </div>
      ) : (
        <SkeletonProfile />
      )}
      <Modal
        className="image-preview-modal"
        open={imagePreview}
        title={candidate?.firstName + ' ' + candidate?.lastName}
        footer={null}
        onCancel={imagePreviewCancel}
        centered
      >
        <img
          src={candidate?.profilePicture}
          style={{
            width: '100%',
            height: '50vh',
            objectFit: 'contain',
            marginTop: '5px',
          }}
        />
      </Modal>
    </>
  );
};

export default PersonalInfo;
