import { NextButton, PreviousButton } from 'assets/icons/DetailedProfile';
import { useGetSingleJobQuery } from 'graphql/generated/graphql';
import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { EditContext } from '../Context/EditContext';
import { Col, Row, Skeleton } from 'antd';

interface PropsType {
  jobId: any;
}

const Styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    color: '#012653',
    fontSize: 26,
    fontWeight: 600,
  },
  navBtn: {
    width: 40,
    cursor: 'pointer',
  },
  disabledNavBtn: {
    width: 40,
    cursor: 'not-allowed',
    opacity: 0.5,
  },
};

const TopNavigation: React.FC<PropsType> = ({ jobId }) => {
  const [candidates, setCandidates] = useState<any>(null);
  const location: any = useLocation();
  const history = useHistory();
  const editContext: any = useContext(EditContext);
  const [candidateIndex, setCandidateIndex]: any = useState(null);
  const candidateArray = [...(location?.state?.candidateArray || [])];
  const [isNavigationDisabled, setIsNavigationDisabled] = useState(false);

  const { data: jobData, loading }: any = useGetSingleJobQuery({
    variables: {
      jobId: jobId,
    },
    fetchPolicy: 'network-only',
  });

  const jobTitle = jobData?.getJobDetails?.title;
  const companyName = jobData?.getJobDetails?.company?.name;
  const isJobDataAvailable = jobTitle && companyName;
  const pageTitle = isJobDataAvailable ? `${jobTitle} at ${companyName}` : null;

  useEffect(() => {
    if (location.state?.candidateArray) {
      const index = candidateArray.findIndex(
        (cand) => cand._id == location.state?.candidateId
      );
      setCandidateIndex(index);
      setCandidates(location.state?.candidateArray);

      setIsNavigationDisabled(editContext.edit);
    } else {
      goBack();
      return;
    }
  }, [location.state?.candidateId, editContext.edit]);

  const goBack = () => {
    history.push({ pathname: `/backoffice/jobs/${jobId}` });
  };

  const btnHandleNavigate = (e: 'next' | 'prev') => {
    let nextCandidate = null;
    if (e === 'next') {
      nextCandidate = candidates[candidateIndex + 1];
    } else {
      nextCandidate = candidates[candidateIndex - 1];
    }

    if (!isNavigationDisabled) {
      // Add this condition to prevent navigation when disabled
      history.push({
        pathname: `/backoffice/jobs/${jobId}/candidates/${nextCandidate._id}`,
        state: {
          candidateArray: location.state?.candidateArray,
          candidateId: nextCandidate._id,
          fromURL: location.state?.fromURL,
        },
      });
    }
  };

  return (
    <div style={Styles.root}>
      {candidates?.length > 1 && (
        <span
          onClick={() => {
            candidateIndex > 0 && btnHandleNavigate('prev');
          }}
        >
          <img
            style={
              candidateIndex === 0 ||
              candidateIndex === -1 ||
              isNavigationDisabled
                ? Styles.disabledNavBtn
                : Styles.navBtn
            }
            src={PreviousButton}
          />
        </span>
      )}

      <span style={{ ...Styles.title, textAlign: 'center', width: '100%' }}>
        {!loading ? (
          pageTitle
        ) : (
          <>
            <Row
              gutter={24}
              style={{ position: 'relative', left: '30%', marginTop: 5 }}
            >
              <Col span={24}>
                <Skeleton active paragraph={{ rows: 0, width: '100%' }} />
              </Col>
            </Row>
          </>
        )}
      </span>
      {candidates?.length > 1 && (
        <span
          onClick={() => {
            candidateIndex < candidates?.length - 1 &&
              btnHandleNavigate('next');
          }}
        >
          <img
            style={
              candidateIndex === candidates?.length - 1 || isNavigationDisabled
                ? Styles.disabledNavBtn
                : Styles.navBtn
            }
            src={NextButton}
          />
        </span>
      )}
    </div>
  );
};

export default TopNavigation;
