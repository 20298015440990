import React, { useContext, useEffect, useRef, useState } from 'react';
import { Drawer, Form, Tag, Select, Button, Space, Row, Col } from 'antd';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { useGetPersonalInterestsQuery } from 'graphql/generated/graphql';
import { EditContext } from '../Context/EditContext';
import { update } from 'lodash';

interface PropsType {
  candidate: any;
  open: boolean;
  handleClose?: any;
  onUpdate?: (data: any) => void;
}

const Styles = {
  tagsWrapper: {
    marginTop: 20,
  },
  submitBtn: {
    width: 101,
    float: 'right',
    backgroundColor: '#BC9623',
    borderRadius: 20,
    marginTop: 20,
    border: 'none',
    color: 'white',
  },
};

const ProfessionalInterestsDrawer: React.FC<PropsType> = ({
  candidate,
  open,
  handleClose,
  onUpdate,
}) => {
  const [tags, setTags] = useState<any>([]);
  const editContext = useContext(EditContext);
  const { setData, setUpdatedData }: any = editContext;
  const ref = useRef<any>(null);
  const { data, loading, error } = useGetPersonalInterestsQuery();
  const [val, setVal] = useState<any>([]);
  const [isDisable, SetIsDisable] = useState<boolean>(false);
  const [InterestedJobFileds, setInterestedJobFileds] = useState<any>([
    { _id: '608baaa02b3c3c6eea54953d', title: 'Finance', selected: false },
    { _id: '608baaaf2b3c3c6eea54953e', title: 'ITC', selected: false },
    { _id: '608baac02b3c3c6eea54953f', title: 'Engineering', selected: false },
    { _id: '608baacb2b3c3c6eea549540', title: 'Logistics', selected: false },
    {
      _id: '608baaea2b3c3c6eea549541',
      title: 'Sales',
      label1: '&',
      label2: 'Marketing',
      selected: false,
    },
    {
      _id: '608bab042b3c3c6eea549542',
      title: 'Professional',
      label1: 'Services',
      selected: false,
    },
    { _id: '608bab102b3c3c6eea549543', title: 'Retail', selected: false },
    {
      _id: '608bab272b3c3c6eea549544',
      title: 'Healthcare',
      label1: '&',
      label2: 'Medical',
      selected: false,
    },
    {
      _id: '608bab3d2b3c3c6eea549545',
      title: 'Industry',
      label1: '&',
      label2: 'Energy',
      selected: false,
    },
    { _id: '608bab4e2b3c3c6eea549546', title: 'Management', selected: false },
    { _id: '608bab602b3c3c6eea549547', title: 'HR & Legal', selected: false },
    { _id: '608bab6f2b3c3c6eea549548', title: 'Other', selected: false },
  ]);
  const [selectedTagIds, setSelectedTagIds] = useState<string[]>([]);
  useEffect(() => {
    if (!loading && data) {
      setTags(data?.getPersonalInterests);
    }
  }, [data]);
  useEffect(() => {
    setSelectedTagIds(
      candidate?.professionalInterests?.map((obj: any) => obj?._id) || []
    );
  }, [candidate]);
  const SingleTag = ({ title, color, id }: any) => {
    const isMatch = selectedTagIds?.includes(id);
    const backgroundColor = isMatch ? '#2E4A79' : '#FFFFFF';
    const matchColor = isMatch ? '#FFFFFF' : '#2E4A79';

    const handleClick = (id: any) => {
      setSelectedTagIds((prevSelectedTagIds) => {
        if (!prevSelectedTagIds.includes(id)) {
          return [...prevSelectedTagIds, id];
        } else {
          return prevSelectedTagIds.filter((tagId) => tagId !== id);
        }
      });
    };

    return (
      <div
        onClick={() => handleClick(id)}
        style={{
          display: 'flex',
          backgroundColor,
          color: matchColor,
          maxWidth: '120px',
          padding: '15px',
          border: '1px solid #2E4A79',
          borderRadius: '8px',
          marginBottom: '10px',
          height: '80px',
          cursor: 'pointer',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <span
          style={{
            lineHeight: 1.4,
            margin: '0 auto',
            wordWrap: 'break-word',
            width: '150%', // Set a specific width for the span
          }}
        >
          <span style={{ fontSize: 14, fontWeight: 700 }}>{title}</span>
          <br />
          <span style={{ fontSize: 14, fontWeight: 400 }}>Jobs</span>
        </span>
      </div>
    );
  };

  const handleData = () => {
    if (candidate?.professionalInterests.length <= 5) {
      const newTags = tags.filter((tag: any) =>
        selectedTagIds.includes(tag._id)
      ); // for update data in real time
      const newTagsIds = tags
        .filter((tag: any) => selectedTagIds.includes(tag._id))
        .map((tag: any) => tag._id); //extract ids for sending end point

      setData({
        ...editContext?.candidate,
        professionalInterests: [...newTags],
      });
      setUpdatedData({
        ...editContext?.updatedData,
        professionalInterests: newTagsIds,
      });
    }
    handleClose();
  };
  const handleCloseDrawer = () => {
    setSelectedTagIds(
      candidate?.professionalInterests?.map((obj: any) => obj?._id) || []
    );
    handleClose();
  };
  return (
    <div>
      <Drawer
        title="Edit target job fields"
        className="Ant-D-Title"
        placement={'right'}
        visible={open}
        onClose={handleCloseDrawer}
        extra={
          <Space onClick={handleData} style={{ cursor: 'pointer' }}>
            <CloseOutlined />
          </Space>
        }
      >
        <div style={{ marginTop: 8 }}>
          <Row gutter={8}>
            {InterestedJobFileds?.map((tag: any) => (
              <Col span={8} key={tag._id}>
                <SingleTag
                  key={tag._id}
                  id={tag._id}
                  title={`${tag.title}${' '}${
                    tag?.label1 !== undefined ? tag?.label1 : ''
                  }${' '}${tag?.label2 !== undefined ? tag?.label2 : ''}`}
                />
              </Col>
            ))}
          </Row>
        </div>
      </Drawer>
    </div>
  );
};

export default ProfessionalInterestsDrawer;
