import React, { useContext } from 'react';
import { Button, Drawer, Form, Input, Space } from 'antd';
import { MailIcon } from 'assets/icons/DetailedProfile';
import StrokeIcon from 'assets/icons/DetailedProfile/Stroke.svg';
import { CloseOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { useGenericResetEmailMutation } from '../../../../../graphql/generated/graphql';
import { EditContext } from '../Context/EditContext';

interface PropsType {
  candidate: any;
  open: boolean;
  handleClose?: any;
}

const ResetEmail: React.FC<PropsType> = ({ candidate, open, handleClose }) => {
  const [form] = Form.useForm();
  const [genericResetEmailMutation, { data, loading, error }] =
    useGenericResetEmailMutation();
  const editContext = useContext(EditContext);
  const { setData, setUpdatedData }: any = editContext;
  const onFinish = (values: any) => {
    const { newEmail } = values;
    genericResetEmailMutation({
      variables: {
        email: candidate?.user.email,
        newEmail: newEmail,
      },
    })
      .then(({ data }: any) => {
        handleClose();
        message.success({
          content: 'Email reset successfuly!',
          duration: 5,
        });
        setUpdatedData({
          ...editContext?.updatedData,
        });
        setData({
          ...editContext?.candidate,
          user: {
            ...editContext?.candidate.user,
            email: newEmail, // Set the new email here
          },
        });
        form.resetFields();
      })
      .catch((err: any) => {
        message.error({
          content: err?.message?.split(':')[1],
          duration: 5,
        });
      });
  };
  const handleCloseDrawer = () => {
    form.resetFields();
    handleClose();
  };
  return (
    <div>
      <Drawer
        title="Reset Email"
        placement={'right'}
        // closable={true}
        onClose={handleCloseDrawer}
        visible={open}
        extra={
          <Space onClick={handleCloseDrawer} style={{ cursor: 'pointer' }}>
            <CloseOutlined />
          </Space>
        }
      >
        <Form
          onFinish={onFinish}
          autoComplete="off"
          initialValues={{ remember: true }}
          form={form}
        >
          {/* new email */}
          <label style={{ fontWeight: 500, marginBottom: 0, color: '#495057' }}>
            Email address
          </label>
          <Form.Item
            name="newEmail"
            rules={[
              {
                required: true,
                type: 'email',
                message: (
                  <span>
                    {' '}
                    <img
                      src={StrokeIcon}
                      alt="StrokeIcon"
                      style={{
                        marginRight: '4px',
                        marginTop: '-2px',
                        height: '13px',
                        width: '13px',
                      }}
                    />
                    Please enter a valid email address.
                  </span>
                ),
              },
            ]}
          >
            <Input
              placeholder="Enter your new email"
              prefix={<img src={MailIcon} />}
            />
          </Form.Item>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Form.Item style={{ margin: 0 }}>
              <Button
                htmlType="submit"
                style={{
                  height: 31,
                  width: 101,
                  backgroundColor: '#BC9623',
                  borderRadius: 40,
                  color: 'white',
                  border: 'none',
                  marginTop: 10,
                }}
              >
                Submit
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Drawer>
    </div>
  );
};

export default ResetEmail;
