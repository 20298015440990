import React, { useState, useEffect } from 'react';
import PageTitle from '../../../../components/UI/PageTitle/PageTitle';

import {
  Row,
  Col,
  Tabs,
  Card,
  Modal,
  Form,
  Input,
  Button,
  notification,
} from 'antd';

import { Fade } from 'react-awesome-reveal';

import ActiveSkillList from './Components/ActiveSkillList';
import ArchivedSkillList from './Components/ArchivedSkillList';
import PendingSkillList from './Components/PendingSkillList';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import {
  MoveToActiveSkillsFromArchivedSkills,
  MoveToDeletedSkillsFromArchivedSkills,
  MoveToDeletedSkillsFromPendingSkills,
  MoveToDeletedSkillsFromActiveSkills,
  MoveToActiveSkillsFromPendingSkills,
  MoveToArchivedSkillsFromActiveSkills,
  MoveToActiveSkillsFromNewSkills,
  UpdateActiveSkillTitle,
  UpdatePendingSkillTitle,
} from '../../../../store/skills/actions';

import {
  useAddSkillsMutation,
  useUpdateStatusMutation,
} from '../../../../graphql/generated/graphql';

const { TabPane } = Tabs;

const SkillLists: React.FC = () => {
  const [showPopup, setShowPopup] = useState(false);

  const dispatch = useDispatch();

  const triggerPopup = () => setShowPopup(!showPopup);
  const [form] = Form.useForm();

  const [addSkillsMutation, { data, loading, error }] = useAddSkillsMutation();

  const [updateStatusMutation] = useUpdateStatusMutation();

  const actionHandler = (
    type: string,
    id: string,
    value: any,
    from?: string
  ) => {
    if (type === 'new') {
      dispatch(MoveToActiveSkillsFromNewSkills(value));
    } else if (type === 'edit') {
      if (from == 'active') {
        dispatch(UpdateActiveSkillTitle(id, value.title));
      } else if (from == 'pending') {
        dispatch(UpdatePendingSkillTitle(id, value.title));
      }
    } else if (type === 'archive') {
      updateStatusMutation({
        variables: {
          skillId: id,
          status: value.status,
          isArchived: true,
          isDeleted: false,
        },
      })
        .then(({ data }) => {
          if (data?.updateStatus) {
            notification['success']({
              message: 'Archived successfully.',
              duration: 3,
              description: `Skill has been archived successfully`,
            });
            dispatch(MoveToArchivedSkillsFromActiveSkills(id));
          }
          return;
        })
        .catch((err) => {
          const errors = err.graphQLErrors.map((el: any) => el.message);
          notification['error']({
            message: 'Unable to update the status of skill to approved',
            description: errors.map((er: any) => <div key={er}>{er}</div>),
            duration: 3,
          });
          return;
        });
    } else if (type === 'delete') {
      updateStatusMutation({
        variables: {
          skillId: id,
          status: value.status,
          isArchived: false,
          isDeleted: true,
        },
      })
        .then(({ data }) => {
          if (data?.updateStatus) {
            notification['success']({
              message: 'Deleted successfully.',
              duration: 3,
              description: `Skill has been deleted successfully`,
            });
            if (from === 'archived') {
              dispatch(MoveToDeletedSkillsFromArchivedSkills(id));
            } else if (from === 'pending') {
              dispatch(MoveToDeletedSkillsFromPendingSkills(id));
            } else if (from === 'active') {
              dispatch(MoveToDeletedSkillsFromActiveSkills(id));
            }
          }
          return;
        })
        .catch((err) => {
          const errors = err.graphQLErrors.map((el: any) => el.message);
          notification['error']({
            message: 'Unable to update the status of skill to approved',
            description: errors.map((er: any) => <div key={er}>{er}</div>),
            duration: 3,
          });
          return;
        });
    } else if (type === 'rollback') {
      updateStatusMutation({
        variables: {
          skillId: id,
          status: 'Approved',
          isArchived: false,
          isDeleted: false,
        },
      })
        .then(({ data }) => {
          if (data?.updateStatus) {
            notification['success']({
              message: 'Rollback successfully.',
              duration: 3,
              description: `Skill has been roll backed successfully`,
            });
            dispatch(MoveToActiveSkillsFromArchivedSkills(id));
          }
          return;
        })
        .catch((err) => {
          const errors = err.graphQLErrors.map((el: any) => el.message);
          notification['error']({
            message: 'Unable to update the status of skill to approved',
            description: errors.map((er: any) => <div key={er}>{er}</div>),
            duration: 3,
          });
          return;
        });
    } else if (type === 'approve') {
      updateStatusMutation({
        variables: {
          skillId: id,
          status: 'Approved',
          isArchived: false,
          isDeleted: false,
        },
      })
        .then(({ data }) => {
          if (data?.updateStatus) {
            notification['success']({
              message: 'Approved successfully.',
              duration: 3,
              description: `Skill has been approved successfully`,
            });
            dispatch(MoveToActiveSkillsFromPendingSkills(id));
          }
          return;
        })
        .catch((err) => {
          const errors = err.graphQLErrors.map((el: any) => el.message);
          notification['error']({
            message: 'Unable to update the status of skill to approved',
            description: errors.map((er: any) => <div key={er}>{er}</div>),
            duration: 3,
          });
          return;
        });
    }
  };

  const cancelPopup = () => {
    setShowPopup(false);
  };

  const submitSkills = () => {
    const { skillNames } = form.getFieldsValue();
    form.validateFields();

    if ((skillNames && skillNames.length < 1) || !skillNames) return;

    addSkillsMutation({
      variables: {
        title: skillNames,
      },
    })
      .then(({ data }) => {
        if (data?.createSkills && data?.createSkills.length > 0) {
          notification['success']({
            message: 'Skills created successfully.',
            duration: 3,
            description: `${data?.createSkills.length} has been added successfully`,
          });
        }
        actionHandler('new', 'null', data?.createSkills);
        cancelPopup();
        form.resetFields();
        return;
      })
      .catch((err) => {
        const errors = err.graphQLErrors.map((el: any) => el.message);
        notification['error']({
          message: 'Unable to create skill',
          description: errors.map((er: any) => <div key={er}>{er}</div>),
          duration: 3,
        });
        return;
      });
  };

  return (
    <>
      {showPopup && (
        <Modal
          centered={true}
          style={{ borderRadius: 30 }}
          title={[<h2 key={9}>Create new skills</h2>]}
          visible={showPopup}
          onOk={() => setShowPopup(false)}
          onCancel={() => setShowPopup(false)}
          footer={[
            <Button
              key="back"
              style={{ borderRadius: '1.25rem' }}
              onClick={cancelPopup}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              htmlType="submit"
              loading={loading}
              style={{
                backgroundColor: '#bc9623',
                borderRadius: '1.25rem',
                border: 'none',
              }}
              onClick={submitSkills}
            >
              Submit
            </Button>,
          ]}
        >
          <Form form={form} name="multpleSkill">
            <Form.List
              name="skillNames"
              rules={[
                {
                  validator: async (_, names) => {
                    if (!names || names.length == 0) {
                      return Promise.reject(
                        new Error('At least 1 skill required')
                      );
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <Form.Item label={''} required={false} key={field.key}>
                      <Form.Item
                        {...field}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: 'Please input skill name.',
                          },
                        ]}
                        noStyle
                      >
                        <Input
                          placeholder="Skill name"
                          style={{ width: fields.length > 1 ? '91%' : '100%' }}
                        />
                      </Form.Item>
                      {fields.length > 1 ? (
                        <MinusCircleOutlined
                          className="dynamic-delete-button"
                          onClick={() => remove(field.name)}
                        />
                      ) : null}
                    </Form.Item>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      style={{
                        borderRadius: '1.25rem',
                        color: '#2e4a79',
                        width: '100%',
                      }}
                      icon={<PlusOutlined />}
                    >
                      Add more fields
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form>
        </Modal>
      )}
      <PageTitle
        back
        title="List of skills"
        btnText="Add skill"
        btnActive={true}
        btnCallback={triggerPopup}
      />
      <Row justify="center" className="pb-4" gutter={16}>
        <Col span={24}>
          <Fade>
            <Card>
              <Tabs defaultActiveKey="1">
                <TabPane tab={<span>Active skills</span>} key="1">
                  <ActiveSkillList actionHandler={actionHandler} />
                </TabPane>
                <TabPane tab={<span>Pending for approval</span>} key="2">
                  <PendingSkillList actionHandler={actionHandler} />
                </TabPane>
                <TabPane tab={<span>Archived</span>} key="3">
                  <ArchivedSkillList actionHandler={actionHandler} />
                </TabPane>
              </Tabs>
            </Card>
          </Fade>
        </Col>
      </Row>
    </>
  );
};

export default SkillLists;
