import { SearchOutlined } from '@ant-design/icons';
import {
  Button,
  Input,
  notification,
  Popconfirm,
  Space,
  Table,
  Tag,
  Tooltip,
  Popover,
} from 'antd';
import RecruiterContent from './RecruiterContent';
import { ColumnsType } from 'antd/es/table';
import {
  useAllPendingJobsLazyQuery,
  useDeleteJobMutation,
  useGetCurrentUserQuery,
  useUpdateJobIsFavouriteMutation,
  useUpdateJobStatusMutation,
} from 'graphql/generated/graphql';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { StorePendingJobs, StoreJob } from 'store/jobs/actions';
import { JobsState } from 'store/jobs/types';
import PopoverText from 'helpers/PopOver';

interface PropsType {
  count: any;
  searchParams: any;
  currentTab: any;
  AllJobsCountHandler: any;
  realtimeCountHandler: any;
}

const PendingJobsTable: React.FC<PropsType> = ({
  count,
  currentTab,
  searchParams,
  AllJobsCountHandler,
  realtimeCountHandler,
}) => {
  const history = useHistory();
  const [archiveVisible, setArchiveVisible] = React.useState(false);
  const [deleteVisible, setDeleteVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [hasError, setHasError] = useState(false);

  // const [loading, setLoading] = useState(true);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [updateJobStatusMutation, UpdateJobStatus] =
    useUpdateJobStatusMutation();

  const [allJobs, setallJobs] = useState<any>([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    // total: 0,
  });
  const [sorting, setSorting] = useState({
    column: '',
    order: 'ascend',
  });

  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const [pageOption, setPageOption] = useState<any>({
    size: 10,
    current: 1,
  });
  const { data: currentUser } = useGetCurrentUserQuery({
    fetchPolicy: 'network-only',
  });
  const [fetchallJobs, { data: dataPendingJobs, loading }]: any =
    useAllPendingJobsLazyQuery();
  const currentUserId = currentUser?.currentUser?._id;
  const jobs = useSelector((state: JobsState) => state.jobs?.pendingJobs);

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.field === 'link') {
      sorter.field = 'title';
    }
    if (sorter.field === '_id') {
      sorter.field = 'favouriteBy ';
    }
    setPagination({
      ...pagination,
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
    setSorting({
      column: sorter.field,
      order: sorter.order,
    });
  };

  useEffect(() => {
    fetchallJobs({
      variables: {
        pageSize: 10,
        page: 1,
        searchText: searchParams?.searchText,
        searchField: searchParams?.searchType,
      },
    });
  }, [searchParams, currentUser]);

  useEffect(() => {
    currentTab == 'Pending Jobs' &&
      fetchallJobs({
        variables: {
          pageSize: 10,
          page: 1,
          userId: currentUserId,
          searchText: searchParams?.searchText,
          searchField: searchParams?.searchType,
        },
      });
  }, [searchParams, currentTab]);

  useEffect(() => {
    if (!loading && dataPendingJobs?.AllPendingJobs) {
      dispatch(StorePendingJobs(dataPendingJobs?.AllPendingJobs));
    }
  }, [dataPendingJobs]);

  useEffect(() => {
    if (jobs) {
      if (!sorting.column) {
        const allJobs_ = jobs
          .filter((job: any) => job.status == 'pending')
          .sort((a: any, b: any) => (a.createdAt > b.createdAt && -1) || 1)
          .sort(
            (a: any, b: any) =>
              (a.favouriteBy?.includes(currentUserId) && -1) || 1
          );
        setallJobs(allJobs_);
      } else {
        setallJobs(jobs);
      }
    }
  }, [jobs, currentUser, dispatch]);

  useEffect(() => {
    // fetchActiveJobs(pagination.current, pagination.pageSize, sorting.column, sorting.order);
    fetchallJobs({
      variables: {
        pageSize: pagination.pageSize,
        page: pagination.current,
        userId: currentUserId,
        sortingColumn: sorting.column,
        sortingOrder: sorting.order,
        searchText: searchParams?.searchText,
        searchField: searchParams?.searchType,
      },
    });
  }, [pagination.current, pagination.pageSize, sorting]);

  const updateJobStatusHandler = (jobId: any, status: string) => {
    setConfirmLoading(true);
    updateJobStatusMutation({
      variables: {
        jobId: jobId,
        status: status,
      },
    })
      .then(({ data }) => {
        dispatch(StoreJob({ ...data?.updateJobStatus }));
        const pendingJobsStatus: any = jobs
          ?.map((job: any) => {
            if (job?._id == jobId) {
              job.status = status;
            }
            return job;
          })
          .filter((job: any) => job && job.status === 'pending');
        setallJobs(pendingJobsStatus);
        dispatch(StorePendingJobs(pendingJobsStatus));
        notification['success']({
          message: 'Job status updated successfully.',
          // onClose: () =>
          //   history.push(`/job-listings/edit/${jobId}`),
          description: `Your Job status has been to "${status}" now.`,
        });
        realtimeCountHandler(`${status}FromPendingTable`);
        setArchiveVisible(false);
        setConfirmLoading(false);
      })
      .catch((err) => {
        const errors = err.graphQLErrors.map((el: any) => el.message);
        notification['error']({
          message: 'Unable to create job',
          description: errors.map((er: any) => <div key={er}>{er}</div>),
          duration: 3,
          onClose: () => setHasError(false),
        });
      });
  };
  const onActiveChange = (page: number, pageSize: number) => {
    setPageOption({
      pageSize: pageSize,
      current: page,
    });
    fetchallJobs({
      variables: {
        pageSize: pageSize,
        page: page,
      },
    });
  };

  const pendingActionsHandler = (_id: any) => {
    return (
      <Space>
        <Tooltip title="Activate">
          <Popconfirm
            title="Are you sure you want to activate this job?"
            okText="Yes"
            cancelText="No"
            // visible={archiveVisible}
            onConfirm={() => updateJobStatusHandler(_id, 'active')}
            okButtonProps={{ loading: confirmLoading }}
            onCancel={handleArchiveCancel}
          >
            <div className="btn-approve"></div>
          </Popconfirm>
        </Tooltip>
        <Tooltip title="Reject">
          <Popconfirm
            title="Are you sure you want to reject this job?"
            okText="Yes"
            cancelText="No"
            placement="topLeft"
            onConfirm={() => updateJobStatusHandler(_id, 'rejected')}
            okButtonProps={{ loading: confirmLoading }}
            onCancel={handleDeleteCancel}
          >
            <div className="btn-dislike"></div>
          </Popconfirm>
        </Tooltip>
        <Tooltip title="Archive">
          <Popconfirm
            title="Are you sure archive this job?"
            okText="Yes"
            cancelText="No"
            placement="topLeft"
            onConfirm={() => updateJobStatusHandler(_id, 'archive')}
            okButtonProps={{ loading: confirmLoading }}
            onCancel={handleArchiveCancel}
          >
            <div className="btn-archive"></div>
          </Popconfirm>
        </Tooltip>
      </Space>
    );
  };

  const generateJobs = () => {
    if (allJobs) {
      const jobsData = allJobs.map((job: any) => {
        if (job?.recruitersDetail?.length == 0) {
          return {
            key: job?._id,
            _id: job?._id,
            // logoUrl: job.logoUrl,
            name: job?.title,
            link: { id: job?._id, title: job?.title },
            // companyName: <PopoverText country={job.company.name} isTag={false} />,
            companyName: { title: job?.company?.name },
            recruiters: job?.recruiters ? (
              <>
                {
                  /* {
                job.recruiters.map((el: any) => {
                  return (
                    <Tag key={el.email} style={{ marginBottom: 5 }}>
                      {el.email}
                    </Tag>
                  );
                }) */
                  <Button
                    style={{
                      border: 'none',
                      width: 40,
                      height: 40,
                      padding: 'unset',
                      backgroundColor: '#BC9623',
                      borderRadius: '50%',
                      color: '#FFFFFF',
                      outline: 'none',
                    }}
                    // className="ant-btn-teal"
                  >
                    {' '}
                    {job?.recruiters?.length}{' '}
                  </Button>
                  // <Button
                  //   style={{
                  //     border: 'none',
                  //     width: 40,
                  //     height: 40,
                  //     padding: 'unset',
                  //     backgroundColor: '#BC9623',
                  //     borderRadius: '50%',
                  //     color: '#FFFFFF',
                  //     outline: 'none',
                  //   }}
                  //   // className="ant-btn-teal"
                  // >
                  //   {' '}
                  //   {job?.recruiters?.length}{' '}
                  // </Button>
                }
              </>
            ) : (
              0
            ),
            countries: job?.countries?.map((countrySigle: any, index: any) => {
              if (countrySigle) {
                if (
                  job?.countries.length > 1 &&
                  index + 1 < job?.countries.length
                )
                  return countrySigle?.name + ', ';
                else return countrySigle?.name;
              }
            }),
            // title: job.owner.title,
            // email: job.createdBy.email,
            // jobCount: job.totalJobs,
            // recruiterCount: job.users.length,
            createdAt: moment(parseInt(job.createdAt)).format('DD/MM/YY'),
          };
        } else {
          return {
            key: job._id,
            _id: job._id,
            // logoUrl: job.logoUrl,
            name: job.title,
            link: { id: job?._id, title: job?.title },
            // companyName: <PopoverText country={job.company.name} isTag={false} />,
            companyName: { title: job?.company?.name },
            recruiters: job.recruiters ? (
              <>
                {
                  /* {
                job.recruiters.map((el: any) => {
                  return (
                    <Tag key={el.email} style={{ marginBottom: 5 }}>
                      {el.email}
                    </Tag>
                  );
                }) */
                  <Popover
                    trigger="click"
                    placement="bottom"
                    showArrow={false}
                    content={() => RecruiterContent(job)}
                  >
                    <Button
                      style={{
                        border: 'none',
                        width: 40,
                        height: 40,
                        padding: 'unset',
                        backgroundColor: '#BC9623',
                        borderRadius: '50%',
                        color: '#FFFFFF',
                        outline: 'none',
                      }}
                      // className="ant-btn-teal"
                    >
                      {' '}
                      {job?.recruiters?.length}{' '}
                    </Button>
                  </Popover>
                  // <Button
                  //   style={{
                  //     border: 'none',
                  //     width: 40,
                  //     height: 40,
                  //     padding: 'unset',
                  //     backgroundColor: '#BC9623',
                  //     borderRadius: '50%',
                  //     color: '#FFFFFF',
                  //     outline: 'none',
                  //   }}
                  //   // className="ant-btn-teal"
                  // >
                  //   {' '}
                  //   {job?.recruiters?.length}{' '}
                  // </Button>
                }
              </>
            ) : (
              0
            ),
            countries: job?.countries?.map((countrySigle: any, index: any) => {
              if (countrySigle) {
                if (
                  job?.countries.length > 1 &&
                  index + 1 < job?.countries.length
                )
                  return countrySigle?.name + ', ';
                else return countrySigle?.name;
              }
            }),
            // title: job.owner.title,
            // email: job.createdBy.email,
            // jobCount: job.totalJobs,
            // recruiterCount: job.users.length,
            createdAt: moment(parseInt(job.createdAt)).format('DD/MM/YY'),
          };
        }
        //   key: job._id,
        //   _id: job._id,
        //   // logoUrl: job.logoUrl,
        //   name: job.title,
        //   link: { id: job?._id, title: job?.title },
        //   // companyName: <PopoverText country={job.company.name} isTag={false} />,
        //   companyName: { title: job?.company?.name },
        //   recruiters: job.recruiters ? (
        //     <>
        //       {
        //         /* {
        //       job.recruiters.map((el: any) => {
        //         return (
        //           <Tag key={el.email} style={{ marginBottom: 5 }}>
        //             {el.email}
        //           </Tag>
        //         );
        //       }) */
        //       <Popover trigger='click' showArrow={false} content={()=>RecruiterContent(job)}>
        //     <Button
        //       style={{
        //         border: 'none',
        //         width: 40,
        //         height: 40,
        //         padding: 'unset',
        //         backgroundColor: '#BC9623',
        //         borderRadius: '50%',
        //         color: '#FFFFFF',
        //         outline: 'none',
        //       }}
        //       // className="ant-btn-teal"
        //     >
        //       {' '}
        //       {job?.recruiters?.length}{' '}

        //     </Button>
        //    </ Popover>
        //         // <Button
        //         //   style={{
        //         //     border: 'none',
        //         //     width: 40,
        //         //     height: 40,
        //         //     padding: 'unset',
        //         //     backgroundColor: '#BC9623',
        //         //     borderRadius: '50%',
        //         //     color: '#FFFFFF',
        //         //     outline: 'none',
        //         //   }}
        //         //   // className="ant-btn-teal"
        //         // >
        //         //   {' '}
        //         //   {job?.recruiters?.length}{' '}
        //         // </Button>
        //       }
        //     </>
        //   ) : (
        //     0
        //   ),
        //   countries: job?.countries?.map((countrySigle: any, index: any) => {
        //     if (countrySigle) {
        //       if (
        //         job?.countries.length > 1 &&
        //         index + 1 < job?.countries.length
        //       )
        //         return countrySigle?.name + ', ';
        //       else return countrySigle?.name;
        //     }
        //   }),
        //   // title: job.owner.title,
        //   // email: job.createdBy.email,
        //   // jobCount: job.totalJobs,
        //   // recruiterCount: job.users.length,
        //   createdAt: moment(parseInt(job.createdAt)).format('DD/MM/YY'),
        // };
      });
      return jobsData;
    }
  };
  const handleArchiveCancel = () => {
    setArchiveVisible(false);
  };

  const handleDeleteCancel = () => {
    setDeleteVisible(false);
  };

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    // confirm();
    // setSearchText(selectedKeys[0]);
    // setSearchedColumn(dataIndex);
    fetchallJobs({
      variables: {
        pageSize: 10,
        page: 1,
        searchText: selectedKeys[0],
        searchField: dataIndex == 'link' ? 'title' : 'company.name',
      },
    });
  };

  const handleReset = (clearFilters: any) => {
    // clearFilters();
    // setSearchText('');
    fetchallJobs({
      variables: {
        pageSize: 10,
        page: 1,
        searchText: '',
        searchField: '',
      },
    });
  };
  let searchInput: any;

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: any, record: any) => {
      if (dataIndex === 'link') {
        return record[dataIndex]?.props?.children
          ?.toString()
          ?.toLowerCase()
          ?.includes(value.toLowerCase());
      } else if (dataIndex === 'recruiters' || dataIndex === 'countries') {
        const foundResults = record[dataIndex]?.props?.children?.filter(
          (el: any) => {
            return el.key
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase());
          }
        );
        return foundResults?.length ? record[dataIndex] : '';
      } else {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    },
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
    render: (text: any) => {
      if (searchedColumn === dataIndex) {
        if (searchedColumn === 'link') {
          // for name its a <Link /> element
          const textHighlight = text?.props?.children;
          return (
            <Link to={text?.props?.to}>
              <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={textHighlight ? textHighlight.toString() : ''}
              />
            </Link>
          );
        } else if (
          searchedColumn === 'recruiters' ||
          searchedColumn === 'countries'
        ) {
          // also jsx elements
          return text?.props?.children?.map((el: any) => {
            // Need to highlight text inside the tag
            return (
              <Tag key={el.key} style={{ marginBottom: 5 }}>
                <Highlighter
                  highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                  searchWords={[searchText]}
                  autoEscape
                  textToHighlight={el.key ? el.key.toString() : ''}
                />
              </Tag>
            );
          });
        } else {
          // for default text only cases
          return (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          );
        }
      } else {
        return text;
      }
    },
  });

  const columnsPending: ColumnsType<any> = [
    {
      title: 'Job',
      dataIndex: 'link',
      key: 'link',
      // fixed: 'left',
      width: 280,
      showSorterTooltip: false,
      // sorter: (a: any, b: any) => a?.link?.title?.localeCompare(b?.link?.title),
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      // defaultSortOrder: 'ascend',
      // ...getColumnSearchProps('link'),
      render: (company) => {
        return (
          <Link to={`${url}/${company?.id}`}>
            <PopoverText
              country={company?.title}
              isTag={false}
              event={'hover'}
            />
          </Link>
        );
      },
    },
    {
      title: 'Company',
      dataIndex: 'companyName',
      key: 'companyName',
      width: 280,
      showSorterTooltip: false,
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      // defaultSortOrder: 'ascend',
      // sorter: (a: any, b: any) =>
      //   a?.companyName?.title?.localeCompare(b?.companyName?.title),
      render: (company) => {
        return (
          <PopoverText country={company?.title} isTag={false} event="hover" />
        );
      },
    },
    {
      title: 'Location',
      key: 'countries',
      dataIndex: 'countries',
      // ...getColumnSearchProps('countries'),
      width: 280,
      // ...getColumnSearchProps('countries'),
      render: (address) => (
        <PopoverText country={address} isTag={false} event="hover" />
      ),
    },
    // {
    //   title: 'Candidates',
    //   dataIndex: 'id',
    //   width: 170,
    //   key: 'candidates',
    //   render: (_id, row: any) => (
    //     <>
    //       <Link to={`/candidate-listings/${_id}`}>
    //         <Button className="ant-btn-teal">
    //           {row.candidates ? row.candidates.length : 0} Candidates
    //         </Button>
    //       </Link>
    //     </>
    //   ),
    // },
    {
      title: 'Recruiters',
      key: 'recruiters',
      dataIndex: 'recruiters',
      width: 150,
      // ...getColumnSearchProps('recruiters'),
    },
    {
      title: 'Created on',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'descend',
      // render: (createdAt: any, row: any) =>
      //   moment(parseInt(createdAt)).format('DD MMM YYYY'),
      width: 200,
    },
    {
      title: 'Action',
      key: '_id',
      dataIndex: '_id',
      fixed: 'right',
      width: 180,
      render: pendingActionsHandler,
    },
  ];
  useEffect(() => {
    AllJobsCountHandler({ PendingJobs: allJobs[0]?.count });
  }, [allJobs]);

  return (
    <Table
      className="table-striped"
      columns={columnsPending}
      dataSource={generateJobs()}
      loading={loading}
      size="small"
      pagination={{
        total: count?.pendingJobsCount || allJobs[0]?.count,
        current: pageOption.current,
        pageSize: pageOption.size,
        onChange: onActiveChange,
      }}
      onChange={handleTableChange}
      // scroll={{ x: 1300 }}
    />
  );
};

export default PendingJobsTable;
