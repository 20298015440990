import {
  Matchs,
  matchsActionTypes,
  STORE_NEW_CANDIDATES,
  STORE_LIKED_CANDIDATES,
  MOVED_TO_MATCHED_CANDIDATES,
  STORE_DISLIKED_CANDIDATES,
  STORE_MATCHED_CANDIDATES, 
  MOVED_TO_LIKED_CANDIDATES,
  MOVED_TO_DISLIKED_CANDIDATES,
  MOVED_TO_DISLIKED_FROM_LIKED_CANDIDATES,
  MOVED_TO_LIKED_FROM_DISLIKED_CANDIDATES,
  MOVED_TO_DISLIKED_FROM_MATCHED_CANDIDATES
} from "./types";
import _ from 'lodash';

import { updateObject } from "../../helpers/updateObject";

const initialState: Matchs = {
  newCandidates: [],
  disLikedCandidates: [],
  likedCandidates: [],
  matchedCandidates: [],
};

const storeLikedCandidates = (state: Matchs, action: any) => {
  debugger;
  return updateObject(state, { likedCandidates: action.likedCandidates });
};

const moveToLikedFromNewCandidates = (state: Matchs, action: any) => {
  const likedCandidates = [...state.likedCandidates];
  let newCandidates = [...state.newCandidates];
  const candidate = newCandidates.find((c:any) => c._id === action.candidateId);
  newCandidates = newCandidates.filter((c: any) => c._id !== action.candidateId)
  likedCandidates.push(candidate);

  return updateObject(state, { newCandidates: newCandidates, likedCandidates: likedCandidates });
};

const moveToMatchedFromNewCandidates = (state: Matchs, action: any) => {
  const matchedCandidates = [...state.matchedCandidates];
  let newCandidates = [...state.newCandidates];
  const candidate = newCandidates.find((c:any) => c._id === action.candidateId);
  newCandidates = newCandidates.filter((c: any) => c._id !== action.candidateId)
  matchedCandidates.push(candidate);

  return updateObject(state, { newCandidates: newCandidates, matchedCandidates: matchedCandidates });
};

const moveToLikedFromDislikedCandidates = (state: Matchs, action: any) => {
  const likedCandidates = [...state.likedCandidates];
  let disLikedCandidates = [...state.disLikedCandidates];
  const candidate = disLikedCandidates.find((c:any) => c._id === action.candidateId);
  disLikedCandidates = disLikedCandidates.filter((c: any) => c._id !== action.candidateId)
  likedCandidates.push(candidate);

  return updateObject(state, { disLikedCandidates: disLikedCandidates, likedCandidates: likedCandidates });
};

const moveToDisLikedFromMatchedCandidates = (state: Matchs, action: any) => {
  const disLikedCandidates = [...state.disLikedCandidates];
  let matchedCandidates = [...state.matchedCandidates];
  const candidate = matchedCandidates.find((c:any) => c._id === action.candidateId);
  matchedCandidates = matchedCandidates.filter((c: any) => c._id !== action.candidateId)
  disLikedCandidates.push(candidate);

  return updateObject(state, { matchedCandidates: matchedCandidates, disLikedCandidates: disLikedCandidates });
};

const moveToDisLikedFromNewCandidates = (state: Matchs, action: any) => {
  debugger;
  const disLikedCandidates = [...state.disLikedCandidates];
  let newCandidates = [...state.newCandidates];
  const candidate = newCandidates.find((c:any) => c._id === action.candidateId);
  newCandidates = newCandidates.filter((c: any) => c._id !== action.candidateId)
  disLikedCandidates.push(candidate);

  return updateObject(state, { newCandidates: newCandidates, disLikedCandidates: disLikedCandidates });
};

const moveToDisLikedFromLikedCandidates = (state: Matchs, action: any) => {
  const disLikedCandidates = [...state.disLikedCandidates];
  let likedCandidates = [...state.likedCandidates];
  const candidate = likedCandidates.find((c:any) => c._id === action.candidateId);
  likedCandidates = likedCandidates.filter((c: any) => c._id !== action.candidateId)
  disLikedCandidates.push(candidate);

  return updateObject(state, { likedCandidates: likedCandidates, disLikedCandidates: disLikedCandidates });
};

const storeDisLikedCandidates = (state: Matchs, action: any) => {
  return updateObject(state, { disLikedCandidates: action.disLikedCandidates || [] });
};

const storeNewCandidates = (state: Matchs, action: any) => {
  return updateObject(state, { newCandidates: action.newCandidates || [] });
};

const storeMatchedCandidates = (state: Matchs, action: any) => {
  return updateObject(state, { matchedCandidates: action.matchedCandidates });
};

const matchsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case STORE_NEW_CANDIDATES:
      return storeNewCandidates(state, action);
    case STORE_LIKED_CANDIDATES:
      return storeLikedCandidates(state, action);
    case STORE_DISLIKED_CANDIDATES:
      return storeDisLikedCandidates(state, action);
    case STORE_MATCHED_CANDIDATES:
      return storeMatchedCandidates(state, action);
    case MOVED_TO_LIKED_CANDIDATES:
      return moveToLikedFromNewCandidates(state, action);
    case MOVED_TO_MATCHED_CANDIDATES:
      return moveToMatchedFromNewCandidates(state, action);
    case MOVED_TO_DISLIKED_CANDIDATES:
      return moveToDisLikedFromNewCandidates(state, action);
    case MOVED_TO_DISLIKED_FROM_LIKED_CANDIDATES:
      return moveToDisLikedFromLikedCandidates(state, action);
    case MOVED_TO_LIKED_FROM_DISLIKED_CANDIDATES:
      return moveToLikedFromDislikedCandidates(state, action);
    case MOVED_TO_DISLIKED_FROM_MATCHED_CANDIDATES:
      return moveToDisLikedFromMatchedCandidates(state, action);
    default:
      return state;
  }
};

export default matchsReducer;
