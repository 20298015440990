import { Tag, Typography } from 'antd';
import objective from 'assets/icons/profilesvgs/objective.svg';
import React, { useContext, useState } from 'react';
import MyobjectiveDrawer from '../Edit/MyobjectiveDrawer';
import { EditContext } from '../Context/EditContext';

// import { ProfessionalInterestsDrawer } from '../Edit'
import { SectionTitle } from '../Shared';

interface PropsType {
  candidate: any;
}

const Styles = {
  root: {
    padding: 15,
    // border: "solid 1px #364972",
    boxShadow: '0px 0px 4px #00000040 ',
    borderRadius: 10,
    paddingBottom: 5,
  },
  body: {
    paddingLeft: 24,
    marginTop: 10,
  },
};

const Myobjective: React.FC<PropsType> = ({ candidate }) => {
  const [open, setOpen] = useState(false);
  const editContext = useContext(EditContext);
  const { edit }: any = editContext;
  const handleClose = () => {
    setOpen(false);
  };

  const openEdit = () => {
    setOpen(true);
  };

  const Interest = ({ title }: any) => {
    return (
      <Tag
        style={{
          border: 'none',
          marginRight: 10,
          marginBottom: 10,
          backgroundColor: '#BC9623',
          borderRadius: 20,
          color: 'white',
          paddingTop: 5,
          paddingBottom: 5,
          paddingLeft: 10,
          paddingRight: 10,
        }}
      >
        {title}
      </Tag>
    );
  };

  return (
    <>
      <MyobjectiveDrawer
        candidate={candidate}
        open={open}
        handleClose={handleClose}
      />
      {(candidate?.objectives.length > 0 || edit) && (
        <div style={Styles.root}>
          <SectionTitle
            actionHandler={openEdit}
            title="Objectives"
            icon={objective}
            fontSize="16px"
            fontWeight={'600'}
            actionType="edit"
          />
          <div style={Styles.body}>
            {
              // candidate?.professionalInterests != null &&

              candidate?.objectives.map(
                (interest: any, index: any) =>
                  index < 5 && <Interest title={interest.title} />
              )
            }
          </div>
        </div>
      )}
    </>
  );
};

export default Myobjective;
