import React, { useEffect, useState } from 'react';
import { Drawer, Input, Button, Space, Form } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
const { TextArea } = Input;

interface PropsType {
  candidate: any;
  open: boolean;
  handleClose?: any;
  onUpdate: (data: any) => void;
}

const Styles = {
  button: {
    float: 'right',
  },
};

const SummaryDrawer: React.FC<PropsType> = ({
  candidate,
  open,
  handleClose,
  onUpdate,
}) => {
  const ref = React.useRef<any>();
  const [form] = Form.useForm();
  const handleSubmit = () => {
    onUpdate(ref.current?.resizableTextArea.textArea.value);
  };
  useEffect(() => {
    form.resetFields();
  }, [candidate]);
  const handleDrawerClose = () => {
    form.resetFields();
    handleClose();
  };

  return (
    <div>
      <Drawer
        title="Candidate’s summary"
        placement={'right'}
        // closable={true}
        onClose={handleDrawerClose}
        visible={open}
        extra={
          <Space onClick={handleDrawerClose} style={{ cursor: 'pointer' }}>
            <CloseOutlined />
          </Space>
        }
      >
        <Form form={form}>
          <Form.Item name="summary" initialValue={candidate?.description}>
            <label
              style={{
                marginBottom: '7px',
                lineHeight: 1,
                fontSize: '16px',
                fontWeight: 400,
              }}
            >
              Summary
            </label>
            {
              <TextArea
                name="summary"
                ref={ref}
                defaultValue={candidate?.description}
                placeholder="Summary"
                autoSize={{ minRows: 7 }}
              />
            }
            <Button
              onClick={handleSubmit}
              style={{
                width: 101,
                float: 'right',
                backgroundColor: '#BC9623',
                borderRadius: 20,
                marginTop: 20,
                border: 'none',
                color: 'white',
              }}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
};

export default SummaryDrawer;
