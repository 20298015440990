import React, { useRef, useContext } from 'react';
import {
  Col,
  Card,
  Table,
  Button,
  Tag,
  Skeleton,
  Checkbox,
  Popover,
  Tooltip,
} from 'antd';
import {
  SnippetsOutlined,
  CloseCircleFilled,
  CloseOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import Linkedin from '../../../../assets/crawliesTableIcons/linkedin.svg';
import Crawler from '../../../../assets/crawliesTableIcons/crawler.svg';
import Vector from '../../../../assets/crawliesTableIcons/Vector.svg';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Link } from 'react-router-dom';
import { ColumnsType } from 'antd/es/table';
import { Bounce } from 'react-awesome-reveal';
import ErrorCard from '../../../../components/UI/ErrorCard/ErrorCard';
// import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  SelectCandidates,
  UnSelectCandidates,
  ClearSelectCandidates,
  GetCandidateTags,
  ClearSelectTags,
} from '../../../../store/candidates/actions';
import { Candidates } from 'store/candidates/types';
import { randomColor } from '../../../../helpers/randomColor';
import CrawliesLists from '../CrawliesLists';
// import { useUntagCrawliesMutation } from '../../../../graphql/generated/graphql';
import './crawliesTable.css';
import { CrawliesContext } from '../Context/crawliesContext';
import moment from 'moment';

interface Props {
  loading: any;
  data: any;
  error: any;
  url: string;
  totalCount: number;
  pageOptions: any;
  setPageOptions: any;
  searchOptions: any;
  showAdvanceFilters: any;
  advanceFilters: any;
  confirmUnTagCandidate: any;
}

interface Users {
  key: number;
  id: string;
  photo: string;
  fname: string;
  lname: string;
  link: JSX.Element;
  title: string;
  position: string;
  email: string;
  summary: string;
  location: string;
  status: string;
  jobCount: number;
  companyCount: number;
  createdAt: Date;
}

const WebCandidates: React.FC<Props> = ({
  loading,
  data,
  error,
  url,
  totalCount,
  pageOptions,
  setPageOptions,
  searchOptions,
  showAdvanceFilters,
  advanceFilters,
  confirmUnTagCandidate,
}) => {
  let displayContent = null;
  console.log(data?.crawliesPersonalDetails[0]?.count);
  const dispatch = useDispatch();

  const [allSelected, setAllSelected] = React.useState(false);
  const selectedCandidates = useSelector((state: any) => state?.candidates);

  const {
    selectedCrawlies,
    setSelectedCrawlies,
    candidatesListing,
    setCandidatesListing,
  } = React.useContext(CrawliesContext);

  React.useEffect(() => {
    dispatch(ClearSelectCandidates([]));
    dispatch(ClearSelectTags([]));
  }, []);

  React.useEffect(() => {
    dispatch(ClearSelectCandidates([]));
    dispatch(ClearSelectTags([]));
    return;
  }, []);

  React.useEffect(() => {
    if (selectedCandidates?.candidates.length == 0) setAllSelected(false);
  }, [selectedCandidates]);

  const CVHandler = (CV: any, status: any) => {
    if (CV) {
      return (
        <Button
          className="ant-btn-teal"
          shape="circle"
          onClick={() =>
            window.open('https://docs.google.com/gview?url=' + CV, '_blank')
          }
        >
          <SnippetsOutlined />
        </Button>
      );
    } else {
      return <CloseCircleFilled className="text-light font-30" />;
    }
  };

  const LinkedInHandler = (linkedIn: any, status: any) => {
    if (linkedIn) {
      return (
        <span
          // className="ant-btn-teal"
          // shape="circle"
          style={{ cursor: 'pointer' }}
          onClick={() => window.open(linkedIn)}
        >
          <img src={Linkedin} alt="linkedin" style={{ width: '32px' }} />
        </span>
      );
    } else {
      return null;
    }
  };

  const TypeHandler = (status: any) => {
    if (status == 'complete') {
      return <img src={Vector} alt="Vector" />;
    } else {
      return <img src={Crawler} alt="Crawler" />;
    }
  };

  const sourceHandler = (source: any, status: any) => {
    return <Tag color="blue">{source}</Tag>;
  };

  const onSelectCandidate = (e: CheckboxChangeEvent) => {
    const val = e.target.value;
    if (e.target.checked) {
      // dispatch(SelectCandidates(val._id));
      // if (val.tags) {
      //   dispatch(GetCandidateTags(val.tags));
      // }
      setSelectedCrawlies([...selectedCrawlies, val._id]);
      console.log('val._id while selecting single crawlie', val._id);
    } else {
      // dispatch(UnSelectCandidates(val._id));
      const crawliesIds: any = selectedCrawlies.filter(
        (id: any) => id != val._id
      );

      setSelectedCrawlies(crawliesIds);
      console.log('selectedCrawlies while unselecting', selectedCrawlies);
      console.log('crawliesIds while unselecting', crawliesIds);
    }
  };

  const onSelectAllCandidate = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      setAllSelected(true);
      const selectedCrawliesIds: any = [];
      data.crawliesPersonalDetails.forEach((candidate: any) => {
        // dispatch(SelectCandidates(candidate._id));
        console.log('candidate._id while onSelectAllCandidate', candidate._id);
        selectedCrawliesIds.push(candidate._id);
      });
      setSelectedCrawlies(selectedCrawliesIds);
      console.log('selectedCrawliesIds', selectedCrawliesIds);
    } else {
      console.log('selectedCrawlies when all unselecting', selectedCrawlies);

      setAllSelected(false);
      dispatch(ClearSelectCandidates([]));
      setSelectedCrawlies([]);
    }
  };

  const columns: ColumnsType<Users> = [
    {
      title: (
        <Checkbox
          checked={allSelected}
          className="top-checkbox"
          onChange={(e: CheckboxChangeEvent) => onSelectAllCandidate(e)}
        />
      ),
      dataIndex: 'checkbox',
      key: 'checkbox',
      fixed: 'left',
      width: 27,
    },
    {
      title: 'First name',
      dataIndex: 'firstNameLink',
      key: 'firstNameLink',
      // fixed: 'left',
      width: 160,
      showSorterTooltip: false,
      sorter: (a: any, b: any) => a.firstName?.localeCompare(b?.firstName),
      sortDirections: ['ascend', 'descend', 'ascend'],
      // defaultSortOrder: 'ascend',
      render: (firstNameLink) => {
        console.log(firstNameLink, '---ccc');
        return (
          <>
            <Tooltip title={firstNameLink.props.children}>
              <span className="contentManipulation">
                <div
                // contentEditable="true"
                // dangerouslySetInnerHTML={{
                //   __html:,
                // }}
                >
                  {firstNameLink}
                </div>
              </span>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: 'Last name',
      dataIndex: 'lastNameLink',
      key: 'lastNameLink',
      // fixed: 'left',
      width: 160,
      showSorterTooltip: false,
      sorter: (a: any, b: any) => a.lastName?.localeCompare(b.lastName),
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (lastNameLink) => {
        return (
          <Tooltip title={lastNameLink.props.children}>
            <span className="contentManipulation">
              <div
              // contentEditable="true"
              // dangerouslySetInnerHTML={{
              //   __html:,
              // }}
              >
                {lastNameLink}
              </div>
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      // fixed: 'left',
      width: 160,
      showSorterTooltip: false,
      sorter: (a: any, b: any) => a.email?.localeCompare(b.email),
      sortDirections: ['ascend', 'descend', 'ascend'],
      // defaultSortOrder: 'ascend',
      render: (email) => (
        <>
          <Tooltip title={email}>
            <span className="contentManipulation">{email}</span>
          </Tooltip>
        </>
      ),
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: 160,
      showSorterTooltip: false,
      sorter: (a: any, b: any) => a?.title?.localeCompare(b?.title),
      sortDirections: ['ascend', 'descend', 'ascend'],
      // defaultSortOrder: 'ascend',
      render: (title) => (
        <>
          <Tooltip title={title}>
            <span className="contentManipulation">
              <div
                contentEditable="true"
                dangerouslySetInnerHTML={{
                  __html: title,
                }}
              />
            </span>
          </Tooltip>
        </>
      ),
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      width: 200,
      showSorterTooltip: false,
      sorter: (a: any, b: any) => a.company?.localeCompare(b.company),
      sortDirections: ['ascend', 'descend', 'ascend'],
      // defaultSortOrder: 'ascend',
      render: (company) => (
        <>
          <Tooltip title={company}>
            <span className="contentManipulation">{company}</span>
          </Tooltip>
        </>
      ),
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      showSorterTooltip: false,
      sorter: (a, b) => a.location.localeCompare(b.location),
      sortDirections: ['ascend', 'descend', 'ascend'],
      // defaultSortOrder: 'ascend',
      width: 130,
      render: (location) => (
        <>
          <Tooltip title={location}>
            <span className="contentManipulation">{location}</span>
          </Tooltip>
        </>
      ),
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      key: 'tags',
      width: 204,
      showSorterTooltip: false,
      sorter: (a: any, b: any) => a.tags?.length - b.tags?.length,
      sortDirections: ['ascend', 'descend', 'ascend'],
      // defaultSortOrder: 'ascend',
      render: (tags) => {
        console.log(tags);
        // tags.props.children[0].length > 1
        return (
          <div>
            {tags.props.children[0]?.length > 1 ? (
              <span>
                <span
                  style={{ paddingTop: 1, paddingBottom: 1, marginBottom: 1 }}
                >
                  {' '}
                  {tags.props.children[0][0]}
                </span>
                <Popover
                  placement="leftTop"
                  title={<h4 style={{ fontSize: '16px' }}>Tags</h4>}
                  content={tags}
                  style={{ backgroundColor: '#F5F7F9', maxWidth: '761px' }}
                >
                  <span
                    style={{
                      // paddingTop:4,
                      // paddingBottom:4,
                      paddingLeft: 10,
                      paddingRight: 10,
                      border: 1,
                      borderRadius: 15,
                      borderColor: '#012653',
                      borderStyle: 'solid',
                      color: '#012653',
                      fontWeight: 600,
                      height: 20,
                      width: 36,
                      cursor: 'pointer',
                    }}
                  >
                    +{tags.props.children[0]?.length - 1}
                  </span>
                </Popover>
              </span>
            ) : (
              tags
            )}
          </div>
        );
      },
    },
    {
      title: 'Type',
      dataIndex: 'status',
      key: 'status',
      width: 90,
      showSorterTooltip: false,
      sorter: (a: any, b: any) => a.status?.length - b.status?.length,
      sortDirections: ['ascend', 'descend', 'ascend'],
      // defaultSortOrder: 'ascend',

      render: (status) => (
        <div style={{ textAlign: 'center' }}>{TypeHandler(status)}</div>
      ),
    },
    {
      title: 'Mobile phone',
      dataIndex: 'phone',
      key: 'phone',
      showSorterTooltip: false,
      sorter: (a: any, b: any) => a.phone?.length - b.phone?.length,
      sortDirections: ['ascend', 'descend', 'ascend'],
      // defaultSortOrder: 'ascend',
      width: 140,
    },
    {
      title: 'LinkedIn',
      dataIndex: 'linkedIn',
      key: 'linkedIn',
      width: 75,
      render: (linkedIn, row) => LinkedInHandler(linkedIn, row.status),
    },
    {
      title: 'CV',
      dataIndex: 'CV',
      key: 'CV',
      width: 50,
      render: (CV, row) => CVHandler(CV, row.status),
    },
    // {
    //   title: 'Resume',
    //   dataIndex: 'resume',
    //   key: 'resume',
    //   width: 90,
    // render: (resume, row) => resumeHandler(resume, row.status),
    // },
    // {
    //   title: 'Source',
    //   dataIndex: 'source',
    //   key: 'source',
    //   width: 100,
    //   render: (source, row) => sourceHandler(source, row.status),
    // },
    {
      title: 'Uploaded',
      dataIndex: 'createdAt',
      key: 'createdAt',
      showSorterTooltip: false,
      // sorter: (a: any, b: any) =>{
      //   console.log(a)
      //   return( a.createdAt - b.createdAt)
      // },
      sorter: (a, b) =>
        moment(a.createdAt, 'M/D/YY').unix() -
        moment(b.createdAt, 'M/D/YY').unix(),
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'descend',

      width: 113,
    },
  ];

  const Tags = (candidateId: any, tags: any[]) => {
    const AllTags = (tags: any[]) => {
      return (
        <div style={{ display: 'flex', flexWrap: 'wrap', maxWidth: '761px' }}>
          {tags.map((tag, index) => {
            const color: any = '#1e97a2';
            // var color: any = randomColor()
            return (
              <Tag
                style={{
                  borderRadius: 20,
                  marginTop: 5,
                  maxHeight: 20,
                  fontSize: 12,
                  fontWeight: 600,
                }}
                color={tag.color}
                key={index}
              >
                {tag.name}
                <CloseOutlined
                  style={{ marginLeft: 5 }}
                  onClick={() =>
                    confirmUnTagCandidate({
                      candidateId: candidateId,
                      tagId: tag._id,
                      tagName: tag.name,
                    })
                  }
                />
              </Tag>
            );
          })}
        </div>
      );
    };

    return (
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {tags.map((tag, index) => {
          const color: any = '#1e97a2';
          // var color: any = randomColor()
          return (
            (index < 3 || true) && (
              <Tag
                style={{ borderRadius: 20, marginTop: 5 }}
                color={tag.color}
                key={index}
              >
                {tag.name}
                <CloseOutlined
                  style={{ marginLeft: 5 }}
                  onClick={() =>
                    confirmUnTagCandidate({
                      candidateId: candidateId,
                      tagId: tag._id,
                      tagName: tag.name,
                    })
                  }
                />
              </Tag>
            )
          );
        })}
        {tags.length > 3 && (
          <>
            {/* 

          // <Popover
          //   placement="leftBottom"
          //   content={() => AllTags(tags)}
          //   title="Tags"
          //   trigger="click"
          // >
          //   <Tag
          //     style={{
          //       color: '#012653',
          //       borderColor: '#012653',
          //       borderRadius: 20,
          //       marginTop: 5,
          //       cursor: 'pointer',
          //       backgroundColor: 'transparent',
          //     }}
          //   >
          //     See more
          //   </Tag>
          // </Popover>
          */}
          </>
        )}
      </div>
    );
  };

  const generateActiveCandidatesTableData = () => {
    if (data != null) {
      const candidateslistData: any = data?.crawliesPersonalDetails;

      console.log('candidateslistData: ', candidateslistData);

      const candidatesData = candidateslistData.map((candidate: any) => {
        const date = new Date(parseInt(candidate.createdAt));
        let formattedDate = date.toLocaleDateString();
        formattedDate =
          formattedDate.slice(0, formattedDate.length - 4) +
          formattedDate.slice(formattedDate.length - 2, formattedDate.length);

        if (candidate?.phone?.includes('MOBILE')) {
          candidate.phone = candidate.phone.split('MOBILE :')[1];
        }

        if (candidate?.phone?.includes('HOME')) {
          candidate.phone = candidate.phone.split('HOME :')[1];
        }

        return {
          key: candidate._id,
          id: candidate._id,
          firstName: candidate.firstName,
          lastName: candidate.lastName,
          checkbox: (
            <Checkbox
              checked={selectedCrawlies.includes(candidate._id)}
              value={candidate}
              className="row-checkbox"
              onChange={onSelectCandidate}
            />
          ),
          firstNameLink: (
            <Link
              to={{
                pathname: `${url}/${candidate._id}`,
                state: {
                  candidateId: candidate._id,
                  fromURL: `${url}`,
                },
              }}
            >
              {candidate.firstName}
            </Link>
          ),
          lastNameLink: (
            <Link
              to={{
                pathname: `${url}/${candidate._id}`,
                state: {
                  candidateId: candidate._id,
                  fromURL: `${url}`,
                },
              }}
            >
              {candidate.lastName}
            </Link>
          ),
          title: candidate?.title,
          position: candidate?.position,
          company: candidate?.company,
          resume: candidate.CV,
          status: candidate.isProfileCompleted ? 'complete' : 'incomplete',
          email: candidate?.email,
          linkedIn: candidate?.linkedIn,
          phone: (
            <Tooltip
              title={candidate?.phone}
              style={{ backgroundColor: 'transparent' }}
            >
              <span className="contentManipulation">{candidate?.phone}</span>
            </Tooltip>
          ),
          location: candidate?.location,
          source: candidate?.source ? candidate?.userSource : candidate?.source,
          tags: candidate?.tags ? Tags(candidate?._id, candidate?.tags) : '',
          createdAt: (
            <div style={{ backgroundColor: 'transparent' }}>
              {formattedDate}
            </div>
          ),
        };
      });
      return candidatesData;
    }
  };

  const onChange = (current: number, pageSize: number) => {
    setPageOptions({
      page: current,
      pageSize: pageSize,
    });
  };

  displayContent = (
    <Col span={24}>
      <Bounce>
        <Card>
          <Skeleton active paragraph={{ rows: 7 }} />
        </Card>
      </Bounce>
    </Col>
  );

  if (!loading && error) {
    displayContent = (
      <Col span={12}>
        <ErrorCard />
      </Col>
    );
  }

  if (!loading && data) {
    const activeCandidatesData: any = generateActiveCandidatesTableData() || [];

    displayContent = (
      <Table
        className={
          activeCandidatesData.length > 0
            ? 'table-striped crawlies-table'
            : 'table-striped crawlies-table'
        }
        columns={columns}
        dataSource={activeCandidatesData}
        loading={loading}
        size="small"
        locale={{
          emptyText: (
            <div
              style={{
                height: 300,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              No data found
            </div>
          ),
        }}
        pagination={{
          total: data?.crawliesPersonalDetails[0]?.count,
          current: pageOptions.page,
          // hideOnSinglePage: true,
          pageSize: pageOptions.pageSize,
          pageSizeOptions: [10, 15, 20, 50, 100],
          onChange: onChange,
        }}
        scroll={{ x: 1300 }}
      />
    );
  }

  return <>{displayContent}</>;
};

export default WebCandidates;
