import React from 'react';

const Dashboard = () => {
  return (
    <p style={{ textAlign: 'center', fontSize: '22px', paddingTop: '30px' }}>
      Main Dashboard
    </p>
  );
};

export default Dashboard;
