import React, { useState, useEffect } from 'react';
import Highlighter from 'react-highlight-words';
import PageTitle from '../../../../components/UI/PageTitle/PageTitle';
import {
  useCompaniesLazyQuery,
  useCompaniesQuery,
  useGetCurrentUserQuery,
  useUpdateCompanyMutation,
} from '../../../../graphql/generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import { numberWithCommas } from 'helpers/numberConvert';

import {
  Row,
  Col,
  Tabs,
  Card,
  Table,
  Input,
  Button,
  Space,
  Tooltip,
  Switch,
  Popconfirm,
  Skeleton,
} from 'antd';
import {
  FolderOpenOutlined,
  DeleteOutlined,
  SearchOutlined,
  UserOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';

import { Fade, Bounce } from 'react-awesome-reveal';
import ErrorCard from '../../../../components/UI/ErrorCard/ErrorCard';
import { generateCompanies } from '../../../../faker/companies';
import RecruiterDrawer from '../../../../components/BackOffice/RecruiterDrawer';
import JobDrawer from '../../../../components/BackOffice/JobDrawer';
import { ColumnsType } from 'antd/es/table';
import { useDispatch, useSelector } from 'react-redux';
import { StoreCompanies } from 'store/companies/actions';
import { CompaniesState } from 'store/companies/types';
import EditIcon from 'assets/icons/icons/EditPencil.svg';
import StarIcon from 'assets/icons/icons/Star2.svg';
import StarFilledIcon from 'assets/icons/icons/StarFilled.svg';
import DeleteIcon from 'assets/icons/icons/DeleteBox.png';
import TopSearchbar from 'components/SearchComponent/TopSearch';

interface Companies {
  key: number;
  companyNameLink: any;
  owner: string;
  title: string;
  email: string;
  jobCount: number;
  recruiterCount: number;
  createdAt: string;
}

const { TabPane } = Tabs;

const CompaniesList: React.FC = () => {
  const { url } = useRouteMatch();

  const [fakeData, setFakeData] = useState<any>({
    companyRecord: [],
  });
  const [searchText, setSearchText] = useState('');
  const [selectedCompany, setSelectedCompany]: any = useState(null);
  const [displayRecruiterDrawer, setDisplayRecruiterDrawer] = useState(false);
  const [displayJobDrawer, setDisplayJobDrawer] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState('');
  const [activeCompanies, setActiveCompanies] = useState<any>([]);
  const [archivedCompanies, setArchivedCompanies] = useState([]);
  const [searchData, setSearchData] = useState<any>();
  const [userId, setUserId] = useState('');
  const [pageOption, setPageOption] = useState<any>({
    size: 20,
    current: 1,
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    // total: 0,
  });
  const [sorting, setSorting] = useState({
    column: '',
    order: 'ascend',
  });
  const [tableData, setTableData] = useState<any>([]);
  const history = useHistory();
  const dispatch = useDispatch();

  const { data: currentUser } = useGetCurrentUserQuery({
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (currentUser?.currentUser?._id) {
      setUserId(currentUser?.currentUser?._id);
    }
  }, [currentUser]);

  const { data, loading, error } = useCompaniesQuery({
    fetchPolicy: 'network-only',
  });
  const [
    getCompanies,
    {
      data: searchCompaniesData,
      loading: searchCompaniesDataLoading,
      error: searchCompaniesDataError,
    },
  ] = useCompaniesLazyQuery({
    fetchPolicy: 'network-only',
    // variables:{}
  });
  const [
    updateCompany,
    {
      data: updateCompanyData,
      loading: updateCompanyLoading,
      error: updateCompanyError,
    },
  ] = useUpdateCompanyMutation();
  const companies = useSelector(
    (state: CompaniesState) => state.companies?.companies
  );

  useEffect(() => {
    getCompanies({
      variables: {
        pageSize: pagination.pageSize,
        page: pagination.current,
        userId: currentUser?.currentUser?._id,
        sortingColumn: sorting.column,
        sortingOrder: sorting.order,
      },
    });
    //  searchCompaniesData
    if (searchCompaniesData) {
      dispatch(StoreCompanies(searchCompaniesData.companies));
      setActiveCompanies(searchCompaniesData.companies);
    }
  }, [pagination.current, pagination.pageSize, sorting.column, sorting.order]);
  // console.log(companies)

  useEffect(() => {
    if (companies) {
      // const activecompanies = companies.filter(
      //   (job: any) => job.status == 'active'
      // );
      const archivedcompanies = companies.filter(
        (job: any) => job.status == 'archive'
      );
      // setActiveCompanies(activecompanies);
      setActiveCompanies(companies);
      setArchivedCompanies(archivedcompanies);
    }
  }, [companies]);

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.field === 'link') {
      sorter.field = 'title';
    }
    if (sorter.field === 'companyNameLink') {
      sorter.field = 'name';
    }
    if (sorter.field === 'company') {
      sorter.field = 'favouritesCount';
    }
    setPagination({
      ...pagination,
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
    setSorting({
      column: sorter.field,
      order: sorter.order,
    });
  };

  const onActiveChange = (page: number, pageSize: number) => {
    setPageOption({
      pageSize: pageSize,
      current: page,
    });
    getCompanies({
      variables: {
        pageSize: pageSize,
        page: page,
        userId: currentUser?.currentUser?._id,
      },
    });
  };

  // useEffect(() => {
  //   setFakeData({
  //     ...fakeData,
  //     companyRecord: [...generateCompanies(20, url).data],
  //   });
  //   setTimeout(function () {
  //     setLoading(false);
  //   }, 1000);
  // }, []);
  let searchInput: any;
  const generateActiveCompaniesTableData = () => {
    if (activeCompanies) {
      const companiesData = activeCompanies?.map((company: any) => {
        return {
          key: company._id,
          _id: company._id,
          logoUrl: company.logoUrl,
          name: company.name,
          companyName: company?.name,
          status: company.status,
          website: company?.website,
          favouritedBy: company.favouritedBy,
          count: company?.count,
          company: company,
          companyNameLink: (
            <Link
              to={{
                pathname: `${url}/${company?._id}`,
                state: { fromURL: '/backoffice/companies' },
              }}
            >
              {company?.name}
            </Link>
          ),
          owner: company.owner.fullName,
          title: company.owner.title,
          email: company.createdBy.email,
          jobCount: company.totalJobs,
          recruiterCount: company.users.length,
          createdAt: moment(parseInt(company.createdAt)).format('DD MMM YYYY'),
        };
      });

      // Sort the companiesData array
      // companiesData.sort((a: any, b: any) => {
      //   const aFavorited = a?.favouritedBy?.includes(userId);
      //   const bFavorited = b?.favouritedBy?.includes(userId);

      //   if (aFavorited && !bFavorited) {
      //     return -1; // a comes before b
      //   }
      //   if (!aFavorited && bFavorited) {
      //     return 1; // b comes before a
      //   }
      //   return 0; // no change in order
      // });

      return companiesData;
    }
  };

  const generateArchiveCompaniesTableData = () => {
    if (archivedCompanies) {
      const companiesData = archivedCompanies?.map((company: any) => {
        return {
          key: company._id,
          _id: company._id,
          logoUrl: company.logoUrl,
          name: company.name,
          companyName: company?.name,
          companyNameLink: (
            <Link to={`${url}/${company?._id}`}>{company?.name}</Link>
          ),
          owner: company.owner.fullName,
          title: company.owner.title,
          email: company.createdBy.email,
          jobCount: company.totalJobs,
          recruiterCount: company.users.length,
          createdAt: company.createdAt,
        };
      });
      return companiesData;
    }
  };

  // const getColumnSearchProps = (dataIndex: any) => ({
  //   filterDropdown: ({
  //     setSelectedKeys,
  //     selectedKeys,
  //     confirm,
  //     clearFilters,
  //   }: any) => (
  //     <div style={{ padding: 8 }}>
  //       <Input
  //         ref={(node) => {
  //           searchInput = node;
  //         }}
  //         placeholder={`Search ${dataIndex}`}
  //         value={selectedKeys[0]}
  //         onChange={(e) =>
  //           setSelectedKeys(e.target.value ? [e.target.value] : [])
  //         }
  //         onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
  //         style={{ width: 188, marginBottom: 8, display: 'block' }}
  //       />
  //       <Space>
  //         <Button
  //           type="primary"
  //           onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
  //           icon={<SearchOutlined />}
  //           size="small"
  //           style={{ width: 90 }}
  //         >
  //           Search
  //         </Button>
  //         <Button
  //           onClick={() =>
  //             handleReset(clearFilters, selectedKeys, confirm, dataIndex)
  //           }
  //           size="small"
  //           style={{ width: 90 }}
  //         >
  //           Reset
  //         </Button>
  //       </Space>
  //     </div>
  //   ),
  //   filterIcon: (filtered: any) => (
  //     <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
  //   ),
  //   onFilter: (value: any, record: any) =>
  //     record[dataIndex]
  //       ? record[dataIndex]
  //           .toString()
  //           .toLowerCase()
  //           .includes(value.toLowerCase())
  //       : '',
  //   onFilterDropdownVisibleChange: (visible: any) => {
  //     if (visible) {
  //       setTimeout(() => searchInput.select());
  //     }
  //   },
  //   render: (text: any) => {
  //     if (searchedColumn === dataIndex) {
  //       const textHighlight =
  //         searchedColumn === 'companyName' ? text?.props?.children : text;
  //       return (
  //         <Highlighter
  //           highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
  //           searchWords={[searchText]}
  //           autoEscape
  //           textToHighlight={textHighlight ? textHighlight.toString() : ''}
  //         />
  //       );
  //     } else {
  //       return text;
  //     }
  //   },
  // });
  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (
    clearFilters: any,
    selectedKeys: any,
    confirm: any,
    dataIndex: any
  ) => {
    clearFilters();
    setSearchText('');
    confirm();
    setSearchedColumn(dataIndex);
  };

  const displayRecruiterDrawerHandler = (companyId: any) => {
    const company = companies.find((c: any) => c._id == companyId);
    setSelectedCompany(company);
    setDisplayRecruiterDrawer(true);
  };

  const displayJobsDrawerHandler = (companyId: any) => {
    const company = companies.find((c: any) => c._id == companyId);
    setSelectedCompany(company);
    setDisplayJobDrawer(true);
  };

  const setRecruitersList = (companyId: any, recruiterList: any) => {
    const companiesCopy = [...companies];
    const index = companiesCopy.findIndex((c: any) => c._id == companyId);
    _.set(companiesCopy[index], 'users', recruiterList);
    setSelectedCompany(companiesCopy[index]);
    dispatch(StoreCompanies(companiesCopy));
  };

  const recruitersHandler = (id: any, recruiterCount: any) => {
    return (
      <Button
        className="ant-btn-amber"
        size="large"
        shape="circle"
        style={{ fontSize: '14px' }}
        onClick={() => displayRecruiterDrawerHandler(id)}
      >
        {recruiterCount}
      </Button>
    );
  };

  const careerPageHandler = (id: any) => {
    return (
      <Tooltip title="Activate career page">
        <Switch />
      </Tooltip>
    );
  };

  const handleFavourite = (company: any) => {
    updateCompany({
      variables: {
        companyId: company?._id,
        companyData: { favouritedBy: userId },
      },
    }).then(() => {
      getCompanies();

      // console.log(searchCompaniesData?.companies)
      // setActiveCompanies([...searchCompaniesData?.companies as any])
      // generateActiveCompaniesTableData()
    });
  };
  const handleUnFavourite = (company: any) => {
    updateCompany({
      variables: {
        companyId: company?._id,
        companyData: { favouritedBy: `remove ${userId}` },
      },
    }).then(() => {
      getCompanies();

      // console.log(searchCompaniesData?.companies)
      // setActiveCompanies([...searchCompaniesData?.companies as any])
      // generateActiveCompaniesTableData()
    });
  };

  const actionsHandler = (id: any) => {
    return (
      <Space>
        {id?.favouritedBy?.includes(userId) ? (
          <Tooltip title="Unfavorite this company">
            <Button
              className="bg-amber"
              shape="circle"
              onClick={() => {
                handleUnFavourite(id);
              }}
              icon={<img src={StarIcon} />}
            >
              {/* <i className="dripicons-archive text-amber" /> */}
            </Button>
          </Tooltip>
        ) : (
          <Tooltip title="Favorite this company">
            <Button
              className="bg-white"
              shape="circle"
              onClick={() => {
                handleFavourite(id);
              }}
              icon={<img src={StarFilledIcon} />}
            >
              {/* <i className="dripicons-archive text-amber" /> */}
            </Button>
          </Tooltip>
        )}

        {/* </Popconfirm> */}
        <Tooltip title="edit">
          <Button
            className="border-none"
            disabled={true}
            shape="circle"
            style={{ backgroundColor: '#2B1E56' }}
            icon={<img src={EditIcon} className="text-white" />}
          />
        </Tooltip>
        <Popconfirm
          title="Are you sure archive this company?"
          okText="Yes"
          cancelText="No"
          placement="topLeft"
          disabled={true}
        >
          <Button
            className=""
            shape="circle"
            disabled={true}
            style={{ backgroundColor: '#7A8087' }}
            icon={<img src={DeleteIcon} className="text-white" />}
          />
        </Popconfirm>
      </Space>
    );
  };

  const jobsHandler = (id: any, jobCount: any) => {
    return (
      <Button
        className="ant-btn-teal"
        shape="circle"
        size="large"
        style={{ fontSize: '14px' }}
        onClick={() => displayJobsDrawerHandler(id)}
      >
        {jobCount}
      </Button>
    );
  };

  const columns: ColumnsType<Companies> = [
    {
      title: 'Name',
      dataIndex: 'companyNameLink',
      key: 'companyNameLink',
      // fixed: 'left',
      width: 20,
      showSorterTooltip: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      // defaultSortOrder: 'ascend',
      sorter: true,
      // sorter: (a: any, b: any) => {
      //   const isAFavourited = a?.favouritedBy?.includes(userId);
      //   const isBFavourited = b?.favouritedBy?.includes(userId);

      //   if (isAFavourited && !isBFavourited) {
      //     return -1;
      //   } else if (!isAFavourited && isBFavourited) {
      //     return 1;
      //   } else {
      //     return a?.companyName?.localeCompare(b?.companyName);
      //   }
      // },
      // ...getColumnSearchProps('companyName'),
    },
    {
      title: 'Recruiters',
      dataIndex: 'recruitersCount',
      key: 'recruiters',
      width: 10,
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend', 'descend'],
      sorter: true,
      render: (id, row: any) => recruitersHandler(id, row.recruiterCount),

      // render: recruiters,
    },
    {
      title: 'Jobs',
      dataIndex: 'totalJobs',
      key: 'jobs',
      width: 10,
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend', 'descend'],
      // sorter: (a: any, b: any) => a?.jobCount - b?.jobCount,
      sorter: true,
      render: (id, row) => jobsHandler(id, row.jobCount),
    },
    // {
    //   title: 'Owner',
    //   dataIndex: 'owner',
    //   key: 'owner',
    //   width: 150,
    //   showSorterTooltip: false,
    //   sortDirections: ['ascend', 'descend', 'ascend'],
    //   defaultSortOrder: 'ascend',
    //   sorter: (a: any, b: any) => a?.owner?.localeCompare(b?.owner),
    //   ...getColumnSearchProps('owner'),
    // },
    // {
    //   title: 'Title',
    //   dataIndex: 'title',
    //   key: 'title',
    //   width: 150,
    //   showSorterTooltip: false,
    //   sortDirections: ['ascend', 'descend', 'ascend'],
    //   defaultSortOrder: 'ascend',
    //   sorter: (a: any, b: any) => a?.title?.localeCompare(b?.title),
    //   ...getColumnSearchProps('title'),
    // },
    {
      title: 'Website',
      dataIndex: 'website',
      key: 'website',
      width: 30,
      // showSorterTooltip: false,
      // sortDirections: ['ascend', 'descend', 'ascend'],
      // defaultSortOrder: 'ascend',
      // sorter: (a: any, b: any) => a?.email?.localeCompare(b?.email),
      // ...getColumnSearchProps('email'),
    },
    // {
    //   title: 'Careerpage',
    //   dataIndex: '_id',
    //   key: 'careerpage',
    //   render: careerPageHandler,
    // },
    {
      title: 'Created on',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 10,
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend', 'descend'],
      sorter: true,
      // sorter: (a: any, b: any) => {
      //   const createdOnA = new Date(a?.createdAt).getTime();
      //   const createdOnB = new Date(b?.createdAt).getTime();

      //   return createdOnA - createdOnB;
      // },
    },
    {
      title: 'Action',
      key: 'company',
      dataIndex: 'company',
      fixed: 'right',
      width: 15,
      sorter: true,
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend', 'descend'],
      defaultSortOrder: 'descend',
      render: actionsHandler,
    },
  ];

  let displayContent: any;

  displayContent = (
    <Col span={24}>
      <Bounce>
        <Card>
          <Skeleton active paragraph={{ rows: 7 }} />
        </Card>
      </Bounce>
    </Col>
  );

  if (
    (!loading && error) ||
    (!searchCompaniesDataLoading && searchCompaniesDataError)
  ) {
    displayContent = (
      <Col span={12}>
        <ErrorCard />
      </Col>
    );
  }
  if (!loading && data) {
    // if (data.companies.length === 0) {
    //   history.push("/companies/new");
    // }
    dispatch(StoreCompanies(data.companies));

    // dispatch(StoreCompanies(data.userCompanies));
    // displayContent = data.userCompanies.map((company, index) => (
    //   <Col span={7} key={index}>
    //     <Fade>
    //       <Card className="directory-card m-b-20">
    //         <CompanyCard trimText={true} company={company} />
    //       </Card>
    //     </Fade>
    //   </Col>
    // ));
    const activeCompaniesData = generateActiveCompaniesTableData() || [];
    // console.log(activeCompaniesData)
    displayContent = (
      <Table<Companies>
        className="table-striped"
        // sortDirections={['ascend', 'descend', 'ascend']}
        columns={columns}
        dataSource={activeCompaniesData}
        loading={loading || searchCompaniesDataLoading}
        size="small"
        pagination={{
          total: activeCompaniesData[0]?.count,
          current: pageOption.current,
          pageSize: pageOption.size,
          onChange: onActiveChange,
        }}
        scroll={{ x: 1200 }}
        onChange={handleTableChange}
      />
    );
  }

  const handleSearchTop = (searchText: string, searchField: string) => {
    // Do something with the search text and field
    // console.log(searchText, searchField, 'data in job table');
    setSearchData({
      ...searchData,
      searchText: searchText,
      searchType: searchField,
    });
    getCompanies({
      variables: {
        searchField: searchField,
        searchText: searchText,
      },
    });
  };

  useEffect(() => {
    if (searchCompaniesData) {
      setActiveCompanies(searchCompaniesData.companies);
      generateActiveCompaniesTableData();
    }
  }, [searchCompaniesData]);

  return (
    <>
      <PageTitle
        back
        title="List of companies"
        btnText="Add company"
        to={`${url}/add`}
        btnActive={true}
      />
      <TopSearchbar
        onSearch={handleSearchTop}
        isJob={true}
        isCompanies={true}
      />
      <Row justify="center" className="pb-4" gutter={16}>
        <Col span={24}>
          <Fade>
            <Card>
              <Tabs defaultActiveKey="1">
                <TabPane
                  tab={
                    <span>
                      <FolderOpenOutlined />{' '}
                      <span style={{ fontSize: '16px' }}>Active companies</span>
                      <span className="tab-pane-count">
                        {numberWithCommas(activeCompanies[0]?.count)}
                      </span>
                    </span>
                  }
                  key="1"
                >
                  {displayContent}
                </TabPane>
                {/* <TabPane //////////////////////////////////// Archived Companies
                  tab={
                    <span>
                      <DeleteOutlined /> Archived companies
                      <span className="tab-pane-count">
                        {numberWithCommas(archivedCompanies?.length)}
                      </span>
                    </span>
                  }
                  key="2"
                >
                  <Table<Companies>
                    columns={columns}
                    bordered={true}
                    sortDirections={['ascend', 'descend', 'ascend']}
                    dataSource={generateArchiveCompaniesTableData()}
                    size="small"
                    pagination={{ pageSize: 10 }}
                    scroll={{ x: 1300 }}
                    className="table-striped"
                  />
                </TabPane> */}
              </Tabs>
            </Card>
          </Fade>
        </Col>
        {/* <Col span={24}>
            <Fade>
              <Card>
                <Tabs defaultActiveKey="1">
                  <TabPane
                    tab={
                      <span>
                        <FolderOpenOutlined /> Active companies
                        <span className="tab-pane-count">123</span>
                      </span>
                    }
                    key="1"
                  >
                    <Table<Companies>
                      className="table-striped"
                      columns={columns}
                      dataSource={fakeData.companyRecord}
                      loading={loading}
                      size="small"
                      pagination={{ pageSize: 10 }}
                      scroll={{ x: 1300 }}
                    />
                  </TabPane>
                  <TabPane
                    tab={
                      <span>
                        <DeleteOutlined /> Archived companies
                        <span className="tab-pane-count">13</span>
                      </span>
                    }
                    key="2"
                  >
                    <Table<Companies>
                      columns={columns}
                      bordered={true}
                      dataSource={generateCompanies(2, url).data}
                      size="small"
                      pagination={{ pageSize: 10 }}
                      scroll={{ x: 1300 }}
                      className="table-striped"
                    />
                  </TabPane>
                </Tabs>
              </Card>
            </Fade>
          </Col> */}
      </Row>
      {selectedCompany && (
        <RecruiterDrawer
          company={selectedCompany}
          recruiters={selectedCompany?.users}
          setRecruitersListHandler={setRecruitersList}
          show={displayRecruiterDrawer}
          hide={() => setDisplayRecruiterDrawer(false)}
        />
      )}

      {selectedCompany && (
        <JobDrawer
          company={selectedCompany}
          jobs={selectedCompany?.jobs}
          show={displayJobDrawer}
          hide={() => setDisplayJobDrawer(false)}
        />
      )}
    </>
  );
};

export default CompaniesList;
