import { Col, Row, Skeleton } from 'antd';
import React, { FC } from 'react';

interface IProps {}
const Styles = {
  root: {
    height: '100%',
    borderRadius: 10,
    boxShadow: '0px 0px 4px #00000040 ',
    overflow: 'hidden',
  },
  topWrapper: {
    height: '120px',
    backgroundSize: 'cover',
    paddingRight: 15,
    paddingTop: 15,
  },
};

const SkeletonProfile: FC<IProps> = (props) => {
  return (
    <>
      <div className="bg-color" style={{ ...Styles.root }}>
        <Row gutter={24}>
          <Col span={24} style={Styles.topWrapper}></Col>
        </Row>
        <Row gutter={24}>
          <Col span={3} className="img-skeleton">
            <Skeleton.Image active style={{ borderRadius: '50' }} />
          </Col>
          <Col span={6} style={{ marginLeft: '45px' }}>
            <Skeleton active paragraph={{ rows: 2, width: '100%' }} />
          </Col>
          <Col span={5}>
            <Skeleton active paragraph={{ rows: 2, width: '100%' }} />
          </Col>
          <Col span={9}>
            <Skeleton active paragraph={{ rows: 2, width: '100%' }} />
          </Col>
        </Row>
        <Row gutter={22} style={{ marginTop: 58, marginLeft: 15 }}>
          <Col span={22}>
            <Skeleton active paragraph={{ rows: 5 }} />
          </Col>
        </Row>
        <Row gutter={22} style={{ marginTop: 10 }}>
          <Col span={11}>
            <Skeleton active paragraph={{ rows: 1 }} />
          </Col>
          <Col span={11}>
            <Skeleton active paragraph={{ rows: 1 }} />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SkeletonProfile;
