import { useState, useEffect } from 'react';
import { useMatchesForAJobQuery } from '../graphql/generated/graphql';

const useMatchesForAJob = (jobId: any) => {
  const [matchs, setMatchs]: any = useState(null);
  const { data, loading, error } = useMatchesForAJobQuery({
    variables: {
      jobId: jobId,
    },
    fetchPolicy: 'network-only',
  });
  const loadMatches = () => {
    if (!loading && data) {
      setMatchs(data.matchesForAJob);
    }
  };

  useEffect(() => {
    loadMatches();
  }, [data]);

  return matchs;
};

export default useMatchesForAJob;
