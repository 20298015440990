import React, { createContext, useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const TableStateContext = createContext<any>({});

export const useTableState = () => useContext(TableStateContext);

export const MatchTableContext = ({ children }: { children: any }) => {
  const [tableState, setTableState] = useState({
    pagination: { current: 1, pageSize: 10 },
    sorter: {},
    filters: {},
  });

  const location = useLocation();

  const updateTableState = (newState: any) => {
    setTableState((prevState) => ({ ...prevState, ...newState }));
  };

  const resetTableState = () => {
    setTableState({
      pagination: { current: 1, pageSize: 10 },
      sorter: {},
      filters: {},
    });
  };

  useEffect(() => {
    if (!location.pathname.includes('/jobs/')) {
      resetTableState();
    }
  }, [location.pathname]);

  return (
    <TableStateContext.Provider value={{ tableState, updateTableState }}>
      {children}
    </TableStateContext.Provider>
  );
};
