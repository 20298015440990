import {
  Candidates,
  CandidateTags,
  candidatesActionTypes,
  SELECT_CANDIDATES,
  UN_SELECT_CANDIDATES,
  CLEAR_SELECT_CANDIDATES,
  GET_CANDIDATE_TAGS,
  CLEAR_SELECT_TAGS,
} from './types';
import _ from 'lodash';

import { updateObject } from '../../helpers/updateObject';

const initialState: Candidates = {
  candidates: [],
};

const initialTagState: CandidateTags = {
  candidateTags: [],
};

const selectCandidates = (state: Candidates, action: any) => {
  return updateObject(state, {
    candidates: [...state.candidates, action.candidates],
  });
};

const unSelectCandidates = (state: Candidates, action: any) => {
  return updateObject(state, {
    candidates: state.candidates.filter(
      (candidate) => candidate != action.candidates
    ),
  });
};

const clearCandidates = (state: Candidates) => {
  return updateObject(state, { candidates: [] });
};

const clearTags = (state: CandidateTags) => {
  return updateObject(state, { candidateTags: [] });
};

const getCandidateTags = (state: CandidateTags, action: any) => {
  return updateObject(state, {
    candidateTags: [...state.candidateTags, ...action.candidateTags],
  });
};

const candidatesReducer = (
  state = initialState,
  action: candidatesActionTypes
) => {
  switch (action.type) {
    case SELECT_CANDIDATES:
      return selectCandidates(state, action);
    case UN_SELECT_CANDIDATES:
      return unSelectCandidates(state, action);
    case CLEAR_SELECT_CANDIDATES:
      return clearCandidates(state);
    default:
      return state;
  }
};

const candidateTagsReducer = (
  state = initialTagState,
  action: candidatesActionTypes
) => {
  switch (action.type) {
    case GET_CANDIDATE_TAGS:
      return getCandidateTags(state, action);
    case CLEAR_SELECT_TAGS:
      return clearTags(state);
    default:
      return state;
  }
};

export { candidatesReducer, candidateTagsReducer };
