import React from 'react';
import { Table, Tooltip } from 'antd';
import {
  useParams,
  useLocation,
  useHistory,
  Link,
  useRouteMatch,
} from 'react-router-dom';
import moment from 'moment';
import './tablecomponent.scss';
import newcd from './statusIcon/newcd.svg';
import reject from './statusIcon/reject.svg';
import select from './statusIcon/selected.svg';
import chat from './statusIcon/chat.svg';
import hire from './statusIcon/hire.svg';
import like from './statusIcon/like.svg';
import dislike from './statusIcon/dislike.svg';
import Column from 'antd/lib/table/Column';
import selected from './statusIcon/select.svg';
import talent from './statusIcon/talent.svg';

import { ColumnsType } from 'antd/es/table';
interface Activity {
  createdAt: string;
  job: String;
  company: String;
  country: String;
  recruiterAction: any;
}
interface props {
  loadingMatches: boolean;
  data: any;
  listingId: any;
  candidateName: any;
  activityData?: any;
}
export default function TableActivity(props: props) {
  const propsData = props?.data?.getAllMatch;

  const location: any = useLocation();

  // console.log(location?.pathname);
  // const columns: ColumnsType<Activity> = [
  //   {
  //     title: 'Application',
  //     dataIndex: 'createdAt',
  //     key: 'createdAt',
  //     fixed: 'left',
  //     showSorterTooltip: false,
  //     defaultSortOrder: 'ascend',
  //     sorter: (a: any, b: any) =>
  //       moment(b?.createdAt, 'DD/MM/YYYY').unix() -
  //       moment(a?.createdAt, 'DD/MM/YYYY').unix(),
  //     width: 100,
  //     render: (createdAt) => <>{createdAt}</>,
  //   },

  //   {
  //     title: 'Job',
  //     dataIndex: 'job',
  //     key: 'job',
  //     showSorterTooltip: false,
  //     sorter: (a: any, b: any) => a?.job.localeCompare(b?.job),
  //     width: 250,
  //   },
  //   {
  //     title: 'Company',
  //     dataIndex: 'company',
  //     key: 'company',
  //     showSorterTooltip: false,
  //     sorter: (a: any, b: any) => a?.company.localeCompare(b?.company),
  //     width: 250,
  //   },
  //   {
  //     title: 'Location',
  //     dataIndex: 'country',
  //     key: 'country',
  //     showSorterTooltip: false,
  //     sorter: (a: any, b: any) =>
  //       a?.country?.toString().localeCompare(b?.country?.toString()),
  //     width: 250,
  //     render: (country) => (
  //       <div className="ellipse-text">
  //         <Tooltip placement="topLeft" title={country}>
  //           <div>{country}</div>
  //         </Tooltip>
  //       </div>
  //     ),
  //   },
  //   {
  //     title: 'status',
  //     dataIndex: 'recruiterAction',
  //     key: 'recruiterAction',
  //     width: 150,
  //   },
  // ];
  const matchActivityData: Activity[] = propsData?.map(
    (item: any, key: any) => {
      const jobSpecificRoute = `/backoffice/jobs/${item?.job?._id}/candidates/${item?._id}`;
      return {
        company: item?.company,
        job: item?.job,
        country: item?.country.map((countrySigle: any, index: any) => {
          if (countrySigle) {
            if (item?.country.length > 1 && index + 1 < item?.country.length)
              return countrySigle?.name + ', ';
            else return countrySigle?.name;
          }
        }),
        createdAt: moment(parseInt(item?.job.createdAt)).format('DD/MM/YYYY'),
        recruiterAction:
          item?.recruiterAction == null && item?.candidateAction == 'like' ? (
            <Tooltip title="New candidate">
              <Link
                to={{
                  pathname: jobSpecificRoute,
                  state: {
                    fromURL: location?.pathname,
                    candidateId: props?.listingId,
                    candidateName: props.candidateName,
                    candidateArray: props?.activityData?.candidateArray,
                    isActivty: true,
                  },
                }}
              >
                <span className="btn-activity">
                  <img src={newcd} />
                </span>
              </Link>
            </Tooltip>
          ) : item?.recruiterAction == null &&
            item?.candidateAction == 'dislike' ? (
            <Tooltip title="Dislike">
              <span className="btn-activity">
                <img src={dislike} />
              </span>
            </Tooltip>
          ) : item?.recruiterAction == 'rejected' ? (
            <Tooltip title="Rejected">
              <Link
                to={{
                  pathname: jobSpecificRoute,
                  state: {
                    fromURL: location?.pathname,
                    candidateId: props?.listingId,
                    candidateName: props.candidateName,
                    candidateArray: props?.activityData?.candidateArray,
                    isActivty: true,
                  },
                }}
              >
                <span className="btn-activity">
                  <img src={reject} />
                </span>
              </Link>
            </Tooltip>
          ) : item?.recruiterAction == 'dislike' ? (
            <Tooltip title="Disliked">
              <Link
                to={{
                  pathname: location?.state?.locationUrl,
                  state: {
                    fromURL: location?.pathname,
                    candidateId: props?.listingId,
                    candidateName: props.candidateName,
                    candidateArray: props?.activityData?.candidateArray,
                    isActivty: true,
                  },
                }}
              >
                <span className="btn-activity">
                  <img src={dislike} />
                </span>
              </Link>
            </Tooltip>
          ) : item?.recruiterAction == 'like' ? (
            <Tooltip title="Liked">
              <Link
                to={{
                  pathname: jobSpecificRoute,
                  state: {
                    fromURL: location?.pathname,
                    candidateId: props?.listingId,
                    candidateName: props.candidateName,
                    candidateArray: props?.activityData?.candidateArray,
                    isActivty: true,
                  },
                }}
              >
                <span className="btn-activity">
                  <img src={like} />
                </span>
              </Link>
            </Tooltip>
          ) : item?.recruiterAction == 'hired' ? (
            <Tooltip title="Hired">
              <Link
                to={{
                  pathname: jobSpecificRoute,
                  state: {
                    fromURL: location?.pathname,
                    candidateId: props?.listingId,
                    candidateName: props.candidateName,
                    candidateArray: props?.activityData?.candidateArray,
                    isActivty: true,
                  },
                }}
              >
                <span className="btn-activity">
                  <img src={hire} />
                </span>
              </Link>
            </Tooltip>
          ) : item?.recruiterAction == 'chat' ? (
            <Tooltip title="Chat started">
              <Link
                to={{
                  pathname: jobSpecificRoute,
                  state: {
                    fromURL: location?.pathname,
                    candidateId: props?.listingId,
                    candidateName: props.candidateName,
                    candidateArray: props?.activityData?.candidateArray,
                    isActivty: true,
                  },
                }}
              >
                <span className="btn-activity">
                  <img src={chat} />
                </span>
              </Link>
            </Tooltip>
          ) : item?.recruiterAction == 'Matched' ? (
            <Tooltip title="Matched">
              <Link
                to={{
                  pathname: jobSpecificRoute,
                  state: {
                    fromURL: location?.pathname,
                    candidateId: props?.listingId,
                    candidateName: props.candidateName,
                    candidateArray: props?.activityData?.candidateArray,
                    isActivty: true,
                  },
                }}
              >
                <span className="btn-activity">
                  {/* single tick */}
                  <img src={select} />
                </span>
              </Link>
            </Tooltip>
          ) : item?.recruiterAction == 'selected' ? (
            <Tooltip title="Selected">
              <Link
                to={{
                  pathname: jobSpecificRoute,
                  state: {
                    fromURL: location?.pathname,
                    candidateId: props?.listingId,
                    candidateName: props.candidateName,
                    candidateArray: props?.activityData?.candidateArray,
                    isActivty: true,
                  },
                }}
              >
                <span className="btn-activity">
                  <img src={selected} />
                </span>
              </Link>
            </Tooltip>
          ) : item?.recruiterAction == 'talent' ? (
            <Tooltip title="Talent">
              <Link
                to={{
                  pathname: jobSpecificRoute,
                  state: {
                    fromURL: location?.pathname,
                    candidateId: props?.listingId,
                    candidateName: props.candidateName,
                    candidateArray: props?.activityData?.candidateArray,
                    isActivty: true,
                  },
                }}
              >
                <span className="btn-activity">
                  <img src={talent} />
                </span>
              </Link>
            </Tooltip>
          ) : (
            <></>
          ),
      };
    }
  );

  return (
    <>
      <div className="Activity-table">
        <Table
          className="table-striped"
          dataSource={matchActivityData}
          loading={propsData ? false : true}
          size="small"
          sortDirections={['ascend', 'descend', 'ascend']}
          pagination={{ pageSize: 10 }}
        >
          <Column
            title="Application"
            sortDirections={['ascend', 'descend', 'ascend']}
            sorter={(a: any, b: any) =>
              moment(b?.createdAt, 'DD/MM/YYYY').unix() -
              moment(a?.createdAt, 'DD/MM/YYYY').unix()
            }
            defaultSortOrder={'ascend'}
            showSorterTooltip={false}
            dataIndex="createdAt"
            key="createdAt"
            width={100}
            render={(createdAt) => <>{createdAt}</>}
          />
          <Column
            title="Company"
            sortDirections={['ascend', 'descend', 'ascend']}
            sorter={(a: any, b: any) =>
              a?.company?.name.localeCompare(b?.company?.name)
            }
            showSorterTooltip={false}
            dataIndex="company"
            key="company"
            width={250}
            render={(company) => (
              <Link
                to={{
                  pathname: `/backoffice/companies/${company?._id}`,
                  state: {
                    fromURL: location?.pathname,
                    candidateId: props?.listingId,
                    candidateName: props.candidateName,
                  },
                }}
              >
                {company.name}
              </Link>
            )}
          />
          <Column
            title="Job"
            sortDirections={['ascend', 'descend', 'ascend']}
            sorter={(a: any, b: any) =>
              a?.job?.title.localeCompare(b?.job?.title)
            }
            dataIndex="job"
            key="job"
            showSorterTooltip={false}
            width={250}
            render={(job) => (
              <Link
                to={{
                  pathname: `/backoffice/jobs/${job?._id}`,
                  state: {
                    fromURL: location?.pathname,
                    candidateId: props.listingId,
                    candidateName: props.candidateName,
                  },
                }}
              >
                {job.title}
              </Link>
            )}
          />
          <Column
            title="Location"
            sortDirections={['ascend', 'descend', 'ascend']}
            sorter={(a: any, b: any) =>
              a?.country.toString().localeCompare(b?.country.toString())
            }
            dataIndex="country"
            key="country"
            showSorterTooltip={false}
            width={250}
            render={(country: any) => (
              <div className="ellipse-text">
                {/* <Tooltip placement="topLeft" title={country}> */}
                {country}
                {/* </Tooltip> */}
              </div>
            )}
          />
          <Column
            title="Status"
            dataIndex="recruiterAction"
            key="recruiterAction"
            width={150}
            render={(recruiterAction) => recruiterAction}
          />
        </Table>
      </div>
    </>
  );
}
