import { FlagIcon } from 'assets/icons/DetailedProfile';
import React, { useContext, useState } from 'react';
import SectionTitle from './SectionTitle';
import SingleLanguage from './SingleLanguage';

interface PropsType {
  candidate: any;
}

const Styles = {
  root: {
    padding: '10px 12px',
    boxShadow: '0px 0px 4px #00000040 ',
    borderRadius: 10,
  },
  body: {},
};

const Languages: React.FC<PropsType> = ({ candidate }) => {
  const [open, setOpen] = useState(false);
  const handleEdit = () => {
    setOpen(true);
  };

  return (
    <>
      {candidate?.languages?.length > 0 && (
        <div style={Styles.root}>
          <SectionTitle
            title="Languages"
            icon={FlagIcon}
            fontSize="13px"
            fontWeight={'600'}
            actionType="edit"
          />
          <div style={Styles.body}>
            {candidate?.languages &&
              candidate?.languages.map((language: any, index: number) => {
                return (
                  <div key={index}>
                    <SingleLanguage language={language} />
                  </div>
                );
              })}
            {/* {candidate?.languageComments != null &&
              candidate?.languageComments && (
                <LanguageComment comment={candidate?.languageComments} />
              )} */}
          </div>
        </div>
      )}
    </>
  );
};

export default Languages;
