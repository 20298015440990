import React, { useState, useContext, useRef, useEffect } from 'react';
import {
  Drawer,
  Input,
  Form,
  Button,
  UploadProps,
  Upload,
  message,
  UploadFile,
  Space,
  Typography,
  Modal,
  Select,
} from 'antd';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import TopBanner from 'assets/topBannerEdit.png';
import { EditContext } from '../../Profile/Context/EditContext';
import '../styles.scss';
import { DeleteWhiteIcon, ViewWhiteIcon } from 'assets/icons/DetailedProfile';
import uploadSvg from './icons/upload.svg';
import {
  CloseOutlined,
  InboxOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { image } from 'faker';
import { from } from 'apollo-boost';
import axios from 'axios';
import ImgCrop from 'antd-img-crop';
const { Dragger } = Upload;
const { Option } = Select;

interface PropsType {
  candidate: any;
  open: boolean;
  handleClose?: any;
}

interface InputPropsType {
  placeholder: string;
  value: string;
  label: string;
  name: string;
  required: boolean;
}

const Styles = {
  topWrapper: {
    height: '120px',
    // backgroundImage: `url(${TopBanner})`,
    backgroundSize: 'cover',
  },
  dpWrapper: {
    display: 'flex',
  },
  dpContainer: {
    height: 130,
    width: 130,
    backgroundSize: 'cover',
    marginTop: 15,
  },
  fieldWrapper: {
    marginBottom: 15,
  },
  fieldsContainer: {
    marginTop: 10,
  },
};

const InputField = (Props: InputPropsType) => {
  const { placeholder, value, label, name, required } = Props;
  return (
    <div style={Styles.fieldWrapper}>
      <label style={{ marginBottom: 0, fontSize: 14, fontWeight: 400 }}>
        <span style={{ color: '#990000' }}>{required && '*'}</span>
        {label}
      </label>
      <Form.Item
        name={name}
        rules={[{ required: required, message: 'This field is required!' }]}
        initialValue={value}
      >
        <Input
          placeholder={placeholder}
          disabled={label == 'Email' ? true : false}
        />
      </Form.Item>
    </div>
  );
};

const PersonalInfoDrawer: React.FC<PropsType> = ({
  candidate,
  open,
  handleClose,
}) => {
  const editContext = useContext(EditContext);
  const { data, setData, setUpdatedData }: any = editContext;
  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    useGoogle({
      apiKey: 'AIzaSyACXA9qapZNvq51WpF69eIH908sX_efNMs',
      options: {
        types: ['geocode'],
      },
    });
  const [value, setValue] = useState([]);
  const locationRef = useRef<any>();
  const [form] = Form.useForm();

  // const [visible, setCoverPreview] = useState<any>(false);
  const [dpPreview, setDpPreview] = useState<any>(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const [isImage, setIsImage] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [imgLoad, setImgLoad] = useState(false);
  const [imageCoverUrl, setImageCoverUrl] = useState<string>();
  const [videoUrl, setVideoUrl] = useState<string>();
  const [isCoverUploading, setIsCoverUploading] = useState<boolean>(false);
  const [loadingCover, setLoadingCover] = useState(false);
  const [imgCoverLoad, setImgCoverLoad] = useState(false);
  const [profilePicture, setProfilePicture] = useState();

  useEffect(() => {
    form.resetFields();
  }, [candidate]);
  useEffect(() => {
    if (imageUrl != '' && imageUrl !== null) {
      setIsImage(false);
    } else {
      setIsImage(true);
    }
  }, [imageUrl]);

  const [fileList, setFileList] = useState<UploadFile[]>([
    {
      uid: '-1',
      name: 'xxx.png',
      status: 'done',
      url: candidate?.profilePicture,
    },
  ]);
  const [coverFileList, setCoverFileList] = useState<UploadFile[]>([
    {
      uid: '-1',
      name: 'xxx.png',
      status: 'done',
      url: candidate?.coverPhoto,
    },
  ]);
  const Fields = [
    {
      name: 'firstName',
      required: true,
      placeholder: 'Please enter your first name',
      label: 'First name',
      value: candidate?.firstName,
    },
    {
      name: 'lastName',
      required: true,
      placeholder: 'Please enter your last name',
      label: 'Last name',
      value: candidate?.lastName,
    },
    {
      name: 'email',
      required: true,
      placeholder: 'Please enter your email',
      label: 'Email',
      value: candidate?.user?.email,
    },
    {
      name: 'position',
      required: true,
      placeholder: 'Please enter your Position',
      label: 'Position',
      value: candidate?.position,
    },
    // {
    //   name: 'currentLocation',
    //   required: true,
    //   placeholder: 'Location',
    //   label: 'Location',
    //   value: candidate?.currentLocation?.country
    //     ? candidate?.currentLocation?.country
    //     : '',
    // },
  ];
  const FieldAfterLocation = [
    {
      name: 'company',
      required: true,
      placeholder: 'Please enter your company name',
      label: 'Company',
      value: candidate?.company,
    },
    // Social
    {
      name: 'linkedIn',
      required: false,
      placeholder: 'www.linkedin.com',
      label: 'LinkedIn',
      value: candidate?.linkedIn,
    },
    {
      name: 'facebook',
      required: false,
      placeholder: 'www.facebook.com',
      label: 'Facebook',
      value: candidate?.facebook,
    },
    {
      name: 'twitter',
      required: false,
      placeholder: 'www.twitter.com',
      label: 'Twitter',
      value: candidate?.twitter,
    },
    {
      name: 'github',
      required: false,
      placeholder: 'www.github.com',
      label: 'Github',
      value: candidate?.github,
    },
  ];
  React.useEffect(() => {
    setImageUrl('');
    setImageCoverUrl('');
    setVideoUrl('');
    // setIsImage(true)
  }, [candidate]);
  React.useEffect(() => {
    if (candidate?.profilePicture) setImageUrl(candidate?.profilePicture);
    // if (candidate?.profilePicture) {
    //   setIsImage(false)
    // } else {
    //   setIsImage(true)
    // }

    if (candidate?.coverPhoto) setImageCoverUrl(candidate?.coverPhoto);
    if (candidate?.profileVideo) setVideoUrl(candidate?.profileVideo);
  }, [candidate]);
  const onFinish = (values: any) => {
    const { email, location, ...rest } = values;
    const countryVal = location?.split(',');
    const update = {
      ...rest,
      currentLocation: {
        country:
          countryVal && countryVal.length > 1 ? countryVal[1] : countryVal[0],
        city: countryVal && countryVal.length >= 2 ? countryVal[0] : '',
      },
      profilePicture: imageUrl,
      coverPhoto: imageCoverUrl,
      profileVideo: videoUrl,
    };
    setUpdatedData({ ...editContext?.updatedData, ...update });
    setData({ ...editContext?.candidate, ...update });
    handleClose();
  };

  const onChange: UploadProps['onChange'] = (e: any) => {
    setIsUploading(true);
    if (imgLoad) {
      setFileList(e.fileList);
    }
    if (e.file.status === 'done') {
      setImageUrl(e.file.response.secure_url);
      setIsUploading(false);
      // setIsImage(false)
    }
  };

  const beforeUpload = (file: any) => {
    setImgLoad(true);
    const img = [
      'image/gif',
      'image/jpeg',
      'image/png',
      'image/jpg',
      'image/svg',
    ];
    const isImg = img.includes(file.type);
    if (!isImg) {
      setImgLoad(false);
      message.error('You can only upload images file!');
    }
    const isLt5M = file.size / 1024 / 1024 < 2;
    if (!isLt5M) {
      setImgLoad(false);
      message.error('Image must be smaller than 2MB!');
    }
    return isImg && isLt5M;
  };
  const beforeCoverUpload = (file: any) => {
    if (file.size < MIN_COVER_IMAGE_SIZE) {
      message.error('File size is too small! Minimum allowed size is 3MB.');
      return false;
    }

    if (file.size > MAX_COVER_IMAGE_SIZE) {
      message.error('File size exceeds the maximum limit of 10MB!');
      return false;
    }

    // Check if file type is allowed
    const imgTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    const isImg = imgTypes.includes(file.type);
    if (!isImg) {
      message.error('You can only upload image files (JPG, JPEG, PNG)!');
      return false;
    }
    return isImg;
  };

  const onCoverChange: UploadProps['onChange'] = (e: any) => {
    setIsCoverUploading(true);
    if (imgCoverLoad) {
      setCoverFileList(e.fileList);
    }
    if (e.file.status === 'done') {
      setImageCoverUrl(e.file.response.secure_url);
      setIsCoverUploading(false);
    }
  };
  const uploadButton = (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {loading ? (
        <LoadingOutlined />
      ) : (
        <PlusOutlined style={{ fontSize: 18 }} />
      )}
      <div style={{ marginLeft: 10, fontSize: 16 }}>Upload logo</div>
    </div>
  );
  const uploadCoverButton = (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      {loading ? (
        <LoadingOutlined />
      ) : (
        <PlusOutlined style={{ fontSize: 18 }} />
      )}
      <div style={{ marginLeft: 10, fontSize: 16 }}>Upload cover photo</div>
    </div>
  );
  //profile photo Props
  const MAX_IMAGE_SIZE = 2 * 1024 * 1024;
  const MAX_VIDEO_SIZE = 50 * 1024 * 1024;

  const props = {
    name: 'file',
    multiple: false,
    accept: 'image/png, image/jpeg,image/jpg',
    maxCount: 1,
    action: `${process.env.REACT_APP_BASE_REST_URL}/upload/candidates/${candidate?._id}`,
    onChange(info: any) {
      const { status } = info.file;
      if (status) {
        if (status !== 'uploading') {
          setImageUrl(info?.file?.response?.secure_url);
          // setIsImage(false)
          form.validateFields(['profilePicture']);
        }
        if (status === 'done') {
          message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      }
    },
    beforeUpload(file: any) {
      if (file.size > MAX_IMAGE_SIZE) {
        message.error('File size exceeds the limit (2MB)!');
        return Upload.LIST_IGNORE; // Prevent file from being uploaded
      }
      const img = ['image/jpeg', 'image/png', 'image/jpg'];
      const isImg = img.includes(file.type);
      if (!isImg) {
        setImgLoad(false);
        message.error('You can only upload images file!');
        return Upload.LIST_IGNORE; // Prevent file from being uploaded
      }
      return true;
    },
  };
  //cover photo
  const MIN_COVER_IMAGE_SIZE = 3 * 1024 * 1024; // 3MB in bytes
  const MAX_COVER_IMAGE_SIZE = 10 * 1024 * 1024; // 10MB in bytes

  const coverProps = {
    name: 'file',
    multiple: true,
    accept: 'image/png, image/jpeg, image/jpg',
    action: `${process.env.REACT_APP_BASE_REST_URL}/upload/candidates/${candidate?._id}`,
    onChange(info: any) {
      const { status } = info.file;
      if (status) {
        if (status !== 'uploading') {
          setImageCoverUrl(info?.file?.response?.secure_url);
        }
        if (status === 'done') {
          message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      }
    },
  };
  //video props
  const videoProps = {
    name: 'file',
    multiple: true,
    accept: 'video/mp4, video/mkv, video/mov, video/webm',
    action: `${process.env.REACT_APP_BASE_REST_URL}/upload/candidates/${candidate?._id}`,
    onChange(info: any) {
      const { status } = info.file;
      if (status) {
        if (status !== 'uploading') {
          setVideoUrl(info?.file.response.secure_url);
        }
        if (status === 'done') {
          message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      }
    },
    beforeUpload(file: any) {
      if (file.size > MAX_VIDEO_SIZE) {
        message.error('File size exceeds the limit (10MB)!');
        return Upload.LIST_IGNORE; // Prevent file from being uploaded
      }
      const img = ['video/mp4', 'video/mov', 'video/mkv', 'video/webm'];
      const isImg = img.includes(file.type);
      if (!isImg) {
        setImgLoad(false);
        message.error('You can only upload video file!');
        return Upload.LIST_IGNORE; // Prevent file from being uploaded
      }
      return true;
    },
  };
  const [previewOpen, setPreviewOpen] = useState(false);
  const [coverpreview, setCoverpreview] = useState(false);
  const [videoPreview, setVideoPreview] = useState(false);

  const handleCancel = () => setPreviewOpen(false);
  const bannerCancel = () => setCoverpreview(false);
  const videoCancel = () => setVideoPreview(false);
  const { city, country } = candidate?.currentLocation || {};

  const cityAndCountry =
    city && country
      ? `${city}, ${country}`
      : city
      ? city
      : country
      ? country
      : '';

  const handleCloseDrawer = () => {
    form.resetFields();
    if (candidate?.profilePicture) setImageUrl(candidate?.profilePicture);
    if (candidate?.coverPhoto) setImageCoverUrl(candidate?.coverPhoto);
    if (candidate?.profileVideo) setVideoUrl(candidate?.profileVideo);
    handleClose();
  };

  return (
    <div>
      <Drawer
        title="Candidate's general information"
        placement={'right'}
        // closable={true}
        onClose={handleCloseDrawer}
        visible={open}
        extra={
          <Space onClick={handleCloseDrawer} style={{ cursor: 'pointer' }}>
            <CloseOutlined />
          </Space>
        }
        className="info-drawer"
      >
        <Form
          name="basic"
          onFinish={onFinish}
          form={form}
          autoComplete="off"
          initialValues={{
            location: cityAndCountry ? cityAndCountry : '',
          }}
        >
          <Typography>
            <span style={{ color: '#990000' }}>{'*'}</span>
            My profile picture
          </Typography>
          <div
            style={
              imageUrl
                ? {
                    ...Styles.dpWrapper,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }
                : Styles.dpWrapper
            }
            className="pf-img"
          >
            <div
              style={
                {
                  // ...Styles.dpContainer,
                  // backgroundImage: `url(${candidate?.profilePicture})`,
                }
              }
            >
              <Form.Item
                name="profilePicture"
                rules={[
                  {
                    required: isImage,
                    message: 'This field is required!',
                  },
                ]}
                // initialValue={imageUrl ?imageUrl:""}
              >
                {imageUrl && (
                  <>
                    {imageUrl || candidate?.profilePicture ? (
                      <>
                        <div className="hover-img">
                          <div className="image-wrapper">
                            <img src={imageUrl} alt="avatar" />
                            <span
                              className="view-icon z-2"
                              onClick={() => setPreviewOpen(true)}
                            ></span>
                            <span
                              className="delete-icon z-2"
                              onClick={() => setImageUrl('')}
                            ></span>
                            <div className="image-overlay"></div>
                          </div>
                        </div>
                      </>
                    ) : (
                      uploadButton
                    )}
                  </>
                )}
                <Modal
                  open={previewOpen}
                  title={'Profile picture'}
                  footer={null}
                  onCancel={handleCancel}
                >
                  <img alt="example" style={{ width: '100%' }} src={imageUrl} />
                </Modal>
                {(imageUrl == '' || imageUrl == null) && (
                  <Dragger
                    {...props}
                    data={{
                      upload_preset: 'fbigkq74',
                    }}
                  >
                    <p className="ant-upload-drag-icon">
                      <img src={uploadSvg} alt="" />
                      <br />
                      <span className="large-text-drawer-color">
                        Click to upload
                      </span>{' '}
                      <span className="small-text-drawer-color">
                        or drag and drop
                      </span>
                    </p>
                    <p className="small-text-drawer-color">
                      Authorized formats: PNG, JPG, or GIF - max size 2MB min
                      256KB
                    </p>
                  </Dragger>
                )}
              </Form.Item>
            </div>
          </div>
          <Typography>My banner image</Typography>

          <div style={{ marginTop: '5px' }}>
            <Form.Item name="coverPhoto">
              {imageCoverUrl && (
                <>
                  {imageCoverUrl || candidate?.profilePicture ? (
                    <>
                      <div className="hover-coverPhoto">
                        <img src={imageCoverUrl} alt="avatar" />
                        <span
                          className="view-icon z-2"
                          onClick={() => setCoverpreview(true)}
                        ></span>
                        <span
                          className="delete-icon z-2"
                          onClick={() => setImageCoverUrl('')}
                        ></span>
                        <div className="image-overlay"></div>
                      </div>
                    </>
                  ) : (
                    uploadButton
                  )}
                </>
              )}
              <Modal
                open={coverpreview}
                title={'Banner picture'}
                footer={null}
                onCancel={bannerCancel}
              >
                <img
                  alt="example"
                  style={{ width: '100%' }}
                  src={imageCoverUrl}
                />
              </Modal>
              {(imageCoverUrl == '' || imageCoverUrl == null) && (
                <ImgCrop
                  beforeCrop={beforeCoverUpload}
                  rotate
                  grid
                  minZoom={0}
                  aspect={5.91 / 1}
                >
                  <Dragger
                    {...coverProps}
                    data={{
                      upload_preset: 'cqdbrmkw',
                    }}
                  >
                    <p className="ant-upload-text">
                      <img src={uploadSvg} alt="" />
                      <br />
                      <span className="large-text-drawer-color">
                        Click to upload
                      </span>{' '}
                      <span className="small-text-drawer-color">
                        or drag and drop
                      </span>
                    </p>
                    <p className="small-text-drawer-color">
                      Authorized formats: PNG, JPG, or GIF - max size 2MB min
                      256KB
                    </p>
                  </Dragger>
                </ImgCrop>
              )}
            </Form.Item>
          </div>
          <Typography>My profile Video</Typography>

          <div style={{ marginTop: '15px' }} className="pf-video">
            <Form.Item name="profileVideo">
              {videoUrl && (
                <>
                  {videoUrl || candidate?.videoUrl ? (
                    <>
                      <div
                        className="hover-video"
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <video
                          width={220}
                          height={140}
                          style={{ borderRadius: '10px' }}
                          // controls
                        >
                          <source src={videoUrl} />
                        </video>
                        <span
                          className="video-view-icon z-2"
                          onClick={() => setVideoPreview(true)}
                        ></span>
                        <span
                          className="video-delete-icon z-2"
                          onClick={() => setVideoUrl('')}
                        ></span>
                        <div className="image-overlay"></div>
                      </div>
                    </>
                  ) : (
                    uploadButton
                  )}
                </>
              )}
              <Modal
                open={videoPreview}
                title={'My video'}
                footer={null}
                onCancel={videoCancel}
              >
                <video width={470} height={240} controls>
                  <source src={videoUrl} />
                </video>
              </Modal>
              {(videoUrl == '' || videoUrl == null) && (
                <Dragger
                  {...videoProps}
                  data={{
                    upload_preset: 'taz3l4yj',
                  }}
                >
                  <p className="ant-upload-drag-icon">
                    <img src={uploadSvg} alt="" />
                  </p>
                  <p className="large-text-drawer-color">Upload your video</p>
                  <p className="small-text-drawer-color">
                    Max size 10MB, Authorized formats: mp4, mov, mkv and webm.
                  </p>
                </Dragger>
              )}
            </Form.Item>
          </div>
          <div style={Styles.fieldsContainer}>
            {Fields.map((field: any, index: any) => (
              <InputField
                key={index}
                required={field.required}
                placeholder={field.placeholder}
                value={field.value}
                label={field.label}
                name={field.name}
              />
            ))}
            <label style={{ marginBottom: 0, fontSize: 14, fontWeight: 400 }}>
              <span style={{ color: '#990000' }}>{'*'}</span>
              Location
            </label>
            <Form.Item
              name="location"
              rules={[{ required: true, message: 'This field is required!' }]}
            >
              <Select
                showSearch
                className="experience-location-selector"
                placeholder="Please enter your location"
                ref={locationRef}
                onSearch={(value: any) => {
                  getPlacePredictions({ input: value });
                  setValue(value);
                }}
                onChange={(value: any) => {
                  setValue(value);
                }}
                loading={isPlacePredictionsLoading}
                filterOption={(input, option: any) =>
                  option.children
                    ?.toLowerCase()
                    .indexOf(input?.toLowerCase()) >= 0
                }
              >
                {placePredictions &&
                  placePredictions.map((company: any) => {
                    return (
                      <Option
                        key={company.description}
                        value={company.description}
                      >
                        {company.description}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
            {FieldAfterLocation.map((field: any, index: any) => (
              <InputField
                key={index}
                required={field.required}
                placeholder={field.placeholder}
                value={field.value}
                label={field.label}
                name={field.name}
              />
            ))}
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'space-between',
              }}
            >
              <label style={{ marginBottom: 0, fontSize: 14, fontWeight: 300 }}>
                <span style={{ color: '#990000' }}>*</span>Mandatory fields
              </label>
              <Form.Item style={{ margin: 0 }}>
                <Button
                  htmlType="submit"
                  style={{
                    height: 31,
                    width: 101,
                    backgroundColor: '#BC9623',
                    borderRadius: 40,
                    color: 'white',
                    border: 'none',
                    marginTop: 10,
                  }}
                >
                  Submit
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </Drawer>
    </div>
  );
};

export default PersonalInfoDrawer;
