import {
  companiesActionTypes,
  STORE_COMPANIES,
  STORE_COMPANY,
} from "./types";

export const StoreCompanies = (companies: Array<Object>): companiesActionTypes => {
  return {
    type: STORE_COMPANIES,
    companies
  };
};

export const StoreCompany = (company: Object): companiesActionTypes => {
  return {
    type: STORE_COMPANY,
    company
  };
};

