export interface Companies {
  companies: [];
}

export interface CompaniesState {
  companies: Companies;
}

// Describing the different ACTION NAMES available
export const STORE_COMPANIES = 'STORE_COMPANIES';
export const STORE_COMPANY = 'STORE_COMPANY';

interface storeCompanies {
  type: typeof STORE_COMPANIES;
  companies: Array<Object>
}
interface storeCompany {
  type: typeof STORE_COMPANY;
  company: Object
}


export type companiesActionTypes =
  | storeCompanies
  | storeCompany