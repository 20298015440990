import { EducationIcon } from 'assets/icons/DetailedProfile';
import React, { useContext, useEffect, useState } from 'react';
import { SectionTitle } from '../../Shared';
import { SingleEducation, EducationComment } from './Index';
import { Button } from 'antd';
// import editPencil from 'assets/icons/profileIcons/editPencil.svg';
import DELETE_ICON from 'assets/icons/DetailedProfile/delete.svg';
import { PlusOutlined } from '@ant-design/icons';
import { EditContext } from '../../Context/EditContext';
import _ from 'lodash';

interface PropsType {
  candidate: any;
  nocomments?: any;
  isRecruiterPage?: boolean;
  handleUpdate?: any;
  handleDeleteItem?: any;
  setDisplayEducationForm?: any;
  isEditing?: any;
}

const Education: React.FC<PropsType> = ({
  candidate,
  nocomments,
  isRecruiterPage,
  handleUpdate,
  handleDeleteItem,
  setDisplayEducationForm,
  isEditing,
}) => {
  const [edu, setEdu] = useState([]);
  const [data, setData] = useState([]);
  const editContext = useContext<any>(EditContext);
  if (!editContext?.edit) {
    candidate?.education?.sort((a: any, b: any): any => {
      if (a.current && !b.current) {
        return -1; // a comes before b
      } else if (!a.current && b.current) {
        return 1; // b comes before a
      } else if (a.current && b.current) {
        return b.endYear - a.endYear; // sort by descending endYear
      } else {
        return b.endYear - a.endYear; // sort by descending endYear
      }
    });
  }

  if (isRecruiterPage) candidate.education = candidate?.educations;

  const Styles = {
    root: {
      padding: isRecruiterPage ? 0 : 15,
      boxShadow: isRecruiterPage ? 'unset' : '0px 0px 4px #00000040 ',
      borderRadius: 10,
      paddingBottom: 5,
    },
    body: {
      marginTop: isRecruiterPage ? 32 : 20,
    },
  };

  function mergeAndSort(arr: any) {
    for (let i = 0; i < arr?.length; i++) {
      for (let j = i + 1; j < arr?.length; j++) {
        const schoolNameI = arr[i]?.schoolName?.toLocaleLowerCase();
        const schoolNameJ = arr[j]?.schoolName?.toLocaleLowerCase();
        if (schoolNameI === schoolNameJ) {
          if (!arr[i].mergedData) {
            arr[i].mergedData = [arr[i]];
          }
          arr[i].mergedData.push(arr[j]);
          arr.splice(j, 1);
          j--;
        }
      }
      if (arr[i].mergedData && !editContext?.edit) {
        arr[i].mergedData.sort((a: any, b: any) => {
          if (a.startYear !== b.startYear) {
            return b.startYear - a.startYear;
          } else {
            return b.endYear - a.endYear;
          }
        });
      }
    }
    return arr;
  }

  useEffect(() => {
    setData(mergeAndSort(edu));
  }, [candidate?.education, edu]);

  const handleClick = (education: any) => {
    handleUpdate({ ...education });
    setDisplayEducationForm(true);
  };

  const handleDelete = (edu: any) => {
    handleDeleteItem(edu);
  };

  useEffect(() => {
    const edu = _.cloneDeep(candidate?.education);
    setEdu(edu);
  }, [candidate?.education]);

  const addNew = () => {
    let edu = editContext?.candidate.education || [];
    edu = [...edu, { _id: Math.random().toString() }];
    editContext?.setUpdatedData({
      ...editContext?.updatedData,
      education: edu,
    });
    editContext?.setData({
      ...editContext?.candidate,
      education: edu,
    });
  };

  return (
    <>
      {((candidate?.education != null && candidate?.education.length > 0) ||
        editContext?.edit) && (
        <div style={Styles.root}>
          <SectionTitle
            title="Education"
            icon={EducationIcon}
            fontSize="16px"
          />
          <div style={Styles.body}>
            {data &&
              data.map((education: any, i: any) => {
                return (
                  <div
                    key={i}
                    style={{
                      marginBottom: '14px',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div style={{ width: '100%' }}>
                      <SingleEducation
                        education={education}
                        candidateId={candidate?._id}
                      />
                      {nocomments === false ? (
                        <></>
                      ) : (
                        (candidate?.educationComment || editContext?.edit) &&
                        i + 1 === data?.length && (
                          <EducationComment
                            comment={candidate?.educationComment}
                          />
                        )
                      )}
                    </div>
                    {isRecruiterPage && isEditing && (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'start',
                          marginLeft: '8px',
                          gap: 4,
                          marginRight: '12px',
                        }}
                      >
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <Button
                            style={{
                              zIndex: '5',
                              position: 'relative',
                              backgroundColor: '#1A838C',
                              width: '20px',
                              minWidth: '20px',
                              height: '20px',
                            }}
                            className="border-primary"
                            shape="circle"
                            icon={
                              <img
                                style={{
                                  height: '9.5px',
                                  width: '9.5px',
                                  marginTop: '-6px',
                                }}
                                src={'editPencil'}
                                alt="editPencil"
                              />
                            }
                            onClick={() => handleClick(education)}
                          />
                          <span
                            onClick={() => handleDelete(education)}
                            style={{ cursor: 'pointer' }}
                          >
                            <img
                              src={DELETE_ICON}
                              alt="DELETE_ICON"
                              style={{ height: '20px', width: '20px' }}
                            />
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            {editContext?.edit && (
              <Button
                style={{
                  maxWidth: '100%',
                  marginBottom: '8px',
                  borderRadius: '15px',
                }}
                type="dashed"
                onClick={addNew}
                block
              >
                Add a new education <PlusOutlined />
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Education;
