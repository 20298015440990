import { NextStepsIcon } from 'assets/icons/DetailedProfile';
import React, { useContext, useState } from 'react';
import { EditContext } from '../Context/EditContext';
import { SectionTitle } from '../Shared';
import { NextStepsDrawer } from '../Edit';
interface PropsType {
  candidate: any;
  matchNextSteps: any;
}

const Styles = {
  root: {
    padding: 15,
    // border: 'solid 1px #364972',
    boxShadow: '0px 0px 4px #00000040 ',
    borderRadius: 10,
    paddingBottom: 5,
  },
  body: {
    paddingLeft: 24,
    marginTop: 10,
  },
};

const NextSteps: React.FC<PropsType> = ({ candidate, matchNextSteps }) => {
  const [open, setOpen] = useState(false);

  const editContext = useContext(EditContext);
  const { edit, match }: any = editContext;
  const handleClose = () => {
    setOpen(false);
  };

  const openEdit = () => {
    setOpen(true);
  };
  return (
    <>
      <NextStepsDrawer
        candidate={candidate}
        open={open}
        handleClose={handleClose}
        matchStep={matchNextSteps}
      />
      {((match?.nextSteps != null && match?.nextSteps != '') || edit) && (
        <div style={Styles.root}>
          <SectionTitle
            actionHandler={openEdit}
            title="Next steps"
            icon={NextStepsIcon}
            fontSize="16px"
            actionType="edit"
            candidateId={candidate?._id}
          />
          <div style={Styles.body}>
            <div
              style={{ marginBottom: 10, fontSize: 14, color: 'black' }}
              dangerouslySetInnerHTML={{
                __html: match?.nextSteps,
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default NextSteps;
