import {
  Candidates,
  candidatesActionTypes,
  SELECT_CANDIDATES,
  UN_SELECT_CANDIDATES,
  CLEAR_SELECT_CANDIDATES,
  GET_CANDIDATE_TAGS,
  CLEAR_SELECT_TAGS,
} from './types';

export const SelectCandidates = (
  candidates: String[]
): candidatesActionTypes => {
  return {
    type: SELECT_CANDIDATES,
    candidates,
  };
};

export const UnSelectCandidates = (
  candidates: String[]
): candidatesActionTypes => {
  return {
    type: UN_SELECT_CANDIDATES,
    candidates,
  };
};

export const ClearSelectCandidates = (
  candidates: String[]
): candidatesActionTypes => {
  return {
    type: CLEAR_SELECT_CANDIDATES,
    candidates,
  };
};

export const ClearSelectTags = (
  candidateTags: String[]
): candidatesActionTypes => {
  return {
    type: CLEAR_SELECT_TAGS,
    candidateTags,
  };
};

export const GetCandidateTags = (
  candidateTags: String[]
): candidatesActionTypes => {
  return {
    type: GET_CANDIDATE_TAGS,
    candidateTags,
  };
};
