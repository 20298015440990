import React, { useEffect, useState } from 'react';
import faker from 'faker';
import _ from 'lodash';
import {
  Drawer,
  List,
  Avatar,
  Button,
  Divider,
  Col,
  Row,
  Card,
  Form,
  Input,
  Menu,
  Dropdown,
  Space,
  notification,
  Select,
  Popconfirm,
} from 'antd';
import { useParams, Link } from 'react-router-dom';
import {
  useCompanyRecruitersQuery,
  useRecruiterSignupForACompanyMutation,
  useAllRecruitersExceptQuery,
  useAttachRecruiterToACompanyMutation,
  useRemoveRecruiterFromACompanyMutation,
} from '../../graphql/generated/graphql';
import ErrorCard from 'components/UI/ErrorCard/ErrorCard';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
const { Option } = Select;

interface Props {
  company: any;
  recruiters: any;
  setRecruitersListHandler: any;
  show: boolean;
  hide: any;
}

const RecruiterDrawer: React.FC<Props> = ({
  show,
  hide,
  company,
  recruiters,
  setRecruitersListHandler,
}) => {
  const [form] = Form.useForm();
  const [recruitersList, setRecruitersList] = useState([]);
  const [showExistingRecruiterForm, setShowExistingRecruiterForm] =
    useState(false);
  const [showNewRecruiterForm, setShowNewRecruiterForm] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [companyId, setCompanyId] = useState(null);
  const [fetchData, setFetchData] = useState(Object);

  const { data, loading, error }: any = useCompanyRecruitersQuery({
    variables: {
      userIds: recruiters.map((r: any) => r._id),
    },
    fetchPolicy: 'network-only',
  });
  const allRecruitersExcept = useAllRecruitersExceptQuery({
    variables: { companyId: company._id },
    fetchPolicy: 'network-only',
  });

  const [addRecruiterMutation, recruiterSignupMutation] =
    useRecruiterSignupForACompanyMutation();
  const [
    attachRecruiterToACompanyMutation,
    attachRecruiterToACompanyMutationMutation,
  ] = useAttachRecruiterToACompanyMutation();
  const [
    removeRecruiterFromACompanyMutation,
    removeRecruiterFromACompanyMutationMutation,
  ] = useRemoveRecruiterFromACompanyMutation();

  let displayContent = null;

  if (!loading && error) {
    console.log(error);
    displayContent = (
      <Col span={12}>
        <ErrorCard />
      </Col>
    );
  }

  useEffect(() => {
    if (data && data.getAllCompanyRecruiters) {
      setRecruitersList(data.getAllCompanyRecruiters);
    }
  }, [data]);

  const onCreateNewRecruiterFinishHandler = (values: any) => {
    console.log('Values', values);
    addRecruiterMutation({
      variables: {
        fullName: values.fullName,
        email: values.emailAddress,
        password: values.password,
        title: values.title,
        companyId: company._id,
      },
    })
      .then(({ data }) => {
        const oldRecruitersList: any = [...recruitersList];
        console.log(_.pick(data?.recruiterSignupForACompany, ['_id', 'email']));
        const newRecruiter = _.pick(data?.recruiterSignupForACompany, [
          '_id',
          'email',
          'fullName',
          'title',
        ]);
        // oldRecruitersList.push({
        //   _id: newRecruiter._id,
        //   email: newRecruiter.email,
        //   fullName: newRecruiter.fullName,
        //   title: newRecruiter.title
        // });
        oldRecruitersList.push(newRecruiter);
        recruiters.push({
          _id: data?.recruiterSignupForACompany?.userId,
          email: data?.recruiterSignupForACompany?.email,
          fullName: data?.recruiterSignupForACompany?.fullName,
          title: data?.recruiterSignupForACompany?.title,
        });
        setRecruitersList(oldRecruitersList);
        setRecruitersListHandler(company._id, recruiters);
        form.resetFields();
        setShowNewRecruiterForm(false);
      })
      .catch((err) => {
        const errors = err.graphQLErrors.map((el: any) => el.message);
        notification['error']({
          message: 'Unable to create recruiter account',
          description: errors.map((er: any) => <div key={er}>{er}</div>),
          duration: 3,
          onClose: () => setHasError(false),
        });
      });
  };

  const onExistingRecruiterFinishHandler = (values: any) => {
    console.log('Values', values);
    attachRecruiterToACompanyMutation({
      variables: {
        userId: values.userId,
        companyId: company._id,
      },
    })
      .then(({ data }) => {
        const oldRecruitersList: any = [...recruitersList];
        console.log(_.pick(data?.AttachRecruiterToACompany, ['_id', 'email']));
        const newRecruiter = _.pick(data?.AttachRecruiterToACompany, [
          '_id',
          'email',
          'fullName',
          'title',
        ]);
        oldRecruitersList.push(newRecruiter);
        recruiters.push({
          _id: data?.AttachRecruiterToACompany?.userId,
          email: data?.AttachRecruiterToACompany?.email,
          fullName: data?.AttachRecruiterToACompany?.fullName,
          title: data?.AttachRecruiterToACompany?.title,
        });
        setRecruitersList(oldRecruitersList);
        setRecruitersListHandler(company._id, recruiters);
        form.resetFields();
        setShowExistingRecruiterForm(false);
      })
      .catch((err) => {
        const errors = err.graphQLErrors.map((el: any) => el.message);
        notification['error']({
          message: 'Unable to attach the recruiter account',
          description: errors.map((er: any) => <div key={er}>{er}</div>),
          duration: 3,
          onClose: () => setHasError(false),
        });
      });
  };

  const removeRecruiterFromACompanyHanlder = (userId: any, e: any) => {
    console.log('userId: ', userId);
    e.preventDefault();

    removeRecruiterFromACompanyMutation({
      variables: {
        userId: userId,
        companyId: company._id,
      },
    })
      .then(({ data }) => {
        const oldRecruitersList: any = [...recruitersList];
        const newRecruiter = _.pick(data?.removeRecruiterFromACompany, [
          '_id',
          'email',
        ]);
        _.remove(oldRecruitersList, { _id: newRecruiter._id });
        _.remove(recruiters, (currentObj: any) => {
          return currentObj.email === newRecruiter.email;
        });
        setRecruitersList(oldRecruitersList);
        setRecruitersListHandler(company._id, recruiters);
      })
      .catch((err) => {
        const errors = err.graphQLErrors.map((el: any) => el.message);
        notification['error']({
          message: 'Unable to remove the recruiter account',
          description: errors.map((er: any) => <div key={er}>{er}</div>),
          duration: 3,
          onClose: () => setHasError(false),
        });
      });
  };

  function handleMenuClick(e: any) {
    console.log('click', e);
    switch (e.key) {
      case 'existing':
        setShowExistingRecruiterForm(true);
        setShowNewRecruiterForm(false);
        break;
      case 'new':
        setShowExistingRecruiterForm(false);
        setShowNewRecruiterForm(true);
        break;
    }
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="existing" icon={<UserOutlined />}>
        Add existing recruiter
      </Menu.Item>
      <Menu.Item key="new" icon={<UserOutlined />}>
        Add new recruiter
      </Menu.Item>
    </Menu>
  );

  const onCompanyDropDownChange = (companyId: any) => {
    console.log('Selected Value: ', companyId);
    setCompanyId(companyId);
  };

  return (
    <>
      {!loading && !data ? (
        <ErrorCard />
      ) : (
        <Drawer
          title="Recruiters"
          placement="right"
          closable={true}
          onClose={hide}
          visible={show}
          width={600}
          className="menu-drawer"
        >
          {/* {faker.image.image()}
        {faker.image.imageUrl()}
        {faker.image.avatar()} */}
          {console.log('Recruiter List', recruitersList)}

          {data && (
            <List
              dataSource={recruitersList}
              bordered
              renderItem={(item: any) => (
                <List.Item
                  key="_id"
                  actions={[
                    <a key="list-loadmore-edit">
                      <Link to={`backoffice/recruiters/recruiters/${item._id}`}>
                        View profile
                      </Link>
                    </a>,
                    <Popconfirm
                      title="Are you sure archive this recruiter?"
                      okText="Yes"
                      onConfirm={(e) =>
                        removeRecruiterFromACompanyHanlder(item._id, e)
                      }
                      cancelText="No"
                    >
                      <Button className="border-amber" shape="circle">
                        <i className="dripicons-cross text-amber" />
                      </Button>
                    </Popconfirm>,
                  ]}
                >
                  <List.Item.Meta
                    avatar={
                      <Avatar src="https://gw.alipayobjects.com/zos/rmsportal/BiazfanxmamNRoxxVxka.png" />
                    }
                    title={
                      <a href={`/recruiters/${item._id}`}>{item.fullName}</a>
                    }
                    description={item.title}
                  />
                </List.Item>
              )}
            />
          )}

          <div
            style={{
              textAlign: 'center',
              marginTop: 12,
              height: 55,
              lineHeight: '32px',
            }}
          >
            {/* <Button>Add recruiter</Button> */}
            <Dropdown
              overlay={menu}
              placement="bottomCenter"
              // icon={}
            >
              <Button>
                {' '}
                Add recruiter <UserOutlined />{' '}
              </Button>
            </Dropdown>
          </div>
          {showNewRecruiterForm && (
            <Card>
              <Divider orientation="center">Add new recruiter</Divider>
              <Form
                name="registration-form"
                layout="vertical"
                form={form}
                //   onFieldsChange={() => dispatch(httpClear())}
                onFinish={onCreateNewRecruiterFinishHandler}
                scrollToFirstError
              >
                <Form.Item
                  name="fullName"
                  label="Full Name"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your Full name!',
                    },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    type="text"
                    placeholder="Enter Full name"
                  />
                </Form.Item>
                <Form.Item
                  name="emailAddress"
                  label="Email Address"
                  rules={[
                    {
                      type: 'email',
                      message: 'The input is not valid E-mail!',
                    },
                    {
                      required: true,
                      message: 'Please input your E-mail!',
                    },
                  ]}
                >
                  <Input
                    prefix={<i className="far fa-envelope font-14" />}
                    placeholder="Enter Email address"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                    {
                      whitespace: true,
                      message: 'No whitespace allowed',
                    },
                    {
                      min: 6,
                      message: 'Please input min 6 characters',
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder="Enter Password"
                  />
                </Form.Item>

                <Form.Item
                  name="confirm"
                  label="Confirm Password"
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                    {
                      whitespace: true,
                      message: 'No whitespace allowed',
                    },
                    {
                      min: 6,
                      message: 'Please input min 6 characters',
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          'The two passwords that you entered do not match!'
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder="Enter confirm password"
                  />
                </Form.Item>
                <Form.Item
                  name="title"
                  label="Title"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your Title!',
                    },
                  ]}
                >
                  <Input
                    prefix={<i className="fas fa-graduation-cap font-14" />}
                    placeholder="Enter Title"
                  />
                </Form.Item>

                <Form.Item>
                  <div className="text-right">
                    {/* <Button
                    // type="amber"
                    type="primary"
                    htmlType="submit"
                    shape="round"
                    // loading={loading}
                  >
                    Register
                  </Button> */}

                    <Space>
                      <Button
                        type="default"
                        shape="round"
                        onClick={() => setShowNewRecruiterForm(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="ant-btn-amber"
                        type="default"
                        shape="round"
                        htmlType="submit"
                      >
                        Register
                      </Button>
                    </Space>
                  </div>
                </Form.Item>
              </Form>
            </Card>
          )}
          {showExistingRecruiterForm && (
            <Card>
              <Divider orientation="center">Add existing recruiter</Divider>
              <Form
                name="registration-form"
                layout="vertical"
                form={form}
                //   onFieldsChange={() => dispatch(httpClear())}
                onFinish={onExistingRecruiterFinishHandler}
                scrollToFirstError
              >
                <Form.Item name="companyId" label="Company name">
                  <Input
                    disabled={true}
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    defaultValue={company.name}
                  />
                </Form.Item>
                <Form.Item
                  name="userId"
                  label="Email Address"
                  rules={[
                    {
                      required: true,
                      message: 'Please select recruiter email!',
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    showSearch
                    placeholder="Select recruiter email"
                    // optionFilterProp="children"
                    onChange={onCompanyDropDownChange}
                    // onFocus={onFocus}
                    // onBlur={onBlur}
                    // onSearch={onSearch}
                    filterOption={(input, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {allRecruitersExcept.data &&
                      allRecruitersExcept.data.getAllRecruitersExcept.map(
                        (rec: any) => {
                          return (
                            <Option key={rec._id} value={rec.userId}>
                              {rec.email}
                            </Option>
                          );
                        }
                      )}
                  </Select>
                </Form.Item>

                <Form.Item>
                  <div className="text-right">
                    <Space>
                      <Button
                        type="default"
                        shape="round"
                        onClick={() => setShowExistingRecruiterForm(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="ant-btn-amber"
                        type="default"
                        shape="round"
                        htmlType="submit"
                      >
                        Attach
                      </Button>
                    </Space>
                  </div>
                </Form.Item>
              </Form>
            </Card>
          )}
        </Drawer>
      )}
    </>
  );
};

export default RecruiterDrawer;
