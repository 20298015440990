import { message, Modal, Spin, Upload } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import React, { useState } from 'react';

interface ProfilePictureProps {
  uploadPicture: string;
  setUploadPicture: any;
  candidateId: any;
  form: any;
  setIsDisabled?: any;
  handleSave?: any;
}

const MAX_IMAGE_SIZE = 2 * 1024 * 1024;

const ProfilePicture = ({
  uploadPicture,
  setUploadPicture,
  candidateId,
  form,
  handleSave,
}: //   setIsDisabled,
ProfilePictureProps) => {
  const [imagePreviewModel, setImagePreviewModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const props = {
    name: 'file',
    multiple: false,
    accept: 'image/png, image/jpeg,image/jpg',
    maxCount: 1,
    action: `${process.env.REACT_APP_BASE_REST_URL}/upload/candidate/${candidateId}`,
    onChange(info: any) {
      setIsLoading(true);
      const { status } = info.file;
      if (status) {
        if (status !== 'uploading') {
          setUploadPicture(info?.file?.response?.secure_url);
          handleSave({ logo: info?.file?.response?.secure_url });
          form.validateFields(['profilePic']);
          setIsLoading(false);
        }
        if (status === 'done') {
          message.success(`${info.file.name} file uploaded successfully.`);
          setIsLoading(false);
        } else if (status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
          setIsLoading(false);
        }
      }
    },
    beforeUpload(file: any) {
      if (file.size > MAX_IMAGE_SIZE) {
        message.error('File size exceeds the limit (2MB)!');
        return Upload.LIST_IGNORE; // Prevent file from being uploaded
      }
      const img = ['image/jpeg', 'image/png', 'image/jpg'];
      const isImg = img.includes(file.type);
      if (!isImg) {
        message.error('You can only upload images file!');
        return Upload.LIST_IGNORE; // Prevent file from being uploaded
      }
      return true;
    },
  };

  return (
    <div className="">
      {uploadPicture && (
        <div
          style={{
            width: '48px',
            height: '48px',
            borderRadius: '50%',
            position: 'relative',
          }}
        >
          <Dragger
            {...props}
            showUploadList={false}
            data={{
              upload_preset: 'cqdbrmkw',
            }}
            style={{ width: '48px', height: '48px', borderRadius: '50%' }}
          >
            <img
              src={uploadPicture}
              alt=""
              style={{ width: '28px', height: '28px', marginTop: '-10px' }}
            />
          </Dragger>
          {isLoading && (
            <div
              className=""
              style={{
                backgroundColor: '#8080803d',
                height: '48px',
                width: '48px',
                borderRadius: '50%',
                position: 'absolute',
                top: '0px',
              }}
            >
              <Spin
                size="small"
                style={{
                  position: 'absolute',
                  left: '17px',
                  top: '17px',
                  zIndex: 2,
                }}
              />
            </div>
          )}
          {uploadPicture?.length > 3 &&
            !uploadPicture?.includes('Placeholder') && (
              <div
                className=""
                style={{
                  backgroundColor: '#8080803d',
                  height: '48px',
                  width: '48px',
                  borderRadius: '50%',
                  position: 'absolute',
                  top: '0px',
                }}
              >
                <>
                  <div className="hover-img-logo">
                    {/* <img src={imageCoverUrl} alt="avatar" /> */}
                    <span
                      className="view-icon z-2"
                      onClick={() => setImagePreviewModal(true)}
                    ></span>
                    <span
                      className="delete-icon z-2"
                      onClick={() => {
                        setUploadPicture('');
                        handleSave({ logo: '' });
                      }}
                    ></span>
                    <div className="image-overlay"></div>
                  </div>
                </>
              </div>
            )}
        </div>
      )}
      <Modal
        className="preview-modal"
        centered
        visible={imagePreviewModel}
        title={''}
        footer={null}
        onCancel={() => {
          setImagePreviewModal(false);
        }}
      >
        <img
          alt="example"
          style={{ width: '100%', height: 475, marginBottom: '20px' }}
          src={uploadPicture}
        />
      </Modal>
    </div>
  );
};

export default ProfilePicture;
