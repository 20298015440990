import React, { useState } from 'react';
import { Tooltip, Typography } from 'antd';
import { ResetIcon, email } from 'assets/icons/DetailedProfile';
import { ResetPassword } from '../../Edit';
import { ResetEmail } from '../../Edit';
const { Title } = Typography;

interface PropsType {
  candidate: any;
}

const Styles = {
  firstLastName: {
    marginTop: 0,
    fontSize: 20,
    fontWeight: 600,
    textAlign: 'start',
    color: '#012653',
  },
  resetIcon: {
    cursor: 'pointer',
    marginLeft: 10,
  },
};

const BasicInfo: React.FC<PropsType> = ({ candidate }) => {
  const [open, setOpen] = useState(false);
  const [openForEmail, setOpenForEmail] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseForEmail = () => {
    setOpenForEmail(false);
  };

  const openDrawer = () => {
    setOpen(true);
  };
  const openDrawerForEmail = () => {
    setOpenForEmail(true);
  };
  let last = candidate?.experiences?.length - 1;
  let title = candidate?.experiences[last];
  return (
    <>
      <ResetPassword
        handleClose={handleClose}
        open={open}
        candidate={candidate}
      />
      <ResetEmail
        handleClose={handleCloseForEmail}
        open={openForEmail}
        candidate={candidate}
      />
      <div
        style={{
          marginTop: 10,
          marginLeft: 15,
          marginRight: 10,
          width: '100%',
          maxWidth: 350,
          wordWrap: 'break-word',
        }}
      >
        <span
          style={{
            marginTop: 0,
            fontSize: 20,
            fontWeight: 600,
            textAlign: 'start',
            color: '#012653',
          }}
        >
          <span>{candidate?.firstName}</span> <span>{candidate?.lastName}</span>
        </span>
        <div
          style={{
            marginTop: 0,
            fontSize: 14,
            fontWeight: 600,
            textAlign: 'start',
            color: '#000000d9',
            // paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <>{candidate?.position && candidate?.position}</>
        </div>
        <Title
          level={5}
          style={{
            textAlign: 'start',
            color: '#000000D9',
            // paddingLeft: 10,
            margin: 0,
            marginTop: -3,
            paddingRight: 10,
          }}
        >
          <span
            style={{
              fontWeight: 400,
              color: '#000000',
              fontSize: 14,
              textAlign: 'start',
            }}
          >
            {candidate?.company && (
              <span
                style={{ color: '#000000D9', fontSize: 14, fontWeight: 700 }}
              >
                @{candidate?.company}
              </span>
            )}{' '}
            {candidate?.currentLocation?.city &&
            candidate?.currentLocation?.country
              ? '| ' +
                candidate?.currentLocation?.city +
                ', ' +
                candidate?.currentLocation?.country
              : (candidate?.currentLocation?.city == null ||
                  candidate?.currentLocation?.city == undefined ||
                  candidate?.currentLocation?.city == '') &&
                candidate?.currentLocation?.country
              ? '| ' + candidate?.currentLocation?.country
              : ''}
          </span>
        </Title>
        <Title
          level={3}
          style={{
            fontSize: 14,
            fontWeight: 400,
            textAlign: 'start',
            color: '#012653',
            margin: 0,
            marginTop: 5,
          }}
        >
          <span style={{ fontWeight: 400 }}>{candidate?.user?.email}</span>
          <Tooltip title="Reset password" placement="top">
            <span onClick={openDrawer} style={Styles.resetIcon}>
              <img src={ResetIcon} />
            </span>
          </Tooltip>
          <Tooltip title="Reset email" placement="top">
            <span onClick={openDrawerForEmail} style={Styles.resetIcon}>
              <img src={email} />
            </span>
          </Tooltip>
        </Title>
      </div>
    </>
  );
};

export default BasicInfo;
