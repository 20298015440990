import { ExperienceIcon } from 'assets/icons/DetailedProfile';
import React, { useContext, useEffect, useState } from 'react';
import { SectionTitle } from '../../Shared';
import { SingleExperience, ExperienceComment } from './Index';
import { Button } from 'antd';
import DELETE_ICON from 'assets/icons/DetailedProfile/delete.svg';
import editPencil from 'assets/icons/DetailedProfile/editPencil.svg';
import _ from 'lodash';
import { EditContext } from '../../Context/EditContext';
import { PlusOutlined } from '@ant-design/icons';

interface PropsType {
  candidate: any;
  nocomments?: any;
  isRecruiterPage?: boolean;
  handleUpdate?: any;
  handleDeleteItem?: any;
  setDisplayExperienceForm?: any;
  isEditing?: any;
}

const Experience: React.FC<PropsType> = ({
  candidate,
  nocomments,
  isRecruiterPage,
  handleUpdate,
  handleDeleteItem,
  setDisplayExperienceForm,
  isEditing,
}) => {
  const [exp, setExp] = useState([]);
  const [data, setData] = useState([]);
  const editContext = useContext<any>(EditContext);
  if (!editContext?.edit) {
    candidate?.experiences?.sort((a: any, b: any): any => {
      if (a.current && !b.current) {
        return -1; // a comes before b
      } else if (!a.current && b.current) {
        return 1; // b comes before a
      } else if (a.current && b.current) {
        return b.endYear - a.endYear; // sort by descending endYear
      } else {
        return b.endYear - a.endYear; // sort by descending endYear
      }
    });
  }

  const Styles = {
    root: {
      padding: isRecruiterPage ? 0 : 15,
      boxShadow: isRecruiterPage ? 'unset' : '0px 0px 4px #00000040 ',
      borderRadius: 10,
      paddingBottom: 5,
    },
    body: {
      marginTop: isRecruiterPage ? '32px' : '20px',
    },
  };

  function mergeAndSort(arr: any) {
    for (let i = 0; i < arr?.length; i++) {
      for (let j = i + 1; j < arr?.length; j++) {
        const schoolNameI = arr[i]?.company?.toLocaleLowerCase();
        const schoolNameJ = arr[j]?.company?.toLocaleLowerCase();
        if (schoolNameI === schoolNameJ) {
          if (!arr[i].mergedData) {
            arr[i].mergedData = [arr[i]];
          }
          arr[i].mergedData.push(arr[j]);
          arr.splice(j, 1);
          j--;
        }
      }
      if (arr[i]?.mergedData && !editContext?.edit) {
        arr[i].mergedData.sort((a: any, b: any) => {
          if (a.startYear !== b.startYear) {
            return b.startYear - a.startYear;
          } else {
            return b.endYear - a.endYear;
          }
        });
      }
    }
    return arr;
  }

  useEffect(() => {
    setData(mergeAndSort(exp));
  }, [candidate?.experiences, exp]);

  const handleClick = (experience: any) => {
    handleUpdate({ ...experience });
    setDisplayExperienceForm(true);
  };

  const handleDelete = (exp: any) => {
    handleDeleteItem(exp);
  };

  useEffect(() => {
    const exp = _.cloneDeep(candidate?.experiences);
    setExp(exp);
  }, [candidate?.experiences]);

  const addNew = () => {
    let exp = editContext?.updatedData?.experiences?.length
      ? editContext?.updatedData?.experiences
      : editContext?.candidate?.experiences;
    exp = [...exp, { _id: Math.random().toString() }];
    editContext?.setUpdatedData({
      ...editContext?.updatedData,
      experiences: exp,
    });
    editContext?.setData({
      ...editContext?.candidate,
      experiences: exp,
    });
  };

  return (
    <>
      {((candidate?.experiences != null &&
        candidate?.experiences.length != 0 &&
        candidate?.experiences[0] != null) ||
        editContext?.edit) && (
        <div style={Styles.root}>
          <SectionTitle
            title={
              <span>
                <img src={ExperienceIcon} alt="ExperienceIcon" /> Experience
              </span>
            }
            fontSize="16px"
          />
          <div style={Styles.body}>
            {data &&
              data.map((experience: any, i: any) => {
                return (
                  <div
                    key={i}
                    style={{
                      marginBottom: '18px',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div style={{ width: '100%' }}>
                      <SingleExperience
                        experience={experience}
                        candidateId={candidate?._id}
                      />
                      {nocomments === false ? (
                        <></>
                      ) : (
                        <>
                          <ExperienceComment
                            experience={experience}
                            mergedData={experience?.mergedData}
                          />
                        </>
                      )}
                    </div>
                    {isRecruiterPage && isEditing && (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'start',
                          marginLeft: '8px',
                          gap: 4,
                          marginRight: '12px',
                        }}
                      >
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <Button
                            style={{
                              zIndex: '5',
                              position: 'relative',
                              backgroundColor: '#1A838C',
                              width: '20px',
                              minWidth: '20px',
                              height: '20px',
                            }}
                            className="border-primary"
                            shape="circle"
                            icon={
                              <img
                                style={{
                                  height: '9.5px',
                                  width: '9.5px',
                                  marginTop: '-6px',
                                }}
                                src={editPencil}
                                alt="editPencil"
                              />
                            }
                            onClick={() => handleClick(experience)}
                          />
                          <span
                            onClick={() => handleDelete(experience)}
                            style={{ cursor: 'pointer' }}
                          >
                            <img
                              src={DELETE_ICON}
                              alt="DELETE_ICON"
                              style={{ height: '20px', width: '20px' }}
                            />
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            {editContext?.edit && (
              <Button
                style={{
                  maxWidth: '100%',
                  marginBottom: '8px',
                  borderRadius: '15px',
                }}
                type="dashed"
                onClick={addNew}
                block
              >
                Add a new experience <PlusOutlined />
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Experience;
