import {
  Jobs,
  ActiveJobs,
  ArchivedJobs,
  PendingJobs,
  RejectedJobs,
  jobsActionTypes,
  STORE_JOBS,
  STORE_ACTIVE_JOBS,
  STORE_ARCHIVED_JOBS,
  STORE_PENDING_JOBS,
  STORE_REJECTED_JOBS,
  STORE_JOB,
  DELETE_JOB,
  NEW_CANDIDATES_AGAINT_JOB,
} from './types';
import _ from 'lodash';

import { updateObject } from '../../helpers/updateObject';

const initialState: Jobs = {
  jobs: [],
  activeJobs: [],
  archivedJobs: [],
  pendingJobs: [],
  rejectedJobs: [],
  newCandidates: [],
};

const storeJobs = (state: Jobs, action: any) => {
  return updateObject(state, { jobs: action.jobs });
};
const storeActiveJob = (state: Jobs, action: any) => {
  return updateObject(state, { activeJobs: action.activeJobs });
};
const storeArchivedJob = (state: Jobs, action: any) => {
  return updateObject(state, { archivedJobs: action.archivedJobs });
};
const storePendingJob = (state: Jobs, action: any) => {
  return updateObject(state, { pendingJobs: action.pendingJobs });
};
const storeRejectedJob = (state: Jobs, action: any) => {
  return updateObject(state, { rejectedJobs: action.rejectedJobs });
};
const storeNewCandidates = (state: Jobs, action: any) => {
  return updateObject(state, { newCandidates: action.candidates });
};
const storeJob = (state: Jobs, action: any) => {
  const findIndex = state.jobs.findIndex(
    (job: any) => job._id == action.job._id
  );
  if (findIndex > -1) {
    const updatedObject = _.merge(state.jobs[findIndex], action.job);
    console.log('Updated : ', updatedObject);
    const jobs = state.jobs;
    jobs[findIndex] = updatedObject;
    return updateObject(state, { jobs: jobs });
  } else return state;
};

const deleteJob = (state: Jobs, action: any) => {
  const findIndex = state.jobs.findIndex((job: any) => job._id == action.jobId);
  if (findIndex > -1) {
    const jobs = [...state.jobs];
    jobs.splice(findIndex, 1);
    state.jobs = jobs;
    return state;
  } else return state;
};

const jobsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case STORE_JOBS:
      return storeJobs(state, action);
    case STORE_ACTIVE_JOBS:
      return storeActiveJob(state, action);
    case STORE_ARCHIVED_JOBS:
      return storeArchivedJob(state, action);
    case STORE_PENDING_JOBS:
      return storePendingJob(state, action);
    case STORE_REJECTED_JOBS:
      return storeRejectedJob(state, action);
    case STORE_JOB:
      return storeJob(state, action);
    case DELETE_JOB:
      return deleteJob(state, action);
    case NEW_CANDIDATES_AGAINT_JOB:
      return storeNewCandidates(state, action);
    default:
      return state;
  }
};

export default jobsReducer;
