import React, { useState, useEffect } from 'react';
import PageTitle from '../../../../components/UI/PageTitle/PageTitle';

import {
  Row,
  Col,
  Tabs,
  Card,
  Table,
  Input,
  Button,
  Space,
  Tooltip,
  Switch,
  Popconfirm,
  Tag,
  Skeleton,
} from 'antd';
import {
  FolderOpenOutlined,
  SnippetsOutlined,
  CloseCircleFilled,
  DeleteOutlined,
  SearchOutlined,
  UserOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { Link, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Bounce, Fade } from 'react-awesome-reveal';
import ErrorCard from '../../../../components/UI/ErrorCard/ErrorCard';
import { generateUsers } from '../../../../faker/users';
import CompanyDrawer from '../../../../components/BackOffice/CompanyDrawer';
// import JobDrawer from '../partials/JobDrawer';
import { ColumnsType } from 'antd/es/table';
import { useAllCandidatesInBoQuery } from '../../../../graphql/generated/graphql';
import moment from 'moment';
interface Users {
  key: number;
  id: string;
  photo: string;
  fname: string;
  lname: string;
  link: JSX.Element;
  title: string;
  email: string;
  summary: string;
  location: string;
  status: string;
  jobCount: number;
  companyCount: number;
  createdAt: string;
}

const { TabPane } = Tabs;

const CandidateLists: React.FC = () => {
  const { url } = useRouteMatch();
  let displayContent = null;

  const newCandidates = useSelector((state: any) => state.jobs.newCandidates);

  console.log(newCandidates);

  const actionsHandler = (id: any) => {
    return (
      <Space>
        <Tooltip title="edit">
          <Button
            className="border-primary"
            shape="circle"
            icon={<EditOutlined className="text-primary" />}
          />
        </Tooltip>
        <Tooltip title="archive">
          <Popconfirm
            title="Are you sure archive this recruiter?"
            okText="Yes"
            cancelText="No"
          >
            <Button className="border-amber" shape="circle">
              <i className="dripicons-archive text-amber" />
            </Button>
          </Popconfirm>
        </Tooltip>
        <Popconfirm
          title="Are you sure delete this recruiter?"
          okText="Yes"
          cancelText="No"
        >
          <Button
            className="border-burgundy"
            shape="circle"
            icon={<DeleteOutlined className="text-burgundy" />}
          />
        </Popconfirm>
      </Space>
    );
  };

  const resumeHandler = (resume: any, status: any) => {
    if (resume) {
      return (
        <Button
          className="ant-btn-teal"
          shape="circle"
          onClick={() => window.open(resume, '_blank')}
        >
          <SnippetsOutlined />
        </Button>
      );
    } else {
      return <CloseCircleFilled className="text-light font-30" />;
    }
  };

  const sourceHandler = (source: any, status: any) => {
    return <Tag color="blue">{source}</Tag>;
  };

  const columns: ColumnsType<Users> = [
    {
      title: 'First name',
      dataIndex: 'firstName',
      key: 'firstName',
      fixed: 'left',
      width: 200,
      sorter: (a: any, b: any) => a.link.length - b.link.length,
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Last name',
      dataIndex: 'firstName',
      key: 'lastName',
      fixed: 'left',
      width: 200,
      sorter: (a: any, b: any) => a.link.length - b.link.length,
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Email address',
      dataIndex: 'email',
      key: 'email',
    },

    {
      title: 'Resume',
      dataIndex: 'resume',
      key: 'resume',
      width: 200,
      render: (resume, row) => resumeHandler(resume, row.status),
    },
    {
      title: 'Source',
      dataIndex: 'source',
      key: 'source',
      width: 200,
      render: (source, row) => sourceHandler(source, row.status),
    },
    {
      title: 'Created on',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt, row) =>
        moment(parseInt(createdAt)).format('MMMM DD,  YYYY'),
    },
  ];

  const generateActiveCandidatesTableData = () => {
    if (newCandidates) {
      const candidatesData = newCandidates?.map((candidate: any) => {
        return {
          key: candidate._id,
          id: candidate._id,
          fullName: candidate.firstName + ' ' + candidate.lastName,
          link: (
            <Link
              to={`${url}/${candidate._id}`}
            >{`${candidate.firstName} ${candidate.lastName}`}</Link>
          ),
          title: candidate.title,
          resume: candidate.resume,
          status: candidate.isProfileCompleted.isProfileCompleted
            ? 'complete'
            : 'incomplete',
          email: candidate?.user?.email,
          source: candidate?.user?.source
            ? candidate?.user?.source
            : candidate?.source,
          createdAt: moment(parseInt(candidate.createdAt)).format(
            'MMMM DD,  YYYY'
          ),
        };
      });
      console.log('***** ', candidatesData);
      return candidatesData;
    }
  };

  console.log(newCandidates);

  if (newCandidates) {
    const candidates = newCandidates.map(
      (candidate: any) => candidate.candidateListing
    );
    const newCandidate = candidates.map((cand: any) => {
      return { ...cand, ...cand.user };
    });

    console.log(candidates);
    displayContent = (
      <Table
        sortDirections={['ascend', 'descend', 'ascend']}
        className="table-striped"
        columns={columns}
        dataSource={newCandidate}
        size="small"
        pagination={{ pageSize: 20 }}
        scroll={{ x: 1300 }}
      />
    );
  }

  return (
    <>
      <PageTitle
        back
        title="New candidates"
        btnText="Add candidate"
        to={`${url}/add`}
        btnActive={true}
      />

      <Row justify="center" className="pb-4" gutter={16}>
        <Col span={24}>
          <Fade>
            <Card>
              <Tabs defaultActiveKey="1">
                <TabPane
                  tab={
                    <span>
                      <FolderOpenOutlined /> New candidates
                      {newCandidates?.length && (
                        <span className="tab-pane-count">
                          {newCandidates?.length}
                        </span>
                      )}
                    </span>
                  }
                  key="1"
                >
                  {displayContent}
                </TabPane>
              </Tabs>
            </Card>
          </Fade>
        </Col>
      </Row>
    </>
  );
};

export default CandidateLists;
