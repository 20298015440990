import React, { useState } from 'react';
import BasicInfo from './BasicInfo';
import { Avatar, Col, Divider, Image, Modal, Row, Skeleton } from 'antd';
import TopBanner from '../../../../../../assets/topBanner.png';
import { PlayIcon, viewProfile } from 'assets/icons/DetailedProfile';
import Benchmark from './Benchmark';

const Styles = {
  root: {
    height: '100%',
    borderRadius: 10,
    overflow: 'hidden',
  },
  basicInfo: {},
  horizontalBar: {
    marginTop: 10,
    marginBottom: 12,
  },
  topWrapper: {
    height: '100px',
    backgroundSize: 'cover',
    paddingRight: 15,
    paddingTop: 15,
  },
  avatar: {
    display: 'flex',
    alignItems: 'center',
    marginTop: -36,
    flex: 1,
    width: '329.8px',
    height: '109.8px',
    marginBottom: 2,
  },
  playIcon: {
    bottom: 17,
    right: 7,
    cursor: 'pointer',
  },
  actionsWrapper: {},
};

interface PropsType {
  candidate: any;
  showActions: boolean;
  match?: any;
  showBenchmark?: boolean;
  showActivation?: boolean;
  loading?: any;
}

const PersonalInfo: React.FC<PropsType> = ({
  candidate,
  showActions,
  match,
  showBenchmark,
  showActivation,
  loading,
}) => {
  const [open, setOpen] = useState(false);
  const [openVideo, setOpenVideo] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [imagePreview, setImagePreview] = useState(false);
  const [profileImage, setProfileImage] = useState(
    candidate?.profilePicture || ''
  );
  const placeholderImage =
    'https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255532-stock-illustration-profile-placeholder-male-default-profile.jpg';

  React.useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  }, [candidate]);

  React.useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  const handleImageClick = () => setImagePreview(true);
  const imagePreviewCancel = () => setImagePreview(false);

  const handleImageError = () => {
    setProfileImage(placeholderImage);
  };

  return (
    <>
      {!isLoading ? (
        <div
          style={
            showActions ? Styles.root : { ...Styles.root, minHeight: '378px' }
          }
        >
          <div>
            <div
              style={{
                ...Styles.topWrapper,
                backgroundImage: `url(${
                  candidate?.coverPhoto ? candidate?.coverPhoto : TopBanner
                })`,
              }}
            ></div>
            <div
              style={{
                display: 'flex',
                marginLeft: '1em',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ ...Styles.avatar }}>
                <div
                  style={{
                    border: '8px solid white',
                    borderRadius: '80px',
                    width: '113px',
                    height: '100%',
                    backgroundSize: 'cover',
                    overflow: 'hidden',
                    backgroundPosition: 'center',
                    backgroundImage: `url(${profileImage})`,
                  }}
                  className="img-preview-set"
                  onClick={handleImageClick}
                >
                  <Image
                    style={{
                      borderRadius: 220,
                      backgroundSize: 'cover',
                      overflow: 'hidden',
                      visibility: 'hidden',
                      height: '100%',
                      width: '100%',
                    }}
                    preview={false}
                    width={323.8}
                    height={10.8}
                    src={profileImage}
                    onError={handleImageError}
                  />
                  <div className="overlay"></div>
                  <div className="preview-text">
                    <img src={viewProfile} alt="" /> Preview
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '24px',
                    marginLeft: '48px',
                  }}
                >
                  <BasicInfo candidate={candidate} />
                </div>
              </div>

              {showBenchmark && (
                <Benchmark candidate={candidate} match={match} />
              )}
            </div>
          </div>
          <div>
            <div
              style={{
                marginTop: '2em',
                paddingLeft: '2.5em',
              }}
            ></div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <Modal
        className="image-preview-modal"
        open={imagePreview}
        title={candidate?.firstName + ' ' + candidate?.lastName}
        footer={null}
        onCancel={imagePreviewCancel}
        centered
      >
        <img
          src={profileImage}
          style={{
            width: '100%',
            height: '50vh',
            objectFit: 'contain',
            marginTop: '5px',
          }}
        />
      </Modal>
    </>
  );
};

export default PersonalInfo;
