/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import './PageTitle.scss';
import { Row, Col, Button, Typography } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { ArrowLeftOutlined, HddOutlined } from '@ant-design/icons';
import { log } from 'console';
import { EditContext } from 'containers/BackOffice/Candidates/Profile/Context/EditContext';
const { Title } = Typography;

interface Props {
  back?: boolean;
  title: string;
  btnText?: string;
  to?: string;
  btnActive?: boolean;
  additionalBtn?: boolean;
  btnCallback?: () => void;
  backHandler?: any;
  handleGoBack?: any;

  btnNavigateActive?: boolean;
  btnPrevText?: string;
  btnNextText?: string;
  btnHandleNavigate?: any;
  btnPrevDisabled?: boolean;
  btnNextDisabled?: boolean;
  fromProfile?: boolean;
  jobId?: string;
  isCancel?: boolean;
  handleCancel?: any;
}
const PageTitle: React.FC<Props> = (props) => {
  const editContext = useContext<any>(EditContext);
  const history = useHistory();
  const location: any = useLocation();
  const goBack = () => {
    if (props?.isCancel && editContext?.edit) {
      if (props?.handleCancel && typeof props.handleCancel === 'function') {
        if (editContext.isDataChanged == true) {
          props.handleCancel();
        } else {
          editContext.cancelEditProfile();
          props.handleGoBack();
        }
      }
      return 1;
    }
    if (props.handleGoBack) {
      props.handleGoBack();
    } else if (location?.state?.fromURL && location?.state?.candidateId) {
      const { fromURL, ...rest } = location?.state;
      const previousStates = rest;
      history.push({
        pathname: location?.state?.fromURL,
        state: previousStates,
      });
    } else if (location?.state?.fromURL) {
      history.push(location.state.fromURL);
    } else {
      history.push('/backoffice/jobs');
    }

    if (props && props.fromProfile) {
      if (editContext) {
        editContext.cancelEditProfile();
      }
    }
  };

  return (
    <div className="page-title-box">
      <Row justify="space-between" gutter={16}>
        <Col span={!props.fromProfile ? 8 : 16}>
          {props.back && (
            <Button
              className="back-btn"
              shape="round"
              size="middle"
              icon={<ArrowLeftOutlined />}
              onClick={goBack}
            >
              Back
            </Button>
          )}
        </Col>
        <Col span={8}>
          <div className="text-center">
            <Title level={4} className="text-primary">
              {props.title}
            </Title>
            {props.children && !props.additionalBtn && (
              <h4 className="font-14 text-info">{props.children}</h4>
            )}
          </div>
        </Col>
        <Col span={8}>
          <div
            className="text-right"
            style={{ display: 'flex', justifyContent: 'end' }}
          >
            {props.btnActive && (
              <>
                {props.to && (
                  <Link to={props.to || ''}>
                    <Button
                      className="ant-btn-teal"
                      shape="round"
                      size="middle"
                      style={{
                        fontSize: '16px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {props.btnText}
                    </Button>
                  </Link>
                )}
                {props.btnCallback && (
                  <Button
                    className="ant-btn-teal"
                    shape="round"
                    size="middle"
                    style={{ fontSize: '16px' }}
                    onClick={props.btnCallback}
                  >
                    {props.btnText}
                  </Button>
                )}
              </>
            )}
            {props.additionalBtn && props.children}
            {props.btnNavigateActive && (
              <>
                {props.btnHandleNavigate && (
                  <Button
                    className="ant-btn-teal"
                    shape="round"
                    size="middle"
                    onClick={() => props.btnHandleNavigate('prev')}
                    disabled={props.btnPrevDisabled}
                  >
                    {props.btnPrevText}
                  </Button>
                )}
                {props.btnHandleNavigate && (
                  <Button
                    style={{ marginLeft: 10 }}
                    className="ant-btn-teal"
                    shape="round"
                    size="middle"
                    onClick={() => props.btnHandleNavigate('next')}
                    disabled={props.btnNextDisabled}
                  >
                    {props.btnNextText}
                  </Button>
                )}
              </>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PageTitle;
