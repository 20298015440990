import { Tag, Typography } from 'antd';
import { ProfessionalIcon } from 'assets/icons/DetailedProfile';
import React, { useContext, useState } from 'react';
import { EditContext } from '../Context/EditContext';
import { ProfessionalInterestsDrawer } from '../Edit';
import { SectionTitle } from '../Shared';

interface PropsType {
  candidate: any;
}
let InterestedJobFileds = [
  { _id: '608baaa02b3c3c6eea54953d', title: 'Finance', selected: false },
  { _id: '608baaaf2b3c3c6eea54953e', title: 'ITC', selected: false },
  { _id: '608baac02b3c3c6eea54953f', title: 'Engineering', selected: false },
  { _id: '608baacb2b3c3c6eea549540', title: 'Logistics', selected: false },
  {
    _id: '608baaea2b3c3c6eea549541',
    title: 'Sales & Marketing',
    label1: '&',
    label2: 'Marketing',
    selected: false,
  },
  {
    _id: '608bab042b3c3c6eea549542',
    title: 'Professional Services',
    label1: 'Services',
    selected: false,
  },
  { _id: '608bab102b3c3c6eea549543', title: 'Retail', selected: false },
  {
    _id: '608bab272b3c3c6eea549544',
    title: 'Healthcare & Medical',
    label1: '&',
    label2: 'Medical',
    selected: false,
  },
  {
    _id: '608bab3d2b3c3c6eea549545',
    title: 'Industry & Energy',
    label1: '&',
    label2: 'Energy',
    selected: false,
  },
  { _id: '608bab4e2b3c3c6eea549546', title: 'Management', selected: false },
  { _id: '608bab602b3c3c6eea549547', title: 'HR & Legal', selected: false },
  { _id: '608bab6f2b3c3c6eea549548', title: 'Other', selected: false },
];
const Styles = {
  root: {
    padding: 15,
    // border: 'solid 1px #364972',
    boxShadow: '0px 0px 4px #00000040 ',
    borderRadius: 10,
    paddingBottom: 5,
  },
  body: {
    paddingLeft: 24,
    marginTop: 10,
  },
};

const ProfessionalIntrests: React.FC<PropsType> = ({ candidate }) => {
  const [open, setOpen] = useState(false);
  const editContext = useContext(EditContext);
  const [professionalInterests, setProfessionalInterests] = useState([]);
  const { edit }: any = editContext;
  const handleClose = () => {
    setOpen(false);
  };

  const openEdit = () => {
    setOpen(true);
  };

  const Interest = ({ title }: any) => {
    return (
      <Tag
        style={{
          border: 'none',
          marginRight: 10,
          marginBottom: 10,
          backgroundColor: '#2E4A79',
          borderRadius: 20,
          color: 'white',
          paddingTop: 5,
          paddingBottom: 5,
          paddingLeft: 10,
          paddingRight: 10,
        }}
      >
        {title}
      </Tag>
    );
  };
  React.useEffect(() => {
    const filteredIntreset = candidate?.professionalInterests?.filter(
      (interest: any) =>
        InterestedJobFileds?.some(
          (sector: any) => sector?.title === interest?.title
        )
    );

    setProfessionalInterests(filteredIntreset);
  }, [candidate, InterestedJobFileds]);
  return (
    <>
      <ProfessionalInterestsDrawer
        candidate={candidate}
        open={open}
        handleClose={handleClose}
      />
      {(professionalInterests?.length > 0 || edit) && (
        <div style={Styles.root}>
          <SectionTitle
            actionHandler={openEdit}
            title="Target job fields"
            icon={ProfessionalIcon}
            fontSize="16px"
            fontWeight={'600'}
            actionType="edit"
          />
          <div style={Styles.body}>
            {
              // candidate?.professionalInterests != null &&

              professionalInterests?.map((interest: any, index: any) => (
                <Interest key={index} title={interest.title} />
              ))
            }
          </div>
        </div>
      )}
    </>
  );
};

export default ProfessionalIntrests;
