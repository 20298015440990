export interface Skills {
  activeSkills: any;
  pendingSkills: any;
  archivedSkills: any;
  deletedSkills: any;
}
export interface SkillsState {
  skills: Skills;
}

export const STORE_ACTIVE_SKILLS = 'STORE_ACTIVE_SKILLS';
export const STORE_PENDING_SKILLS = 'STORE_PENDING_SKILLS';
export const STORE_ARCHIVED_SKILLS = 'STORE_ARCHIVED_SKILLS';
export const STORE_DELETED_SKILLS = 'STORE_DELETED_SKILLS';

export const UPDATE_ACTIVE_SKILL_TITLE = 'UPDATE_ACTIVE_SKILL_TITLE';
export const UPDATE_PENDING_SKILL_TITLE = 'UPDATE_PENDING_SKILL_TITLE';

export const MOVED_TO_ACTIVE_SKILLS_FROM_NEW_SKILLS =
  'MOVED_TO_ACTIVE_SKILLS_FROM_NEW_SKILLS';
export const MOVED_TO_ACTIVE_SKILLS_FROM_PENDING_SKILLS =
  'MOVED_TO_ACTIVE_SKILLS_FROM_PENDING_SKILLS';
export const MOVED_TO_ACTIVE_SKILLS_FROM_ARCHIVED_SKILLS =
  'MOVED_TO_ACTIVE_SKILLS_FROM_ARCHIVED_SKILLS';
export const MOVED_TO_ARCHIVED_SKILLS_FROM_ACTIVE_SKILLS =
  'MOVED_TO_ARCHIVED_SKILLS_FROM_ACTIVE_SKILLS';
export const MOVED_TO_DELETED_SKILLS_FROM_ACTIVE_SKILLS =
  'MOVED_TO_DELETED_SKILLS_FROM_ACTIVE_SKILLS';
export const MOVED_TO_DELETED_SKILLS_FROM_PENDING_SKILLS =
  'MOVED_TO_DELETED_SKILLS_FROM_PENDING_SKILLS';
export const MOVED_TO_DELETED_SKILLS_FROM_ARCHIVED_SKILLS =
  'MOVED_TO_DELETED_SKILLS_FROM_ARCHIVED_SKILLS';

interface storeActiveSkills {
  type: typeof STORE_ACTIVE_SKILLS;
  activeSkills: Array<object>;
}

interface storePendingSkills {
  type: typeof STORE_PENDING_SKILLS;
  pendingSkills: Array<object>;
}

interface storeArchivedSkills {
  type: typeof STORE_ARCHIVED_SKILLS;
  archivedSkills: Array<object>;
}

interface storeDeletedSkills {
  type: typeof STORE_DELETED_SKILLS;
  deletedSkills: Array<object>;
}

interface updateActiveSkillTitle {
  type: typeof UPDATE_ACTIVE_SKILL_TITLE;
  skillId: string;
  title: string;
}

interface updatePendingSkillTitle {
  type: typeof UPDATE_PENDING_SKILL_TITLE;
  skillId: string;
  title: string;
}
interface movedToActiveSkillsFromNewSkills {
  type: typeof MOVED_TO_ACTIVE_SKILLS_FROM_NEW_SKILLS;
  skill: object;
}

interface movedToActiveSkillsFromPendingSkills {
  type: typeof MOVED_TO_ACTIVE_SKILLS_FROM_PENDING_SKILLS;
  skillId: string;
}

interface movedToActiveSkillsFromArchivedSkills {
  type: typeof MOVED_TO_ACTIVE_SKILLS_FROM_ARCHIVED_SKILLS;
  skillId: string;
}

interface movedToArchivedSkillsFromActiveSkills {
  type: typeof MOVED_TO_ARCHIVED_SKILLS_FROM_ACTIVE_SKILLS;
  skillId: string;
}

interface movedToDeletedSkillsFromActiveSkills {
  type: typeof MOVED_TO_DELETED_SKILLS_FROM_ACTIVE_SKILLS;
  skillId: string;
}

interface movedToDeletedSkillsFromPendingSkills {
  type: typeof MOVED_TO_DELETED_SKILLS_FROM_PENDING_SKILLS;
  skillId: string;
}

interface movedToDeletedSkillsFromArchivedSkills {
  type: typeof MOVED_TO_DELETED_SKILLS_FROM_ARCHIVED_SKILLS;
  skillId: string;
}

export type skillsActionTypes =
  | storeActiveSkills
  | storePendingSkills
  | storeArchivedSkills
  | storeDeletedSkills
  | updateActiveSkillTitle
  | updatePendingSkillTitle
  | movedToActiveSkillsFromNewSkills
  | movedToActiveSkillsFromPendingSkills
  | movedToActiveSkillsFromArchivedSkills
  | movedToArchivedSkillsFromActiveSkills
  | movedToDeletedSkillsFromActiveSkills
  | movedToDeletedSkillsFromPendingSkills
  | movedToDeletedSkillsFromArchivedSkills;
