import React from 'react';
import './Buttons.scss';
import { Tooltip, Button } from 'antd';

interface Props {
  type: string;
  enableToolTip?: boolean;
  button?: boolean;
  customRender?: boolean;
  CustomRenderEl?: any;
  icon?: any;
  toolTipTitle?: string;
  onClick?: Function;
}

const Buttons: React.FC<Props> = ({
  type,
  enableToolTip = true,
  customRender = false,
  CustomRenderEl,
  button = false,
  icon,
  toolTipTitle,
  onClick,
}) => {
  return (
    <>
      {enableToolTip ? (
        <Tooltip title={toolTipTitle}>
          {button ? (
            <Button
              className={`${type}`}
              shape="circle"
              onClick={() => (onClick ? onClick() : null)}
              icon={icon}
            />
          ) : (
            <>
              {customRender ? (
                <CustomRenderEl />
              ) : (
                <div
                  className={`${type}`}
                  onClick={() => (onClick ? onClick() : null)}
                ></div>
              )}
            </>
          )}
        </Tooltip>
      ) : (
        <div
          className={`${type}`}
          onClick={() => (onClick ? onClick() : null)}
        ></div>
      )}
    </>
  );
};

export default Buttons;
