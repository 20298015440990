import React from 'react';
const RecruiterContent = (RecArr: any) => {
  console.log('Content function', RecArr);
  return (
    <>
      {RecArr?.recruitersDetail?.map((R: any, index: any) => (
        <div
          key={index}
          style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}
        >
          <img
            src={
              R?.profilePic
                ? R?.profilePic
                : 'https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255532-stock-illustration-profile-placeholder-male-default-profile.jpg'
            }
            alt={R?.fullName}
            className="rounded-circle-cmp thumb-md img-thumbnail-rec"
          />
          <div style={{ marginLeft: '10px' }}>
            <p
              style={{
                margin: '0',
                color: '#012653',
                fontSize: 14,
                fontWeight: 400,
              }}
            >
              {R?.fullName}
            </p>
            <p
              style={{
                margin: '0',
                color: '#495057',
                fontSize: 14,
                fontWeight: 400,
              }}
            >
              {R?.title}
            </p>
          </div>
        </div>
      ))}
    </>
  );
};

export default RecruiterContent;
