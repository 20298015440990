import React from 'react';
import './Footer.scss';

const Footer: React.FC<{ footer: boolean }> = ({ footer }) => {
  return (
    <footer
      className={footer ? 'footer' : ' text-center '}
      style={{ marginBottom: '30px', fontSize: '14px' }}
    >
      <p className=" my-0 text-burgundy">
        © {new Date().getFullYear()} Blonk Group. All rights reserved.
      </p>
    </footer>
  );
};

export default Footer;
