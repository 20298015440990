import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './store';
import { BrowserRouter } from 'react-router-dom';
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  HttpLink,
} from 'apollo-boost';
import { getMainDefinition } from 'apollo-utilities';
import { split } from 'apollo-link';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { ApolloProvider } from '@apollo/react-hooks';
import { WebSocketLink } from 'apollo-link-ws';
import './index.scss';
import App from './App';

const { store } = configureStore();
const GRAPHQL_ENDPOINT = 'wss://api-v3.blonk.co/graphql';

const clientSocket = new SubscriptionClient(GRAPHQL_ENDPOINT, {
  reconnect: true,
});

const linkT = new WebSocketLink(clientSocket);

const httpLink = new HttpLink({ uri: process.env.REACT_APP_BASE_URL });

const authLink = new ApolloLink((operation, forward) => {
  // Retrieve the authorization token from local storage.
  const token = localStorage.getItem('accessToken');

  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
  });

  // Call the next link in the middleware chain.
  return forward(operation);
});

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  linkT,
  httpLink
);

const client = new ApolloClient({
  link: authLink.concat(link), // Chain it with the HttpLink
  cache: new InMemoryCache({
    addTypename: false,
  }),
});

const app = (
  <ApolloProvider client={client}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </ApolloProvider>
);

ReactDOM.render(app, document.getElementById('root'));
